import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import enLocale from "date-fns/locale/he";

const DefaultDatePicker = ({
  input,
  placeholder,
  meta: { touched, error },
  disabled,
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
      <KeyboardDatePicker
        autoOk={true}
        clearable
        margin="dense"
        inputVariant="outlined"
        ampm={false}
        disabled={disabled}
        format="dd/MM/yyyy"
        value={input.value === "" || !input.value ? null : input.value}
        onChange={(x) => input.onChange(x)}
        label={placeholder}
        fullWidth
        showTodayButton
        error={touched && error}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DefaultDatePicker;
