import React, { useEffect, useState } from "react";
import Table from "../../SivanPlusTable/SivanPlusTable";
import { connect } from "react-redux";
import {
  Container,
  Title,
  Body,
  ReceiptDetails,
  Row,
  InputsContainer,
  customStyles,
} from "./drivingPermits.styles";
import {
  getAll,
  create,
  allDrivingPermits,
} from "../../../actions/drivingPermits";
import { getLicenseTypes } from "../../../actions/licenseTypes";
import useLicenseTypes from "../../useComponents/useLicenseTypes";
import Button from "../../UiComponents/Buttons/Default";
import Select from "react-select";

const pickupColumns = [
  {
    Header: "שם",
    accessor: "name",
  },
  {
    Header: "סוג רישיון",
    accessor: "licenseType",
  },
  {
    id: "delete",
    Header: "מחק",
  },
];

const DrivingPermits = ({
  getAll,
  isLoaded,
  getLicenseTypes,
  licenseTypes,
  create,
  drivingPermits,
  allDrivingPermits,
}) => {
  const [branchOptions, setBranchOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [selectedDrivingPermit, setSelectedDrivingPermit] = useState(null);
  const [data, setData] = useState([]);
  const [drivingPermitsOptions, setDrivingPermitsOptions] = useState([]);
  // const [licenseTypesOptions, setLicenseTypesOptions] = useState([]);
  const licenseTypesOptions = useLicenseTypes(
    isLoaded,
    getLicenseTypes,
    licenseTypes
  );

  useEffect(() => {
    async function fetchData() {
      await getAll();
      const buba = await allDrivingPermits();
      setDrivingPermitsOptions(
        buba.map((x) => ({ value: x.id, label: x.name }))
      );
    }
    fetchData();
  }, []);

  useEffect(() => {
    setData(drivingPermits);
  }, [drivingPermits]);

  const onRowClick = async (rowObject) => {
    console.log(rowObject);
  };

  return (
    <Container>
      <Title>היתרי נהיגה</Title>
      <Body>
        <ReceiptDetails>
          <Row>
            <InputsContainer>
              <Select
                value={selectedDrivingPermit}
                isMulti={true}
                isRtl={true}
                onChange={setSelectedDrivingPermit}
                options={drivingPermitsOptions}
                placeholder="בחר היתר"
                styles={customStyles}
              />
              <Select
                value={selected}
                isMulti={true}
                isRtl={true}
                onChange={setSelected}
                options={licenseTypesOptions}
                placeholder="בחר סוג רישיון"
                styles={customStyles}
              />
            </InputsContainer>
            <Button
              text="צור"
              disabled={selectedDrivingPermit === null || selected === null}
              onClick={() => create(selectedDrivingPermit, selected)}
            />
          </Row>
          <div style={{ marginTop: "5vh" }}>
            <Table
              data={data}
              componentColumns={pickupColumns}
              // onRowClick={this.onRowClick}
            />
          </div>
        </ReceiptDetails>
      </Body>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    licenseTypes: state.LicenseTypesReducer.licenseTypes,
    isLoaded: state.LicenseTypesReducer.isLoaded,
    drivingPermits: state.DrivingPermitsReducer.drivingPermits,
  };
}

export default connect(mapStateToProps, {
  getAll,
  getLicenseTypes,
  create,
  allDrivingPermits,
})(DrivingPermits);
