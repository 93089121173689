import permissionsApi from "../../../apis/permissions";
import { toast } from "react-toastify";

export const getPermissions = async (userId) => {
  const token = window.localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await permissionsApi.get(`/getUserPermissions/${userId}`, {
    headers,
  });

  return response.data;
};

export const updateUserPermissions = async (userId, permissions) => {
  const token = window.localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const response = await permissionsApi.patch(
    "/updateUserPermissions",
    {
      userId,
      permissions,
    },
    {
      headers,
    }
  );
  return response.data;
};
