import React, { useEffect, useState } from "react";
import { Collapse } from "react-collapse";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import { findActiveAgreement } from "../../../../utils/calculator";
import { getAgreementDetails } from "../../../../actions/agreementsActions";
import { connect } from "react-redux";
import { eventDurationInMinutes } from "../../../../utils/calculator";
const types = { 1: "שיעור", 2: "טסט", 3: "מבחן פנימי" };
const NewIncome = ({
  student,
  events,
  checked,
  setChecked,
  clearMany,
  chosenOpeningBalance,
  setChosenOpeningBalance,
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const [total, setTotal] = useState(events.reduce((a, b) => a + b.price, 0));
  const [isAll, setIsAll] = useState(true);
  const [addAgreementBalance, setAddAgreementBalance] = useState(true);
  const allEvents = events.map((x) => x.id);

  return (
    <div
      style={{
        direction: "rtl",
        textAlign: "right",
        marginTop: "1.5vh",
        flex: "0 30%",
      }}
    >
      <span
        onClick={() => setCollapsed(!collapsed)}
        style={{ cursor: "pointer" }}
      >
        {`${student.firstName} ${student.lastName}`} -
        {(addAgreementBalance ? student.activeAgreementOpeningBalance : 0) +
          total}
        ₪
        <i className={`fa fa-angle-${collapsed ? "up" : "down"}`} />
      </span>
      <Collapse isOpened={collapsed}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Checkbox
            color="primary"
            checked={isAll}
            onChange={() => {
              if (isAll) {
                setIsAll(false);
                clearMany(allEvents);
                setAddAgreementBalance(false);
                setTotal(0);
              } else {
                setChecked([...checked, ...allEvents]);
                setIsAll(true);
                setAddAgreementBalance(true);
                setTotal(events.reduce((a, b) => a + b.price, 0));
              }
            }}
          />
          הכל
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Checkbox
            color="primary"
            checked={addAgreementBalance}
            onChange={() => {
              if (addAgreementBalance) {
                setAddAgreementBalance(false);
                setChosenOpeningBalance(
                  chosenOpeningBalance.filter((x) => x !== student.studentId)
                );
              } else {
                setAddAgreementBalance(true);
                setChosenOpeningBalance([
                  ...chosenOpeningBalance,
                  student.studentId,
                ]);
              }
            }}
          />
          הסכם - {student.activeAgreementOpeningBalance}
        </div>
        {events.map((x) => (
          <div
            key={x.id}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Checkbox
              color="primary"
              checked={checked.includes(x.id)}
              onChange={() => {
                if (checked.includes(x.id)) {
                  setChecked(checked.filter((e) => e !== x.id));
                  setTotal(total - x.price);
                } else {
                  setChecked([...checked, x.id]);
                  setTotal(total + x.price);
                }
              }}
            />
            <div>
              <span>{`${moment(x.start).format("DD/MM/YYYY hh:mm")}, `}</span>
              <span>{`${x.price}₪, `}</span>
              <span>{`${eventDurationInMinutes(x.start, x.end)} דק, `}</span>
              <span>{`${types[x.eventType]}  על `}</span>
              <span>{x.vehicleNumber}</span>
            </div>
          </div>
        ))}
      </Collapse>
    </div>
  );
};

export default connect(null, {
  getAgreementDetails,
})(NewIncome);
