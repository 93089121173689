import React, { useState, useEffect } from "react";
import i18n from "../../../lib/i18n/i18n";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { Container, customStyles } from "./OdoMeterReport.styles";
import useVehicles from "../../useComponents/useVehicles";
import Table from "../../SivanPlusTable/SivanPlusTable";
import { getVehicles } from "../../../actions/vehicleActions";
import { getAllSchoolEvents } from "../../../actions/schedulerActions";
import { create, getAllVehicleReports } from "../../../actions/odometerActions";
import { eventDurationInMinutes } from "../../../utils/calculator";
import { DateColumnFilter } from "../../SivanPlusTable/filters";
import moment from "moment";
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const odometerColumns = [
  {
    Header: "תאריך",
    accessor: "date",
    Cell: ({ row: { original } }) => moment(original.date).format("DD/MM/YYYY"),
    Filter: DateColumnFilter,
    filter: (array, s, date) => {
      return array.filter((x) => moment(x.original.date).isSame(date, "day"));
    },
  },
  {
    Header: "התחלה",
    accessor: "start",
  },
  {
    Header: "סיום",
    accessor: "end",
  },
  {
    Header: "סהכ",
    accessor: "total",
  },
  {
    Header: "שיעורים",
    accessor: "lessons",
  },
  {
    Header: "מעשי",
    accessor: "tests",
  },
  {
    Header: "מפ",
    accessor: "internalTests",
  },
  {
    Header: "ממוצע",
    accessor: "avg",
  },
];

const OdoMeter = ({
  vehicles,
  isVehiclesLoaded,
  getVehicles,
  getAllVehicleReports,
  getAllSchoolEvents,
  ticketLessonTime,
}) => {
  const [vehicleReports, setVehicleReports] = useState([]);
  const [basicData, setBasicData] = useState([]);
  const [month, setMonth] = useState(new Date());
  const [chosenVehicleId, setChosenVehicleId] = useState(null);
  const vehicleOptions = useVehicles(isVehiclesLoaded, getVehicles, vehicles);

  useEffect(() => {
    async function fetchData() {
      if (chosenVehicleId) {
        const reports = await getAllVehicleReports(chosenVehicleId.value);
        if (reports.length > 0) {
          setBasicData(reports);
          setVehicleReports(await buildingTableData(reports));
        } else {
          setVehicleReports([]);
        }
      }
    }
    fetchData();
  }, [chosenVehicleId]);

  useEffect(() => {
    async function fetchData() {
      setVehicleReports(await buildingTableData(basicData));
    }
    fetchData();
  }, [month]);

  const buildingTableData = async (reports) => {
    if (reports.length > 0) {
      const today = new Date();
      const events = await getAllSchoolEvents(
        new Date(today.getFullYear(), new Date(month).getMonth(), 1),
        new Date(today.getFullYear(), new Date(month).getMonth() + 1, 0)
      );

      const filteredEvents = events.filter(
        (x) => x.vehicleId === chosenVehicleId.value.toString()
      );
      const odoMeterReportFilteredByMonth = reports.filter(
        (x) => new Date(x.date).getMonth() === new Date(month).getMonth()
      );
      const data = [];
      for (
        let index = 0;
        index < odoMeterReportFilteredByMonth.length;
        index++
      ) {
        const element = odoMeterReportFilteredByMonth[index];
        const nextElement =
          index === odoMeterReportFilteredByMonth.length - 1
            ? 0
            : odoMeterReportFilteredByMonth[
                (index + odoMeterReportFilteredByMonth.length + 1) %
                  odoMeterReportFilteredByMonth.length
              ];

        const lessons = filteredEvents.filter(
          (x) =>
            moment(x.start).isSame(new Date(element.date), "day") &&
            x.eventType === 1
        );
        let totalLessons = 0;
        for (let index = 0; index < lessons.length; index++) {
          const element = lessons[index];
          totalLessons +=
            eventDurationInMinutes(element.start, element.end) /
            ticketLessonTime;
        }
        const totalTests = filteredEvents.filter(
          (x) =>
            moment(x.start).isSame(new Date(element.date), "day") &&
            x.eventType === 2
        ).length;
        const totalInternalTests = filteredEvents.filter(
          (x) =>
            moment(x.start).isSame(new Date(element.date), "day") &&
            x.eventType === 3
        ).length;
        data.push({
          date: element.date,
          start: element.km,
          end: nextElement.km,
          total: nextElement.km - element.km || "",
          lessons: totalLessons,
          tests: totalTests,
          internalTests: totalInternalTests,
          avg: isNaN(
            (
              (nextElement.km - element.km) /
              (totalLessons + totalTests + totalInternalTests)
            ).toFixed(3)
          )
            ? ""
            : (
                (nextElement.km - element.km) /
                (totalLessons + totalTests + totalInternalTests)
              ).toFixed(3),
        });
      }

      return data;
    } else {
      return [];
    }
  };

  const handlePrint = useReactToPrint({
    content: () => document.getElementById("table-to-xls"),
  });

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "50vw",
        }}
      >
        <DatePicker
          selected={month}
          onChange={(date) => setMonth(date)}
          selectsEnd
          locale={i18n.language}
          dateFormat="MMMM"
          showMonthYearPicker
          showFullMonthYearPicker
        />

        <Select
          options={vehicleOptions}
          value={chosenVehicleId}
          onChange={setChosenVehicleId}
          placeholder="בחר"
          styles={customStyles}
          isRtl
        />
      </div>
      <br />
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="table-to-xls"
        filename="דוח ספידומטר"
        sheet="ספידומטר"
        buttonText="יצא אקסל"
      />
      <div style={{ cursor: "pointer" }} onClick={handlePrint}>
        <span>הדפס &nbsp;</span>
        <i className="fa fa-print" aria-hidden="true" />
      </div>
      <Table data={vehicleReports} componentColumns={odometerColumns} />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    vehicles: state.VehiclesReducer.vehicles,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    ticketLessonTime: state.session.user.ticketLessonTime,
  };
}

export default connect(mapStateToProps, {
  getVehicles,
  create,
  getAllVehicleReports,
  getAllSchoolEvents,
})(OdoMeter);
