import {
  EXPENSES_FETCH_FAILED,
  EXPENSES_FETCH_SUCCESS,
  EXPENSES_FETCH_STARTED,
  EXPENSE_CREATE_SUCCESS,
  EXPENSE_DELETE_SUCCESS,
  EXPENSE_UPDATE_SUCCESS,
} from "../constants/actionTypes";

const INITIAL_STATE = { isPending: false, expenses: [], isLoaded: false };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case EXPENSE_CREATE_SUCCESS:
      return {
        ...state,
        expenses: [...state.expenses, action.payload],
      };
    case EXPENSE_DELETE_SUCCESS:
      return {
        ...state,
        expenses: state.expenses.filter(
          (expense) => expense.id !== action.payload.id
        ),
      };
    case EXPENSE_UPDATE_SUCCESS:
      const expenses = state.expenses.map((expense) =>
        expense.id === action.payload.id
          ? { ...expense, ...action.payload }
          : expense
      );
      return { ...state, expenses };
    case EXPENSES_FETCH_SUCCESS:
      return { ...state, expenses: action.payload, isLoaded: true };
    case EXPENSES_FETCH_FAILED:
      return { ...state, isPending: false };
    case EXPENSES_FETCH_STARTED:
      return { ...state, isPending: true };
    default:
      return state;
  }
}
