import {
  FUNDING_BODIES_FETCH_FAILED,
  FUNDING_BODIES_FETCH_SUCCESS,
  FUNDING_BODIES_FETCH_STARTED,
  FUNDING_BODY_CREATE_SUCCESS,
  FUNDING_BODY_DELETE_SUCCESS,
  FUNDING_BODY_UPDATE_SUCCESS,
} from "../constants/actionTypes";

const INITIAL_STATE = { isPending: false, fundingBodies: [], isLoaded: false };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FUNDING_BODY_CREATE_SUCCESS:
      return {
        ...state,
        fundingBodies: [...state.fundingBodies, action.payload],
      };
    case FUNDING_BODY_DELETE_SUCCESS:
      return {
        ...state,
        fundingBodies: state.fundingBodies.filter(
          (fundingBody) => fundingBody.id !== action.payload.id
        ),
      };
    case FUNDING_BODY_UPDATE_SUCCESS:
      const fundingBodies = state.fundingBodies.map((fundingBody) =>
        fundingBody.id === action.payload.id
          ? { ...fundingBody, ...action.payload }
          : fundingBody
      );
      return { ...state, fundingBodies };
    case FUNDING_BODIES_FETCH_SUCCESS:
      return { ...state, fundingBodies: action.payload, isLoaded: true };
    case FUNDING_BODIES_FETCH_FAILED:
      return { ...state, isPending: false };
    case FUNDING_BODIES_FETCH_STARTED:
      return { ...state, isPending: true };
    default:
      return state;
  }
}
