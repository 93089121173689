import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .react-datepicker-wrapper {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    width: 100%;
    height: 6vh;
    margin-top: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__input-container {
    text-align: center;
  }

  .react-datepicker__input-container input {
    text-align: center;
  }

  .react-datepicker__input-container input {
    width: 100%;

    &::placeholder {
      text-align: left;
      padding-left: 1vw;
      color: #c2c2c2;
    }
  }

  .react-toggle-track {
    background-color: #f5f5f5 !important;
    border: 1px solid #e5e5e5;
    padding: 0.2vh;
  }

  .react-toggle--checked .react-toggle-thumb {
    background-color: #edca92;
  }
  .react-toggle-thumb {
    background-color: #e5e3e0;
    border: none;
  }
`;
