import {
  CLEAR_SESSION,
  SET_SESSION,
  UPDATE_SESSION,
} from "../constants/actionTypes";

const initialState = {
  isLoggedIn: false,
  user: { calendarStartTime: new Date(), calendarEndTime: new Date() },
  credentials: { idToken: "" },
};

const session = (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION:
      return {
        ...action.session,
        isLoggedIn: true,
      };

    case UPDATE_SESSION:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    case CLEAR_SESSION:
      return initialState;

    default:
      return state;
  }
};

export default session;
