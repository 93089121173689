import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Navbar from "../../../../../Navbar/Navbar";
import { Container, Button } from "./assignment.styles";
import {
  setStudentAssignments,
  getStudentAssignments,
} from "../../../../../../actions/textBooksActions";
import Chapter from "./Chapter/Chapter";

const Agreements = ({
  getAssignments,
  setStudentAssignments,
  studentId,
  getStudentAssignments,
  studentLicense,
}) => {
  const { t, i18n } = useTranslation("common");
  const [menuItems, setMenuItems] = useState([]);
  const [currentOpenedView, setCurrentOpenedView] = useState();
  const [assignmentStatus, setAssignmentStatus] = useState();
  const [assignmentsAndStatus, setAssignmentsAndStatus] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const studentAssignment = await getStudentAssignments(
        studentId,
        studentLicense
      );
      const categories = buildChaptersArray(studentAssignment);
      setAssignmentsAndStatus(studentAssignment);
      setAssignmentStatus(
        studentAssignment.map((x) => ({
          assignmentId: x.assignmentId,
          id: x.id,
          note: x.note,
          status: x.status,
          studentId: studentId,
        }))
      );

      setMenuItems(categories);
      setCurrentOpenedView(categories[0]);
    }
    fetchData();
  }, []);

  const renderChapters = () => {
    let chaptersComponents = [];
    for (let index = 0; index < menuItems.length; index++) {
      const element = menuItems[index];
      const assignments = assignmentsAndStatus.filter(
        (x) => x.categoryName === currentOpenedView
      );
      chaptersComponents.push(
        <Chapter
          key={element}
          assignments={assignments}
          chapterName={element}
          changeAssigmentStatus={changeAssigmentStatus}
          changeAssigmentNote={changeAssigmentNote}
          changeAssigmentDate={changeAssigmentDate}
        />
      );
    }

    return chaptersComponents;
  };

  const buildChaptersArray = (studentAssignmentArray) =>
    Array.from(
      new Set(studentAssignmentArray.map((item) => item.categoryName))
    );

  const changeAssigmentStatus = (id, newStatus) => {
    const assignments = assignmentStatus.map((x) =>
      x.assignmentId === id ? { ...x, status: newStatus } : x
    );
    setAssignmentStatus(assignments);
  };

  const changeAssigmentDate = (id, newStatus) => {
    const assignments = assignmentStatus.map((x) =>
      x.assignmentId === id ? { ...x, date: newStatus } : x
    );
    setAssignmentStatus(assignments);
  };

  const changeAssigmentNote = (id, e) => {
    const assignments = assignmentStatus.map((x) =>
      x.assignmentId === id ? { ...x, note: e.target.value } : x
    );
    setAssignmentStatus(assignments);
  };

  const saveAssigments = async () => {
    await setStudentAssignments(assignmentStatus);
  };

  return (
    <Container>
      <Navbar
        menuOptions={menuItems}
        currentView={currentOpenedView}
        setCurrentOpenedView={setCurrentOpenedView}
      />
      <div style={{ maxHeight: "60vh", overflow: "auto", width: "100%" }}>
        {renderChapters()}
      </div>
      <Button onClick={saveAssigments}>שמור</Button>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isAssignmentsLoaded: state.LearningAssignmentsReducer.isLoaded,
    learningAssignments: state.LearningAssignmentsReducer.learningAssignments,
  };
}

export default connect(mapStateToProps, {
  setStudentAssignments,
  getStudentAssignments,
})(Agreements);
