import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import i18n from "../../../../lib/i18n/i18n";
import EditAgreement from "../../Agreements/EditAgreement/EditAgreement";
import { connect } from "react-redux";
import EventStatusChange from "../../../Calendar/EventStatusChange/EventStatusChange";
import { Container, BalanceTable } from "./studentTicket.styles";
import Table from "../../../SivanPlusTable/SivanPlusTable";
import {
  getStudentEvents,
  getStudentEventCount,
  updateEventPrice,
} from "../../../../actions/schedulerActions";
import {
  updateIsChecked,
  sendPaymentRequest,
} from "../../../../actions/studentesActions";
import {
  getStudentTextBooks,
  fetchTextBook,
  setStudentBalance,
} from "../../../../actions/textBooksActions";
import {
  getAgreementDetails,
  getAgreements,
} from "../../../../actions/agreementsActions";
import { getStudentDiscountsAndObligations } from "../../../../actions/discountsAndObligationsActions";
import IncomeData from "../../../Financial/IncomesFromStudents/IncomeData/IncomeData";
import {
  getStudentIncomes,
  getClientIncomes,
} from "../../../../actions/incomesAction";
import {
  getPaymentRequestById,
  getSchoolFundingBodies,
} from "../../../../actions/fundingBodiesActions";
import moment from "moment";
import _ from "lodash";
import { getUsers } from "../../../../actions/userActions";
import { getLicenseTypes } from "../../../../actions/licenseTypes";
import { getVehicles } from "../../../../actions/vehicleActions";
import ReactTooltip from "react-tooltip";
import Button from "../../../UiComponents/Buttons/Default";
import NewLesson from "../../../Calendar/NewLesson/NewLesson";
import UpdateCharge from "./UpdateCharge/UpdateCharge";
import NewCharge from "../../../Calendar/NewCharge/NewCharge";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FadeLoader from "react-spinners/FadeLoader";
import EditEvent from "../../../Calendar/EditEvent/EditEvent";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import {
  eventPriceCalculator,
  findActiveAgreement,
} from "../../../../utils/calculator";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;
const eventTypes = {
  1: "שיעור",
  2: "טסט",
  3: "מבחן פנימי",
  4: "פגישה",
  5: "חשבונית/קבלה",
  6: "קבלה",
  7: "חשבונית",
  8: "חשבונית/קבלה זיכוי",
  9: "קבלה זיכוי",
  10: "חשבונית זיכוי",
  11: "הנחה",
  12: "חיוב",
};

const incomesTypes = { 0: 5, 1: 6, 2: 7, 3: 8, 4: 9, 5: 10, 6: 11, 7: 12 };

const eventStatusTypes = {
  0: "הזמנה",
  1: "בוצע",
  2: "בוצע ותוקבל",
  3: "בוטל",
  4: "בוטל וחויב",
};

const internalTestStatus = [
  { icon: "icons8-crying", text: "נכשל" },
  { icon: "icons8-happy", text: "עבר" },
  { icon: "icons8-crying", text: "נכשל" },
];

const testStatus = [
  { icon: "icons8-question", text: "בהזמנה" },
  { icon: "icons8-happy", text: "עבר" },
  { icon: "icons8-crying", text: "נכשל" },
];

let studentColumns = [
  {
    Header: dictionary.table.number,
    accessor: "number",
    Cell: ({ row: { original } }) => {
      return (
        <div>
          <p
            data-tip={original.notes}
            style={{ color: original.notes ? "red" : "black" }}
          >
            {original.number}
          </p>
          <ReactTooltip />
        </div>
      );
    },
  },
  {
    Header: dictionary.table.date,
    accessor: "start",
  },
  {
    Header: dictionary.table.eventType,
    accessor: "eventType",
  },
  {
    Header: dictionary.table.status,
    accessor: "eventStatus",
    Cell: ({ row: { original } }) => {
      if (
        original.data.eventType === 2 &&
        original.data.eventStatus !== 3 &&
        original.data.eventStatus !== 4
      ) {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className={testStatus[original.data.testStatus].icon} />
            <span>{testStatus[original.data.testStatus].text}</span>
          </div>
        );
      } else if (
        original.data.eventType === 3 &&
        original.data.eventStatus !== 3 &&
        original.data.eventStatus !== 4
      ) {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              className={
                internalTestStatus[original.data.internalTestStatus].icon
              }
            />
            <span>
              {internalTestStatus[original.data.internalTestStatus].text}
            </span>
          </div>
        );
      } else {
        return <span>{original.eventStatus}</span>;
      }
    },
  },
  {
    Header: "משך",
    accessor: "time",
  },
  {
    Header: "סוג רישיון",
    accessor: "license",
  },
  {
    Header: "תיאור רכב",
    accessor: "vehicleDescription",
  },
  {
    Header: "מורה",
    accessor: "teacher",
    Cell: ({ row: { original } }) => {
      if (
        original.teacher === "תיק לימוד תקול" ||
        original.teacher === "תיק לימוד נמחק" ||
        original.teacher === "undefined undefined"
      ) {
        return (
          <span style={{ fontWeight: "bold", color: "red" }}>
            תקלה נא לעדכן את האירוע
          </span>
        );
      } else {
        return <span>{original.teacher}</span>;
      }
    },
  },
  {
    Header: "נוצר ע״י",
    accessor: "createdBy",
  },
];

const StudentTicket = ({
  getStudentEvents,
  studentId,
  getStudentTextBooks,
  fetchTextBook,
  getAgreementDetails,
  getStudentEventCount,
  ticketShowCanceledAndCharged,
  ticketShowCanceled,
  getStudentIncomes,
  isUsersLoaded,
  users,
  getUsers,
  getLicenseTypes,
  getVehicles,
  getAgreements,
  phone,
  isChecked,
  id,
  updateIsChecked,
  isAllowedTicketCheck,
  getStudentDiscountsAndObligations,
  user,
  updateEventPrice,
  updateagreement,
  glasses,
  studentTicket,
  setStudentBalance,
  ticketLessonTime,
  sendPaymentRequest,
  studentname,
  getClientIncomes,
  getPaymentRequestById,
  getSchoolFundingBodies,
}) => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [studentTextBookData, setStudentTextBookData] = useState([]);
  const [isNewLessonModalOpen, setIsNewLessonModalOpen] = useState(false);
  const [fundingBodies, setFundingBodies] = useState([]);
  const [isProviderDetailsModalOpen, setProviderDetailsModalOpen] =
    useState(false);
  const [reCalculateUserPriceRunned, setReCalculateUserPriceRunned] =
    useState(false);
  const [isEditAgreementModalOpen, setEditAgreementModalIsOpen] =
    useState(false);
  const [providerData, setProviderData] = useState(false);
  const [textBookId, setTextBookId] = useState();
  const [isNewChargeModalOpen, setIsNewChargeModalOpen] = useState(false);
  const [ordersCount, setOrdersCount] = useState(0);
  const [balance, setBalance] = useState(0);
  const [balanceWithFutureOrders, setBalanceWithFutureOrders] = useState(0);
  const [testsCount, setTestsCount] = useState(0);
  const [internalTestsCount, setInternalTestsCount] = useState(0);
  const [lessonInAgreementTime, setLessonInAgreementTime] = useState(0);
  const [openingBalance, setOpeningBalance] = useState(0);
  const [openingBalanceTest, setOpeningBalanceTest] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [openingBalanceInternalTest, setOpeningBalanceInternalTest] =
    useState(0);
  const [isShowCanceled, setIsShowCanceled] = useState(false);
  const [openingBalanceLesson, setOpeningBalanceLesson] = useState(0);
  const [studentEvents, setStudentEvents] = useState([]);
  const [agreement, setAgreement] = useState("");
  const [isTicketChecked, setIsTicketChecked] = useState(isChecked);
  const [agreementData, setAgreementData] = useState({});
  const [textbookOptions, setTextbookOptions] = useState([]);
  const [selectedTextBook, setSelectedTextBook] = useState([]);
  const [createdBy, setCreatedBy] = useState("");
  const [lessonsWithFutureLessons, setLessonsWithFutureLessons] = useState(0);
  const [
    internalTestsWithFutureInternalTests,
    setInternalTestsWithFutureInternalTests,
  ] = useState(0);
  const [testsWithFutureTests, setTestsWithFutureTests] = useState(0);
  const [totalStudentDidntShowedUp, setTotalStudentDidntShowedUp] = useState(0);
  const [
    totalStudentDidntShowedUpAndCharged,
    setTotalStudentDidntShowedUpAndCharged,
  ] = useState(0);
  const [totalStudentDiscounts, setTotalStudentDiscounts] = useState(0);
  const [isPassedInternal, setIsPassedInternal] = useState(0);
  const [isPassedTest, setIsPassedTest] = useState(0);
  const [isEventStatusChangeModalOpen, setIsEventStatusChangeModalOpen] =
    useState(false);
  const [isEditLessonModalOpen, setIsEditLessonModalOpen] = useState(false);
  const [isIncomeDataOpen, setIsIncomeDataOpen] = useState(false);
  const [currEvent, setCurrEvent] = useState({});

  useEffect(() => {
    (async () => {
      const fetchedStundetTextBooks = await getStudentTextBooks(studentId);
      const options = fetchedStundetTextBooks.map((x) => ({
        value: x.textBookId,
        label: x.description || "",
      }));
      setTextbookOptions(options);
      setSelectedTextBook(options[options.length - 1]);
    })();

    if (
      studentTicket &&
      studentColumns.findIndex((x) => x.Header === "מחיר") === -1
    ) {
      studentColumns = [
        ...studentColumns,
        {
          Header: dictionary.table.price,
          accessor: "price",
          Cell: ({ row: { original } }) => {
            if (
              original.eventType === "חשבונית" ||
              original.eventType === "חשבונית זיכוי"
            ) {
              return (
                <span style={{ fontWeight: "bold" }}>{original.price}</span>
              );
            } else if (
              original.eventType === "קבלה" ||
              original.eventType === "חשבונית/קבלה" ||
              original.eventType === "הנחה" ||
              original.eventType === "חיוב"
            ) {
              return (
                <span style={{ color: "green", fontWeight: "bold" }}>
                  {original.price}
                </span>
              );
            } else {
              return (
                <span style={{ color: "red", fontWeight: "bold" }}>
                  {original.price}
                </span>
              );
            }
          },
        },
        {
          Header: dictionary.table.balance,
          accessor: "balance",
          Cell: ({ row: { original } }) => {
            return original.balance < 0 ? (
              <span style={{ color: "red", fontWeight: "bold" }}>
                {original.balance}
              </span>
            ) : (
              <span style={{ color: "green", fontWeight: "bold" }}>
                {original.balance}
              </span>
            );
          },
        },
      ];
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      await initTable();
      setIsLoading(false);
    }
    if (
      !isNewLessonModalOpen &&
      !isNewChargeModalOpen &&
      !isEditLessonModalOpen &&
      !reCalculateUserPriceRunned &&
      !isIncomeDataOpen &&
      !isEventStatusChangeModalOpen &&
      selectedTextBook.length !== 0
    )
      fetchData();
  }, [
    isUsersLoaded,
    isNewLessonModalOpen,
    isNewChargeModalOpen,
    reCalculateUserPriceRunned,
    selectedTextBook,
    isEditLessonModalOpen,
    isIncomeDataOpen,
    isEventStatusChangeModalOpen,
    isShowCanceled,
  ]);

  const reCalculateUserPrice = async (userEvents) => {
    setIsLoading(true);
    setReCalculateUserPriceRunned(true);
    const studentEventsFiltered = userEvents.filter(
      (x) => x.eventStatus !== 3 && x.textBookId === studentTextBookData.id
    );
    const calculates = [];
    studentEventsFiltered.map((x) => {
      calculates.push(checkIfEventPriceUpdateNeeded(x, studentEventsFiltered));
    });
    await Promise.all(calculates);
    setReCalculateUserPriceRunned(false);
    setIsLoading(false);
  };

  const checkIfEventPriceUpdateNeeded = async (x, studentEventsFiltered) => {
    const activeAgreement = findActiveAgreement(
      studentTextBookData.agreements,
      "startDate"
    );
    const agreementsDetails = await getAgreementDetails(
      activeAgreement.agreementId
    );
    const eventPrice = eventPriceCalculator(
      x,
      studentEventsFiltered,
      agreementsDetails,
      studentTextBookData
    );

    if (eventPrice !== x.price) {
      await updateEventPrice(x.id, eventPrice);
    }
  };

  const updateStudentIsChecked = async () => {
    if (isAllowedTicketCheck)
      if (await updateIsChecked(id, !isTicketChecked, studentId)) {
        setIsTicketChecked(!isTicketChecked);
      }
  };

  const openAgreement = async () => {
    if (updateagreement) {
      const activeAgreement = findActiveAgreement(
        studentTextBookData.agreements,
        "startDate"
      );
      const agreementsDetails = await getAgreementDetails(
        activeAgreement.agreementId
      );
      setAgreementData({ ...activeAgreement, ...agreementsDetails });

      setEditAgreementModalIsOpen(true);
    }
  };

  const getTextBookAgreementOpeningBalance = async (agreements) => {
    let openingBalance = 0;
    for (let index = 0; index < agreements.length; index++) {
      const element = agreements[index];
      const startDate = element.startDate ? element.startDate : new Date(2001);

      if (moment(new Date()).isAfter(startDate)) {
        const agreementsDetails = await getAgreementDetails(
          element.agreementId
        );

        const activeAgreementAmount = findActiveAgreement(
          agreementsDetails["amountAgreementDetails"],
          "from",
          new Date(element.startDate)
        );
        openingBalance += activeAgreementAmount?.price || 0;
      }
    }
    return openingBalance;
  };

  const initTable = async () => {
    if (!isUsersLoaded) {
      await getUsers();
    } else {
      try {
        const id = selectedTextBook.value;
        setTextBookId(id);
        let [
          userEvents,
          textBooksData,
          lessons,
          tests,
          internalTests,
          incomes,
          licenses,
          schoolVehicles,
          agreements,
          discountsAndObligations,
          bodies,
        ] = await Promise.all([
          getStudentEvents(studentId),
          fetchTextBook(id),
          getStudentEventCount(1, studentId, id, new Date()),
          getStudentEventCount(2, studentId, id, new Date()),
          getStudentEventCount(3, studentId, id, new Date()),
          getStudentIncomes(studentId),
          getLicenseTypes(),
          getVehicles(),
          getAgreements(),
          getStudentDiscountsAndObligations(studentId),
          getSchoolFundingBodies(),
        ]);
        setFundingBodies(bodies);
        setStudentEvents(userEvents.filter((x) => x.textBookId === id));
        // const data = _.uniq(
        //   userEvents.map((x) => x.fundingBodyStatus).filter((x) => x !== 0)
        // );
        const fundingBodyIncomes = await getClientIncomes(
          textBooksData.fundingBody
        );
        const paymentRequestsStudentTotal = {};
        for (let index = 0; index < fundingBodyIncomes.length; index++) {
          const element = fundingBodyIncomes[index];
          const paymentRequestData = await getPaymentRequestById(
            element.paymentRequestId
          );
          paymentRequestsStudentTotal[element.id] =
            JSON.parse(paymentRequestData[0].invoiceData).find(
              (x) => x.studentId === studentId
            ).total || 0;
        }
        incomes = [...incomes, ...fundingBodyIncomes];
        setStudentTextBookData(textBooksData);
        setAgreement(
          agreements.find(
            (x) => x.id === textBooksData.agreements[0].agreementId
          ).description
        );

        let studentBalance = parseFloat(textBooksData.openingBalance) || 0;
        let studentBalanceWithFutureBalance = 0;
        studentBalance -= await getTextBookAgreementOpeningBalance(
          textBooksData.agreements
        );
        setOrdersCount(lessons);
        setIsPassedInternal(textBooksData.passedInternalTest ? 1 : 0);
        setOpeningBalance(textBooksData.openingBalance);
        setOpeningBalanceTest(textBooksData.remainingTests);
        setOpeningBalanceInternalTest(textBooksData.remainingInternalTests);
        setTestsCount(tests + textBooksData.remainingTests);
        setInternalTestsCount(
          internalTests + textBooksData.remainingInternalTests
        );
        let eventCount = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const eventsThatNotHaveTextBook = [];
        for (let index = 0; index < userEvents.length; index++) {
          const event = userEvents[index];
          let isExists = false;
          for (let index = 0; index < textbookOptions.length; index++) {
            const textBook = textbookOptions[index];
            if (textBook.value === event.textBookId) isExists = true;
          }
          if (!isExists)
            eventsThatNotHaveTextBook.push({
              ...event,
              textbookIsMissing: true,
            });
        }

        let eventsFiltered = userEvents.filter((x) => x.textBookId === id);
        if (!ticketShowCanceledAndCharged)
          eventsFiltered = eventsFiltered.filter(
            (event) => event.eventStatus !== 4
          );

        if (isShowCanceled)
          eventsFiltered = eventsFiltered.filter(
            (event) => event.eventStatus === 3
          );
        else if (!ticketShowCanceled)
          eventsFiltered = eventsFiltered.filter(
            (event) => event.eventStatus !== 3
          );
        const vehicleLicenseType = schoolVehicles.find(
          (x) => x.id.toString() === textBooksData.vehicleId.toString()
        ).licenseType;
        const textbookLicense = licenses.find(
          (x) => x.id === parseInt(vehicleLicenseType)
        );

        let totalLessonsInMinutes = 0;
        let futureInternalTests = 0;
        let futureTests = 0;
        let totalFutureLessonsInMinutes = 0;
        let totalPaymentRecived = 0;
        let totalDidntShowedUp = 0;
        let totalDidntShowedUpAndCharged = 0;
        let totalDiscounts = 0;
        let testStatus = 0;
        let internalTestStatus = 0;
        let studentData = [...eventsFiltered, ...eventsThatNotHaveTextBook];

        if (studentTicket) {
          studentData = [
            ...incomes.filter(
              (y) =>
                y.textbookId === "[object Object]" ||
                y.textbookId === id.toString() ||
                textbookOptions.findIndex(
                  (x) => x.value.toString() === y.textbookId
                ) === -1 ||
                y.textbookId === null
            ),
            ...discountsAndObligations.filter(
              (y) => y.textbookId === id.toString() || y.textbookId === null
            ),
            ...studentData,
          ];
        }
        const tableEvents = _.sortBy(studentData, [
          function (o) {
            return o.start ? new Date(o.start) : new Date(o.date);
          },
        ]).map((x, i) => {
          if (x?.hasOwnProperty("clientId") && !x.isFundingBody) {
            if (
              x.documentType === 0 ||
              x.documentType === 1 ||
              x.documentType === 9 ||
              x.documentType === 8
            ) {
              studentBalance += x.amount;
              totalPaymentRecived += x.amount;
            } else if (x.documentType === 3 || x.documentType === 4) {
              studentBalance -= x.amount;
              totalPaymentRecived -= x.amount;
            }
            let teacherName = "לא הוזן מורה";
            let vehicleDescription = "לא הוזן רכב";
            if (x.textbookId && x.textbookId !== "[object Object]") {
              const teacher = users.find(
                (y) => y.id.toString() === x.teacherId?.toString()
              );
              teacherName = `${teacher?.firstName} ${teacher?.lastName}`;

              const vehicle = schoolVehicles.find(
                (y) => y.id.toString() === x.vehicleId?.toString()
              );
              vehicleDescription = vehicle?.description;
            }
            return {
              number: (eventCount[incomesTypes[x.documentType]] += 1),
              start: moment(x.date).format("DD-MM-YYYY HH:mm"),
              eventType: eventTypes[incomesTypes[x.documentType]],
              eventStatus: x.documentNumber,
              time: 0,
              price: x.amount,
              license: textbookLicense?.name,
              vehicleDescription: vehicleDescription,
              balance: studentBalance.toFixed(2),
              teacher: teacherName,
              notes: x.notes,
              type: "income",
              id: x.id,
              createdBy: x.createUsername,
              data: x,
            };
          } else if (x?.hasOwnProperty("isFundingBody") && x.isFundingBody) {
            studentBalance += paymentRequestsStudentTotal[x.id];
            totalPaymentRecived += paymentRequestsStudentTotal[x.id];
            let teacherName = "גוף מימון";
            let vehicleDescription = "גוף מימון";

            return {
              number: (eventCount[incomesTypes[x.documentType]] += 1),
              start: moment(x.date).format("DD-MM-YYYY HH:mm"),
              eventType: "תקבול ג.מ",
              eventStatus: x.documentNumber,
              time: 0,
              price: paymentRequestsStudentTotal[x.id],
              license: textbookLicense?.name,
              vehicleDescription: vehicleDescription,
              balance: studentBalance.toFixed(2),
              teacher: teacherName,
              notes: x.notes,
              type: "income",
              id: x.id,
              data: x,
            };
          } else if (x.hasOwnProperty("isDiscount")) {
            if (x.isDiscount) {
              totalDiscounts += x.amount;
              studentBalance += x.amount;
            } else {
              studentBalance -= x.amount;
            }

            let teacherName = "תיק לימוד תקול";
            let vehicleDescription = "תיק לימוד תקול";
            if (x.textbookId !== "[object Object]") {
              const teacher = users.find(
                (y) => y.id.toString() === x.teacherId?.toString()
              );
              teacherName = `${teacher?.firstName} ${teacher?.lastName}`;

              const vehicle = schoolVehicles.find(
                (y) => y.id.toString() === x.vehicleId?.toString()
              );
              vehicleDescription = vehicle?.description;
            }

            return {
              number: (eventCount[incomesTypes[x.isDiscount ? 6 : 7]] += 1),
              start: moment(x.date).format("DD-MM-YYYY HH:mm"),
              eventType: eventTypes[incomesTypes[x.isDiscount ? 6 : 7]],
              price: x.isDiscount ? x.amount : -x.amount,
              balance: studentBalance.toFixed(2),
              license: textbookLicense?.name,
              teacher: teacherName,
              eventStatus: "בוצע",
              vehicleDescription,
              notes: x.notes,
              data: { ...x, clientId: x.studentId },
              time: 0,
              type: "discount",
            };
          } else {
            try {
              const today = new Date();
              if (x.eventStatus !== 3) {
                if (
                  moment(x.start).isSameOrBefore(today) &&
                  x.eventStatus !== 0
                ) {
                  if (x.eventType === 1) {
                    totalLessonsInMinutes +=
                      Math.abs(new Date(x.end) - new Date(x.start)) /
                      (1000 * 60);
                  }

                  studentBalance -= x.price;
                } else {
                  if (x.eventType === 1) {
                    totalFutureLessonsInMinutes +=
                      Math.abs(new Date(x.end) - new Date(x.start)) /
                      (1000 * 60);
                  }
                  if (x.eventType === 2) {
                    futureTests += 1;
                  }
                  if (x.eventType === 3) {
                    futureInternalTests += 1;
                  }
                  studentBalanceWithFutureBalance -= x.price;
                }

                if (
                  x.eventStatus === 4 &&
                  x.cancelReason === "התלמיד לא הגיע"
                ) {
                  totalDidntShowedUp +=
                    Math.abs(new Date(x.end) - new Date(x.start)) /
                    (1000 * 60) /
                    ticketLessonTime;
                  totalDidntShowedUpAndCharged +=
                    Math.abs(new Date(x.end) - new Date(x.start)) /
                    (1000 * 60) /
                    ticketLessonTime;
                }
              } else if (x.cancelReason === "התלמיד לא הגיע") {
                totalDidntShowedUp +=
                  Math.abs(new Date(x.end) - new Date(x.start)) /
                  (1000 * 60) /
                  ticketLessonTime;
              }

              const teacher = users.find(
                (y) => y.id.toString() === x.teacherId?.toString()
              );
              const createUserName = users.find(
                (y) => y.id.toString() === x.userId?.toString()
              );
              const vehicle = schoolVehicles.find(
                (y) => y.id.toString() === x.vehicleId?.toString()
              );

              if (x.eventType === 2) testStatus = x.testStatus;
              if (x.eventType === 3) internalTestStatus = x.internalTestStatus;

              return {
                number: (eventCount[x.eventType] += 1),
                start: moment(x.start).format("DD-MM-YYYY HH:mm dddd"),
                eventType: eventTypes[x.eventType],
                eventStatus: `${eventStatusTypes[x.eventStatus]} ${
                  x.eventStatus === 3 || x.eventStatus === 4
                    ? `- ${x.cancelReason}`
                    : ""
                }`,
                time:
                  Math.abs(new Date(x.end) - new Date(x.start)) / (1000 * 60),
                teacher: x.hasOwnProperty("textbookIsMissing")
                  ? "תיק לימוד נמחק"
                  : `${teacher?.firstName} ${teacher?.lastName}`,
                license: textbookLicense?.name,
                vehicleDescription: vehicle?.description,
                price: x.price,
                notes: x.notes,
                balance: studentBalance.toFixed(2),
                type: "event",
                data: x,
                createdBy: `${createUserName?.firstName} ${createUserName?.lastName}`,
              };
            } catch (err) {
              return {
                number: "אירוע תקול",
                start: moment(x.start).format("DD-MM-YYYY HH:mm dddd"),
                eventType: eventTypes[x.eventType],
                eventStatus: "אירוע תקול",
                time: "אירוע תקול",
                teacher: "אירוע תקול",
                license: "אירוע תקול",
                vehicleDescription: "אירוע תקול",
                price: "אירוע תקול",
                notes: "אירוע תקול",
                balance: "אירוע תקול",
                data: x,
                type: "event",
              };
            }
          }
        });
        setIsPassedTest(testStatus);
        setIsPassedInternal(internalTestStatus);
        setTotalStudentDidntShowedUp(totalDidntShowedUp);
        setTotalStudentDidntShowedUpAndCharged(totalDidntShowedUpAndCharged);
        setTotalStudentDiscounts(totalDiscounts);
        setTotalPaid(totalPaymentRecived);
        setEvents(tableEvents);
        setBalance(studentBalance.toFixed(2));
        setBalanceWithFutureOrders(
          (studentBalance + studentBalanceWithFutureBalance).toFixed(2)
        );
        await setStudentBalance(id, studentBalance.toFixed(2));
        setLessonInAgreementTime(
          (textBooksData.remaininglessonsInMinutes + totalLessonsInMinutes) /
            ticketLessonTime
        );
        setInternalTestsWithFutureInternalTests(
          textBooksData.remainingInternalTests +
            internalTests +
            futureInternalTests
        );
        setTestsWithFutureTests(
          tests + futureTests + textBooksData.remainingTests
        );
        setLessonsWithFutureLessons(
          (textBooksData.remaininglessonsInMinutes + totalLessonsInMinutes) /
            ticketLessonTime +
            totalFutureLessonsInMinutes / ticketLessonTime
        );
        setOpeningBalanceLesson(textBooksData.remaininglessonsInMinutes);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onClickSendToStudent = async () => {
    const title = `כרטסת תלמיד ${studentname} תז ${studentId}`;
    await sendPaymentRequest(
      {
        name: `${user.firstName} ${user.lastName}`,
        schoolFullName: user.schoolFullName,
        schoolId: user.schoolId,
        bnNumber: user.bnNumber,
        logo: user.logo,
      },
      events,
      openingBalance,
      openingBalanceLesson,
      openingBalanceInternalTest,
      openingBalanceTest,
      ticketLessonTime,
      balance,
      lessonInAgreementTime,
      internalTestsCount,
      testsCount,
      balanceWithFutureOrders,
      lessonsWithFutureLessons,
      internalTestsWithFutureInternalTests,
      testsWithFutureTests,
      agreement,
      totalStudentDidntShowedUp,
      totalPaid,
      totalStudentDidntShowedUpAndCharged,
      totalStudentDiscounts,
      glasses,
      title
    );
  };

  const onRowClick = async (event, e, d) => {
    if (event.column.Header === "סטטוס") {
      if (
        event.original.type === "income" ||
        event.original.type === "discount"
      ) {
        setCurrEvent(event.original.data);
        setIsIncomeDataOpen(true);
      } else if (event.original.type === "event") {
        setCurrEvent(event.original.data);
        setIsEventStatusChangeModalOpen(true);
      }
    } else if (event.column.Header === "סוג אירוע") {
      if (event.original.type === "event") {
        setCurrEvent(event.original.data);
        setIsEditLessonModalOpen(true);
      } else if (event.original.type === "income") {
        setProviderData(event.original.data);
        setProviderDetailsModalOpen(true);
      }
    }
  };
  return (
    <Container>
      {isLoading && (
        <div
          style={{
            height: "73%",
            width: "82%",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 5454,
          }}
        >
          <FadeLoader size={150} color={"#123abc"} loading={true} />
        </div>
      )}
      <div
        style={{ display: "flex", flexDirection: "row", marginBottom: "3px" }}
      >
        <Toggle
          id="cheese-status"
          defaultChecked={false}
          onChange={() => setIsShowCanceled(!isShowCanceled)}
        />
        <label htmlFor="cheese-status">הראה מבוטלים</label>
        <IncomeData
          isModalOpen={isProviderDetailsModalOpen}
          closeModal={() => setProviderDetailsModalOpen(false)}
          providerData={providerData}
        />
        <Autocomplete
          id="combo-box-demo"
          options={textbookOptions}
          getOptionLabel={(option) => option.label}
          value={selectedTextBook}
          onChange={(event, value) => setSelectedTextBook(value)}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              label="בחר תיק לימוד"
              variant="outlined"
            />
          )}
        />
        <Button
          text="יצירת אירוע"
          onClick={() => setIsNewLessonModalOpen(true)}
          marginRight="1"
        />
        {studentEvents.length > 0 && (
          <Button
            text="חישוב חוזר"
            onClick={() => reCalculateUserPrice(studentEvents)}
            marginRight="1"
          />
        )}
        {user.createStudentIncome ? (
          <Button
            text="מסמך כספי"
            onClick={() => setIsNewChargeModalOpen(true)}
            marginRight="1"
          />
        ) : null}

        <Button text="הדפס" onClick={onClickSendToStudent} marginRight="1" />
      </div>
      {isNewLessonModalOpen && (
        <NewLesson
          isNewAgreementModalOpen={setIsNewLessonModalOpen}
          setNewAgreementModalIsOpen={() => setIsNewLessonModalOpen(false)}
          selectedEvent={{
            start: new Date(),
            slots: [{}, {}],
          }}
          step={user.calendarSpacingInMinutes}
        />
      )}
      {isEditAgreementModalOpen && (
        <EditAgreement
          isEditAgreementModalOpen={isEditAgreementModalOpen}
          setEditAgreementModalIsOpen={setEditAgreementModalIsOpen}
          agreementData={agreementData}
        />
      )}
      {isNewChargeModalOpen && (
        <NewCharge
          isNewAgreementModalOpen={isNewChargeModalOpen}
          setNewAgreementModalIsOpen={() => setIsNewChargeModalOpen(false)}
          studentIdFromTextBook={studentId}
          textBookId={textBookId}
        />
      )}
      {isEditLessonModalOpen && (
        <EditEvent
          isNewAgreementModalOpen={isEditLessonModalOpen}
          setNewAgreementModalIsOpen={setIsEditLessonModalOpen}
          step={40}
          selectedEvent={{
            ...currEvent,
            name: currEvent.name
              ? currEvent.name
              : `${currEvent.studentId} - ${currEvent.firstName} ${currEvent.lastName}`,
          }}
        />
      )}
      {isEventStatusChangeModalOpen && (
        <EventStatusChange
          isNewAgreementModalOpen={isEventStatusChangeModalOpen}
          setNewAgreementModalIsOpen={setIsEventStatusChangeModalOpen}
          selectedEvent={{
            ...currEvent,
            name: currEvent.name
              ? currEvent.name
              : `${currEvent.studentId} - ${currEvent.firstName} ${currEvent.lastName}`,
          }}
        />
      )}
      {isIncomeDataOpen && (
        <UpdateCharge
          isNewAgreementModalOpen={isIncomeDataOpen}
          setNewAgreementModalIsOpen={() => setIsIncomeDataOpen(false)}
          income={currEvent}
        />
      )}
      <BalanceTable>
        {(openingBalance > 0 ||
          openingBalanceLesson > 0 ||
          openingBalanceInternalTest > 0 ||
          openingBalanceTest > 0) && (
          <tr>
            <td>י.פ</td>
            <td style={{ color: openingBalance < 0 ? "red" : "green" }}>
              {openingBalance}
            </td>
            <td>שיעורים={openingBalanceLesson / ticketLessonTime}</td>
            <td>מ.פ={openingBalanceInternalTest}</td>
            <td>טסטים={openingBalanceTest}</td>
          </tr>
        )}
        <tr>
          <td>יתרה נוכחית</td>
          {/* <td>מפגשים={ordersCount}</td> */}
          {studentTicket ? (
            <td style={{ color: balance < 0 ? "red" : "green" }}>{balance}</td>
          ) : null}
          <td>
            שיעורים לפי {ticketLessonTime} דקות=
            {lessonInAgreementTime.toFixed(2)}
          </td>
          <td>מ.פ={internalTestsCount}</td>
          <td>טסטים={testsCount}</td>
          <td>
            גוף מימון=
            {
              fundingBodies.find(
                (x) => x.id === studentTextBookData.fundingBody
              )?.name
            }
          </td>
        </tr>
        <tr>
          <td>כולל הזמנות</td>
          {studentTicket ? (
            <td
              style={{ color: balanceWithFutureOrders < 0 ? "red" : "green" }}
            >
              {balanceWithFutureOrders}
            </td>
          ) : null}
          <td>
            שיעורים לפי {ticketLessonTime} דקות=
            {lessonsWithFutureLessons.toFixed(2)}
          </td>
          <td>מ.פ={internalTestsWithFutureInternalTests}</td>
          <td>טסטים={testsWithFutureTests}</td>
        </tr>
        <tr>
          <td onClick={openAgreement}>הסכם:{agreement}</td>
          <td>
            <span
              className={isTicketChecked ? "icons8-checkmark" : "icons8-cancel"}
              onClick={updateStudentIsChecked}
              style={{
                color: isTicketChecked ? "green" : "red",
                cursor: "pointer",
              }}
            />
          </td>
          <td>סה״כ לא הופיע={totalStudentDidntShowedUp}</td>
          <td>
            <span
              className="icons8-phone"
              onClick={() => window.open(`tel:${phone}`)}
              style={{ cursor: "pointer" }}
            />
          </td>
          <td>{phone}</td>
          <td>נוצר ע״י {studentTextBookData.createdBy}</td>
          <td>
            בתאריך {moment(studentTextBookData.createdAt).format("YYYY-MM-DD")}
          </td>
        </tr>
        <tr>
          <td>{glasses ? "צריך משקפי ראייה" : null}</td>
          {studentTicket ? (
            <td>
              סה״כ ששולם:{" "}
              <span style={{ color: "green" }}>{totalPaid.toFixed(2)}</span>
            </td>
          ) : null}
          {studentTicket ? (
            <td>סה״כ לא הופיע וחוייב={totalStudentDidntShowedUpAndCharged}</td>
          ) : null}
          {studentTicket ? <td>סה״כ הנחות={totalStudentDiscounts}</td> : null}
          <td>
            מפ:
            <span className={internalTestStatus[isPassedInternal].icon}></span>
          </td>
          <td>
            טסט: <span className={testStatus[isPassedTest].icon}></span>
          </td>
          <td>{studentId}</td>
        </tr>
      </BalanceTable>
      <Table
        data={events}
        height="40vh"
        componentColumns={studentColumns}
        onRowClick={onRowClick}
      />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    ticketShowCanceledAndCharged:
      state.session.user.ticketShowCanceledAndCharged,
    isAllowedTicketCheck: state.session.user.isAllowedTicketCheck,
    ticketShowCanceled: state.session.user.ticketShowCanceled,
    ticketLessonTime: state.session.user.ticketLessonTime,
    studentTicket: state.session.user.studentTicket,
    isUsersLoaded: state.UsersReducer.isLoaded,
    users: state.UsersReducer.users,
    user: state.session.user,
    updateagreement: state.session.user.updateagreement,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getStudentEvents,
    getStudentTextBooks,
    fetchTextBook,
    getAgreementDetails,
    getStudentEventCount,
    getStudentIncomes,
    getUsers,
    getLicenseTypes,
    getVehicles,
    getAgreements,
    updateIsChecked,
    getStudentDiscountsAndObligations,
    updateEventPrice,
    setStudentBalance,
    sendPaymentRequest,
    getClientIncomes,
    getPaymentRequestById,
    getSchoolFundingBodies,
  })(StudentTicket)
);
