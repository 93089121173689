import React from "react";
import { connect } from "react-redux";
import i18n from "../../../../lib/i18n/i18n";
import { Field, reduxForm } from "redux-form";
import Toggle from "react-toggle";
import SivanModal from "../../../Modal/Modal";
import {
  Body,
  Section,
  Row,
  ToggleContainer,
  ToggleText,
} from "./newClient.styles";
import { create } from "../../../../actions/clientsActions";
import CreateButton from "../../../UiComponents/Buttons/Default";
import InputWrappedWithField from "../../../UiComponents/Inputs/DefaultWrappedWithField";
import NotesInput from "../../../UiComponents/TextArea/Default";

const renderToggle = ({
  input,
  label,
  meta: { touched, error },
  type = "text",
}) => {
  const isHebrew = i18n.language === "he";
  const dir = isHebrew ? "rtl" : "ltr";
  return (
    <ToggleContainer isRtl={isHebrew}>
      <ToggleText>{label}</ToggleText>
      <Toggle
        checked={input.value}
        onChange={(x) => input.onChange(x.target.checked ? 1 : 0)}
      />
    </ToggleContainer>
  );
};

const NewFundingBodies = ({
  isModalOpen,
  setIsModalOpen,
  handleSubmit,
  create,
}) => {
  const fundingBodyCreateAction = async (formValues) => {
    await create(formValues);
    setIsModalOpen(false);
  };

  return (
    <SivanModal
      titleText="צור לקוח"
      modalIsOpen={isModalOpen}
      closeModal={() => setIsModalOpen(false)}
    >
      <Body onSubmit={handleSubmit(fundingBodyCreateAction)}>
        <Section basicData noBorder>
          <Row>
            <Field
              name="name"
              component={InputWrappedWithField}
              placeholder="שם"
              type="text"
            />
            <Field
              name="clientId"
              component={InputWrappedWithField}
              placeholder="ת.ז."
              type="number"
            />
            <Field
              name="address"
              component={InputWrappedWithField}
              placeholder="כתובת"
              type="text"
            />
            <Field
              name="city"
              component={InputWrappedWithField}
              placeholder="עיר"
              type="text"
            />
            <Field
              name="postalCode"
              component={InputWrappedWithField}
              placeholder="מיקוד"
              type="number"
            />
          </Row>
          <Row>
            <Field
              name="phoneNumber"
              component={InputWrappedWithField}
              placeholder="טלפון"
              type="text"
            />
            <Field
              name="email"
              component={InputWrappedWithField}
              placeholder="מייל"
              type="email"
            />
            <Field name="notes" component={NotesInput} placeholder="הערות" />
            <Field
              name="vatExemption"
              component={renderToggle}
              label="פטור ממע״מ"
            />
          </Row>
        </Section>
        <CreateButton text="צור" type="submit" />
      </Body>
    </SivanModal>
  );
};

export default connect(null, { create })(
  reduxForm({
    form: "NewFundingBodies",
  })(NewFundingBodies)
);
