import styled from "styled-components";
import { isTablet, isMobileOnly, isMobile } from "react-device-detect";

export const Container = styled.div`
  border-bottom: 1px solid #ffefca;
  display: flex;
  flex-direction: ${(props) => (props.isRtl ? "row-reverse" : "row")};
  height: ${isMobile ? "5vh" : "10vh"};
  width: 100vw;
`;

export const LoggedUserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "center" : "space-between"};
  flex-direction: ${isMobile ? "column" : "row"};
  width: ${isMobile ? "55vw" : "75vw"};
  padding-right: 2vw;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #ffefca;
  width: 25vw;
  background-color: #fffdf9;
`;

export const Logo = styled.img`
  height: ${isMobile ? "2.5vh" : "8vh"};
  width: auto;
`;

export const LoggedUserName = styled.span`
  font-size: ${isMobileOnly ? "2.3vw" : "16pt"};
`;
