import generalMessagesApi from "../apis/generalMessages";
import {
  STUDENT_CREATE_SUCCESS,
  STUDENT_CREATE_FAILED,
  STUDENT_CREATE_STARTED,
  STUDENT_FETCH_SUCCESS,
} from "../constants/actionTypes";
import { toast } from "react-toastify";

//TODO: added teacherId and schoolId
export const createMessage = (message) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await generalMessagesApi.post(
      "/create",
      {
        message,
      },
      {
        headers,
      }
    );
  } catch (error) {
    toast.error("כישלון ביצירת הודעה");
  }
};

export const deleteMessage = (message) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await generalMessagesApi.post(
      "/deleteOne",
      {
        message,
      },
      {
        headers,
      }
    );
  } catch (error) {
    toast.error("כישלון במחיקת הודעה");
  }
};

export const getMessages = () => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await generalMessagesApi.get("/allMessages", {
      headers,
    });
    return response.data;
  } catch (error) {}
};
