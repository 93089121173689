import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Title, TitleText } from "./users.styles";
import TeacherActionsModal from "./TeacherActionsModal/TeacherActionsModal";
import { SelectColumnFilter } from "../../SivanPlusTable/filters";
import i18n from "../../../lib/i18n/i18n";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Table from "../../SivanPlusTable/SivanPlusTable";
import { getUsers } from "../../../actions/userActions";
import {
  getTeacherVacations,
  getTeacheresVehicles,
} from "../../../actions/teacherActions";
import FadeLoader from "react-spinners/FadeLoader";
import CreateButton from "../../UiComponents/Buttons/Default";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const userColumns = [
  {
    Header: dictionary.table.name,
    accessor: (d) => `${d.firstName} ${d.lastName}`,
  },
  {
    Header: dictionary.table.phone,
    accessor: "phoneNumber",
  },
  {
    Header: dictionary.table.email,
    accessor: "email",
  },
  {
    Header: dictionary.table.branch,
    accessor: "branchName",
  },
  {
    Header: dictionary.table.active,
    accessor: "isActive",
    Cell: ({ row: { original } }) => {
      return original.isActive === 1
        ? dictionary.users.active
        : dictionary.users.inactive;
    },
    Filter: SelectColumnFilter,
    filter: "equals",
  },
];

const Users = ({
  getUsers,
  getTeacherVacations,
  history,
  users,
  getTeacheresVehicles,
  isLoaded,
  editUser,
  createUser,
}) => {
  const [isTeacherActionsModalOpen, setTeacherActionsModalIsOpen] = useState(
    false
  );
  const [teacherData, setTeacherData] = useState({});
  const [teacherVacations, setTeacherVacations] = useState([]);
  const { t, i18n } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      if (!isLoaded) {
        await getUsers();
      }
      setIsLoading(false);
    }
    fetchData();
  }, []);

  const onRowClick = async (rowObject) => {
    if (
      rowObject.column.id === "phoneNumber" &&
      rowObject.original.phoneNumber
    ) {
      window.open(`tel:${rowObject.original.phoneNumber}`);
    } else if (rowObject.column.id === "email" && rowObject.original.email) {
      window.open(`mailto:${rowObject.original.email}`);
    } else if (editUser) {
      const selectedUser = rowObject.original;
      if (selectedUser.userType === 1) {
        const vehicels = await getTeacheresVehicles(rowObject.original.id);
        const vacations = await getTeacherVacations(rowObject.original.id);
        selectedUser.vehicles = vehicels.map(({ vehicleNumber, id }) => ({
          value: id,
          label: vehicleNumber,
        }));

        setTeacherVacations(
          vacations.map((x) => ({
            id: x.id,
            notes: x.notes,
            start: new Date(parseInt(x.start)),
            end: new Date(parseInt(x.end)),
          }))
        );
      }
      setTeacherData(selectedUser);
      setTeacherActionsModalIsOpen(true);
    }
  };

  return (
    <Container>
      {isLoading && (
        <div
          style={{
            height: "78%",
            width: "74%",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FadeLoader size={100} color={"#123abc"} loading={true} />
        </div>
      )}
      {isTeacherActionsModalOpen && (
        <TeacherActionsModal
          isTeacherActionsModalOpen={isTeacherActionsModalOpen}
          setTeacherActionsModalIsOpen={setTeacherActionsModalIsOpen}
          teacherData={teacherData}
          teacherVacations={teacherVacations}
        />
      )}
      <Title>
        <TitleText>{t("users.title")}</TitleText>
        {createUser ? (
          <CreateButton
            onClick={() => history.push("/createUser")}
            text={t("users.createButton")}
          />
        ) : null}
      </Title>
      <Table
        data={users}
        componentColumns={userColumns}
        onRowClick={onRowClick}
      />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isPending: state.StudentsReducer.isPending,
    users: state.UsersReducer.users,
    isLoaded: state.UsersReducer.isLoaded,
    createUser: state.session.user.createUser,
    editUser: state.session.user.editUser,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getUsers,
    getTeacherVacations,
    getTeacheresVehicles,
  })(Users)
);
