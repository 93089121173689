import clientsApi from "../apis/clients";
import {
  CLIENTS_FETCH_FAILED,
  CLIENTS_FETCH_SUCCESS,
  CLIENTS_FETCH_STARTED,
  CLIENT_CREATE_SUCCESS,
  CLIENT_DELETE_SUCCESS,
  CLIENT_UPDATE_SUCCESS,
} from "../constants/actionTypes";
import { toast } from "react-toastify";

export const create = (client) => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const { branchId, schoolId } = getState().session.user;

    const response = await clientsApi.post(
      "/create",
      {
        client: { ...client, schoolId },
      },
      {
        headers,
      }
    );
    dispatch({
      type: CLIENT_CREATE_SUCCESS,
      payload: { ...client, id: response.data.id, isActive: 1 },
    });
  } catch (error) {
    toast.error("כישלון ביצירת לקוח");
  }
};

export const getSchoolClients = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLIENTS_FETCH_STARTED,
    });
    const token = window.localStorage.getItem("token");
    const { branchId, schoolId } = getState().session.user;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await clientsApi.get(`/getAll/${schoolId}`, {
      headers,
    });

    dispatch({
      type: CLIENTS_FETCH_SUCCESS,
      payload: response.data,
    });

    return response.data;
  } catch (error) {
    dispatch({
      type: CLIENTS_FETCH_FAILED,
    });
  }
};

export const update = (client) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await clientsApi.patch(
      "/update",
      {
        client,
      },
      {
        headers,
      }
    );

    dispatch({
      type: CLIENT_UPDATE_SUCCESS,
      payload: { ...client },
    });
  } catch (error) {
    toast.error("כישלון בעדכון לקוח");
  }
};

export const deleteItem = (clientId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await clientsApi.delete(
      "/delete",
      {
        data: { clientId },
      },
      {
        headers,
      }
    );

    dispatch({
      type: CLIENT_DELETE_SUCCESS,
      payload: { id: clientId },
    });
  } catch (error) {
    toast.error("כישלון במחיקת לקוח");
  }
};
