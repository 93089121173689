import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import SivanModal from "../../../Modal/Modal";
import { Body, Section, Row } from "./newFundingBodies.styles";
import { create } from "../../../../actions/fundingBodiesActions";
import CreateButton from "../../../UiComponents/Buttons/Default";
import InputWrappedWithField from "../../../UiComponents/Inputs/DefaultWrappedWithField";
import NotesInput from "../../../UiComponents/TextArea/Default";

const NewFundingBodies = ({
  isNewFundingBodiesModalOpen,
  setNewFundingBodiesModalOpen,
  handleSubmit,
  create,
}) => {
  const fundingBodyCreateAction = async (formValues) => {
    await create(formValues);
    setNewFundingBodiesModalOpen(false);
  };

  return (
    <SivanModal
      titleText="צור גוף מימון"
      modalIsOpen={isNewFundingBodiesModalOpen}
      closeModal={() => setNewFundingBodiesModalOpen(false)}
    >
      <Body onSubmit={handleSubmit(fundingBodyCreateAction)}>
        <Section basicData noBorder>
          <Row>
            <Field
              name="name"
              component={InputWrappedWithField}
              placeholder="שם"
              type="text"
            />
            <Field
              name="pc"
              component={InputWrappedWithField}
              placeholder="ח.פ"
              type="number"
            />
            <Field
              name="openingBalance"
              component={InputWrappedWithField}
              placeholder="מאזן פתיחה"
              type="number"
            />
            <Field
              name="contactName"
              component={InputWrappedWithField}
              placeholder="איש קשר"
              type="text"
            />
            <Field
              name="address"
              component={InputWrappedWithField}
              placeholder="כתובת"
              type="text"
            />
            <Field
              name="city"
              component={InputWrappedWithField}
              placeholder="עיר"
              type="text"
            />
          </Row>
          <Row>
            <Field
              name="providerNumber"
              component={InputWrappedWithField}
              placeholder="מספר ספק"
              type="number"
            />
            <Field
              name="postalCode"
              component={InputWrappedWithField}
              placeholder="מיקוד"
              type="number"
            />
            <Field
              name="phoneNumber"
              component={InputWrappedWithField}
              placeholder="טלפון"
              type="text"
            />
            <Field
              name="email"
              component={InputWrappedWithField}
              placeholder="מייל"
              type="email"
            />
            <Field name="notes" component={NotesInput} placeholder="הערות" />
          </Row>
        </Section>
        <CreateButton text="צור" type="submit" />
      </Body>
    </SivanModal>
  );
};

export default connect(null, { create })(
  reduxForm({
    form: "NewFundingBodies",
  })(NewFundingBodies)
);
