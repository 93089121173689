import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Header from "./Header/Header";
import Menu from "./Menu/Menu";
import { Container, ComponentContainer, BodyContainer } from "./default.styles";
import { create } from "jss";
import rtl from "jss-rtl";
import {
  StylesProvider,
  jssPreset,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const ltrTheme = createMuiTheme({ direction: "ltr" });
const rtlTheme = createMuiTheme({ direction: "rtl" });
const DefaultLayout = ({ children }) => {
  const { t, i18n } = useTranslation("common");

  return (
    <Container>
      <Header />
      <BodyContainer isRtl={i18n.language === "he"}>
        <Menu />
        <StylesProvider jss={jss}>
          <ThemeProvider theme={i18n.language === "he" ? rtlTheme : ltrTheme}>
            <ComponentContainer>{children}</ComponentContainer>
          </ThemeProvider>
        </StylesProvider>
      </BodyContainer>
    </Container>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
};

export default DefaultLayout;
