import styled from "styled-components";
import LoadingOverlay from "react-loading-overlay";
import i18n from "../../../../../../lib/i18n/i18n";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .error {
    border: 1px solid red !important;
  }

  .react-datepicker-wrapper:after {
    font-family: "FontAwesome";
    position: relative;
    left: ${isHebrew ? "-90%" : "-5%"};
    content: "\f133";
  }

  .react-datepicker-wrapper {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    width: 100%;
    height: 6vh;
    margin-top: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__input-container input {
    width: 100%;
    ${isHebrew ? "padding-right" : "padding-left"}: 1vw;
    direction: ${isHebrew ? "rtl" : "ltr"};

    &::placeholder {
      color: #c2c2c2;
    }
  }

  .react-toggle-track {
    background-color: #f5f5f5 !important;
    border: 1px solid #e5e5e5;
    padding: 0.2vh;
  }

  .react-toggle--checked .react-toggle-thumb {
    background-color: #edca92;
  }

  .react-toggle-thumb {
    background-color: #e5e3e0;
    border: none;
  }
`;

export const Title = styled.span`
  font-size: 22pt;
  font-weight: bold;
  color: #404041;
  display: block;
  height: 7vh;
`;

export const Body = styled.form`
  height: 75vh;
  width: 100%;
  background-color: white;
  border: 1px solid #ffefca;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 3vh;
  align-items: center;
  margin-top: 3vh;
`;

export const ColumnTitle = styled.span`
  font-size: 2.65vh;
  color: #404041;
  height: 7vh;
  display: flex;
  align-items: center;
`;

export const PermanentDetailsContainer = styled.div`
  padding-left: 2vh;
  flex: 1;
`;
export const AdditionalDetailsContainer = styled.div`
  padding-left: 2vh;
  flex: 1;

  .react-datepicker-wrapper:after {
    font-family: "FontAwesome";
    position: relative;
    left: ${isHebrew ? "-90%" : "-5%"};
    content: "\f133";
  }

  .react-datepicker-wrapper {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    width: 100%;
    height: 6vh;
    margin-top: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__input-container input {
    width: 100%;
    ${isHebrew ? "padding-right" : "padding-left"}: 1vw;
    direction: ${isHebrew ? "rtl" : "ltr"};

    &::placeholder {
      color: #c2c2c2;
    }
  }
`;

export const Button = styled.button`
  border-radius: 5px;
  height: 6vh;
  background: ${(props) =>
    props.reset ? "linear-gradient(to bottom, #ebd794, #d2ab51)" : "white"};
  border: ${(props) => (props.reset ? "" : "1px solid #e1c89d")};
  color: ${(props) => (props.reset ? "white" : "#e1c89d")};
  font-size: 15pt;
  margin-top: 5vh;
  font-weight: bolder;
  width: 45%;

  &:hover {
    background: ${(props) =>
      !props.reset ? "linear-gradient(to bottom, #ebd794, #d2ab51)" : "white"};
    border: ${(props) => (!props.reset ? "" : "1px solid #e1c89d")};
    color: ${(props) => (!props.reset ? "white" : "#e1c89d")};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 25vw;
  justify-content: space-between;
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isRtl ? "row-reverse" : "row")};
  width: 100%;
`;

export const ToggleContainer = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  width: 100%;
  height: 6vh;
  margin-top: 1vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1vw;
  padding-right: 1vw;
  flex-direction: ${(props) => (props.isRtl ? "row-reverse" : "row")};
`;

export const ToggleText = styled.span`
  font-size: 1.55vh;
`;

export const CounterButton = styled.span`
  background-color: #fcf2de;
  color: #cb9d3c;
  border-radius: 50%;
  width: 1.5vw;
  height: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.55vh;
`;

export const StyledLoader = styled(LoadingOverlay)`
  ._loading_overlay_overlay {
    position: fixed !important;
  }
`;
