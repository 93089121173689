import React from "react";
import { Field, reduxForm } from "redux-form";
import i18n from "../../../../lib/i18n/i18n";
import Toggle from "react-toggle";
import {
  ToggleContainer,
  ToggleText,
  AdditionalDetailsContainer,
  Fields,
} from "../permissions.styles";

const renderToggle = ({ input, label }) => {
  const isHebrew = i18n.language === "he";
  return (
    <ToggleContainer isRtl={isHebrew}>
      <ToggleText>{label}</ToggleText>
      <Toggle checked={input.value} onChange={input.onChange} />
    </ToggleContainer>
  );
};

const GeneralPermissions = () => {
  return (
    <Fields>
      <AdditionalDetailsContainer>
        <Field label="רכבים" component={renderToggle} name="vehicles" />
        <Field
          label="יצירת רכב"
          component={renderToggle}
          name="createVehicle"
        />
        <Field label="ערוך רכב" component={renderToggle} name="editVehicle" />
        <Field label="הרשאות" component={renderToggle} name="permissions" />
        <Field label="משתמשים" component={renderToggle} name="users" />
        <Field label="ספידומטר" component={renderToggle} name="odoMeter" />
        <Field label="יצירת משתמש" component={renderToggle} name="createUser" />
        <Field
          label="מנהלים מקצועיים"
          component={renderToggle}
          name="professionalTeachers"
        />
        <Field label="טסטרים" component={renderToggle} name="testers" />
      </AdditionalDetailsContainer>
    </Fields>
  );
};

export default reduxForm({
  form: "permissions", // a unique identifier for this form
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  enableReinitialize: true, // this is needed!!
})(GeneralPermissions);
