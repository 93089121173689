import styled from "styled-components";

export const Container = styled.div``;
export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FieldText = styled.span``;

export const Field = styled.div`
  flex: 0 33%;
  text-align: center;
  height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
