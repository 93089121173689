import React from "react";
import Modal from "react-modal";
import { Title, Circle, TitleText, CloseIcon } from "./modal.styles";
import FadeLoader from "react-spinners/FadeLoader";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    paddingTop: "0",
    zIndex: 5,
    overflow: "visible",
    maxWidth: "97vw",
  },
  overlay: { zIndex: 1050 },
};

Modal.setAppElement("#root");

const SivanPlusModal = ({
  children,
  titleText,
  modalIsOpen,
  closeModal,
  isLoading,
}) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      style={customStyles}
      contentLabel="Example Modal"
      onRequestClose={closeModal}
    >
      {isLoading && (
        <div
          style={{
            height: "100%",
            width: "99%",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 5454,
          }}
        >
          <FadeLoader size={150} color={"#123abc"} loading={true} />
        </div>
      )}
      <Title>
        <TitleText>{titleText}</TitleText>
        <Circle onClick={closeModal}>
          <CloseIcon className="fa fa-close" />
        </Circle>
      </Title>
      {children}
    </Modal>
  );
};

export default SivanPlusModal;
