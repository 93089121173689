import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import i18n from "../../../lib/i18n/i18n";
import {
  Container,
  ToggleContainer,
  DataContainer,
  DataName,
  DataValue,
} from "./studentBalances.styles";
import { connect } from "react-redux";
import { getAllBalances } from "../../../actions/studentesActions";
import Table from "../../SivanPlusTable/SivanPlusTable";
import Toggle from "react-toggle";
import { SelectColumnFilter } from "../../SivanPlusTable/filters";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const studentColumns = [
  {
    Header: dictionary.table.name,
    accessor: "firstName",
  },
  {
    Header: dictionary.table.lastName,
    accessor: "lastName",
  },
  {
    Header: dictionary.table.id,
    accessor: "studentId",
    Cell: ({ row: { original } }) => {
      return original.isChecked === 1 ? (
        original.studentId
      ) : (
        <span style={{ color: "red", fontWeight: "bold" }}>
          {original.studentId}
        </span>
      );
    },
  },
  {
    Header: "שיעורים",
    accessor: "totalLessons",
  },
  {
    Header: "מ.פ",
    accessor: "totalInternalTests",
  },
  {
    Header: "טסטים",
    accessor: "totalTests",
  },
  {
    Header: "מאזן",
    accessor: "totalBalance",
    Cell: ({ row: { original } }) => {
      return original.totalBalance < 0 ? (
        <span style={{ color: "red", fontWeight: "bold" }}>
          {original.totalBalance}
        </span>
      ) : (
        original.totalBalance
      );
    },
  },
  {
    Header: "מורים",
    accessor: "teacherName",
  },
  {
    Header: "רכב",
    accessor: "vehicle",
  },
  {
    Header: dictionary.table.active,
    accessor: "active",
    Cell: ({ row: { original } }) => {
      return original.active === 1
        ? dictionary.users.active
        : dictionary.users.inactive;
    },
    Filter: SelectColumnFilter,
    filter: "equals",
  },
];

const getToggleValueFromLocalStorage = (type) => {
  const val = window.localStorage.getItem(`studentReport-${type}`);
  if (val && JSON.parse(val) !== null) {
    return JSON.parse(val);
  } else {
    return false;
  }
};

const StudentBalances = ({ getAllBalances, history }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showNullBalance, setShowNullBalance] = useState(
    getToggleValueFromLocalStorage("showNullBalance")
  );
  const [showPositiveOnly, setShowPositiveOnly] = useState(
    getToggleValueFromLocalStorage("showPositiveOnly")
  );
  const [showNegativeOnly, setShowNegativeOnly] = useState(
    getToggleValueFromLocalStorage("showNegativeOnly")
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const balances = await getAllBalances();
      setData(balances);
      if (showNegativeOnly) {
        setFilteredData(balances.filter((x) => x.totalBalance < 0));
      } else if (showPositiveOnly) {
        setFilteredData(balances.filter((x) => x.totalBalance > 0));
      } else if (showNullBalance) {
        setFilteredData(balances.filter((x) => x.totalBalance !== 0));
      } else {
        setFilteredData(balances);
      }
      setIsLoading(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (showNegativeOnly) {
      setFilteredData(data.filter((x) => x.totalBalance < 0));
    } else if (showPositiveOnly) {
      setFilteredData(data.filter((x) => x.totalBalance > 0));
    } else if (showNullBalance) {
      setFilteredData(data.filter((x) => x.totalBalance !== 0));
    } else {
      setFilteredData(data);
    }
    setIsLoading(false);
  }, [showNegativeOnly, showPositiveOnly, showNullBalance]);

  const changeToggle = (type, value, cb) => {
    window.localStorage.setItem(`studentReport-${type}`, value);
    cb(value);
  };
  const handlePrint = useReactToPrint({
    content: () => document.getElementById("table-to-xls"),
  });
  return (
    <Container>
      <ToggleContainer>
        <Toggle
          id="cheese-status"
          defaultChecked={showNullBalance}
          onChange={() =>
            changeToggle(
              "showNullBalance",
              !showNullBalance,
              setShowNullBalance
            )
          }
        />
        <label htmlFor="cheese-status">האם להסתיר יתרה 0</label>&nbsp;
        <Toggle
          id="cheese-status"
          defaultChecked={showPositiveOnly}
          onChange={() =>
            changeToggle(
              "showPositiveOnly",
              !showPositiveOnly,
              setShowPositiveOnly
            )
          }
        />
        <label htmlFor="cheese-status">יתרה חיובית</label>&nbsp;
        <Toggle
          id="cheese-status"
          defaultChecked={showNegativeOnly}
          onChange={() =>
            changeToggle(
              "showNegativeOnly",
              !showNegativeOnly,
              setShowNegativeOnly
            )
          }
        />
        <label htmlFor="cheese-status">יתרה שלילית</label>
      </ToggleContainer>
      <DataContainer>
        <DataName>סה״כ תלמידים</DataName>
        <DataValue>{filteredData.length}</DataValue>
        <DataName>סה״כ כסף</DataName>
        <DataValue>
          {filteredData
            .reduce((a, b) => a + b.totalBalance, 0)
            .toFixed(2)
            .toLocaleString("he")}
        </DataValue>
      </DataContainer>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="table-to-xls"
        filename="דוח חייבים"
        sheet="חייבים"
        buttonText="יצא אקסל"
      />
      <div style={{ cursor: "pointer" }} onClick={handlePrint}>
        <span>הדפס &nbsp;</span>
        <i className="fa fa-print" aria-hidden="true" />
      </div>
      <Table
        data={filteredData}
        componentColumns={studentColumns}
        onRowClick={(rowObject) =>
          history.push(`/updateStudent`, {
            studentData: rowObject.original,
            view: dictionary.editStudent.ticket,
          })
        }
        isLoading={isLoading}
      />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isLoggedIn: state.session.isLoggedIn,
    userId: state.session.user.id,
    drivingPermits: state.DrivingPermitsReducer.drivingPermits,
    user: state.session.user,
    students: state.StudentsReducer.students,
    isLoading: state.StudentsReducer.isLoaded,
    licenseTypes: state.LicenseTypesReducer.licenseTypes,
    isLoaded: state.LicenseTypesReducer.isLoaded,
  };
}
export default withRouter(
  connect(mapStateToProps, {
    getAllBalances,
  })(StudentBalances)
);
