import styled from "styled-components";
import i18n from "../../../lib/i18n/i18n";
import { isMobile, isMobileOnly } from "react-device-detect";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  direction: rtl;

  ${(props) =>
    props.isStudentChosen &&
    `background: ${props.chosenStudentColor};color: ${props.fontChosenStudentColor};`}
`;

export const IconsAndTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;

  &:nth-child(1) {
    margin: 0;
  }
`;

export const Icon = styled.span`
  font-size: ${isMobileOnly ? "6vw" : "3vh"};
  margin-right: ${isMobileOnly && "0.3vw"};

  @media (min-width: 720px) and (max-width: 1024px) and (orientation: landscape) {
    font-size: 22px;
  }
`;

export const WheelIcon = styled.img`
  height: ${isMobileOnly ? "7vw" : "3vh"};
  margin-right: ${isMobileOnly && "0.3vw"};
`;

export const TitleContainer = styled.div`
  display: flex;
  // flex-direction: ${(props) => (props.isDay ? "column" : "row")};
  flex-direction: row;
  direction: rtl;

  ${IconsAndTextContainer} {
    ${(props) => props.isDay && `margin-right: 15px;`}
    ${isMobileOnly && "margin:0;"}
  }
`;

export const TextAfterIcon = styled.span`
  ${!isMobileOnly && "margin-right: 0.3vw;"}
  text-align: right;
  direction: rtl;

  font-size: ${isMobileOnly ? "2vh" : "2vh"};

  @media (min-width: 720px) and (max-width: 1024px) and (orientation: landscape) {
    font-size: 22px;
  }
`;
