import React, { useState, useEffect } from "react";
import Navbar from "../../Navbar/Navbar";
import i18n from "../../../lib/i18n/i18n";
import { NavAndBackContainer } from "./trafficLaws.styles";
import { Document, Page, pdfjs } from "react-pdf";
import EshumPdf from "./eshum.pdf";
// const url = "http://www.files.e-shop.co.il/topdriver/low/low/taknot.pdf";
const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const TrafficLaws = ({}) => {
  const [menuItems, setMenuItems] = useState([dictionary.editStudent.basic]);
  const [currentOpenedView, setCurrentOpenedView] = useState(
    dictionary.editStudent.basic
  );
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <NavAndBackContainer>
        <Navbar
          menuOptions={menuItems}
          currentView={currentOpenedView}
          setCurrentOpenedView={setCurrentOpenedView}
        />
      </NavAndBackContainer>
      {currentOpenedView === dictionary.editStudent.basic && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Document file={EshumPdf} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
          <p>
            <span
              onClick={() => setPageNumber(pageNumber - 1)}
              style={{
                cursor: "pointer",
                marginLeft: "1vw",
                fontWeight: "bold",
              }}
            >
              הקודם
            </span>
            דף {pageNumber} מתוך {numPages}
            <span
              onClick={() => setPageNumber(pageNumber + 1)}
              style={{
                cursor: "pointer",
                marginRight: "1vw",
                fontWeight: "bold",
              }}
            >
              הבא
            </span>
          </p>
        </div>
      )}
    </div>
  );
};

export default TrafficLaws;
