import styled from "styled-components";
import i18n from "../../../../../lib/i18n/i18n";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const Title = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7vh;
`;

export const TitleText = styled.span`
  font-size: 20pt;
  text-align: center;
  width: 100%;
`;

export const Circle = styled.span`
  background-color: #fcf2de;
  color: #cb9d3c;
  border-radius: 50%;
  width: 1.5vw;
  height: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16pt;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .react-toggle-track {
    background-color: #f5f5f5 !important;
    border: 1px solid #e5e5e5;
    padding: 0.2vh;
  }

  .react-toggle--checked .react-toggle-thumb {
    background-color: #edca92;
  }

  .react-toggle-thumb {
    background-color: #e5e3e0;
    border: none;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: ${isHebrew ? "row-reverse" : "row"};
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 1vh;
  justify-content: space-around;
  width: 98%;

  .react-datepicker-wrapper:after {
    font-family: "FontAwesome";
    position: relative;
    left: ${isHebrew ? "-90%" : "-5%"};
    content: "\f133";
  }

  .react-datepicker-wrapper {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    flex: 0 15%;
    height: 6vh;
    margin-top: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__input-container input {
    width: 100%;
    ${isHebrew ? "padding-right" : "padding-left"}: 1vw;
    direction: ${isHebrew ? "rtl" : "ltr"};

    &::placeholder {
      color: #c2c2c2;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 48%;

  .react-datepicker-wrapper:after {
    font-family: "FontAwesome";
    position: relative;
    left: ${isHebrew ? "-90%" : "-5%"};
    content: "\f133";
  }

  .react-datepicker-wrapper {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    margin-top: 1vh;
    width: 100%;
    height: 6vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__input-container input {
    width: 100%;
    ${isHebrew ? "padding-right" : "padding-left"}: 1vw;
    direction: ${isHebrew ? "rtl" : "ltr"};

    &::placeholder {
      color: #c2c2c2;
    }
  }
`;

export const CreateButton = styled.button`
  margin-top: 1vh;
  border-radius: 5px;
  color: white;
  height: 3vw;
  width: 8vw;
  background-image: linear-gradient(to bottom, #ebd794, #d2ab51);
  font-size: 15pt;
`;

export const ToggleContainer = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  width: 100%;
  height: 6vh;
  margin-top: 1vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1vw;
  padding-right: 1vw;
  flex-direction: ${(props) => (props.isRtl ? "row-reverse" : "row")};
`;

export const ToggleText = styled.span`
  font-size: 1.55vh;
`;

export const SubTitle = styled.span`
  font-size: 2.4vh;
  font-weight: bold;
  color: #404041;
  display: block;
`;

export const AddMoreButton = styled.span`
  color: #d2ab51;
  cursor: pointer;
`;

export const CounterButton = styled.span`
  background-color: #fcf2de;
  color: #cb9d3c;
  border-radius: 50%;
  width: 1.5vw;
  height: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16pt;
`;

export const Icon = styled.i`
  color: #e1c89d;
  cursor: pointer;
`;
