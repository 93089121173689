import {
  TESTERS_FETCH_FAILED,
  TESTERS_FETCH_SUCCESS,
  TESTERS_FETCH_STARTED,
  TESTER_CREATE_SUCCESS,
  TESTER_DELETE_SUCCESS,
  TESTER_UPDATE_SUCCESS,
} from "../constants/actionTypes";

const INITIAL_STATE = {
  isPending: false,
  testers: [],
  isLoaded: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case TESTER_CREATE_SUCCESS:
      return {
        ...state,
        testers: [...state.testers, action.payload],
      };
    case TESTER_DELETE_SUCCESS:
      return {
        ...state,
        testers: state.testers.filter(
          (tester) => tester.id !== action.payload.id
        ),
      };
    case TESTER_UPDATE_SUCCESS:
      const testers = state.testers.map((tester) =>
        tester.id === action.payload.id
          ? { ...tester, ...action.payload }
          : tester
      );
      return { ...state, testers };
    case TESTERS_FETCH_SUCCESS:
      return { ...state, testers: action.payload, isLoaded: true };
    case TESTERS_FETCH_FAILED:
      return { ...state, isPending: false };
    case TESTERS_FETCH_STARTED:
      return { ...state, isPending: true };
    default:
      return state;
  }
}
