import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import i18n from "../../../../lib/i18n/i18n";
import validate from "./validate";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import _ from "lodash";
import SivanModal from "../../../Modal/Modal";
import {
  Body,
  Section,
  Row,
  ToggleContainer,
  ToggleText,
  BalanceTable,
} from "./newIncome.styles";
import Toggle from "react-toggle";
import { create, getDocumentNumber } from "../../../../actions/incomesAction";
import CreateButton from "../../../UiComponents/Buttons/Default";
import { getVehicles } from "../../../../actions/vehicleActions";
import {
  getSchoolFundingBodies,
  getPaymentRequests,
  updatePaymentRequestStatus,
} from "../../../../actions/fundingBodiesActions";
import {
  getStudentEvents,
  updateStudentFundingBodyStatus,
} from "../../../../actions/schedulerActions";
import useVehicles from "../../../useComponents/useVehicles";
import useFundingBodies from "../../../useComponents/useFundingBodies";
import { getSchoolProviders } from "../../../../actions/providersActions";
import DefaultWrappedWithField from "../../../UiComponents/Inputs/DefaultWrappedWithField";
import Input from "../../../UiComponents/Inputs/Default";
import NotesInput from "../../../UiComponents/TextArea/Default";
import DefaultSelect from "../../../UiComponents/Select/Default";
import DefaultDateTimePicker from "../../../UiComponents/DatePicker/WithTime";
import DefaultDatePicker from "../../../UiComponents/DatePicker/Default";

const documentTypes = [
  { value: 0, label: "חשבונית/קבלה" },
  { value: 1, label: "קבלה" },
  { value: 2, label: "חשבונית" },
  { value: 3, label: "חשבונית/קבלה זיכוי" },
  { value: 4, label: "קבלה זיכוי" },
  { value: 5, label: "חשבונית זיכוי" },
];

const paymentMethods = [
  { value: 0, label: "מזומן" },
  { value: 1, label: "שיק" },
  { value: 3, label: "העברה" },
  { value: 4, label: "אשראי" },
  { value: 5, label: "אפליקציה" },
];

const renderToggle = ({
  input,
  label,
  meta: { touched, error },
  type = "text",
}) => {
  const isHebrew = i18n.language === "he";
  const dir = isHebrew ? "rtl" : "ltr";
  return (
    <ToggleContainer isRtl={isHebrew}>
      <ToggleText>{label}</ToggleText>
      <Toggle checked={input.value} onChange={input.onChange} />
    </ToggleContainer>
  );
};

const NewIncome = ({
  isModalOpen,
  setIsModalOpen,
  handleSubmit,
  create,
  getVehicles,
  vehicles,
  isVehiclesLoaded,
  change,
  paymentMethod,
  documentType,
  getDocumentNumber,
  isFundingBodiesLoaded,
  fundingBodies,
  user,
  getSchoolFundingBodies,
  clientId,
  getPaymentRequests,
  paymentRequest,
  updatePaymentRequestStatus,
  getStudentEvents,
  updateStudentFundingBodyStatus,
}) => {
  const [sum, setSum] = useState(0);
  const vehicleOptions = useVehicles(isVehiclesLoaded, getVehicles, vehicles);
  const [paymentRequestOptions, setPaymentRequestOptions] = useState([]);
  const [paymentRequests, setPaymentRequests] = useState([]);
  const [paymentRequestData, setPaymentRequestData] = useState([]);
  const fundingBodiesOptions = useFundingBodies(
    isFundingBodiesLoaded,
    getSchoolFundingBodies,
    fundingBodies
  );

  useEffect(() => {
    async function fetchData() {
      const requests = await getPaymentRequests(clientId.value);
      setPaymentRequests(requests);
      setPaymentRequestOptions(
        requests
          .filter((x) => x.status === 0)
          .map((x) => ({
            value: x.id,
            label: `₪${x.amount} - ${x.documentNumber}`,
          }))
      );
      setPaymentRequestData([]);
    }

    if (typeof clientId !== "undefined" && clientId !== 999) {
      fetchData();
    }
  }, [clientId]);

  useEffect(() => {
    if (
      paymentMethod?.value === 1 ||
      paymentMethod?.value === 4 ||
      paymentMethod?.value === 3
    ) {
      change("dateOfMaturity", new Date());
    }
  }, [paymentMethod]);

  useEffect(() => {
    async function fetchData() {
      if (typeof documentType !== "undefined") {
        const a = await getDocumentNumber(documentType.value);

        change("documentNumber", a);
      }
    }

    fetchData();
  }, [documentType]);

  useEffect(() => {
    if (typeof paymentRequest !== "undefined") {
      change(
        "amount",
        paymentRequests.find((x) => x.id === paymentRequest.value).amount
      );
      setPaymentRequestData(
        JSON.parse(
          paymentRequests.find((x) => x.id === paymentRequest.value).invoiceData
        )
      );
    }
  }, [paymentRequest]);

  const fundingBodyCreateAction = async (formValues) => {
    await updatePaymentRequestStatus(formValues.paymentRequest.value, 1);
    let incomeToSave = {
      ...formValues,
      clientId: formValues.clientId.value,
      documentType: formValues.documentType.value,
      date: new Date(formValues.date),
      isFundingBody: 1,
      createUsername: `${user.firstName} ${user.lastName}`,
      paymentRequestId: formValues.paymentRequest.value,
    };
    if (documentType.value !== 2)
      incomeToSave = {
        ...incomeToSave,
        paymentMethod: formValues.paymentMethod.value,
      };

    // if (documentType.value === 0 || documentType.value === 1) {
    //   const allStudentsEvents = [];
    //   const studentIds = paymentRequestData.map((x) => x.studentId);
    //   for (let index = 0; index < studentIds.length; index++) {
    //     const element = studentIds[index];
    //     const events = await getStudentEvents(element);
    //     allStudentsEvents.push(
    //       ...events.filter(
    //         (x) => x.fundingBodyStatus === formValues.paymentRequest.value
    //       )
    //     );
    //   }
    //   const updateEventActions = [];

    //   for (let index = 0; index < allStudentsEvents.length; index++) {
    //     const element = allStudentsEvents[index];
    //     updateEventActions.push(updateStudentFundingBodyStatus(element.id, 1));
    //   }
    //   await Promise.all(updateEventActions);
    // }
    await create(incomeToSave);
    setIsModalOpen(false);
  };

  return (
    <SivanModal
      titleText="הכנסה חדשה"
      modalIsOpen={isModalOpen}
      closeModal={() => setIsModalOpen(false)}
      style={{ direction: "rtl" }}
    >
      <Body onSubmit={handleSubmit(fundingBodyCreateAction)}>
        <Section basicData noBorder>
          <Row>
            <Field
              name="date"
              component={DefaultDateTimePicker}
              placeholder="תאריך ושעה"
            />
            <Field
              name="clientId"
              component={DefaultSelect}
              placeholder="לקוח"
              options={fundingBodiesOptions}
            />
            <Field
              name="paymentRequest"
              component={DefaultSelect}
              placeholder="דרישת תשלום"
              options={paymentRequestOptions}
            />
            <Field
              name="description"
              component={NotesInput}
              placeholder="תאור"
            />
            <Field
              name="quantity"
              component={DefaultWrappedWithField}
              placeholder="כמות"
              type="number"
            />
            <Field
              name="purchaseOrderNumber"
              component={DefaultWrappedWithField}
              placeholder="הזמנת רכש מספר"
              type="number"
            />
            <Field
              name="auctionNumber"
              component={DefaultWrappedWithField}
              placeholder="מכרז מספר"
              type="number"
            />
            <Field
              name="isEquipmentAndAssets"
              component={renderToggle}
              label="ציוד ונכסים קבועים"
            />
            <Field
              name="vehicleId"
              component={DefaultSelect}
              placeholder="רכב"
              options={[{ value: 999, label: "כל הרכבים" }, ...vehicleOptions]}
            />
          </Row>
          <Row>
            <Field
              name="documentType"
              component={DefaultSelect}
              placeholder="סוג"
              options={documentTypes}
            />
            <Field
              name="vat"
              component={DefaultWrappedWithField}
              placeholder="מע״מ"
              disabled
            />
            <Field
              name="documentNumber"
              component={DefaultWrappedWithField}
              placeholder="מספר מסמך"
              type="number"
              disabled
            />
            <Field name="notes" component={NotesInput} placeholder="הערה" />
          </Row>
          <Row>
            {documentType?.value !== 2 && (
              <Field
                name="paymentMethod"
                component={DefaultSelect}
                placeholder="סוג תשלום"
                options={paymentMethods}
              />
            )}
            <Field
              name="amount"
              component={DefaultWrappedWithField}
              placeholder="סכום"
              type="number"
              disabled
            />
            <Input
              placeholder="סכום ללא מעמ"
              value={sum}
              disabled
              isRtl={true}
            />
            {(paymentMethod === paymentMethods[1] ||
              paymentMethod === paymentMethods[2]) && (
              <Field
                name="bank"
                component={DefaultWrappedWithField}
                placeholder="בנק"
                type="number"
              />
            )}
            {(paymentMethod === paymentMethods[1] ||
              paymentMethod === paymentMethods[2]) && (
              <Field
                name="bankBranch"
                component={DefaultWrappedWithField}
                placeholder="סניף"
                type="number"
              />
            )}
            {paymentMethod === paymentMethods[1] && (
              <Field
                name="checkNumber"
                component={DefaultWrappedWithField}
                placeholder="מס המחאה"
                type="number"
              />
            )}
            {(paymentMethod === paymentMethods[1] ||
              paymentMethod === paymentMethods[2] ||
              paymentMethod === paymentMethods[4]) && (
              <Field
                name="accountId"
                component={DefaultWrappedWithField}
                placeholder="חשבון"
                type="number"
              />
            )}
            {paymentMethod === paymentMethods[2] && (
              <Field
                name="reference"
                component={DefaultWrappedWithField}
                placeholder="אסמכתא"
                type="text"
              />
            )}
            {(paymentMethod === paymentMethods[3] ||
              paymentMethod === paymentMethods[4]) && (
              <Field
                name="confirmationNumber"
                component={DefaultWrappedWithField}
                placeholder="מספר אישור"
                type="text"
              />
            )}
            {paymentMethod === paymentMethods[3] && (
              <Field
                name="confirmationName"
                component={DefaultWrappedWithField}
                placeholder="שם מאשר"
                type="text"
              />
            )}
            {(paymentMethod === paymentMethods[1] ||
              paymentMethod === paymentMethods[2] ||
              paymentMethod === paymentMethods[3]) && (
              <Field
                name="dateOfMaturity"
                component={DefaultDatePicker}
                placeholder="תאריך פרעון"
              />
            )}
            {paymentMethod === paymentMethods[4] && (
              <Field
                name="appName"
                component={DefaultWrappedWithField}
                placeholder="אפליקציה"
                type="text"
              />
            )}
          </Row>
        </Section>
        <CreateButton text="צור" type="submit" />
      </Body>
      {paymentRequestData.length > 0 && (
        <BalanceTable>
          <tr>
            <td>תלמיד</td>
            <td>שיעורים</td>
            <td>מ.פ.</td>
            <td>טסטים</td>
            <td>הסכם</td>
            <td>סה״כ</td>
          </tr>
          {paymentRequestData.map((x) => (
            <tr>
              <td>
                {x.name} {x.studentId}
              </td>
              <td>{x.lessons}</td>
              <td>{x.internalTests}</td>
              <td>{x.tests}</td>
              <td>{x.activeAgreementOpeningBalance}</td>
              <td>{x.total}</td>
            </tr>
          ))}
        </BalanceTable>
      )}
    </SivanModal>
  );
};

const selector = formValueSelector("NewFundingBodyIncome"); // <-- same as form name

function mapStateToProps(state, ownProps) {
  return {
    vehicles: state.VehiclesReducer.vehicles,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    providers: state.ProvidersReducer.providers,
    paymentMethod: selector(state, "paymentMethod"),
    documentType: selector(state, "documentType"),
    clientId: selector(state, "clientId"),
    paymentRequest: selector(state, "paymentRequest"),
    isProvidersLoaded: state.ProvidersReducer.isLoaded,
    user: state.session.user,
    fundingBodies: state.FundingBodiesReducer.fundingBodies,
    isFundingBodiesLoaded: state.FundingBodiesReducer.isLoaded,
    initialValues: {
      date: new Date(),
      vat: 17,
      quantity: 1,
      paymentMethod: paymentMethods[0],
      documentType: documentTypes[0],
      vehicleId: { value: 999, label: "כל הרכבים" },
      amount: 0,
      purchaseOrderNumber: null,
      auctionNumber: null,
    },
  };
}

export default connect(mapStateToProps, {
  create,
  getVehicles,
  getSchoolProviders,
  change,
  getSchoolFundingBodies,
  getDocumentNumber,
  getPaymentRequests,
  updatePaymentRequestStatus,
  getStudentEvents,
  updateStudentFundingBodyStatus,
})(
  reduxForm({
    form: "NewFundingBodyIncome",
    validate,
  })(NewIncome)
);
