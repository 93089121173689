import React, { useState, useEffect } from "react";
import i18n from "../../../lib/i18n/i18n";
import { connect } from "react-redux";
import { Container, Title } from "./fundingBodies.styles";
import NewFundingBodies from "./NewFundingBodies/NewFundingBodies";
import FundingBodiesData from "./FundingBodiesData/FundingBodiesData";
import {
  getSchoolFundingBodies,
  sendPaymentRequest,
} from "../../../actions/fundingBodiesActions";
import Table from "../../SivanPlusTable/SivanPlusTable";
import CreateButton from "../../UiComponents/Buttons/Default";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const vehicleColumns = [
  {
    Header: dictionary.table.name,
    accessor: "name",
  },
  {
    Header: dictionary.table.phone,
    accessor: "phoneNumber",
  },
  {
    Header: dictionary.table.openingBalance,
    accessor: "openingBalance",
  },
];

const FundingBodies = ({ fundingBodies, isLoaded, getSchoolFundingBodies }) => {
  const [isNewFundingBodiesModalOpen, setNewFundingBodiesModalOpen] =
    useState(false);
  const [isFundingBodyDetailsModalOpen, setFundingBodyDetailsModalOpen] =
    useState(false);
  const [fundingBodyData, setFundingBodyData] = useState(false);

  const onRowClick = async (rowObject) => {
    setFundingBodyDetailsModalOpen(true);
    setFundingBodyData(rowObject.original);
  };

  useEffect(() => {
    async function fetchData() {
      if (!isLoaded) {
        await getSchoolFundingBodies();
      }
    }
    fetchData();
  }, []);

  return (
    <Container>
      <NewFundingBodies
        isNewFundingBodiesModalOpen={isNewFundingBodiesModalOpen}
        setNewFundingBodiesModalOpen={setNewFundingBodiesModalOpen}
      />
      <FundingBodiesData
        isModalOpen={isFundingBodyDetailsModalOpen}
        closeModal={() => setFundingBodyDetailsModalOpen(false)}
        fundingBodyData={fundingBodyData}
      />
      <Title>
        גופי מימון
        <CreateButton
          onClick={() => setNewFundingBodiesModalOpen(true)}
          text="צור חדש"
        />
      </Title>
      <Table
        data={fundingBodies}
        componentColumns={vehicleColumns}
        onRowClick={onRowClick}
      />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    fundingBodies: state.FundingBodiesReducer.fundingBodies,
    isLoaded: state.FundingBodiesReducer.isLoaded,
  };
}

export default connect(mapStateToProps, {
  getSchoolFundingBodies,
  sendPaymentRequest,
})(FundingBodies);
