import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { reduxForm, change } from "redux-form";
import i18n from "../../../lib/i18n/i18n";
import Navbar from "../../Navbar/Navbar";
import Calendar from "./Calendar/Calendar";
import Students from "./Students/Students";
import Finance from "./Finance/Finance";
import General from "./General/General";
import Reports from "./Reports/Reports";
import Charts from "./Charts/Charts";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import {
  Body,
  AdditionalDetailsContainer,
  customStyles,
  Fields,
  ButtonContainer,
  Button,
} from "./permissions.styles";
import { getUsers } from "../../../actions/userActions";
import { getPermissions, updateUserPermissions } from "./actions";
import useTeacheres from "../../useComponents/useTeacherss";

const menuItems = ["יומן", "תלמידים", "פיננסי", "כללי", "דוחות", "תרשימים"];

const Permissions = ({
  handleSubmit,
  isUsersLoaded,
  getUsers,
  users,
  change,
}) => {
  const [currentOpenedSettings, setCurrentOpenedSettings] = useState("יומן");
  const { t, i18n } = useTranslation("common");
  const teachersOptions = useTeacheres(
    isUsersLoaded,
    getUsers,
    users.filter((x) => x.userType !== 4)
  );
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    async function fetchData() {
      if (currentUser.value) {
        const permissions = await getPermissions(currentUser.value);
        for (const property in permissions) {
          if (property === "userId" || property === "id") {
            change(property, permissions[property]);
          } else if (
            property === "autoCompleteLesson" ||
            property === "autoCompleteInternalTest" ||
            property === "autoCompleteTest" ||
            property === "lessonWithoutTheoryAmount" ||
            property === "autoCompleteAppointment"
          ) {
            change(property, permissions[property]);
          } else {
            change(property, !!+permissions[property]);
          }
        }
      }
    }
    fetchData();
  }, [currentUser]);

  const userCreateAction = async (formValues) => {
    await updateUserPermissions(formValues.userId, formValues);
  };

  return (
    <Body onSubmit={handleSubmit(userCreateAction)}>
      <Navbar
        currentView={currentOpenedSettings}
        setCurrentOpenedView={setCurrentOpenedSettings}
        menuOptions={menuItems}
      />
      <Select
        value={currentUser}
        isRtl={i18n.language === "he"}
        onChange={setCurrentUser}
        options={teachersOptions}
        placeholder="בחר משתמש"
        styles={customStyles}
      />
      {currentOpenedSettings === "יומן" && <Calendar />}
      {currentOpenedSettings === "כללי" && <General />}
      {currentOpenedSettings === "תלמידים" && <Students />}
      {currentOpenedSettings === "פיננסי" && <Finance />}
      {currentOpenedSettings === "דוחות" && <Reports />}
      {currentOpenedSettings === "תרשימים" && <Charts />}

      <Fields>
        <AdditionalDetailsContainer></AdditionalDetailsContainer>
      </Fields>
      <ButtonContainer>
        <Button type="submit">{t("buttons.save")}</Button>
      </ButtonContainer>
    </Body>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isUsersLoaded: state.UsersReducer.isLoaded,
    users: state.UsersReducer.users,
  };
}

export default connect(mapStateToProps, {
  getUsers,
  change,
})(
  reduxForm({
    form: "permissions", // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize: true, // this is needed!!
  })(Permissions)
);
