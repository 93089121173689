import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import i18n from "../../../../../lib/i18n/i18n";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import {
  Body,
  InvoiceCompanyNameHeader,
  InvoiceType,
  InvoiceStatus,
  Line,
  InvoiceBody,
  InvoiceHeader,
  InvoiceContainer,
  Table,
  TableRow,
  TableHeader,
  TableCell,
  PrintFooter,
  ClientDataText,
  ClientData,
  NotesContainer,
  NotesText,
} from "./incomeDetails.styles";
import {
  updateInvoicePrinted,
  deleteItem,
} from "../../../../../actions/incomesAction";
import Button from "../../../../UiComponents/Buttons/Default";
import { getVehicles } from "../../../../../actions/vehicleActions";
import useVehicles from "../../../../useComponents/useVehicles";
import { getSchoolClients } from "../../../../../actions/clientsActions";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

const documentTypes = [
  { value: 0, label: "חשבונית/קבלה" },
  { value: 1, label: "קבלה" },
  { value: 2, label: "חשבונית" },
];

const paymentMethods = [
  { value: 0, label: "מזומן" },
  { value: 1, label: "שיק" },
  { value: 3, label: "העברה" },
  { value: 4, label: "אשראי" },
  { value: 5, label: "אפליקציה" },
];

const FundingBodiesDetails = ({
  handleSubmit,
  getVehicles,
  vehicles,
  isVehiclesLoaded,
  paymentMethod,
  documentType,
  isClientsLoaded,
  clients,
  change,
  providerData,
  getSchoolClients,
  user,
  documentNumber,
  updateInvoicePrinted,
  amount,
}) => {
  const [clientsOptions, setClientsOptions] = useState([]);
  const [sum, setSum] = useState(0);
  const [clientData, setClientData] = useState(0);
  const [invoiceType, setInvoiceType] = useState("תצוגה מקדימה");
  const [providersOptions, setProvidersOptions] = useState([]);
  const vehicleOptions = useVehicles(isVehiclesLoaded, getVehicles, vehicles);

  const handlePrint = async (type) => {
    if (type === "העתק") {
      setInvoiceType("(העתק) ");
    } else {
      if (providerData.isOriginalPrinted) {
        setInvoiceType(" (העתק נאמן למקור) ");
      } else {
        const response = await updateInvoicePrinted(providerData);
        if (response.isNew) {
          setInvoiceType(" (העתק נאמן למקור) ");
        } else {
          setInvoiceType(" (מקור) ");
        }
      }
    }

    print();
  };

  const print = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => setInvoiceType("תצוגה מקדימה"),
    documentTitle: `${documentType?.label} מס / ${providerData.documentNumber}`,
  });

  const componentRef = useRef();

  useEffect(() => {
    async function fetchData() {
      if (!isClientsLoaded) {
        await getSchoolClients();
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (clients.length) {
      const options = clients.map((x) => ({ value: x.id, label: x.name }));
      const client = clients.find(
        (x) => x.id.toString() === providerData.clientId
      );
      setClientData(client);
      setClientsOptions(options);
      change("clientId", { value: client.id, label: client.name });
    }
  }, [clients]);

  const paymentMethodDetails = () => {
    if (providerData.paymentMethod === 0) {
      return "";
    } else if (providerData.paymentMethod === 1) {
      return ` חשבון: ${providerData.accountId}, סניף: ${providerData.bankBranch}, בנק: ${providerData.bank}, שיק: ${providerData.checkNumber}`;
    } else if (providerData.paymentMethod === 3) {
      return ` חשבון: ${providerData.accountId}, סניף: ${providerData.bankBranch}, בנק: ${providerData.bank}, אסמכתא: ${providerData.reference}`;
    } else if (providerData.paymentMethod === 4) {
      return ` מספר אישור: ${providerData.confirmationNumber}, מאשר: ${providerData.confirmationName}`;
    } else if (providerData.paymentMethod === 5) {
      return ` חשבון: ${providerData.accountId}, מספר אישור: ${providerData.confirmationNumber}, סוג: ${providerData.appName}`;
    }
  };

  const fundingBodyCreateAction = async (formValues) => {};
  return (
    <Body onSubmit={handleSubmit(fundingBodyCreateAction)}>
      {/* <Section basicData noBorder>
        <Row>
          <Field
            name="date"
            component={DefaultDatePicker}
            placeholder="תאריך ושעה"
          />
          <Field
            name="clientId"
            component={DefaultSelect}
            placeholder="לקוח"
            options={clientsOptions}
            disabled
          />
          <Field name="description" component={NotesInput} placeholder="תאור" />
          <Field
            name="quantity"
            component={DefaultWrappedWithField}
            placeholder="כמות"
            type="number"
            disabled
          />
          <Field
            name="isEquipmentAndAssets"
            component={renderToggle}
            label="ציוד ונכסים קבועים"
          />
          <Field
            name="vehicleId"
            component={DefaultSelect}
            placeholder="רכב"
            options={[{ value: 999, label: "כל הרכבים" }, ...vehicleOptions]}
            disabled
          />
        </Row>
        <Row>
          <Field
            name="documentType"
            component={DefaultSelect}
            placeholder="סוג"
            options={documentTypes}
            disabled
          />
          <Field name="isCredit" component={renderToggle} label="זיכוי" />
          <Field
            name="vat"
            component={DefaultWrappedWithField}
            placeholder="מע״מ"
            disabled
          />
          <Field
            name="documentNumber"
            component={DefaultWrappedWithField}
            placeholder="מספר מסמך"
            type="number"
            disabled
          />
          <Field
            name="notes"
            component={NotesInput}
            placeholder="הערה"
            disabled
          />
        </Row>
        <Row>
          {documentType?.value !== 2 && (
            <Field
              name="paymentMethod"
              component={DefaultSelect}
              placeholder="סוג תשלום"
              options={paymentMethods}
              disabled
            />
          )}
          <Field
            name="amount"
            component={DefaultWrappedWithField}
            placeholder="סכום"
            type="number"
            disabled
          />
          <Input placeholder="סהכ לחיוב" value={sum} disabled />
          {(paymentMethod === paymentMethods[1] ||
            paymentMethod === paymentMethods[2]) && (
            <Field
              name="bank"
              component={DefaultWrappedWithField}
              placeholder="בנק"
              type="number"
            />
          )}
          {(paymentMethod === paymentMethods[1] ||
            paymentMethod === paymentMethods[2]) && (
            <Field
              name="bankBranch"
              component={DefaultWrappedWithField}
              placeholder="סניף"
              type="number"
            />
          )}
          {paymentMethod === paymentMethods[1] && (
            <Field
              name="checkNumber"
              component={DefaultWrappedWithField}
              placeholder="מס המחאה"
              type="number"
            />
          )}
          {(paymentMethod === paymentMethods[1] ||
            paymentMethod === paymentMethods[4]) && (
            <Field
              name="accountId"
              component={DefaultWrappedWithField}
              placeholder="חשבון"
              type="number"
            />
          )}
          {paymentMethod === paymentMethods[2] && (
            <Field
              name="reference"
              component={DefaultWrappedWithField}
              placeholder="אסמכתא"
              type="text"
            />
          )}
          {(paymentMethod === paymentMethods[3] ||
            paymentMethod === paymentMethods[4]) && (
            <Field
              name="confirmationNumber"
              component={DefaultWrappedWithField}
              placeholder="מספר אישור"
              type="text"
            />
          )}
          {paymentMethod === paymentMethods[3] && (
            <Field
              name="confirmationName"
              component={DefaultWrappedWithField}
              placeholder="שם מאשר"
              type="text"
            />
          )}
          {(paymentMethod === paymentMethods[1] ||
            paymentMethod === paymentMethods[3]) && (
            <Field
              name="dateOfMaturity"
              component={DefaultDatePicker}
              placeholder="תאריך פרעון"
              isNoTime
            />
          )}
          {paymentMethod === paymentMethods[4] && (
            <Field
              name="appName"
              component={DefaultWrappedWithField}
              placeholder="אפליקציה"
              type="text"
            />
          )}
        </Row>
      </Section> */}
      <InvoiceContainer ref={componentRef}>
        <InvoiceHeader>
          <InvoiceCompanyNameHeader dir="auto">
            <span>{user.schoolFullName}</span>
          </InvoiceCompanyNameHeader>
          <br />
          <span>
            {user.isCompany ? "חפ:" : "עוסק מורשה:"} {user.bnNumber}
          </span>
          <span>
            {user.schoolPhone}, {user.schoolEmail}
          </span>
          <span>{user.schoolAddress}</span>
          <br />
          <InvoiceType>
            {documentType?.label} מס / {providerData.documentNumber}
            <InvoiceStatus> {invoiceType}</InvoiceStatus>
          </InvoiceType>
          <Line />
        </InvoiceHeader>
        <ClientData>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ClientDataText>
              לכבוד: {clientData.name} ({clientData.clientId})
            </ClientDataText>
            <ClientDataText>
              תאריך: {moment(providerData.date).format("DD/MM/YYYY HH:mm")}
            </ClientDataText>
          </div>
          <ClientDataText>
            פרטי כתובת: {clientData.address},{clientData.city}
          </ClientDataText>
        </ClientData>
        {(documentType?.value === 2 || documentType?.value === 0) && (
          <InvoiceBody>
            <Table>
              <TableRow style={{ background: "dimgrey", color: "white" }}>
                <TableHeader alignRight>מוצר/שירות</TableHeader>
                <TableHeader style={{ width: "8%", textAlign: "center" }}>
                  סה״כ
                </TableHeader>
              </TableRow>
              <TableRow>
                <TableCell specialBackground style={{ textAlign: "right" }}>
                  {providerData.description}
                </TableCell>
                <TableCell specialBackground>
                  {(amount / 1.17).toFixed(2)?.toLocaleString()}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell alignLeft bold>
                  17% מע״מ:
                </TableCell>
                <TableCell specialBackground bold>
                  {(amount - amount / 1.17).toFixed(2)?.toLocaleString()}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell alignLeft bold>
                  סה״כ כולל מע״מ:
                </TableCell>
                <TableCell specialBackground bold>
                  {amount?.toLocaleString()}
                </TableCell>
              </TableRow>
            </Table>
            <Line />
          </InvoiceBody>
        )}
        {(documentType?.value === 1 || documentType?.value === 0) && (
          <InvoiceBody>
            <Table>
              <TableRow style={{ background: "dimgrey", color: "white" }}>
                <TableHeader alignRight>סוג תשלום</TableHeader>
                {providerData.paymentMethod !== 0 && (
                  <TableHeader alignRight>פרטים נוספים</TableHeader>
                )}
                {providerData.paymentMethod !== 0 &&
                  (paymentMethod?.value === 3 ||
                    paymentMethod?.value === 4 ||
                    paymentMethod?.value === 1) && (
                    <TableHeader>תאריך פרעון</TableHeader>
                  )}
                <TableHeader style={{ width: "8%", textAlign: "center" }}>
                  סה״כ
                </TableHeader>
              </TableRow>
              <TableRow>
                <TableCell specialBackground style={{ textAlign: "right" }}>
                  {paymentMethod?.label}
                </TableCell>
                {paymentMethod?.value !== 0 && (
                  <TableCell specialBackground style={{ textAlign: "right" }}>
                    {paymentMethodDetails()}
                  </TableCell>
                )}
                {providerData.paymentMethod !== 0 &&
                  (paymentMethod?.value === 3 ||
                    paymentMethod?.value === 4 ||
                    paymentMethod?.value === 1) && (
                    <TableCell specialBackground>
                      {new Date(
                        providerData.dateOfMaturity
                      ).toLocaleDateString()}
                    </TableCell>
                  )}
                <TableCell specialBackground>
                  {amount.toLocaleString()}
                </TableCell>
              </TableRow>
            </Table>
            <NotesContainer>
              <NotesText>{providerData.notes}</NotesText>
            </NotesContainer>
            <Line />
          </InvoiceBody>
        )}
        <PrintFooter>
          הופק באמצעות קמע מחשבים סיון בע"מ. טלפון 0537-222-228
        </PrintFooter>
      </InvoiceContainer>
      <Button
        text="הפק העתק"
        onClick={() => handlePrint("העתק")}
        marginRight={1}
      />
      <Button text="הפק מקור" onClick={() => handlePrint("מקור")} />
    </Body>
  );
};

const selector = formValueSelector("incomeDetails"); // <-- same as form name
function mapStateToProps(state, ownProps) {
  return {
    vehicles: state.VehiclesReducer.vehicles,
    user: state.session.user,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    providers: state.ProvidersReducer.providers,
    paymentMethod: selector(state, "paymentMethod"),
    amount: selector(state, "amount"),
    documentType: selector(state, "documentType"),
    clientId: selector(state, "clientId"),
    isCredit: selector(state, "isCredit"),
    documentNumber: selector(state, "documentNumber"),
    isProvidersLoaded: state.ProvidersReducer.isLoaded,
    clients: state.ClientsReducer.clients,
    isClientsLoaded: state.ClientsReducer.isLoaded,
    initialValues: {
      ...ownProps.providerData,
      paymentMethod: paymentMethods.find(
        (x) => x.value === ownProps.providerData.paymentMethod
      ),
      documentType: documentTypes.find(
        (x) => x.value === ownProps.providerData.documentType
      ),
      vehicleId: {
        value: ownProps.providerData.vehicleId,
        label: ownProps.providerData.vehicleNumber
          ? ownProps.providerData.vehicleNumber
          : "כל הרכבים",
      },
    },
  };
}

export default connect(mapStateToProps, {
  updateInvoicePrinted,
  deleteItem,
  getVehicles,
  getSchoolClients,
  change,
})(
  reduxForm({
    form: "incomeDetails",
  })(FundingBodiesDetails)
);
