import styled from "styled-components";

export const Container = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const FieldTitle = styled.span`
  flex: 0 50%;
`;
export const FieldValue = styled.span`
  flex: 0 50%;
`;
export const FieldContainer = styled.div`
  flex: 0 50%;
  display: flex;
  flex-direction: row-reverse;
`;
export const StudentDataContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
`;
