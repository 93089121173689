import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import i18n from "../../../lib/i18n/i18n";
import {
  Container,
  Title,
  TitleText,
  CreateButton,
  Tag,
} from "./vehicles.styles";
import {
  getVehicles,
  getVehicleScheduler,
} from "../../../actions/vehicleActions";
import EditVehicle from "./EditVehicle/EditVehicle";
import { SelectColumnFilter } from "../../SivanPlusTable/filters";
import Table from "../../SivanPlusTable/SivanPlusTable";
import FadeLoader from "react-spinners/FadeLoader";
import { getBranches } from "../../../actions/branchActions";
const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const vehicleColumns = [
  {
    Header: dictionary.table.vehicleNumber,
    accessor: "vehicleNumber",
  },
  {
    Header: dictionary.table.description,
    accessor: "description",
  },
  {
    Header: dictionary.table.branch,
    accessor: "branchName",
  },
  {
    Header: dictionary.table.teachers,
    accessor: "teacherNames",
    // Cell: ({ row: { original } }) => {
    //   return original.teacherNames
    //     .split(",")
    //     .map((x) => <Tag key={x}>{x}</Tag>);
    // },
  },
  {
    Header: dictionary.table.active,
    accessor: "active",
    Cell: ({ row: { original } }) => {
      return original.active === 1
        ? dictionary.users.active
        : dictionary.users.inactive;
    },
    Filter: SelectColumnFilter,
    filter: "equals",
  },
];

const Vehicles = ({
  getVehicles,
  vehicles,
  history,
  getVehicleScheduler,
  getBranches,
  isLoaded,
  createVehicle,
  editVehicle,
}) => {
  const [isEditVehicleModalOpen, setEditVehicleModalIsOpen] = useState(false);
  const [vehicleData, setVehicleData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation("common");

  useEffect(() => {
    async function fetchData() {
      if (!isLoaded) {
        await getVehicles();
      }
      setIsLoading(false);
    }
    fetchData();
  }, []);

  const onRowClick = async (rowObject) => {
    if (editVehicle) {
      const optionsArray = [];
      const allBranches = await getBranches();
      allBranches.map(({ id, name }) =>
        optionsArray.push({ value: id, label: name })
      );
      const scheduler = await getVehicleScheduler(rowObject.original.id);
      for (var key in scheduler) {
        // skip loop if the property is from prototype
        if (!scheduler.hasOwnProperty(key)) continue;
        scheduler[key] = optionsArray.find(
          ({ value, label }) => value === scheduler[key]
        );
      }
      setVehicleData({
        ...rowObject.original,
        ...scheduler,
        branchOptions: optionsArray,
      });
      setEditVehicleModalIsOpen(true);
    }
  };

  return (
    <Container>
      {isLoading && (
        <div
          style={{
            height: "78%",
            width: "74%",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FadeLoader size={100} color={"#123abc"} loading={true} />
        </div>
      )}
      {isEditVehicleModalOpen && (
        <EditVehicle
          isEditVehicleModalOpen={isEditVehicleModalOpen}
          setEditVehicleModalIsOpen={setEditVehicleModalIsOpen}
          vehicleData={vehicleData}
        />
      )}
      <Title>
        <TitleText>{t("vehicles.title")}</TitleText>
        {createVehicle ? (
          <CreateButton onClick={() => history.push("/createVehicle")}>
            {t("vehicles.createButton")}
          </CreateButton>
        ) : null}
      </Title>
      <Table
        data={vehicles}
        componentColumns={vehicleColumns}
        onRowClick={onRowClick}
      />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    vehicles: state.VehiclesReducer.vehicles,
    isLoaded: state.VehiclesReducer.isLoaded,
    createVehicle: state.session.user.createVehicle,
    editVehicle: state.session.user.editVehicle,
  };
}

export default withRouter(
  connect(mapStateToProps, { getVehicles, getVehicleScheduler, getBranches })(
    Vehicles
  )
);
