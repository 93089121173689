import styled from "styled-components";
import i18n from "../../../../../lib/i18n/i18n";
import { isMobile } from "react-device-detect";
const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";
export const Body = styled.form`
  width: 100%;
  text-align: center;
`;

export const Section = styled.div`
  display: flex;
  direction: rtl;
  flex-direction: row;
  border: ${(props) => (props.noBorder ? "" : "1px solid #e5e5e5")};
  border-radius: ${(props) => (props.noBorder ? "" : "5px")};
  padding: 1vh;
  justify-content: space-around;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  align-items: flex-end;
`;

export const ToggleContainer = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  width: 100%;
  height: 6vh;
  margin-top: 1vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1vw;
  padding-right: 1vw;
  flex-direction: row;
`;

export const ToggleText = styled.span`
  font-size: 1.55vh;
`;
