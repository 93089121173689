import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import i18n from "../../../../../../lib/i18n/i18n";
import { useTranslation } from "react-i18next";
import {
  Body,
  AdditionalDetailsContainer,
  Button,
  Fields,
  ButtonContainer,
} from "./general.styles";
import { getVehicles } from "../../../../../../actions/vehicleActions";
import { getSchoolFundingBodies } from "../../../../../../actions/fundingBodiesActions";
import { getLicenseTypes } from "../../../../../../actions/licenseTypes";
import { updateTextBook } from "../../../../../../actions/textBooksActions";
import useTeacheres from "../../../../../useComponents/useTeachers";
import useFundingBodies from "../../../../../useComponents/useFundingBodies";
import useVehicles from "../../../../../useComponents/useVehicles";
import useUsers from "../../../../../useComponents/useTeacherss";
import { getUsers } from "../../../../../../actions/userActions";
import cleanObject from "../../../../../../utils/cleanObjectForServer";
import validate from "./validate";
import moment from "moment";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const obligoOptions = [
  { value: "none", label: dictionary.newStudent.none },
  { value: "notify", label: dictionary.newStudent.notify },
  { value: "block", label: dictionary.newStudent.block },
];

const TextBookGeneral = ({
  handleSubmit,
  getVehicles,
  renderSelect,
  renderInput,
  renderToggle,
  getLicenseTypes,
  licenseTypes,
  updateTextBook,
  textBookData,
  isUsersLoaded,
  vehicles,
  isVehiclesLoaded,
  users,
  change,
  vehicleId,
  setStudentLicense,
  getUsers,
  history,
  isAllowedToUpdateObligo,
  getSchoolFundingBodies,
  isFundingBodiesLoaded,
  fundingBodies,
  user,
  renderDatePickerField,
}) => {
  const { t, i18n } = useTranslation("common");
  const [licenseTypesOptions, setLicenseTypesOptions] = useState(licenseTypes);
  const teachersOptions = useTeacheres(isUsersLoaded, getUsers, users);
  const usersOptions = useUsers(isUsersLoaded, getUsers, users);
  const vehicleOptions = useVehicles(isVehiclesLoaded, getVehicles, vehicles);
  const fundingBodiesOptions = useFundingBodies(
    isFundingBodiesLoaded,
    getSchoolFundingBodies,
    fundingBodies
  );

  useEffect(() => {
    change(
      "teacherId",
      teachersOptions.find(
        (x) => x.value.toString() === textBookData?.teacherId?.toString()
      )
    );
  }, [teachersOptions]);

  useEffect(() => {
    change(
      "vehicleId",
      vehicleOptions.find(
        (x) => x.value.toString() === textBookData?.vehicleId?.toString()
      )
    );
  }, [vehicleOptions]);

  useEffect(() => {
    change(
      "fundingBody",
      fundingBodiesOptions.find(
        (x) => x.value.toString() === textBookData.fundingBody.toString()
      )
    );
  }, [fundingBodiesOptions]);

  useEffect(() => {
    if (textBookData.updatedBy)
      change(
        "updatedBy",
        usersOptions.find(
          (x) => x.label.toString() === textBookData.updatedBy.toString()
        )
      );
    if (textBookData.createdBy)
      change(
        "createdBy",
        usersOptions.find(
          (x) => x.label.toString() === textBookData.createdBy.toString()
        )
      );
  }, [usersOptions]);

  useEffect(() => {
    async function fetchData() {
      let vehicle = vehicleId;
      if (!vehicleId) {
        vehicle = textBookData.vehicleId;
      }
      if (licenseTypes.length === 0) {
        await getLicenseTypes();
      } else if (typeof vehicle !== "string") {
        const vehicleLicenseType = vehicles.find(
          (x) => x.id.toString() === vehicle.value.toString()
        ).licenseType;
        const license = licenseTypes.find(
          (x) => x.id === parseInt(vehicleLicenseType)
        );
        setStudentLicense(license.vehicleType);
        change("licenseId", { value: license.id, label: license.name });
      } else if (typeof vehicle === "string") {
        const vehicleLicenseType = vehicles.find(
          (x) => x.id.toString() === vehicle
        ).licenseType;
        const license = licenseTypes.find(
          (x) => x.id === parseInt(vehicleLicenseType)
        );
        setStudentLicense(license.vehicleType);
        change("licenseId", { value: license.id, label: license.name });
      }
    }

    fetchData();
  }, [vehicleId, licenseTypes]);

  const userCreateAction = async (formValues) => {
    // setIsLoading(true);
    let basicData = {
      id: formValues.id,
      active: formValues.active,
      obligo: formValues.obligo,
      obligoAmount: formValues.obligoAmount,
      passedInternalTest: formValues.passedInternalTest,
      teacherId: formValues.teacherId,
      vehicleId: formValues.vehicleId,
      licenseId: formValues.licenseId,
      openingBalance: formValues.openingBalance,
      refresh: formValues.refresh,
      renewal: formValues.renewal,
      conversion: formValues.conversion,
      fundingBody: formValues.fundingBody,
      control: formValues.control,
      remaininglessonsInMinutes: formValues.remaininglessonsInMinutes,
      remainingTests: formValues.remainingTests,
      remainingInternalTests: formValues.remainingInternalTests,
      minLessonsForInternalTest: formValues.minLessonsForInternalTest,
    };

    if (user.userType === 4) {
      basicData = {
        ...basicData,
        updatedBy: formValues?.updatedBy?.label,
        updatedAt: formValues.updatedAt,
        createdBy: formValues?.createdBy?.label,
        createdAt: formValues.createdAt,
      };
    }

    if (await updateTextBook(cleanObject(basicData)))
      history.push("/studentSearch");
    // setIsLoading(false);
  };
  return (
    <Body onSubmit={handleSubmit(userCreateAction)}>
      <Fields>
        <AdditionalDetailsContainer>
          {/* <Field
            name="fundingBody"
            component={renderSelect}
            placeholder="גוף מימון"
            options={vehicleOptions}
          /> */}
          <Field label="פעיל" component={renderToggle} name="active" />
          <Field
            name="obligo"
            component={renderSelect}
            placeholder={t("newStudent.obligo")}
            options={obligoOptions}
            disabled={isAllowedToUpdateObligo === 0}
          />
          <Field
            name="obligoAmount"
            component={renderInput}
            placeholder={t("newStudent.obligoAmount")}
            type="number"
            disabled={isAllowedToUpdateObligo === 0}
          />
          <Field
            label="עבר טסט פנימי"
            component={renderToggle}
            name="passedInternalTest"
          />
          <Field
            name="fundingBody"
            component={renderSelect}
            placeholder="גוף מימיון"
            options={fundingBodiesOptions}
          />
          <Field label="רענון" component={renderToggle} name="refresh" />
          <Field label="חידוש" component={renderToggle} name="renewal" />
          <Field label="המרה" component={renderToggle} name="conversion" />
          <Field label="שליטה" component={renderToggle} name="control" />
        </AdditionalDetailsContainer>
        <AdditionalDetailsContainer>
          <Field
            name="teacherId"
            component={renderSelect}
            placeholder="מורה"
            options={teachersOptions}
          />
          <Field
            name="vehicleId"
            component={renderSelect}
            placeholder="רכב"
            options={vehicleOptions}
          />
          <Field
            name="licenseId"
            component={renderSelect}
            placeholder="רישיון"
            options={licenseTypesOptions}
            disabled
          />
          <Field
            name="openingBalance"
            component={renderInput}
            placeholder={t("newStudent.openingBalance")}
            type="number"
            isNegativeNumberPossible
          />
          <Field
            name="remaininglessonsInMinutes"
            component={renderInput}
            placeholder="יתרת שיעורים בדקות"
            type="number"
          />
          <Field
            name="remainingTests"
            component={renderInput}
            placeholder="יתרת טסטים"
            type="number"
          />
          <Field
            name="remainingInternalTests"
            component={renderInput}
            placeholder="יתרת מ.פ"
            type="number"
          />
          <Field
            name="minLessonsForInternalTest"
            component={renderInput}
            placeholder="מספר שיעורים מינימלי ל מ.פ"
            type="number"
          />
          {user.userType === 4 && (
            <Field
              name="createdBy"
              component={renderSelect}
              placeholder="יוצר"
              options={usersOptions}
            />
          )}
          {user.userType === 4 && (
            <Field
              name="createdAt"
              component={renderDatePickerField}
              placeholder="נוצר ב"
            />
          )}
          {user.userType === 4 && (
            <Field
              name="updatedBy"
              component={renderSelect}
              placeholder="מעדכן"
              options={usersOptions}
            />
          )}
          {user.userType === 4 && (
            <Field
              name="updatedAt"
              component={renderDatePickerField}
              placeholder="עודכן ב"
            />
          )}
          <div dir="auto" style={{ textAlign: "right" }}>
            עדכון אחרון:{" "}
            {moment(textBookData.updatedAt).format("YYYY-MM-DD HH:mm")} על ידי:{" "}
            {textBookData.updatedBy}
          </div>
          <div dir="auto" style={{ textAlign: "right" }}>
            נוצר בתאריך:{" "}
            {moment(textBookData.createdAt).format("YYYY-MM-DD HH:mm")} על ידי:{" "}
            {textBookData.createdBy}
          </div>
        </AdditionalDetailsContainer>
      </Fields>
      <ButtonContainer>
        <Button type="submit">{t("buttons.save")}</Button>
      </ButtonContainer>
    </Body>
  );
};
const selector = formValueSelector("textBookGeneral"); // <-- same as form name

function mapStateToProps(state, ownProps) {
  return {
    isUsersLoaded: state.UsersReducer.isLoaded,
    user: state.session.user,
    users: state.UsersReducer.users,
    vehicles: state.VehiclesReducer.vehicles,
    isAllowedToUpdateObligo: state.session.user.isAllowedToUpdateObligo,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    licenseTypes: state.LicenseTypesReducer.licenseTypes,
    fundingBodies: state.FundingBodiesReducer.fundingBodies,
    isFundingBodiesLoaded: state.FundingBodiesReducer.isLoaded,
    vehicleId: selector(state, "vehicleId"),
    initialValues: {
      active: true,
      passedInternalTest: false,
      ...ownProps.textBookData,
      obligo: ownProps.textBookData.obligo
        ? obligoOptions.find((x) => x.value === ownProps.textBookData.obligo)
        : null,
    },
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getVehicles,
    getLicenseTypes,
    updateTextBook,
    change,
    getSchoolFundingBodies,
    getUsers,
  })(
    reduxForm({
      form: "textBookGeneral", // a unique identifier for this form
      destroyOnUnmount: false, // <------ preserve form data
      forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
      enableReinitialize: true, // this is needed!!
      validate,
    })(TextBookGeneral)
  )
);
