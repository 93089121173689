import React from "react";
import { CreateButton } from "./buttons.styles";

const DefaultButton = ({
  text,
  onClick,
  type = "button",
  disabled = false,
  marginTop = 0,
  marginRight = 0,
  isDelete = false,
}) => (
  <CreateButton
    onClick={onClick}
    type={type}
    disabled={disabled}
    style={{ marginTop: `${marginTop}vh`, marginRight: `${marginRight}vw` }}
    isDelete={isDelete}
  >
    {text}
  </CreateButton>
);

export default DefaultButton;
