import {
  USER_FETCH_SUCCESS,
  USER_FETCH_STARTED,
  USER_FETCH_FAILED,
  USER_CREATE_SUCCESS,
  USER_UPDATE_SUCCESS,
  USER_DELETE_SUCCESS,
} from "../constants/actionTypes";

const INITIAL_STATE = {
  isPending: false,
  users: [],
  isLoaded: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case USER_FETCH_SUCCESS:
      return {
        ...state,
        users: action.payload,
        isPending: false,
        isLoaded: true,
      };
    case USER_CREATE_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload],
        isPending: false,
      };
    case USER_FETCH_FAILED:
      return {
        ...state,
        isPending: false,
      };
    case USER_UPDATE_SUCCESS:
      const users = state.users.map((user) =>
        user.id === action.payload.id ? { ...user, ...action.payload } : user
      );
      return {
        ...state,
        users,
        isPending: false,
      };
    case USER_DELETE_SUCCESS:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.payload.id),
      };
    case USER_FETCH_STARTED:
      return {
        ...state,
        isPending: true,
      };
    default:
      return state;
  }
}
