import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  FieldText,
  Field,
  Table,
} from "./YearlyDrivingPermitsActivity.styles";
import { connect } from "react-redux";
import { getAllSchoolEvents } from "../../../actions/schedulerActions";
import { getAll } from "../../../actions/drivingPermits";
import { getStudents } from "../../../actions/studentesActions";
import i18n from "../../../lib/i18n/i18n";
import DatePicker from "react-datepicker";
import moment from "moment";
import _ from "lodash";
import ReactTooltip from "react-tooltip";

const TeacherWeekly = ({
  getAllSchoolEvents,
  students,
  getStudents,
  getAll,
  drivingPermits,
}) => {
  const today = new Date();

  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear() + 1, 0, 1)
  );
  const [events, setEvents] = useState([]);

  useEffect(() => {
    (async () => {
      if (students.length === 0) {
        await getStudents();
      }
      if (drivingPermits.length === 0) {
        await getAll();
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setEvents(await getAllSchoolEvents(startDate, endDate));
    })();
  }, [startDate, endDate]);

  return (
    <Container>
      {/* <Row>
        <>
          <Field>
            <FieldText>תאריך סוף הדוח</FieldText>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              locale={i18n.language}
              startDate={startDate}
              dateFormat="dd/MM/yyyy"
              endDate={endDate}
              minDate={startDate}
            />
          </Field>
          <Field>
            <FieldText>תאריך התחלת הדוח</FieldText>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              dateFormat="dd/MM/yyyy"
              locale={i18n.language}
              endDate={endDate}
            />
          </Field>
        </>
      </Row> */}
      <Table>
        {/* <ReactTooltip place="top" type="light" effect="float" id="123" /> */}
        <thead>
          <tr>
            <th> </th>
            {[
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
            ].map((x, i) => {
              return <th>{x}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {drivingPermits.map((x, i) => (
            <tr>
              <td>{x.name}</td>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((d, i) => (
                <td
                  data-tip={_.uniqBy(events, "studentId").map(
                    (l) => `<div>${l.studentId}</div>`
                  )}
                  data-for="123"
                >
                  {
                    _.uniqBy(events, "studentId").filter(
                      (y) =>
                        y.drivingPermitId === x.id &&
                        new Date(y.start).getMonth() + 1 === d
                    ).length
                  }
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isLoggedIn: state.session.isLoggedIn,
    userId: state.session.user.id,
    drivingPermits: state.DrivingPermitsReducer.drivingPermits,
    students: state.StudentsReducer.students,
    user: state.session.user,
  };
}

export default connect(mapStateToProps, {
  getStudents,
  getAllSchoolEvents,
  getAll,
})(TeacherWeekly);
