import React, { PureComponent } from "react";
import Table from "../../SivanPlusTable/SivanPlusTable";
import { connect } from "react-redux";
import {
  Container,
  Title,
  Body,
  Row,
  Input,
  CreateButton,
  ReceiptDetails,
  InputsContainer,
} from "./pickupLocations.styles";
import {
  createPickupLocation,
  getPickupLocations,
  deletePickupLocation,
} from "../../../actions/pickupLocationsActions";

class FundingBodyReceipt extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { name: "", address: "" };
  }

  componentDidMount = async () => {
    await this.props.getPickupLocations();
  };

  onRowClick = async (rowObject) => {
    console.log(rowObject);
  };

  render() {
    const { name, address } = this.state;
    const { data } = this.props;

    const pickupColumns = [
      {
        Header: "שם",
        accessor: "name",
      },
      {
        Header: "כתובת",
        accessor: "address",
      },
      {
        id: "delete",
        Header: "מחק",
        accessor: (data) => (
          <i
            className="fa fa-trash"
            aria-hidden="true"
            onClick={async () => await this.props.deletePickupLocation(data.id)}
          />
        ),
      },
    ];

    return (
      <Container>
        <Title>Pickup Locations</Title>
        <Body>
          <ReceiptDetails>
            <Row>
              <InputsContainer>
                <Input
                  placeholder="Name"
                  value={name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
                <Input
                  placeholder="Address"
                  value={address}
                  onChange={(e) => this.setState({ address: e.target.value })}
                />
              </InputsContainer>
              <CreateButton
                onClick={() =>
                  name.length > 0 && address.length > 0
                    ? this.props.createPickupLocation({ address, name })
                    : ""
                }
              >
                Create
              </CreateButton>
            </Row>
            <Table
              data={data}
              componentColumns={pickupColumns}
              // onRowClick={this.onRowClick}
            />
          </ReceiptDetails>
        </Body>
      </Container>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    data: state.PickupLocationsReducer.locations,
    isLoading: state.PickupLocationsReducer.isPending,
  };
}

export default connect(mapStateToProps, {
  getPickupLocations,
  createPickupLocation,
  deletePickupLocation,
})(FundingBodyReceipt);
