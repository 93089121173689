import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import _ from "lodash";
import moment from "moment";
import Select from "react-select";
import i18n from "../../../lib/i18n/i18n";
import {
  Row,
  FieldText,
  Field,
  customStyles,
  Container,
} from "./LessonsByMonth.styles";
import Button from "../../UiComponents/Buttons/Default";
import { eventDurationInMinutes } from "../../../utils/calculator";
import { getBranches } from "../../../actions/branchActions";
import { getAllSchoolEvents } from "../../../actions/schedulerActions";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const ActivityReport = ({
  getAllSchoolEvents,
  events,
  getBranches,
  ticketLessonTime,
}) => {
  const [graphData, setGraphData] = useState([]);
  const [rawGraphData, setRawGraphData] = useState([]);
  const [currentView, setCurrentView] = useState("bymonths");
  const [startDate, setStartDate] = useState(new Date().setMonth(0));
  const [endDate, setEndDate] = useState(new Date());
  const [selectedTeacher, setSelectedTeacher] = useState();
  const [selectOptions, setSelectOptions] = useState([]);

  useEffect(() => {
    (async () => {
      await getAllSchoolEvents(new Date(startDate), new Date(endDate));
    })();
  }, [startDate, endDate]);

  useEffect(() => {
    (async () => {
      if (events.length > 0) {
        const filteredEvents = events.filter(
          (x) => x.eventType === 1 && x.eventStatus !== 3 && x.eventStatus !== 4
        );
        setSelectedTeacher(null);
        const data = [];
        for (
          var day = moment(new Date(startDate));
          day.isSameOrBefore(new Date(endDate), "day");
          day.add(1, "months")
        ) {
          if (currentView === "byVehicles") {
            const uniqueVehicle = _.uniqBy(filteredEvents, "vehicleNumber");
            const vehicels = uniqueVehicle
              .filter((x) => x.vehicleNumber !== null)
              .map((x) => x.vehicleNumber);
            setSelectOptions(vehicels.map((x) => ({ value: x, label: x })));
            filterByData(vehicels, day, "vehicleNumber", data, filteredEvents);
          } else if (currentView === "byLicense") {
            const uniqueLicenses = _.uniqBy(filteredEvents, "name");
            const licenses = uniqueLicenses
              .filter((x) => x.name !== null)
              .map((x) => x.name);
            setSelectOptions(licenses.map((x) => ({ value: x, label: x })));
            filterByData(licenses, day, "name", data, filteredEvents);
          } else if (currentView === "byBranch") {
            const allBranches = await getBranches();
            setSelectOptions(
              allBranches.map((x) => ({ value: x.name, label: x.name }))
            );
            filterByData(allBranches, day, "branchId", data, events, true);
          } else if (currentView === "byTeacher") {
            const uniqueLicenses = _.uniqBy(filteredEvents, "teacherUsername");
            const licenses = uniqueLicenses
              .filter((x) => x.teacherUsername !== null)
              .map((x) => x.teacherUsername);
            setSelectOptions(licenses.map((x) => ({ value: x, label: x })));
            filterByData(
              licenses,
              day,
              "teacherUsername",
              data,
              filteredEvents
            );
          } else if (currentView === "byUser") {
            const uniqueLicenses = _.uniqBy(filteredEvents, "createUsername");
            const licenses = uniqueLicenses
              .filter((x) => x.createUsername !== null)
              .map((x) => x.createUsername);
            setSelectOptions(licenses.map((x) => ({ value: x, label: x })));
            filterByData(licenses, day, "createUsername", data, filteredEvents);
          } else {
            setSelectOptions([]);
            let totalLessons = 0;
            const currentMonthLessons = filteredEvents.filter(
              (x) =>
                moment(new Date(x.start)).isSame(day, "month") &&
                moment(new Date(x.start)).isSame(day, "year")
            );
            for (let index = 0; index < currentMonthLessons.length; index++) {
              const element = currentMonthLessons[index];
              totalLessons +=
                eventDurationInMinutes(element.start, element.end) /
                ticketLessonTime;
            }
            data.push({
              date: day.format("MM/YY"),
              total: totalLessons,
            });
          }
        }
        setGraphData(data);
        setRawGraphData(data);
      }
    })();
  }, [events, startDate, endDate, currentView]);

  const filterByData = (array, day, param, data, events, isBranch) => {
    for (let j = 0; j < array.length; j++) {
      const element = array[j];
      const elementValue = isBranch ? element.id : element;
      const currentMonthTests = events.filter(
        (x) =>
          moment(new Date(x.start)).isSame(day, "month") &&
          moment(new Date(x.start)).isSame(day, "year") &&
          x[param] === elementValue
      );
      let totalLessons = 0;
      for (let index = 0; index < currentMonthTests.length; index++) {
        const element = currentMonthTests[index];
        totalLessons +=
          eventDurationInMinutes(element.start, element.end) / ticketLessonTime;
      }
      data.push({
        date: `${isBranch ? element.name : element} - ${day.format("MM/YY")}`,
        total: totalLessons,
      });
    }
  };

  const handleChange = (selected) => {
    setSelectedTeacher(selected);
    if (selected && selected.length > 0) {
      let filteredData = [];
      for (let index = 0; index < selected.length; index++) {
        const element = selected[index];
        filteredData = [
          ...filteredData,
          ...rawGraphData.filter((x) => x.date.includes(element.value)),
        ];
      }
      setGraphData(filteredData);
    } else {
      setGraphData(rawGraphData);
    }
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1>כמות שיעורים לפי חודש</h1>
        <p>שיעורים שלא בוטלו לפי חודש</p>
      </div>

      <Row>
        <Button
          text="לפי חודשים"
          onClick={() => {
            setCurrentView("byMonths");
          }}
          marginRight="1"
        />
        <Button
          text="לפי רכבים"
          onClick={() => {
            setCurrentView("byVehicles");
          }}
          marginRight="1"
        />
        <Button
          text="לפי רישון"
          onClick={() => {
            setCurrentView("byLicense");
          }}
          marginRight="1"
        />
        <Button
          text="לפי יוצר"
          onClick={() => {
            setCurrentView("byUser");
          }}
          marginRight="1"
        />
        <Button
          text="לפי מורה"
          onClick={() => {
            setCurrentView("byTeacher");
          }}
          marginRight="1"
        />
        <Button
          text="לפי סניפים"
          onClick={() => {
            setCurrentView("byBranch");
          }}
          marginRight="1"
        />
        <div style={{ width: "33%", marginRight: "1vw" }}>
          <Select
            options={selectOptions}
            value={selectedTeacher}
            isMulti
            placeholder="בחר"
            styles={customStyles}
            onChange={handleChange}
            isRtl
          />
        </div>
        <>
          <Field>
            <FieldText>עד</FieldText>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              locale={i18n.language}
              startDate={startDate}
              dateFormat="MMMM"
              endDate={endDate}
              minDate={startDate}
              showMonthYearPicker
              showFullMonthYearPicker
            />
          </Field>
          <Field>
            <FieldText>מ</FieldText>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              locale={i18n.language}
              dateFormat="MMMM"
              showMonthYearPicker
              showFullMonthYearPicker
              selectsStart
              startDate={startDate}
              endDate={endDate}
            />
          </Field>
        </>
      </Row>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={graphData}>
          <XAxis dataKey="date" />
          <YAxis dataKey="total" />
          <Tooltip />
          <Bar dataKey="total" fill="#ffc658" radius={[10, 10, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    events: state.CalendarReducer.events,
    ticketLessonTime: state.session.user.ticketLessonTime,
  };
}
export default connect(mapStateToProps, {
  getAllSchoolEvents,
  getBranches,
})(ActivityReport);
