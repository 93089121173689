import styled from "styled-components";
import i18n from "../../lib/i18n/i18n";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const Title = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7vh;
  flex-direction: ${isHebrew ? "row-reverse" : "row"};
`;

export const TitleText = styled.span`
  font-size: 20pt;
  text-align: center;
  width: 100%;
`;

export const Circle = styled.span`
  background-color: #fcf2de;
  color: #cb9d3c;
  border-radius: 50%;
  width: 1.5vw;
  height: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Text = styled.div`
  color: red;
`;

export const CloseIcon = styled.i``;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 75%;
  justify-content: space-between;
  margin-top: 1vh;
`;

export const Button = styled.button`
  border-radius: 5px;
  background: ${(props) => (props.reset ? "white" : "white")};
  border: ${(props) => (props.reset ? "1px solid black" : "1px solid red")};
  color: ${(props) => (props.reset ? "black" : "red")};
  font-size: 15pt;
  font-weight: bolder;
  flex: 0 20%;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
