import {
  AGREEMENTS_FETCH_SUCCESS,
  AGREEMENTS_FETCH_FAILED,
  AGREEMENTS_FETCH_STARTED,
  AGREEMENT_CREATE_SUCCESS,
  AGREEMENT_UPDATE_SUCCESS,
  AGREEMENT_DELETE_SUCCESS,
} from "../constants/actionTypes";

const INITIAL_STATE = { isPending: false, agreements: [], isLoaded: false };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case AGREEMENT_CREATE_SUCCESS:
      return { ...state, agreements: [...state.agreements, action.payload] };
    case AGREEMENT_DELETE_SUCCESS:
      return {
        ...state,
        agreements: state.agreements.filter(
          (agreement) => agreement.id !== action.payload.id
        ),
      };
    case AGREEMENT_UPDATE_SUCCESS:
      const agreements = state.agreements.map((agreement) =>
        agreement.id === action.payload.id
          ? { ...agreement, ...action.payload }
          : agreement
      );
      return { ...state, agreements };
    case AGREEMENTS_FETCH_SUCCESS:
      return { ...state, agreements: action.payload, isLoaded: true };
    case AGREEMENTS_FETCH_FAILED:
      return { ...state, isPending: false };
    case AGREEMENTS_FETCH_STARTED:
      return { ...state, isPending: true };
    default:
      return state;
  }
}
