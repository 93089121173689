import {
  VEHICLE_FETCH_FAILED,
  VEHICLE_FETCH_STARTED,
  VEHICLE_FETCH_SUCCESS,
  VEHICLE_CREATE_SUCCESS,
  VEHICLE_UPDATE_SUCCESS,
  VEHICLE_DELETE_SUCCESS,
} from "../constants/actionTypes";

const INITIAL_STATE = { isPending: false, vehicles: [], isLoaded: false };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case VEHICLE_CREATE_SUCCESS:
      return { ...state, vehicles: [...state.vehicles, action.payload] };
    case VEHICLE_DELETE_SUCCESS:
      return {
        ...state,
        vehicles: state.vehicles.filter(
          (vehicle) => vehicle.id !== action.payload.id
        ),
      };
    case VEHICLE_UPDATE_SUCCESS:
      const vehicles = state.vehicles.map((vehicle) =>
        vehicle.id === action.payload.id
          ? { ...vehicle, ...action.payload }
          : vehicle
      );
      return { ...state, vehicles };
    case VEHICLE_FETCH_SUCCESS:
      return { ...state, vehicles: action.payload, isLoaded: true };
    case VEHICLE_FETCH_FAILED:
    case VEHICLE_FETCH_STARTED:
      return { ...state, isPending: true };
    default:
      return state;
  }
}
