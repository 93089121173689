import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { useTranslation } from "react-i18next";
import { updateSettings } from "../../../actions/settingsActions";
import ColorPicker from "./ColorPicker";
import {
  SectionContainer,
  SectionContainerHeader,
  Icon,
} from "./calendarColors.styles";

const CalendarEventColor = ({ header, elements }) => {
  const { t, i18n } = useTranslation("common");
  return (
    <SectionContainer
      style={{
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div style={{ textAlign: "center" }}>{header}</div>
      {elements.map((x) => (
        <SectionContainer>
          <SectionContainerHeader background={x.background} fontColor={x.font}>
            {x.text} <Icon className={x.icon} />
          </SectionContainerHeader>
          <Field
            name={x.backgroundFieldName}
            component={ColorPicker}
            text={t("settings.background")}
          />
          <Field
            name={x.fontFieldName}
            component={ColorPicker}
            text={t("settings.font")}
          />
        </SectionContainer>
      ))}
    </SectionContainer>
  );
};

export default CalendarEventColor;
