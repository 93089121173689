import styled from "styled-components";
import i18n from "../../../lib/i18n/i18n";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const Container = styled.div``;
export const Title = styled.span`
  font-size: 22pt;
  font-weight: bold;
  color: #404041;
`;

export const Body = styled.div`
  height: 75vh;
  width: 100%;
  background-color: white;
  border: 1px solid #ffefca;
  border-radius: 5px;
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  padding: 3vh;
  align-items: center;
`;

export const ColumnTitle = styled.span`
  font-size: 18pt;
  color: #404041;
  height: 7vh;
  display: flex;
  align-items: center;
`;

export const Row = styled.div`
  padding-left: 2vh;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;
  height: 10%;
  align-items: center;
`;

export const Input = styled.input`
  border: 1px solid black;
  border-radius: 5px;
  width: 46%;
  height: 5vh;

  &::placeholder {
    padding-left: 1vw;
    color: black;
  }
`;

export const ReceiptDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
`;

export const customStyles = {
  container: (provided, state) => ({
    ...provided,
    marginTop: "1vh",
    width: "50%",
  }),
  control: (provided, state) => ({
    ...provided,
    border: "1px solid #e5e5e5",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#c2c2c2",
    [isHebrew ? "paddingRight" : "paddingLeft"]: "0.5vw",
  }),
};
