export default (values) => {
  const errors = {};
  if (!values.teacherId) {
    errors.teacherId = "Required";
  }

  if (!values.vehicleId) {
    errors.vehicleId = "Required";
  }

  return errors;
};
