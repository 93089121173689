import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Body,
  InvoiceContainer,
  PrintFooter,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  Title,
  Teachers,
} from "./printCalendar.styles";
import SivanModal from "../../Modal/Modal";
import Toggle from "react-toggle";
import Button from "../../UiComponents/Buttons/Default";
import "react-toggle/style.css";
import { useReactToPrint } from "react-to-print";
import moment from "moment";

let eventTypes = { 1: "שיעור", 2: "טסט", 3: "מבחן פנימי", 4: "פגישה" };

const PrintCalendar = ({
  isNewAgreementModalOpen,
  setNewAgreementModalIsOpen,
  isLoading,
  events,
  teachers,
  currentDate,
  user,
}) => {
  const componentRef = useRef();
  const [isFreeTimeSlotShown, setIsFreeTimeSlotShown] = useState(false);
  const [filteredEvents, setFilteredEvents] = useState(
    events.filter((x) => moment(x.start).isSame(moment(currentDate), "day"))
  );

  useEffect(() => {
    if (isFreeTimeSlotShown) {
      const eventsToPush = [];
      const timeSlotsArray = getTimeStops(
        `${new Date(user.calendarStartTime).getHours()}:00`,
        `${new Date(user.calendarEndTime).getHours()}:00`
      );
      for (let index = 0; index < timeSlotsArray.length; index++) {
        const timeSlot = timeSlotsArray[index];
        let isTimeExists = false;
        for (let j = 0; j < filteredEvents.length; j++) {
          const event = filteredEvents[j];
          const eventStartTimeFormated = moment(event.start).format("HH:mm");
          if (eventStartTimeFormated === timeSlot) {
            isTimeExists = true;
            break;
          }
        }

        if (!isTimeExists) {
          eventsToPush.push({
            empty: true,
            start: new Date(currentDate).setHours(
              timeSlot.slice(0, 2),
              timeSlot.slice(3, 5)
            ),
          });
        }
      }
      setFilteredEvents([...filteredEvents, ...eventsToPush]);
    } else {
      setFilteredEvents(
        events.filter((x) => moment(x.start).isSame(moment(currentDate), "day"))
      );
    }
  }, [isFreeTimeSlotShown]);

  const handlePrint = async () => {
    print();

    if (window.location.pathname === "/calendar") {
      setNewAgreementModalIsOpen(false);
    }
  };

  const print = useReactToPrint({
    content: () => componentRef.current,
  });

  function getTimeStops(start, end) {
    var startTime = moment(start, "HH:mm");
    var endTime = moment(end, "HH:mm");

    if (endTime.isBefore(startTime)) {
      endTime.add(1, "day");
    }

    var timeStops = [];

    while (startTime <= endTime) {
      timeStops.push(new moment(startTime).format("HH:mm"));
      startTime.add(user.calendarSpacingInMinutes, "minutes");
    }

    return timeStops;
  }

  return (
    <SivanModal
      titleText="הדפסת יומן יומי"
      modalIsOpen={isNewAgreementModalOpen}
      closeModal={() => setNewAgreementModalIsOpen(false)}
      isLoading={isLoading}
    >
      <Body>
        <Toggle
          id="cheese-status"
          defaultChecked={false}
          onChange={() => setIsFreeTimeSlotShown(!isFreeTimeSlotShown)}
        />
        <label htmlFor="cheese-status">הדפס שעות ריקות</label>
        <InvoiceContainer ref={componentRef}>
          <Title>
            יומן לתאריך - {moment(currentDate).format("DD/MM/YYYY")}
          </Title>
          <Teachers>מורים: {teachers.map((x) => x.label).join()}</Teachers>
          <Table>
            <TableRow>
              <TableHeader>שעה</TableHeader>
              <TableHeader>שם תלמיד</TableHeader>
              <TableHeader>סוג</TableHeader>
              <TableHeader>משך</TableHeader>
              <TableHeader>טלפון</TableHeader>
              <TableHeader>כתובת</TableHeader>
              <TableHeader>רישיון</TableHeader>
            </TableRow>
            {filteredEvents
              .sort((a, b) => new Date(a.start) - new Date(b.start))
              .map((x) => (
                <TableRow>
                  <TableCell>{moment(x.start).format("HH:mm")}</TableCell>
                  <TableCell>
                    {x.empty ? "" : x.firstName}&nbsp;
                    {x.empty ? "" : x.lastName}
                  </TableCell>
                  <TableCell>
                    {x.empty ? "" : eventTypes[x.eventType]}
                    {x.eventType === 4 && ` - ${x.notes}`}
                  </TableCell>
                  <TableCell>
                    {x.empty
                      ? ""
                      : Math.abs(new Date(x.end) - new Date(x.start)) /
                        (1000 * 60)}
                  </TableCell>
                  <TableCell>{x.empty ? "" : x.phone}</TableCell>
                  <TableCell>{x.empty ? "" : x.address}</TableCell>
                  <TableCell>{x.empty ? "" : x.description}</TableCell>
                </TableRow>
              ))}
          </Table>
          <div className="">
            <PrintFooter>
              הופק באמצעות קמע מחשבים סיון בע"מ. טלפון 0537-222-228
            </PrintFooter>
          </div>
        </InvoiceContainer>
        <Button text="הדפס" onClick={() => handlePrint("מקור")} />
      </Body>
    </SivanModal>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.session.user,
  };
}

export default connect(mapStateToProps)(PrintCalendar);
