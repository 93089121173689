import styled from "styled-components";
import i18n from "../../../../lib/i18n/i18n";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const Container = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 1vmax;
`;

export const BalanceTable = styled.table`
  direction: rtl;
  border: 1px solid black;
  font-weight: bold;
  font-size: 1vmax;

  td {
    border: 1px solid black;
    padding: 6px;
    text-align: center;
  }
`;
