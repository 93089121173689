import React, { useRef } from "react";
import schedulerApi from "../../../apis/scheduler";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SivanModal from "../../Modal/NewModal";
import { Body, Button, customStyles } from "./studentSignature.styles";
import SignatureCanvas from "react-signature-canvas";
import CreateButton from "../../UiComponents/Buttons/Default";
import { Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

const EventStatusChange = ({
  isNewAgreementModalOpen,
  setNewAgreementModalIsOpen,
  selectedEvent,
}) => {
  const componentRef = useRef();

  const save = async () => {
    try {
      const trimmedDataURL = componentRef.current
        .getTrimmedCanvas()
        .toDataURL("image/png");

      const file = dataURItoBlob(trimmedDataURL);
      const fileName = uuidv4();
      await Storage.vault.put(`${fileName}.png`, file, {
        contentType: "image/png",
        ACL: "public-read",
        visibility: "public",
        level: "public",
      });

      const token = window.localStorage.getItem("token");

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await schedulerApi.patch(
        "/updateSignature",
        {
          eventId: selectedEvent.eventId.id,
          signatureImageString: `${fileName}.png`,
        },
        {
          headers,
        }
      );

      setNewAgreementModalIsOpen();
    } catch (e) {}
  };

  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    //Old Code
    //write the ArrayBuffer to a blob, and you're done
    //var bb = new BlobBuilder();
    //bb.append(ab);
    //return bb.getBlob(mimeString);

    //New Code
    return new Blob([ab], { type: mimeString });
  }

  return (
    <SivanModal
      titleText="אישור אירוע"
      modalIsOpen={isNewAgreementModalOpen}
      closeModal={() => setNewAgreementModalIsOpen(false)}
      isLoading={false}
    >
      {selectedEvent.eventId.signature === null ? (
        <Body>
          <span>חתימת תלמיד</span>
          <SignatureCanvas
            ref={componentRef}
            penColor="black"
            canvasProps={{
              width: 400,
              height: 100,
              className: "sigCanvas",
            }}
          />
          <CreateButton text="שמירה" marginTop={2} onClick={save} />
        </Body>
      ) : (
        <img
          src={`https://signatures63604-prod.s3.eu-central-1.amazonaws.com/public/${selectedEvent.eventId.signature}`}
        />
      )}
    </SivanModal>
  );
};

function mapStateToProps(state) {
  return {
    user: state.session.user,
    vehicles: state.VehiclesReducer.vehicles,
    licenseTypes: state.LicenseTypesReducer.licenseTypes,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
  };
}

export default withRouter(connect(mapStateToProps, {})(EventStatusChange));
