import { useEffect, useState } from "react";
import { optionsArrrayWithOneParam } from "../../utils/buildOptionsArray";

const useTeachers = (getAgreements) => {
  const [agreementOptionsArray, setAgreementsOptionsArray] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const agreements = await getAgreements(1);
        setAgreementsOptionsArray(
          optionsArrrayWithOneParam(agreements, "id", "description")
        );
      } catch (e) {}
    })();
  }, []);
  return agreementOptionsArray;
};

export default useTeachers;
