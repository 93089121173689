import React, { useState, useEffect } from "react";
import i18n from "../../../lib/i18n/i18n";
import { connect } from "react-redux";
import { Container, Title } from "./IncomesFromFundingBodies.styles";
import IncomeData from "./IncomeData/IncomeData";
import { getAllFundingBodiesIncomes } from "../../../actions/incomesAction";
import Table from "../../SivanPlusTable/SivanPlusTable";
import CreateButton from "../../UiComponents/Buttons/Default";
import NewIncome from "./NewIncome/NewIncome";
import NewPaymentRequest from "./NewPaymentRequest/NewPaymentRequest";
import { getSchoolFundingBodies } from "../../../actions/fundingBodiesActions";
import { DateColumnFilter } from "../../SivanPlusTable/filters";
import moment from "moment";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;
const documentTypes = [
  { value: 0, label: "חשבונית/קבלה" },
  { value: 1, label: "קבלה" },
  { value: 2, label: "חשבונית" },
  { value: 3, label: "חשבונית/קבלה זיכוי" },
  { value: 4, label: "קבלה זיכוי" },
  { value: 5, label: "חשבונית זיכוי" },
];

const paymentMethods = [
  { value: 0, label: "מזומן" },
  { value: 1, label: "שיק" },
  { value: 3, label: "העברה" },
  { value: 4, label: "אשראי" },
  { value: 5, label: "אפליקציה" },
];
const Incomes = ({
  incomes,
  getAllFundingBodiesIncomes,
  user,
  fundingBodies,
  getSchoolFundingBodies,
}) => {
  const [isProviderModalOpen, setProviderModalOpen] = useState(false);
  const [isNewPaymentRequestModalOpen, setIsNewPaymentRequestModalOpen] =
    useState(false);
  const [providerData, setProviderData] = useState(false);
  const [isProviderDetailsModalOpen, setProviderDetailsModalOpen] =
    useState(false);

  useEffect(() => {
    async function fetchData() {
      await getAllFundingBodiesIncomes();
      await getSchoolFundingBodies();
    }
    fetchData();
  }, []);

  const vehicleColumns = [
    {
      Header: dictionary.table.date,
      accessor: "date",
      Cell: ({ row: { original } }) => {
        const date = moment(original.date).format("DD/MM/YYYY");

        return original.documentType === 3 ||
          original.documentType === 4 ||
          original.documentType === 5 < 0 ? (
          <span style={{ color: "red", fontWeight: "bold" }}>{date}</span>
        ) : (
          date
        );
      },
      Filter: DateColumnFilter,
      filter: (array, s, date) => {
        return array.filter((x) => moment(x.original.date).isSame(date, "day"));
      },
    },
    {
      Header: "מספר מסמך כספי",
      accessor: "documentNumber",
      Cell: ({ row: { original } }) => {
        return original.documentType === 3 ||
          original.documentType === 4 ||
          original.documentType === 5 < 0 ? (
          <span style={{ color: "red", fontWeight: "bold" }}>
            {original.documentNumber}
          </span>
        ) : (
          original.documentNumber
        );
      },
    },
    {
      Header: "גוף מימון",
      accessor: "clientId",
      Cell: ({ row: { original } }) => {
        let clientName = "";
        if (fundingBodies.length !== 0) {
          clientName = fundingBodies.find(
            (x) => x.id.toString() === original.clientId
          ).name;
        }

        return original.documentType === 3 ||
          original.documentType === 4 ||
          original.documentType === 5 < 0 ? (
          <span style={{ color: "red", fontWeight: "bold" }}>{clientName}</span>
        ) : (
          clientName
        );
      },
    },
    {
      Header: dictionary.table.amount,
      accessor: "amount",
      Cell: ({ row: { original } }) => {
        return original.documentType === 3 ||
          original.documentType === 4 ||
          original.documentType === 5 < 0 ? (
          <span style={{ color: "red", fontWeight: "bold" }}>
            {original.amount}
          </span>
        ) : (
          original.amount
        );
      },
    },
    {
      Header: dictionary.table.documentType,
      accessor: "documentType",
      Cell: ({ row: { original } }) => {
        const document = documentTypes.find(
          (x) => x.value === original.documentType
        )?.label;

        return original.documentType === 3 ||
          original.documentType === 4 ||
          original.documentType === 5 < 0 ? (
          <span style={{ color: "red", fontWeight: "bold" }}>{document}</span>
        ) : (
          document
        );
      },
    },
    {
      Header: "צורת תשלום",
      accessor: "paymentMethod",
      Cell: ({ row: { original } }) => {
        const method = paymentMethods.find(
          (x) => x.value === original.paymentMethod
        )?.label;

        return original.documentType === 3 ||
          original.documentType === 4 ||
          original.documentType === 5 < 0 ? (
          <span style={{ color: "red", fontWeight: "bold" }}>{method}</span>
        ) : (
          method
        );
      },
    },
    {
      Header: "נוצר ע״י",
      accessor: "createUsername",
    },
  ];

  const onRowClick = async (rowObject) => {
    setProviderDetailsModalOpen(true);
    setProviderData(rowObject.original);
  };

  return (
    <Container>
      {isProviderModalOpen && (
        <NewIncome
          isModalOpen={isProviderModalOpen}
          setIsModalOpen={setProviderModalOpen}
        />
      )}
      {isNewPaymentRequestModalOpen && (
        <NewPaymentRequest
          isModalOpen={isNewPaymentRequestModalOpen}
          setIsModalOpen={setIsNewPaymentRequestModalOpen}
        />
      )}
      <IncomeData
        isModalOpen={isProviderDetailsModalOpen}
        closeModal={() => setProviderDetailsModalOpen(false)}
        providerData={providerData}
      />
      <Title>
        הכנסות מגופי מימון
        {user.createStudentIncome ? (
          <CreateButton
            onClick={() => setProviderModalOpen(true)}
            text="צור חדש"
          />
        ) : null}
        {user.createStudentIncome ? (
          <CreateButton
            onClick={() => setIsNewPaymentRequestModalOpen(true)}
            text="דרישת תשלום"
          />
        ) : null}
      </Title>
      <Table
        data={incomes}
        componentColumns={vehicleColumns}
        onRowClick={onRowClick}
      />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    incomes: state.IncomesReducer.incomesFromFundingBodies,
    isLoaded: state.IncomesReducer.isLoaded,
    fundingBodies: state.FundingBodiesReducer.fundingBodies,
    user: state.session.user,
    editVehicle: state.session.user.editVehicle,
  };
}

export default connect(mapStateToProps, {
  getAllFundingBodiesIncomes,
  getSchoolFundingBodies,
})(Incomes);
