import styled from "styled-components";

export const GraphDataContainer = styled.div``;
export const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export const Swatch = styled.div`
  padding: 3px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
`;

export const GraphColorContainer = styled.div`
  display: flex;
  align-items: center;
  height: 4vh;
  justify-content: space-between;
`;

export const GraphColorText = styled.span``;

export const Color = styled.div`
  width: 3vw;
  height: 2vh;
  border-radius: 2px;
  background: ${(props) => props.color};
`;

export const Popover = styled.div`
  z-index: 2;
`;
