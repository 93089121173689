/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:695cce5b-cad4-4408-b120-37f10cad9aef",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_UPbyegoj0",
    "aws_user_pools_web_client_id": "5p3nrpvb3s88ac4fki4hd2j656",
    "oauth": {},
    "aws_user_files_s3_bucket": "signatures63604-prod",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
