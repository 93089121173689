import React, { useRef, useState, useEffect } from "react";
import {
  Row,
  FieldText,
  Field,
  customStyles,
  Container,
} from "./studentsByMonth.styles";
import { connect } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import _ from "lodash";
import Select from "react-select";
import i18n from "../../../lib/i18n/i18n";
import Button from "../../UiComponents/Buttons/Default";
import UseLoadStudents from "../../useComponents/UseLoadStudents";
import { getStudents } from "../../../actions/studentesActions";
import { getBranches } from "../../../actions/branchActions";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const ActivityReport = ({ isLoading, getStudents, students, getBranches }) => {
  const [graphData, setGraphData] = useState(null);
  const [currentView, setCurrentView] = useState("bymonths");
  const [startDate, setStartDate] = useState(new Date().setMonth(0));
  const [endDate, setEndDate] = useState(new Date());
  const [selectedTeacher, setSelectedTeacher] = useState();
  const [rawGraphData, setRawGraphData] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  UseLoadStudents(isLoading, getStudents);

  useEffect(() => {
    (async () => {
      if (students.length > 0) {
        const data = [];
        setSelectedTeacher(null);
        for (
          var day = moment(new Date(startDate));
          day.isSameOrBefore(new Date(endDate), "day");
          day.add(1, "months")
        ) {
          if (currentView === "byVehicles") {
            const uniqueVehicle = _.uniqBy(students, "vehicleNumber");
            const vehicels = uniqueVehicle
              .filter((x) => x.vehicleNumber !== null)
              .map((x) => x.vehicleNumber);
            setSelectOptions(vehicels.map((x) => ({ value: x, label: x })));
            filterByData(vehicels, day, "vehicleNumber", data, students);
          } else if (currentView === "byLicense") {
            const uniqueLicenses = _.uniqBy(students, "licenseType");
            const licenses = uniqueLicenses
              .filter((x) => x.licenseType !== null)
              .map((x) => x.licenseType);
            setSelectOptions(licenses.map((x) => ({ value: x, label: x })));
            filterByData(licenses, day, "licenseType", data, students);
          } else if (currentView === "byBranch") {
            const allBranches = await getBranches();
            setSelectOptions(
              allBranches.map((x) => ({ value: x.name, label: x.name }))
            );
            filterByData(allBranches, day, "branchId", data, students, true);
          } else if (currentView === "byUser") {
            const uniqueLicenses = _.uniqBy(students, "createdBy");
            const licenses = uniqueLicenses
              .filter((x) => x.createdBy !== null)
              .map((x) => x.createdBy);
            setSelectOptions(licenses.map((x) => ({ value: x, label: x })));
            filterByData(licenses, day, "createdBy", data, students);
          } else if (currentView === "byTeacher") {
            const uniqueLicenses = _.uniqBy(students, "teacherName");
            const licenses = uniqueLicenses
              .filter((x) => x.teacherName !== null)
              .map((x) => x.teacherName);
            setSelectOptions(licenses.map((x) => ({ value: x, label: x })));
            filterByData(licenses, day, "teacherName", data, students);
          } else {
            setSelectOptions([]);
            data.push({
              date: day.format("MM/YY"),
              total: students.filter(
                (y) =>
                  moment(new Date(y.createDate)).isSame(day, "month") &&
                  moment(new Date(y.createDate)).isSame(day, "year")
              ).length,
            });
          }
        }

        setGraphData(data);
        setRawGraphData(data);
      }
    })();
  }, [startDate, endDate, currentView, students]);

  const filterByData = (array, index, param, data, events, isBranch) => {
    for (let j = 0; j < array.length; j++) {
      const element = array[j];
      const elementValue = isBranch ? element.id : element;
      const currentMonthTests = events.filter(
        (x) =>
          moment(new Date(x.createDate)).isSame(index, "month") &&
          moment(new Date(x.createDate)).isSame(index, "year") &&
          x[param] === elementValue
      );
      data.push({
        date: `${isBranch ? element.name : element} - ${index.format("MM/YY")}`,
        total: currentMonthTests.length,
      });
    }
  };

  const handleChange = (selected) => {
    setSelectedTeacher(selected);
    if (selected && selected.length > 0) {
      let filteredData = [];
      for (let index = 0; index < selected.length; index++) {
        const element = selected[index];
        filteredData = [
          ...filteredData,
          ...rawGraphData.filter((x) => x.date.includes(element.value)),
        ];
      }
      setGraphData(filteredData);
    } else {
      setGraphData(rawGraphData);
    }
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1>תלמידים חדשים לפי חודשים</h1>
        <p>תלמידים חדשים שנוצרו בחודשים שנבחרו</p>
      </div>

      <Row>
        <Button
          text="לפי חודשים"
          onClick={() => {
            setCurrentView("byMonths");
          }}
          marginRight="1"
        />
        <Button
          text="לפי רכבים"
          onClick={() => {
            setCurrentView("byVehicles");
          }}
          marginRight="1"
        />
        <Button
          text="לפי רישון"
          onClick={() => {
            setCurrentView("byLicense");
          }}
          marginRight="1"
        />
        <Button
          text="לפי יוצר"
          onClick={() => {
            setCurrentView("byUser");
          }}
          marginRight="1"
        />
        <Button
          text="לפי מורה"
          onClick={() => {
            setCurrentView("byTeacher");
          }}
          marginRight="1"
        />
        <Button
          text="לפי סניפים"
          onClick={() => {
            setCurrentView("byBranch");
          }}
          marginRight="1"
        />
        <>
          <div style={{ width: "33%", marginRight: "1vw" }}>
            <Select
              options={selectOptions}
              value={selectedTeacher}
              isMulti
              placeholder="בחר"
              styles={customStyles}
              onChange={handleChange}
              isRtl
            />
          </div>
          <Field>
            <FieldText>עד</FieldText>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              locale={i18n.language}
              startDate={startDate}
              dateFormat="MMMM"
              endDate={endDate}
              minDate={startDate}
              showMonthYearPicker
              showFullMonthYearPicker
            />
          </Field>
          <Field>
            <FieldText>מ</FieldText>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              locale={i18n.language}
              dateFormat="MMMM"
              showMonthYearPicker
              showFullMonthYearPicker
              selectsStart
              startDate={startDate}
              endDate={endDate}
            />
          </Field>
        </>
      </Row>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={graphData}>
          <XAxis dataKey="date" />
          <YAxis dataKey="total" />
          <Tooltip />
          <Bar dataKey="total" fill="#8884d8" radius={[10, 10, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    students: state.StudentsReducer.students,
    isLoading: state.StudentsReducer.isLoaded,
  };
}

export default connect(mapStateToProps, {
  getStudents,
  getBranches,
})(ActivityReport);
