import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import i18n from "../../../../lib/i18n/i18n";
import validate from "./validate";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import DatePicker from "react-datepicker";
import SivanModal from "../../../Modal/Modal";
import {
  Body,
  Section,
  Row,
  ToggleContainer,
  ToggleText,
} from "./newIncome.styles";
import Toggle from "react-toggle";
import { create, getDocumentNumber } from "../../../../actions/incomesAction";
import { getSchoolClients } from "../../../../actions/clientsActions";
import CreateButton from "../../../UiComponents/Buttons/Default";
import { getVehicles } from "../../../../actions/vehicleActions";
import useVehicles from "../../../useComponents/useVehicles";
import { getSchoolProviders } from "../../../../actions/providersActions";
import DefaultWrappedWithField from "../../../UiComponents/Inputs/DefaultWrappedWithField";
import Input from "../../../UiComponents/Inputs/Default";
import NotesInput from "../../../UiComponents/TextArea/Default";
import DefaultSelect from "../../../UiComponents/Select/Default";
import DefaultDateTimePicker from "../../../UiComponents/DatePicker/WithTime";
import DefaultDatePicker from "../../../UiComponents/DatePicker/Default";

const documentTypes = [
  { value: 0, label: "חשבונית/קבלה" },
  { value: 1, label: "קבלה" },
  { value: 2, label: "חשבונית" },
  { value: 3, label: "חשבונית/קבלה זיכוי" },
  { value: 4, label: "קבלה זיכוי" },
  { value: 5, label: "חשבונית זיכוי" },
];

const paymentMethods = [
  { value: 0, label: "מזומן" },
  { value: 1, label: "שיק" },
  { value: 3, label: "העברה" },
  { value: 4, label: "אשראי" },
  { value: 5, label: "אפליקציה" },
];

const renderToggle = ({
  input,
  label,
  meta: { touched, error },
  type = "text",
}) => {
  const isHebrew = i18n.language === "he";
  const dir = isHebrew ? "rtl" : "ltr";
  return (
    <ToggleContainer isRtl={isHebrew}>
      <ToggleText>{label}</ToggleText>
      <Toggle checked={input.value} onChange={input.onChange} />
    </ToggleContainer>
  );
};

// const DefaultDatePicker = ({
//   input,
//   placeholder,
//   meta: { error, touched },
//   isNoTime,
// }) => {
//   const isHebrew = i18n.language === "he";
//   const dir = isHebrew ? "rtl" : "ltr";
//   return (
//     <DatePicker
//       {...input}
//       selected={input.value}
//       dir={dir}
//       placeholderText={placeholder}
//       dateFormat={isNoTime ? "dd/MM/yyyy" : "dd/MM/yyyy HH:mm"}
//       showTimeSelect={!isNoTime}
//       className={`${touched && error ? "error" : ""} date-input`}
//       locale={i18n.language}
//       popperModifiers={{
//         preventOverflow: {
//           enabled: true,
//         },
//       }}
//       onBlur={() => input.onBlur(input.value)}
//     />
//   );
// };

const NewIncome = ({
  isModalOpen,
  setIsModalOpen,
  handleSubmit,
  create,
  getSchoolClients,
  clients,
  isClientsLoaded,
  getVehicles,
  vehicles,
  isVehiclesLoaded,
  change,
  paymentMethod,
  documentType,
  getDocumentNumber,
  clientId,
  amount,
}) => {
  const [clientsOptions, setClientsOptions] = useState([]);
  const [sum, setSum] = useState(0);
  const vehicleOptions = useVehicles(isVehiclesLoaded, getVehicles, vehicles);

  useEffect(() => {
    async function fetchData() {
      if (!isClientsLoaded) {
        await getSchoolClients();
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (isClientsLoaded) {
      setClientsOptions(clients.map((x) => ({ value: x.id, label: x.name })));
    }
  }, [isClientsLoaded]);

  useEffect(() => {
    if (
      paymentMethod?.value === 1 ||
      paymentMethod?.value === 4 ||
      paymentMethod?.value === 3
    ) {
      change("dateOfMaturity", new Date());
    }
  }, [paymentMethod]);

  useEffect(() => {
    if (isClientsLoaded && typeof clientId !== "undefined") {
      const client = clients.find((client) => client.id === clientId.value);
      let vat = 0.83;
      if (client.vatExemption) {
        change("vat", 0);
        vat = 1;
      }
      setSum(amount - (amount - amount / 1.17).toFixed(2));
    }
  }, [clientId, amount]);

  useEffect(() => {
    async function fetchData() {
      if (typeof documentType !== "undefined") {
        const a = await getDocumentNumber(documentType.value);

        change("documentNumber", a);
      }
    }

    fetchData();
  }, [documentType]);

  const fundingBodyCreateAction = async (formValues) => {
    let incomeToSave = {
      ...formValues,
      clientId: formValues.clientId.value,
      documentType: formValues.documentType.value,
      date: new Date(formValues.date),
    };
    if (documentType !== 2)
      incomeToSave = {
        ...incomeToSave,
        paymentMethod: formValues.paymentMethod.value,
      };
    await create(incomeToSave);
    setIsModalOpen(false);
  };

  return (
    <SivanModal
      titleText="הכנסה חדשה"
      modalIsOpen={isModalOpen}
      closeModal={() => setIsModalOpen(false)}
    >
      <Body onSubmit={handleSubmit(fundingBodyCreateAction)}>
        <Section basicData noBorder>
          <Row>
            <Field
              name="date"
              component={DefaultDateTimePicker}
              placeholder="תאריך ושעה"
            />
            <Field
              name="clientId"
              component={DefaultSelect}
              placeholder="לקוח"
              options={clientsOptions}
            />
            <Field
              name="description"
              component={NotesInput}
              placeholder="תאור"
            />
            <Field
              name="quantity"
              component={DefaultWrappedWithField}
              placeholder="כמות"
              type="number"
            />
            <Field
              name="isEquipmentAndAssets"
              component={renderToggle}
              label="ציוד ונכסים קבועים"
            />
            <Field
              name="vehicleId"
              component={DefaultSelect}
              placeholder="רכב"
              options={[{ value: 999, label: "כל הרכבים" }, ...vehicleOptions]}
            />
          </Row>
          <Row>
            <Field
              name="documentType"
              component={DefaultSelect}
              placeholder="סוג"
              options={documentTypes}
            />
            <Field
              name="vat"
              component={DefaultWrappedWithField}
              placeholder="מע״מ"
              disabled
            />
            <Field
              name="documentNumber"
              component={DefaultWrappedWithField}
              placeholder="מספר מסמך"
              type="number"
              disabled
            />
            <Field name="notes" component={NotesInput} placeholder="הערה" />
          </Row>
          <Row>
            {documentType?.value !== 2 && (
              <Field
                name="paymentMethod"
                component={DefaultSelect}
                placeholder="סוג תשלום"
                options={paymentMethods}
              />
            )}
            <Field
              name="amount"
              component={DefaultWrappedWithField}
              placeholder="סכום"
              type="number"
            />
            <Input
              placeholder="סכום ללא מעמ"
              value={sum}
              disabled
              isRtl={true}
            />
            {(paymentMethod === paymentMethods[1] ||
              paymentMethod === paymentMethods[2]) && (
              <Field
                name="bank"
                component={DefaultWrappedWithField}
                placeholder="בנק"
                type="number"
              />
            )}
            {(paymentMethod === paymentMethods[1] ||
              paymentMethod === paymentMethods[2]) && (
              <Field
                name="bankBranch"
                component={DefaultWrappedWithField}
                placeholder="סניף"
                type="number"
              />
            )}
            {paymentMethod === paymentMethods[1] && (
              <Field
                name="checkNumber"
                component={DefaultWrappedWithField}
                placeholder="מס המחאה"
                type="number"
              />
            )}
            {(paymentMethod === paymentMethods[1] ||
              paymentMethod === paymentMethods[2] ||
              paymentMethod === paymentMethods[4]) && (
              <Field
                name="accountId"
                component={DefaultWrappedWithField}
                placeholder="חשבון"
                type="number"
              />
            )}
            {paymentMethod === paymentMethods[2] && (
              <Field
                name="reference"
                component={DefaultWrappedWithField}
                placeholder="אסמכתא"
                type="text"
              />
            )}
            {(paymentMethod === paymentMethods[3] ||
              paymentMethod === paymentMethods[4]) && (
              <Field
                name="confirmationNumber"
                component={DefaultWrappedWithField}
                placeholder="מספר אישור"
                type="text"
              />
            )}
            {paymentMethod === paymentMethods[3] && (
              <Field
                name="confirmationName"
                component={DefaultWrappedWithField}
                placeholder="שם מאשר"
                type="text"
              />
            )}
            {(paymentMethod === paymentMethods[1] ||
              paymentMethod === paymentMethods[2] ||
              paymentMethod === paymentMethods[3]) && (
              <Field
                name="dateOfMaturity"
                component={DefaultDatePicker}
                placeholder="תאריך פרעון"
              />
            )}
            {paymentMethod === paymentMethods[4] && (
              <Field
                name="appName"
                component={DefaultWrappedWithField}
                placeholder="אפליקציה"
                type="text"
              />
            )}
          </Row>
        </Section>
        <CreateButton text="צור" type="submit" />
      </Body>
    </SivanModal>
  );
};

const selector = formValueSelector("NewIncome"); // <-- same as form name

function mapStateToProps(state, ownProps) {
  return {
    vehicles: state.VehiclesReducer.vehicles,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    providers: state.ProvidersReducer.providers,
    paymentMethod: selector(state, "paymentMethod"),
    amount: selector(state, "amount"),
    documentType: selector(state, "documentType"),
    clientId: selector(state, "clientId"),
    isProvidersLoaded: state.ProvidersReducer.isLoaded,
    clients: state.ClientsReducer.clients,
    isClientsLoaded: state.ClientsReducer.isLoaded,
    initialValues: {
      date: new Date(),
      vat: 17,
      quantity: 1,
      paymentMethod: paymentMethods[0],
      documentType: documentTypes[0],
      vehicleId: { value: 999, label: "כל הרכבים" },
      amount: 0,
    },
  };
}

export default connect(mapStateToProps, {
  create,
  getSchoolClients,
  getVehicles,
  getSchoolProviders,
  change,
  getDocumentNumber,
})(
  reduxForm({
    form: "NewIncome",
    validate,
  })(NewIncome)
);
