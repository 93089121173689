import validator from "validator";
import isIsraeliIdValid from "israeli-id-validator";

export default (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = "Required";
  }

  if (!values.lastName) {
    errors.lastName = "Required";
  }

  if (!values.branchId || values.branchId === 1) {
    errors.branchId = "Required";
  }

  if (!values.studentId || !validator.isNumeric(values.studentId.toString())) {
    errors.studentId = "Required";
  } else if (!isIsraeliIdValid(values.studentId)) {
    errors.studentId = "Required";
  }

  if (!values.phone || !validator.isMobilePhone(values.phone, ["he-IL"])) {
    errors.phone = "Required";
  }

  if (!values.gender) {
    errors.gender = "Required";
  }

  if (!values.birthday || values.birthday > Date.now()) {
    errors.birthday = "Required";
  }

  return errors;
};
