export const optionsArrrayWithOneParam = (array, value, label) =>
  array.map((element) => ({
    value: element[value],
    label: element[label],
  }));

export const optionsArrrayWithTwoParams = (
  array,
  value,
  firstLabel,
  secondLabel
) =>
  array.map((element) => ({
    value: element[value],
    label: `${element[firstLabel]} ${element[secondLabel]}`,
  }));
