import React, { useState, useEffect } from "react";
import i18n from "../../../lib/i18n/i18n";
import { connect } from "react-redux";
import { Container, Title } from "./incomes.styles";
import NewIncome from "./NewIncome/NewIncome";
import IncomeData from "./IncomeData/IncomeData";
import { getSchoolClients } from "../../../actions/clientsActions";
import { getAll } from "../../../actions/incomesAction";
import Table from "../../SivanPlusTable/SivanPlusTable";
import CreateButton from "../../UiComponents/Buttons/Default";
import { DateColumnFilter } from "../../SivanPlusTable/filters";
import moment from "moment";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;
const documentTypes = [
  { value: 0, label: "חשבונית/קבלה" },
  { value: 1, label: "קבלה" },
  { value: 2, label: "חשבונית" },
  { value: 3, label: "חשבונית/קבלה זיכוי" },
  { value: 4, label: "קבלה זיכוי" },
  { value: 5, label: "חשבונית זיכוי" },
];
const Incomes = ({
  incomes,
  isLoaded,
  getAll,
  user,
  getSchoolClients,
  isClientsLoaded,
  clients,
}) => {
  const [isProviderModalOpen, setProviderModalOpen] = useState(false);
  const [providerData, setProviderData] = useState(false);
  const [isProviderDetailsModalOpen, setProviderDetailsModalOpen] =
    useState(false);

  useEffect(() => {
    async function fetchData() {
      if (!isClientsLoaded) {
        await getSchoolClients();
      }
      await getAll();
    }
    fetchData();
  }, []);

  const vehicleColumns = [
    {
      Header: dictionary.table.date,
      accessor: "date",
      Cell: ({ row: { original } }) => {
        const date = moment(original.date).format("DD/MM/YYYY");

        return original.documentType === 3 ||
          original.documentType === 4 ||
          original.documentType === 5 < 0 ? (
          <span style={{ color: "red", fontWeight: "bold" }}>{date}</span>
        ) : (
          date
        );
      },
      Filter: DateColumnFilter,
      filter: (array, s, date) => {
        return array.filter((x) => moment(x.original.date).isSame(date, "day"));
      },
    },
    {
      Header: "מספר מסמך כספי",
      accessor: "documentNumber",
      Cell: ({ row: { original } }) => {
        return original.documentType === 3 ||
          original.documentType === 4 ||
          original.documentType === 5 < 0 ? (
          <span style={{ color: "red", fontWeight: "bold" }}>
            {original.documentNumber}
          </span>
        ) : (
          original.documentNumber
        );
      },
    },
    {
      Header: dictionary.table.quantity,
      accessor: "quantity",
    },
    {
      Header: dictionary.table.vehicleNumber,
      accessor: "vehicleNumber",
      Cell: ({ row: { original } }) => {
        return original.vehicleId === 999
          ? "כל הרכבים"
          : original.vehicleNumber;
      },
    },
    {
      Header: dictionary.table.amount,
      accessor: "amount",
    },
    {
      Header: "לקוח",
      accessor: "clientId",
      Cell: ({ row: { original } }) => {
        let clientName = "";
        if (clients.length !== 0)
          clientName = clients.find(
            (x) => x.id.toString() === original.clientId
          ).name;

        return original.documentType === 3 ||
          original.documentType === 4 ||
          original.documentType === 5 < 0 ? (
          <span style={{ color: "red", fontWeight: "bold" }}>{clientName}</span>
        ) : (
          clientName
        );
      },
    },
    {
      Header: dictionary.table.documentType,
      accessor: "documentType",
      Cell: ({ row: { original } }) => {
        return documentTypes.find((x) => x.value === original.documentType)
          ?.label;
      },
    },
  ];

  const onRowClick = async (rowObject) => {
    setProviderDetailsModalOpen(true);
    setProviderData(rowObject.original);
  };
  return (
    <Container>
      {isProviderModalOpen && (
        <NewIncome
          isModalOpen={isProviderModalOpen}
          setIsModalOpen={setProviderModalOpen}
        />
      )}
      <IncomeData
        isModalOpen={isProviderDetailsModalOpen}
        closeModal={() => setProviderDetailsModalOpen(false)}
        providerData={providerData}
      />
      <Title>
        הכנסות
        {user.createIncome ? (
          <CreateButton
            onClick={() => setProviderModalOpen(true)}
            text="צור חדש"
          />
        ) : null}
      </Title>
      <Table
        data={incomes}
        componentColumns={vehicleColumns}
        onRowClick={onRowClick}
      />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    incomes: state.IncomesReducer.incomes,
    isLoaded: state.IncomesReducer.isLoaded,
    createVehicle: state.session.user.createVehicle,
    user: state.session.user,
    editVehicle: state.session.user.editVehicle,
    clients: state.ClientsReducer.clients,
    isClientsLoaded: state.ClientsReducer.isLoaded,
  };
}

export default connect(mapStateToProps, {
  getAll,
  getSchoolClients,
})(Incomes);
