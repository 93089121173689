import React, { useEffect } from "react";
import { Field, reduxForm, FormSection, change } from "redux-form";
import i18n from "../../../lib/i18n/i18n";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Body,
  AdditionalDetailsContainer,
  Button,
  Fields,
  ButtonContainer,
} from "./createStudent.styles";
import { getVehicles } from "../../../actions/vehicleActions";
import { getAgreements } from "../../../actions/agreementsActions";
import useTeacheres from "../../useComponents/useTeachers";
import useAgreements from "../../useComponents/useAgreements";
import useVehicles from "../../useComponents/useVehicles";
import { getUsers } from "../../../actions/userActions";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;
const smsOptions = [
  { value: "sms", label: dictionary.newStudent.sms },
  { value: "whatsapp", label: dictionary.newStudent.whatsapp },
];

const smsLanguages = [
  { value: "hebrew", label: dictionary.newStudent.hebrew },
  { value: "arabic", label: dictionary.newStudent.arabic },
  { value: "russian", label: dictionary.newStudent.russian },
];

const AdditionalDetails = ({
  handleSubmit,
  renderSelect,
  renderDatePickerField,
  renderInput,
  renderToggle,
  back,
  getVehicles,
  getAgreements,
  isUsersLoaded,
  getUsers,
  users,
  vehicles,
  isVehiclesLoaded,
  change,
}) => {
  const { t, i18n } = useTranslation("common");
  const teachersOptions = useTeacheres(isUsersLoaded, getUsers, users);
  const agreementList = useAgreements(getAgreements);
  const vehicleOptions = useVehicles(isVehiclesLoaded, getVehicles, vehicles);

  useEffect(() => {
    if (
      agreementList.length > 0 &&
      vehicleOptions.length > 0 &&
      teachersOptions.length > 0
    ) {
      change("textbook.agreementId", agreementList[0]);
      change("textbook.teacherId", teachersOptions[0]);
      change("textbook.vehicleId", vehicleOptions[0]);
    }
  }, [agreementList, vehicleOptions, teachersOptions]);

  return (
    <Body onSubmit={handleSubmit} autoComplete="off">
      <FormSection isRtl={i18n.language === "he"} name="student">
        <Fields>
          <AdditionalDetailsContainer>
            <Field
              label={t("newStudent.registrationFees")}
              component={renderToggle}
              name="registrationFees"
            />
            <Field
              label={t("newStudent.glasses")}
              component={renderToggle}
              name="glasses"
            />
            <Field
              label={t("newStudent.smsOnEventCreation")}
              component={renderToggle}
              name="smsOnEventCreation"
            />
            <Field
              name="smsLanguage"
              component={renderSelect}
              placeholder={t("newStudent.smsLanguage")}
              options={smsLanguages}
            />
          </AdditionalDetailsContainer>
          <AdditionalDetailsContainer>
            <Field
              name="smsType"
              component={renderSelect}
              placeholder={t("newStudent.smsType")}
              options={smsOptions}
            />
            <Field
              name="certificateOfGoodConduct"
              component={renderDatePickerField}
              placeholder={t("newStudent.certificateOfGoodConduct")}
            />
            <Field
              name="medicalInstitute"
              component={renderDatePickerField}
              placeholder={t("newStudent.medicalInstitute")}
            />
          </AdditionalDetailsContainer>
        </Fields>
      </FormSection>
      <FormSection isRtl={i18n.language === "he"} name="textbook">
        <Fields>
          <AdditionalDetailsContainer>
            <Field
              name="agreementId"
              component={renderSelect}
              placeholder="הסכם"
              options={agreementList}
            />
          </AdditionalDetailsContainer>
          <AdditionalDetailsContainer>
            <Field
              name="teacherId"
              component={renderSelect}
              placeholder="מורה"
              options={teachersOptions}
            />
          </AdditionalDetailsContainer>
          <AdditionalDetailsContainer>
            <Field
              name="vehicleId"
              component={renderSelect}
              placeholder="רכב"
              options={vehicleOptions}
            />
          </AdditionalDetailsContainer>
          <AdditionalDetailsContainer>
            <Field
              name="eyeExamination"
              component={renderDatePickerField}
              placeholder="בדיקת עיניים"
            />
          </AdditionalDetailsContainer>
          <AdditionalDetailsContainer>
            <Field
              name="documentCheck"
              component={renderDatePickerField}
              placeholder="הזנה בברוש"
            />
          </AdditionalDetailsContainer>
        </Fields>
      </FormSection>
      <ButtonContainer>
        <Button type="submit">{t("buttons.save")}</Button>
        <Button onClick={back} type="button">
          {t("buttons.back")}
        </Button>
      </ButtonContainer>
    </Body>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isUsersLoaded: state.UsersReducer.isLoaded,
    users: state.UsersReducer.users,
    isPending: state.StudentsReducer.isPending,
    vehicles: state.VehiclesReducer.vehicles,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
  };
}

export default connect(mapStateToProps, {
  getVehicles,
  getAgreements,
  getUsers,
  change,
})(
  reduxForm({
    form: "createStudent", // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })(AdditionalDetails)
);
