import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getAll } from "../../../actions/incomesAction";
import DatePicker from "react-datepicker";
import _ from "lodash";
import moment from "moment";
import i18n from "../../../lib/i18n/i18n";
import Select from "react-select";
import {
  Row,
  FieldText,
  Field,
  customStyles,
  Container,
} from "./InvoicesTypesByMonth.styles";
import Button from "../../UiComponents/Buttons/Default";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const ActivityReport = ({ getAll, isLoaded, incomes }) => {
  const [graphData, setGraphData] = useState([]);
  const [currentView, setCurrentView] = useState("bymonths");
  const [startDate, setStartDate] = useState(new Date().setMonth(0));
  const [endDate, setEndDate] = useState(new Date());
  const [selectedTeacher, setSelectedTeacher] = useState();
  const [selectOptions, setSelectOptions] = useState([]);
  const [rawGraphData, setRawGraphData] = useState([]);

  useEffect(() => {
    (async () => {
      await getAll(1);
    })();
  }, []);

  useEffect(() => {
    if (incomes.length > 0) {
      const data = [];
      setSelectedTeacher(null);
      for (
        var day = moment(new Date(startDate));
        day.isSameOrBefore(new Date(endDate), "day");
        day.add(1, "months")
      ) {
        if (currentView === "byVehicles") {
          const uniqueVehicle = _.uniqBy(incomes, "vehicleNumber");
          const vehicels = uniqueVehicle
            .filter((x) => x.vehicleNumber !== null)
            .map((x) => x.vehicleNumber);
          setSelectOptions(vehicels.map((x) => ({ value: x, label: x })));
          filterByData(vehicels, day, "vehicleNumber", data);
        } else if (currentView === "byLicense") {
          const uniqueLicenses = _.uniqBy(incomes, "name");
          const licenses = uniqueLicenses
            .filter((x) => x.name !== null)
            .map((x) => x.name);
          setSelectOptions(licenses.map((x) => ({ value: x, label: x })));
          filterByData(licenses, day, "name", data);
        } else if (currentView === "byTeacher") {
          const uniqueLicenses = _.uniqBy(incomes, "teacherUsername");
          const licenses = uniqueLicenses
            .filter((x) => x.teacherUsername !== null)
            .map((x) => x.teacherUsername);
          setSelectOptions(licenses.map((x) => ({ value: x, label: x })));
          filterByData(licenses, day, "teacherUsername", data);
        } else if (currentView === "byUser") {
          const uniqueLicenses = _.uniqBy(incomes, "createUsername");
          const licenses = uniqueLicenses
            .filter((x) => x.createUsername !== null)
            .map((x) => x.createUsername);
          setSelectOptions(licenses.map((x) => ({ value: x, label: x })));
          filterByData(licenses, day, "createUsername", data);
        } else {
          setSelectOptions([]);
          const currentMonthFinancialDocuments = incomes.filter(
            (x) =>
              moment(new Date(x.created)).isSame(day, "month") &&
              moment(new Date(x.created)).isSame(day, "year")
          );
          debugger;
          const totalInvoiceReceipts = currentMonthFinancialDocuments.filter(
            (x) => x.documentType === 0
          ).length;
          const totalReceipts = currentMonthFinancialDocuments.filter(
            (x) => x.documentType === 1
          ).length;
          const totalInvoices = currentMonthFinancialDocuments.filter(
            (x) => x.documentType === 2
          ).length;
          const totalCreditInvoiceReceipts =
            currentMonthFinancialDocuments.filter(
              (x) => x.documentType === 3
            ).length;
          const totalCreditReceipts = currentMonthFinancialDocuments.filter(
            (x) => x.documentType === 4
          ).length;
          const totalCreditInvoices = currentMonthFinancialDocuments.filter(
            (x) => x.documentType === 5
          ).length;

          data.push({
            date: day.format("MM/YY"),
            "חשבונית/קבלה": totalInvoiceReceipts,
            קבלות: totalReceipts,
            חשבוניות: totalInvoices,
            "חשבונית/קבלה זיכוי": totalCreditInvoiceReceipts,
            "קבלה זיכוי": totalCreditReceipts,
            "חשבונית זיכוי": totalCreditInvoices,
          });
        }
      }
      setRawGraphData(data);
      setGraphData(data);
    }
  }, [incomes, startDate, endDate, currentView]);

  const filterByData = (array, day, param, data) => {
    for (let j = 0; j < array.length; j++) {
      const element = array[j];
      const currentMonthFinancialDocuments = incomes.filter(
        (x) =>
          moment(new Date(x.created)).isSame(day, "month") &&
          moment(new Date(x.created)).isSame(day, "year") &&
          x[param] === element
      );

      const totalInvoiceReceipts = currentMonthFinancialDocuments.filter(
        (x) => x.documentType === 0
      ).length;
      const totalReceipts = currentMonthFinancialDocuments.filter(
        (x) => x.documentType === 1
      ).length;
      const totalInvoices = currentMonthFinancialDocuments.filter(
        (x) => x.documentType === 2
      ).length;
      const totalCreditInvoiceReceipts = currentMonthFinancialDocuments.filter(
        (x) => x.documentType === 3
      ).length;
      const totalCreditReceipts = currentMonthFinancialDocuments.filter(
        (x) => x.documentType === 4
      ).length;
      const totalCreditInvoices = currentMonthFinancialDocuments.filter(
        (x) => x.documentType === 5
      ).length;

      data.push({
        date: `${element} - ${day.format("MM/YY")}`,
        "חשבונית/קבלה": totalInvoiceReceipts,
        קבלות: totalReceipts,
        חשבוניות: totalInvoices,
        "חשבונית/קבלה זיכוי": totalCreditInvoiceReceipts,
        "קבלה זיכוי": totalCreditReceipts,
        "חשבונית זיכוי": totalCreditInvoices,
      });
    }
  };

  const handleChange = (selected) => {
    setSelectedTeacher(selected);
    if (selected && selected.length > 0) {
      let filteredData = [];
      for (let index = 0; index < selected.length; index++) {
        const element = selected[index];
        filteredData = [
          ...filteredData,
          ...rawGraphData.filter((x) => x.date.includes(element.value)),
        ];
      }
      setGraphData(filteredData);
    } else {
      setGraphData(rawGraphData);
    }
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1>סוג מסמך לפי חודש</h1>
      </div>

      <Row>
        <Button
          text="לפי חודשים"
          onClick={() => {
            setCurrentView("byMonths");
          }}
          marginRight="1"
        />
        <Button
          text="לפי רכבים"
          onClick={() => {
            setCurrentView("byVehicles");
          }}
          marginRight="1"
        />
        <Button
          text="לפי רישון"
          onClick={() => {
            setCurrentView("byLicense");
          }}
          marginRight="1"
        />
        <Button
          text="לפי יוצר"
          onClick={() => {
            setCurrentView("byUser");
          }}
          marginRight="1"
        />
        <Button
          text="לפי מורה"
          onClick={() => {
            setCurrentView("byTeacher");
          }}
          marginRight="1"
        />
        <div style={{ width: "33%", marginRight: "1vw" }}>
          <Select
            options={selectOptions}
            value={selectedTeacher}
            isMulti
            placeholder="בחר"
            styles={customStyles}
            onChange={handleChange}
            isRtl
          />
        </div>
        <>
          <Field>
            <FieldText>מ</FieldText>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              locale={i18n.language}
              startDate={startDate}
              dateFormat="MMMM"
              endDate={endDate}
              minDate={startDate}
              showMonthYearPicker
              showFullMonthYearPicker
            />
          </Field>
          <Field>
            <FieldText>עד</FieldText>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              locale={i18n.language}
              dateFormat="MMMM"
              showMonthYearPicker
              showFullMonthYearPicker
              selectsStart
              startDate={startDate}
              endDate={endDate}
            />
          </Field>
        </>
      </Row>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={graphData}>
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="חשבונית/קבלה" fill="#CE84AD" radius={[10, 10, 0, 0]} />
          <Bar dataKey="קבלות" fill="#CE96A6" radius={[10, 10, 0, 0]} />
          <Bar dataKey="חשבוניות" fill="#D1A7A0" radius={[10, 10, 0, 0]} />
          <Bar
            dataKey="חשבונית/קבלה זיכוי"
            fill="#D4CBB3"
            radius={[10, 10, 0, 0]}
          />
          <Bar dataKey="קבלה זיכוי" fill="#D2E0BF" radius={[10, 10, 0, 0]} />
          <Bar dataKey="חשבונית זיכוי" fill="#1446A0" radius={[10, 10, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    incomes: state.IncomesReducer.incomes,
    isLoaded: state.IncomesReducer.isLoaded,
  };
}

export default connect(mapStateToProps, {
  getAll,
})(ActivityReport);
