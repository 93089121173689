import React, { useState, useEffect } from "react";
import Select from "react-select";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import i18n from "../../../lib/i18n/i18n";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import {
  Container,
  Title,
  Body,
  PermanentDetailsContainer,
  AdditionalDetailsContainer,
  Input,
  ColumnTitle,
  customStyles,
  Button,
  Fields,
  ButtonContainer,
  ToggleContainer,
  ToggleText,
  CounterButton,
} from "./tests.styles";

const renderInput = ({
  input,
  placeholder,
  meta: { touched, error },
  type = "text",
}) => {
  const isHebrew = i18n.language === "he";
  const dir = isHebrew ? "rtl" : "ltr";
  return (
    <Input
      isRtl={isHebrew}
      dir={dir}
      type={type}
      placeholder={placeholder}
      {...input}
      isError={touched && error}
      autocomplete="off"
    />
  );
};

const renderToggle = ({ input, label }) => {
  const isHebrew = i18n.language === "he";
  return (
    <ToggleContainer isRtl={isHebrew}>
      <ToggleText>{label}</ToggleText>
      <Toggle checked={input.value} onChange={input.onChange} />
    </ToggleContainer>
  );
};

const dayOptions = [
  { value: "sunday", label: "Sunday" },
  { value: "monday", label: "Monday" },
  { value: "tuesday", label: "Tuesday" },
  { value: "wednesday", label: "Wednesday" },
  { value: "thursday", label: "Thursday" },
];
const General = () => {
  const [genderSelectedOption, setGenderSelectedOption] = useState();
  const [vehicleOptions, setVehicleOptions] = useState();

  return (
    <Container>
      <Body>
        <Fields>
          <PermanentDetailsContainer>
            <Select
              value={genderSelectedOption}
              onChange={(option) => setGenderSelectedOption(option)}
              options={dayOptions}
              placeholder="Day"
              styles={customStyles}
            />
            <Field name="cpaEmail" component={renderInput} placeholder="Time" />
          </PermanentDetailsContainer>
          <AdditionalDetailsContainer>
            <Select
              value={genderSelectedOption}
              onChange={(option) => setGenderSelectedOption(option)}
              options={vehicleOptions}
              placeholder="Sort By"
              styles={customStyles}
            />
            <Select
              value={genderSelectedOption}
              onChange={(option) => setGenderSelectedOption(option)}
              options={vehicleOptions}
              placeholder="Then Sort By"
              styles={customStyles}
            />
            <Select
              value={genderSelectedOption}
              onChange={(option) => setGenderSelectedOption(option)}
              options={vehicleOptions}
              placeholder="Then Sort By"
              styles={customStyles}
            />
            <Field
              name="cpaEmail"
              component={renderInput}
              placeholder="Duration Of Tests"
            />
            <Field
              name="businessInEilat"
              component={renderToggle}
              label="One Hour For A Couple Of Tests"
            />
          </AdditionalDetailsContainer>
          <AdditionalDetailsContainer>
            <Select
              value={genderSelectedOption}
              onChange={(option) => setGenderSelectedOption(option)}
              options={vehicleOptions}
              placeholder="Sort By"
              styles={customStyles}
            />
            <Select
              value={genderSelectedOption}
              onChange={(option) => setGenderSelectedOption(option)}
              options={vehicleOptions}
              placeholder="Then Sort By"
              styles={customStyles}
            />
            <Select
              value={genderSelectedOption}
              onChange={(option) => setGenderSelectedOption(option)}
              options={vehicleOptions}
              placeholder="Then Sort By"
              styles={customStyles}
            />
          </AdditionalDetailsContainer>
        </Fields>
      </Body>
      <ButtonContainer>
        <Button reset>Save</Button>
      </ButtonContainer>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isPending: state.StudentsReducer.isPending,
    initialValues: {
      registrationFees: false,
      smsOnEventCreation: false,
      glasses: false,
      registrationFees: false,
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "settingsGeneral",
  })(General)
);
