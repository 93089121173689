import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import i18n from "../../../../../lib/i18n/i18n";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import {
  Body,
  InvoiceCompanyNameHeader,
  InvoiceType,
  InvoiceStatus,
  Line,
  InvoiceBody,
  InvoiceHeader,
  InvoiceContainer,
  Table,
  TableRow,
  TableHeader,
  TableCell,
  PrintFooter,
  ClientDataText,
  ClientData,
  NotesContainer,
  NotesText,
} from "./incomeDetails.styles";
import {
  updateInvoicePrinted,
  deleteItem,
} from "../../../../../actions/incomesAction";
import Button from "../../../../UiComponents/Buttons/Default";
import { getVehicles } from "../../../../../actions/vehicleActions";
import useVehicles from "../../../../useComponents/useVehicles";
import {
  getSchoolFundingBodies,
  getPaymentRequests,
} from "../../../../../actions/fundingBodiesActions";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

const documentTypes = [
  { value: 0, label: "חשבונית/קבלה" },
  { value: 1, label: "קבלה" },
  { value: 2, label: "חשבונית" },
];

const paymentMethods = [
  { value: 0, label: "מזומן" },
  { value: 1, label: "שיק" },
  { value: 3, label: "העברה" },
  { value: 4, label: "אשראי" },
  { value: 5, label: "אפליקציה" },
];

const FundingBodiesDetails = ({
  handleSubmit,
  getVehicles,
  vehicles,
  isVehiclesLoaded,
  paymentMethod,
  documentType,
  isFundingBodiesLoaded,
  fundingBodies,
  providerData,
  user,
  updateInvoicePrinted,
  amount,
  getPaymentRequests,
}) => {
  const [clientData, setClientData] = useState(0);
  const [invoiceType, setInvoiceType] = useState("תצוגה מקדימה");
  const [paymentRequestData, setPaymentRequestData] = useState([]);
  const vehicleOptions = useVehicles(isVehiclesLoaded, getVehicles, vehicles);

  const handlePrint = async (type) => {
    if (type === "העתק") {
      setInvoiceType("(העתק) ");
    } else {
      if (providerData.isOriginalPrinted) {
        setInvoiceType(" (העתק נאמן למקור) ");
      } else {
        const response = await updateInvoicePrinted(providerData);
        if (response.isNew) {
          setInvoiceType(" (העתק נאמן למקור) ");
        } else {
          setInvoiceType(" (מקור) ");
        }
      }
    }

    print();
  };

  const print = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => setInvoiceType("תצוגה מקדימה"),
    documentTitle: `${documentType?.label} מס / ${providerData.documentNumber}`,
  });

  const componentRef = useRef();

  useEffect(() => {
    async function fetchData() {
      if (!isFundingBodiesLoaded) {
        await getSchoolFundingBodies();
      }
      const requests = await getPaymentRequests(providerData.clientId);
      setPaymentRequestData(
        JSON.parse(
          requests.find((x) => x.id === providerData.paymentRequestId)
            .invoiceData
        )
      );
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (fundingBodies.length) {
      const client = fundingBodies.find(
        (x) => x.id.toString() === providerData.clientId
      );
      setClientData(client);
    }
  }, [fundingBodies]);

  const paymentMethodDetails = () => {
    if (providerData.paymentMethod === 0) {
      return "";
    } else if (providerData.paymentMethod === 1) {
      return ` חשבון: ${providerData.accountId}, סניף: ${providerData.bankBranch}, בנק: ${providerData.bank}, שיק: ${providerData.checkNumber}`;
    } else if (providerData.paymentMethod === 3) {
      return ` חשבון: ${providerData.accountId}, סניף: ${providerData.bankBranch}, בנק: ${providerData.bank}, אסמכתא: ${providerData.reference}`;
    } else if (providerData.paymentMethod === 4) {
      return ` מספר אישור: ${providerData.confirmationNumber}, מאשר: ${providerData.confirmationName}`;
    } else if (providerData.paymentMethod === 5) {
      return ` חשבון: ${providerData.accountId}, מספר אישור: ${providerData.confirmationNumber}, סוג: ${providerData.appName}`;
    }
  };
  const fundingBodyCreateAction = async (formValues) => {};
  return (
    <Body onSubmit={handleSubmit(fundingBodyCreateAction)}>
      <InvoiceContainer ref={componentRef}>
        <InvoiceHeader>
          <InvoiceCompanyNameHeader dir="auto">
            <span>{user.schoolFullName}</span>
          </InvoiceCompanyNameHeader>
          <br />
          <span>
            {user.isCompany ? "חפ:" : "עוסק מורשה:"} {user.bnNumber}
          </span>
          <span>
            {user.schoolPhone}, {user.schoolEmail}
          </span>
          <span>{user.schoolAddress}</span>
          <br />
          <InvoiceType>
            {documentType?.label} מס / {providerData.documentNumber}
            <InvoiceStatus> {invoiceType}</InvoiceStatus>
          </InvoiceType>
          <ClientDataText>
            מכרז מספר: {providerData.auctionNumber}
          </ClientDataText>
          <ClientDataText>
            הזמנת רכש מספר: {providerData.purchaseOrderNumber}
          </ClientDataText>
          <Line />
        </InvoiceHeader>
        <ClientData>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ClientDataText>
              לכבוד: {clientData.name} ({clientData.pc})
            </ClientDataText>
            <ClientDataText>
              תאריך: {moment(providerData.date).format("DD/MM/YYYY HH:mm")}
            </ClientDataText>
          </div>
          {clientData.address ||
            (clientData.city && (
              <ClientDataText>
                פרטי כתובת: {clientData.address && `${clientData.address}, `}
                {clientData.city}
              </ClientDataText>
            ))}
        </ClientData>
        {(documentType?.value === 2 || documentType?.value === 0) && (
          <InvoiceBody>
            <Table>
              <TableRow style={{ background: "dimgrey", color: "white" }}>
                <TableHeader alignRight>מוצר/שירות</TableHeader>
                <TableHeader style={{ width: "8%", textAlign: "center" }}>
                  סה״כ
                </TableHeader>
              </TableRow>
              {providerData.description && (
                <TableRow>
                  <TableCell specialBackground style={{ textAlign: "right" }}>
                    {providerData.description}
                  </TableCell>
                </TableRow>
              )}
              {paymentRequestData.map((x) => (
                <TableRow>
                  <TableCell specialBackground style={{ textAlign: "right" }}>
                    {`תלמיד: ${x.name} ${x.studentId} מספר שיעורים: ${x.lessons} מספר מבחנים פנימיים: ${x.internalTests} מספר טסטים: ${x.tests} מחיר הסכם: ${x.activeAgreementOpeningBalance}`}
                  </TableCell>
                  <TableCell specialBackground>
                    {(x.total / 1.17).toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell alignLeft bold>
                  17% מע״מ:
                </TableCell>
                <TableCell specialBackground bold>
                  {(amount - amount / 1.17).toFixed(2)?.toLocaleString()}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell alignLeft bold>
                  סה״כ כולל מע״מ:
                </TableCell>
                <TableCell specialBackground bold>
                  {amount?.toLocaleString()}
                </TableCell>
              </TableRow>
            </Table>
            <Line />
          </InvoiceBody>
        )}
        {(documentType?.value === 1 || documentType?.value === 0) && (
          <InvoiceBody>
            <Table>
              <TableRow style={{ background: "dimgrey", color: "white" }}>
                <TableHeader alignRight>סוג תשלום</TableHeader>
                {providerData.paymentMethod !== 0 && (
                  <TableHeader alignRight>פרטים נוספים</TableHeader>
                )}
                {providerData.paymentMethod !== 0 &&
                  (paymentMethod?.value === 3 ||
                    paymentMethod?.value === 4 ||
                    paymentMethod?.value === 1) && (
                    <TableHeader>תאריך פרעון</TableHeader>
                  )}
                <TableHeader style={{ width: "8%", textAlign: "center" }}>
                  סה״כ
                </TableHeader>
              </TableRow>
              <TableRow>
                <TableCell specialBackground style={{ textAlign: "right" }}>
                  {paymentMethod?.label}
                </TableCell>
                {paymentMethod?.value !== 0 && (
                  <TableCell specialBackground style={{ textAlign: "right" }}>
                    {paymentMethodDetails()}
                  </TableCell>
                )}
                {providerData.paymentMethod !== 0 &&
                  (paymentMethod?.value === 3 ||
                    paymentMethod?.value === 4 ||
                    paymentMethod?.value === 1) && (
                    <TableCell specialBackground>
                      {new Date(
                        providerData.dateOfMaturity
                      ).toLocaleDateString()}
                    </TableCell>
                  )}
                <TableCell specialBackground>
                  {amount.toLocaleString()}
                </TableCell>
              </TableRow>
            </Table>
            <NotesContainer>
              <NotesText>{providerData.notes}</NotesText>
            </NotesContainer>
            <Line />
          </InvoiceBody>
        )}
        <PrintFooter>
          הופק באמצעות קמע מחשבים סיון בע"מ. טלפון 0537-222-228
        </PrintFooter>
      </InvoiceContainer>
      <Button
        text="הפק העתק"
        onClick={() => handlePrint("העתק")}
        marginRight={1}
      />
      <Button text="הפק מקור" onClick={() => handlePrint("מקור")} />
    </Body>
  );
};

const selector = formValueSelector("incomeDetails"); // <-- same as form name
function mapStateToProps(state, ownProps) {
  return {
    vehicles: state.VehiclesReducer.vehicles,
    user: state.session.user,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    providers: state.ProvidersReducer.providers,
    paymentMethod: selector(state, "paymentMethod"),
    amount: selector(state, "amount"),
    documentType: selector(state, "documentType"),
    clientId: selector(state, "clientId"),
    fundingBodies: state.FundingBodiesReducer.fundingBodies,
    isCredit: selector(state, "isCredit"),
    documentNumber: selector(state, "documentNumber"),
    isProvidersLoaded: state.ProvidersReducer.isLoaded,
    isFundingBodiesLoaded: state.FundingBodiesReducer.isLoaded,
    initialValues: {
      ...ownProps.providerData,
      paymentMethod: paymentMethods.find(
        (x) => x.value === ownProps.providerData.paymentMethod
      ),
      documentType: documentTypes.find(
        (x) => x.value === ownProps.providerData.documentType
      ),
      vehicleId: {
        value: ownProps.providerData.vehicleId,
        label: ownProps.providerData.vehicleNumber
          ? ownProps.providerData.vehicleNumber
          : "כל הרכבים",
      },
    },
  };
}

export default connect(mapStateToProps, {
  updateInvoicePrinted,
  deleteItem,
  getVehicles,
  change,
  getSchoolFundingBodies,
  getPaymentRequests,
})(
  reduxForm({
    form: "incomeDetails",
  })(FundingBodiesDetails)
);
