import React, { useState } from "react";
import Toggle from "react-toggle";
import { connect } from "react-redux";
import { FieldArray, reduxForm, Field, change } from "redux-form";
import validate from "./validate";
import { useTranslation } from "react-i18next";
import i18n from "../../../../lib/i18n/i18n";
import DatePicker from "react-datepicker";
import SivanModal from "../../../Modal/Modal";
import {
  CreateButton,
  Icon,
  ToggleContainer,
  ToggleText,
  Body,
  Section,
  Row,
  SubTitle,
  AddMoreButton,
  SectionContainer,
} from "./newAgreement.styles";
import { createAgreement } from "../../../../actions/agreementsActions";
import InputWrappedWithField from "../../../UiComponents/Inputs/DefaultWrappedWithField";
import NotesInput from "../../../UiComponents/TextArea/Default";
// import DatePicker from "../../../UiComponents/DatePicker/Default";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const renderToggle = ({ input, label }) => {
  const isHebrew = i18n.language === "he";
  return (
    <ToggleContainer isRtl={isHebrew}>
      <ToggleText>{label}</ToggleText>
      <Toggle checked={input.value} onChange={input.onChange} />
    </ToggleContainer>
  );
};

const onChange = (
  input,
  inputNewValue,
  fieldsArrayName,
  fieldName,
  index,
  fields,
  changeFunc
) => {
  let valid = true;
  if (index !== 0) {
    const newDate = new Date(inputNewValue);
    const previousFromDate = new Date(fields.get(index - 1).from);
    if (newDate > previousFromDate) {
      newDate.setDate(newDate.getDate() - 1);
      changeFunc(`${fieldsArrayName}[${index - 1}].${fieldName}`, newDate);
    } else {
      valid = false;
    }
  }

  if (valid) input.onChange(inputNewValue);
};

const renderAgreementDetailsFields = ({
  fields,
  meta: { error, submitFailed },
  change,
  hide,
}) => {
  return fields.map((agreementDetail, index) => (
    <SectionContainer key={index}>
      <Section>
        {!hide && (
          <Field
            name={`${agreementDetail}.amount`}
            component={InputWrappedWithField}
            placeholder={dictionary.newAgreement.amount}
            type="number"
            fullWidth={false}
          />
        )}
        <Field
          name={`${agreementDetail}.price`}
          component={InputWrappedWithField}
          placeholder={dictionary.newAgreement.price}
          type="number"
          fullWidth={false}
        />
        {!hide && (
          <Field
            name={`${agreementDetail}.priceAfter`}
            component={InputWrappedWithField}
            placeholder={dictionary.newAgreement.priceAfter}
            type="number"
            fullWidth={false}
          />
        )}
        <Field
          name={`${agreementDetail}.from`}
          component={renderDatePickerField}
          placeholder={dictionary.newAgreement.from}
          fieldsArrayName={fields.name}
          fieldName="until"
          index={index}
          fields={fields}
          change={change}
        />
        <Field
          name={`${agreementDetail}.until`}
          component={renderDatePickerField}
          placeholder={dictionary.newAgreement.until}
          fieldsArrayName={fields.name}
          fieldName="from"
          disabled
          change={change}
        />
        {!hide && (
          <Field
            name={`${agreementDetail}.duration`}
            component={InputWrappedWithField}
            placeholder={dictionary.newAgreement.duration}
            type="number"
            fullWidth={false}
          />
        )}
        {submitFailed && error && <span>{error}</span>}

        {index ? (
          <Icon className="fa fa-trash" onClick={() => fields.remove(index)} />
        ) : null}
      </Section>
      <AddMoreButton dir="auto" onClick={() => fields.push({ id: 0 })}>
        + {dictionary.teacherActionsModal.addMore}
      </AddMoreButton>
    </SectionContainer>
  ));
};

const renderAgreementExtraDetailsFields = ({
  fields,
  meta: { error, submitFailed },
  change,
}) => {
  return fields.map((agreementDetail, index) => (
    <SectionContainer key={index}>
      <Section noBorder basicData>
        <Row>
          <Field
            name={`${agreementDetail}.fromTestNumber`}
            component={InputWrappedWithField}
            placeholder={dictionary.newAgreement.fromTestNumber}
            type="number"
          />
          <Field
            name={`${agreementDetail}.extraPriceAfter`}
            component={InputWrappedWithField}
            placeholder={dictionary.newAgreement.extraPriceAfter}
            type="number"
          />
        </Row>
        <Row>
          <Field
            name={`${agreementDetail}.everyLessonIsFree`}
            component={InputWrappedWithField}
            placeholder={dictionary.newAgreement.everyLessonIsFree}
            type="number"
          />
          <Field
            name={`${agreementDetail}.extrasActiveFrom`}
            component={renderDatePickerField}
            placeholder={dictionary.newAgreement.extrasActiveFrom}
            index={0}
          />
        </Row>
        {submitFailed && error && <span>{error}</span>}

        {index ? (
          <Icon className="fa fa-trash" onClick={() => fields.remove(index)} />
        ) : null}
      </Section>
      <AddMoreButton dir="auto" onClick={() => fields.push({ id: 0 })}>
        + {dictionary.teacherActionsModal.addMore}
      </AddMoreButton>
    </SectionContainer>
  ));
};

const renderDatePickerField = ({
  input,
  placeholder,
  meta: { error, touched },
  fields,
  fieldsArrayName,
  fieldName,
  index = -1,
  disabled = false,
  change,
}) => {
  const isHebrew = i18n.language === "he";
  const dir = isHebrew ? "rtl" : "ltr";
  return (
    <DatePicker
      selected={input.value}
      dir={dir}
      onChange={(e) =>
        onChange(input, e, fieldsArrayName, fieldName, index, fields, change)
      }
      dateFormat="dd/MM/yyyy"
      placeholderText={placeholder}
      className={`${touched && error ? "error" : ""} date-input`}
      locale={i18n.language}
      showMonthDropdown
      showYearDropdown
      disabled={disabled}
    />
  );
};

const NewAgreement = ({
  isNewAgreementModalOpen,
  setNewAgreementModalIsOpen,
  handleSubmit,
  createAgreement,
  reset,
  submitting,
  change,
}) => {
  const { t, i18n } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);

  const createNewAgreement = async (formValues) => {
    setIsLoading(true);
    await createAgreement(formValues);
    setIsLoading(false);
    reset();
    setNewAgreementModalIsOpen(false);
  };

  return (
    <SivanModal
      titleText={t("newAgreement.title")}
      modalIsOpen={isNewAgreementModalOpen}
      closeModal={() => setNewAgreementModalIsOpen(false)}
      isLoading={isLoading}
    >
      <Body onSubmit={handleSubmit(createNewAgreement)}>
        <div style={{ width: "100%", maxHeight: "80vh", overflow: "auto" }}>
          <Section basicData noBorder>
            <Row>
              <Field
                name="description"
                component={NotesInput}
                placeholder={t("newAgreement.description")}
              />
            </Row>
            <Row>
              <Field
                name="active"
                component={renderToggle}
                label={t("newAgreement.active")}
              />
            </Row>
          </Section>
          <SubTitle border dir="auto">
            מחיר ההסכם
          </SubTitle>
          <FieldArray
            name="amountAgreementDetails"
            component={renderAgreementDetailsFields}
            change={change}
            hide
          />
          <SubTitle border dir="auto">
            {t("newAgreement.lessons")}
          </SubTitle>
          <FieldArray
            name="lessonsAgreementDetails"
            component={renderAgreementDetailsFields}
            change={change}
          />
          <SubTitle border dir="auto">
            {t("newAgreement.internalTests")}
          </SubTitle>
          <FieldArray
            name="internalTestsAgreementDetails"
            component={renderAgreementDetailsFields}
            change={change}
          />
          <SubTitle border dir="auto">
            {t("newAgreement.tests")}
          </SubTitle>
          <FieldArray
            name="testsAgreementDetails"
            component={renderAgreementDetailsFields}
            change={change}
          />
          <SubTitle border dir="auto">
            {t("newAgreement.extras")}
          </SubTitle>
          <FieldArray
            name="extra"
            component={renderAgreementExtraDetailsFields}
            change={change}
          />
        </div>
        <CreateButton type="submit" disabled={submitting}>
          Create
        </CreateButton>
      </Body>
    </SivanModal>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isPending: state.StudentsReducer.isPending,
    initialValues: {
      lessonsAgreementDetails: [{ from: new Date(2000, 0, 1, 12) }],
      internalTestsAgreementDetails: [{ from: new Date(2000, 0, 1, 12) }],
      testsAgreementDetails: [{ from: new Date(2000, 0, 1, 12) }],
      amountAgreementDetails: [{ from: new Date(2000, 0, 1, 12) }],
      extra: [{}],
      active: true,
      id: 0,
    },
  };
}

export default connect(mapStateToProps, { createAgreement, change })(
  reduxForm({
    form: "newAgreement",
    validate,
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })(NewAgreement)
);
