import React, { useState } from "react";
import { connect } from "react-redux";
import SivanModal from "../../../Modal/NewModal";
import Default from "../../../UiComponents/Inputs/Default";
import { Body } from "./CreditCardBill.styles";
import Button from "../../../UiComponents/Buttons/Default";
import "react-credit-cards/es/styles-compiled.css";
import CreditCardInput from "react-credit-card-input";
import axios from "axios";

const CreditCardBill = ({
  isNewAgreementModalOpen,
  setNewAgreementModalIsOpen,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [cvc, setCvc] = useState("");
  const [id, setId] = useState("");
  const [expiry, setExpiry] = useState("");
  const [amount, setAmount] = useState(0);
  const [cardNumber, setCardNumber] = useState("");

  const onClickBill = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `https://secure5.tranzila.com/cgi-bin/tranzila71u.cgi?supplier=carmelt2&tranmode=A&ccno=${cardNumber
          .match(/\d|\.|\-/g)
          .join("")}&expdate=${expiry
          .match(/\d|\.|\-/g)
          .join("")}&sum=${parseFloat(amount).toFixed(
          2
        )}&cred_type=1&myid=${id}&mycvv=${cvc}&currency=1`
      );
    } catch (error) {
      debugger;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <SivanModal
      titleText="חיוב תלמיד"
      modalIsOpen={isNewAgreementModalOpen}
      closeModal={() => setNewAgreementModalIsOpen(false)}
      isLoading={isLoading}
    >
      <Body>
        <CreditCardInput
          customTextLabels={{
            invalidCardNumber: "כרטיס אשראי לא תקין",
            expiryError: {
              invalidExpiryDate: "תאריך לא תקין",
              monthOutOfRange: "חודש לא תקין",
              yearOutOfRange: "שנה לא תקינה",
              dateOutOfRange: "תאריך לא תקין",
            },
            invalidCvc: "3 ספרות לא תקינות",
            cardNumberPlaceholder: "מספר אשראי",
          }}
          cardNumberInputProps={{
            value: cardNumber,
            onChange: (e) => {
              setCardNumber(e.target.value);
            },
          }}
          cardExpiryInputProps={{
            value: expiry,
            onChange: (e) => {
              debugger;
              setExpiry(e.target.value);
            },
          }}
          cardCVCInputProps={{
            value: cvc,
            onChange: (e) => {
              debugger;
              setCvc(e.target.value);
            },
          }}
          fieldClassName="input"
        />
        <Default
          placeholder="תעודת זהות"
          onChange={(e) => setId(e.target.value)}
          value={id}
        />
        <Default
          placeholder="סכום"
          onChange={(e) => setAmount(e.target.value)}
          value={amount}
        />
        <Button text="חייב" onClick={onClickBill} />
      </Body>
    </SivanModal>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isAllowedInvoice: state.session.user.isAllowedInvoice,
    isAllowedReceipt: state.session.user.isAllowedReceipt,
    isAllowedInvoiceReceipt: state.session.user.isAllowedInvoiceReceipt,
    isAllowedCreditReceipt: state.session.user.isAllowedCreditReceipt,
    isAllowedCreditInvoiceReceipt:
      state.session.user.isAllowedCreditInvoiceReceipt,
    isAllowedCreditInvoice: state.session.user.isAllowedCreditInvoice,
    isAllowedDiscount: state.session.user.isAllowedDiscount,
    isAllowedBilling: state.session.user.isAllowedBilling,
    vehicles: state.VehiclesReducer.vehicles,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    isUsersLoaded: state.UsersReducer.isLoaded,
    user: state.session.user,
    users: state.UsersReducer.users,
    students: state.StudentsReducer.students,
    isStudentsLoaded: state.StudentsReducer.isLoaded,
  };
}

export default connect(mapStateToProps, {})(CreditCardBill);
