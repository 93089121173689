import testersApi from "../apis/testers";
import {
  TESTERS_FETCH_FAILED,
  TESTERS_FETCH_SUCCESS,
  TESTERS_FETCH_STARTED,
  TESTER_CREATE_SUCCESS,
  TESTER_DELETE_SUCCESS,
  TESTER_UPDATE_SUCCESS,
} from "../constants/actionTypes";
import { toast } from "react-toastify";

export const create = (tester) => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const { branchId, schoolId } = getState().session.user;

    const response = await testersApi.post(
      "/create",
      {
        tester: { ...tester, schoolId },
      },
      {
        headers,
      }
    );
    dispatch({
      type: TESTER_CREATE_SUCCESS,
      payload: { ...tester, id: response.data.id, isActive: 1 },
    });
  } catch (error) {
    toast.error("כישלון ביצירת טסטר");
  }
};

export const getSchoolTesters = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: TESTERS_FETCH_STARTED,
    });
    const token = window.localStorage.getItem("token");
    const { branchId, schoolId } = getState().session.user;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await testersApi.get(`/getAll/${schoolId}`, {
      headers,
    });

    dispatch({
      type: TESTERS_FETCH_SUCCESS,
      payload: response.data,
    });

    return response.data;
  } catch (error) {
    dispatch({
      type: TESTERS_FETCH_FAILED,
    });
  }
};

export const update = (tester) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await testersApi.patch(
      "/update",
      {
        tester,
      },
      {
        headers,
      }
    );

    dispatch({
      type: TESTER_UPDATE_SUCCESS,
      payload: { ...tester },
    });
  } catch (error) {
    toast.error("כישלון בעדכון טסטר");
  }
};

export const deleteItem = (testerId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await testersApi.delete(
      "/delete",
      {
        data: { testerId },
      },
      {
        headers,
      }
    );

    dispatch({
      type: TESTER_DELETE_SUCCESS,
      payload: { id: testerId },
    });
  } catch (error) {
    toast.error("כישלון במחיקת טסטר");
  }
};
