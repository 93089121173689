import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import enLocale from "date-fns/locale/he";

const DefaultDateTimePicker = ({
  input,
  placeholder,
  meta: { touched, error },
  disabled,
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
      <DateTimePicker
        autoOk={true}
        margin="dense"
        inputVariant="outlined"
        ampm={false}
        disabled={disabled}
        value={input.value}
        onChange={(x) => input.onChange(x)}
        label={placeholder}
        fullWidth
        showTodayButton
        error={touched && error}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DefaultDateTimePicker;
