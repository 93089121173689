import styled from "styled-components";
import LoadingOverlay from "react-loading-overlay";
import i18n from "../../../../lib/i18n/i18n";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const Body = styled.form`
  height: 75vh;
  width: 100%;
  background-color: white;
  border: 1px solid #ffefca;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 3vh;
  align-items: center;
  direction: rtl;
  margin-top: 3vh;
`;

export const ColumnTitle = styled.span`
  font-size: 2.65vh;
  color: #404041;
  height: 7vh;
  display: flex;
  align-items: center;
`;

export const PermanentDetailsContainer = styled.div`
  padding-left: 2vh;
  flex: 1;
`;
export const AdditionalDetailsContainer = styled.div`
  padding-left: 2vh;
  flex: 1;
`;

export const Button = styled.button`
  border-radius: 5px;
  height: 6vh;
  background: ${(props) =>
    props.reset ? "linear-gradient(to bottom, #ebd794, #d2ab51)" : "white"};
  border: ${(props) => (props.reset ? "" : "1px solid #e1c89d")};
  color: ${(props) => (props.reset ? "white" : "#e1c89d")};
  font-size: 15pt;
  margin-top: 5vh;
  font-weight: bolder;
  width: 45%;

  &:hover {
    background: ${(props) =>
      !props.reset ? "linear-gradient(to bottom, #ebd794, #d2ab51)" : "white"};
    border: ${(props) => (!props.reset ? "" : "1px solid #e1c89d")};
    color: ${(props) => (!props.reset ? "white" : "#e1c89d")};
  }
`;

export const NavAndBackContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 35vw;
  justify-content: space-between;
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isRtl ? "row-reverse" : "row")};
  width: 100%;
`;

export const CounterButton = styled.span`
  background-color: #fcf2de;
  color: #cb9d3c;
  border-radius: 50%;
  width: 1.5vw;
  height: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.55vh;
`;

export const StyledLoader = styled(LoadingOverlay)`
  ._loading_overlay_overlay {
    position: fixed !important;
  }
`;
