import React, { useState, useEffect } from "react";
import i18n from "../../../lib/i18n/i18n";
import { connect } from "react-redux";
import { Container, Title } from "./expenses.styles";
import NewExpense from "./NewExpense/NewExpense";
import ExpenseData from "./ExpenseData/ExpenseData";
import { getSchoolExpenses } from "../../../actions/expensesActions";
import Table from "../../SivanPlusTable/SivanPlusTable";
import CreateButton from "../../UiComponents/Buttons/Default";
import { getSchoolExpenseTypes } from "../../../actions/expenseTypesAction";
import { getSchoolProviders } from "../../../actions/providersActions";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;
const documentTypes = [
  { value: 0, label: "חשבונית/קבלה" },
  { value: 1, label: "קבלה" },
  { value: 2, label: "חשבונית" },
];

const FundingBodies = ({
  expenses,
  isLoaded,
  getSchoolExpenses,
  getSchoolExpenseTypes,
  expenseTypes,
  isExpenseTypesLoaded,
  getSchoolProviders,
  providers,
  isProvidersLoaded,
}) => {
  const [isProviderModalOpen, setProviderModalOpen] = useState(false);
  const [providerData, setProviderData] = useState(false);
  const [isProviderDetailsModalOpen, setProviderDetailsModalOpen] = useState(
    false
  );

  useEffect(() => {
    async function fetchData() {
      if (!isLoaded) await getSchoolExpenses();
      if (!isExpenseTypesLoaded) await getSchoolExpenseTypes();
      if (!isProvidersLoaded) await getSchoolProviders();
    }
    fetchData();
  }, []);

  const vehicleColumns = [
    {
      Header: dictionary.table.date,
      accessor: "date",
      Cell: ({ row: { original } }) => {
        return new Date(original.date).toLocaleString();
      },
    },
    {
      Header: dictionary.table.supplier,
      accessor: "providerId",
      Cell: ({ row: { original } }) => {
        return providers.length > 0
          ? providers.find(
              (x) => x.id.toString() === original.providerId.toString()
            ).name
          : "";
      },
    },
    {
      Header: dictionary.table.quantity,
      accessor: "quantity",
    },
    {
      Header: dictionary.table.expenseTypes,
      accessor: "expenseType",
      Cell: ({ row: { original } }) => {
        return expenseTypes.length > 0
          ? expenseTypes.find((x) => x.id === original.expenseType).name
          : "";
      },
    },
    {
      Header: dictionary.table.essence,
      accessor: "essence",
      Cell: ({ row: { original } }) => {
        return original.essence ? "ציוד ונכסים קבועים" : "רגיל";
      },
    },
    {
      Header: dictionary.table.vehicleNumber,
      accessor: "vehicleNumber",
      Cell: ({ row: { original } }) => {
        return original.vehicleId === 999
          ? "כל הרכבים"
          : original.vehicleNumber;
      },
    },
    {
      Header: dictionary.table.amount,
      accessor: "amount",
    },
    {
      Header: dictionary.table.documentType,
      accessor: "documentType",
      Cell: ({ row: { original } }) => {
        return documentTypes.find((x) => x.value === original.documentType)
          .label;
      },
    },
  ];

  const onRowClick = async (rowObject) => {
    setProviderDetailsModalOpen(true);
    setProviderData(rowObject.original);
  };

  return (
    <Container>
      {isProviderModalOpen && (
        <NewExpense
          isModalOpen={isProviderModalOpen}
          setIsModalOpen={setProviderModalOpen}
        />
      )}
      <ExpenseData
        isModalOpen={isProviderDetailsModalOpen}
        closeModal={() => setProviderDetailsModalOpen(false)}
        providerData={providerData}
      />
      <Title>
        הוצאות
        <CreateButton
          onClick={() => setProviderModalOpen(true)}
          text="צור חדש"
        />
      </Title>
      <Table
        data={expenses}
        componentColumns={vehicleColumns}
        onRowClick={onRowClick}
      />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    expenses: state.ExpensesReducer.expenses,
    isLoaded: state.ExpensesReducer.isLoaded,
    expenseTypes: state.ExpenseTypesReducer.expenseTypes,
    isExpenseTypesLoaded: state.ExpenseTypesReducer.isLoaded,
    providers: state.ProvidersReducer.providers,
    isProvidersLoaded: state.ProvidersReducer.isLoaded,
    createVehicle: state.session.user.createVehicle,
    editVehicle: state.session.user.editVehicle,
  };
}

export default connect(mapStateToProps, {
  getSchoolExpenses,
  getSchoolExpenseTypes,
  getSchoolProviders,
})(FundingBodies);
