import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Navbar from "../../Navbar/Navbar";
import i18n from "../../../lib/i18n/i18n";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import {
  Container,
  NavAndBackContainer,
  ToggleContainer,
  ToggleText,
  StyledLoader,
} from "./createStudent.styles";
import GeneralData from "./GeneralData";
import AdditionalData from "./AdditionalData";
import Ranks from "./Ranks/Ranks";
import TextBooks from "./TextBooks/TextBooks";
import StudentTicket from "./StudentTicket/StudentTicket";
import InputWrappedWithField from "../../UiComponents/Inputs/DefaultWrappedWithField";
import NotesInput from "../../UiComponents/TextArea/Default";
import DefaultSelect from "../../UiComponents/Select/Default";
import DefaultDatePicker from "../../UiComponents/DatePicker/Default";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const renderToggle = ({ input, label }) => {
  const isHebrew = i18n.language === "he";
  return (
    <ToggleContainer isRtl={isHebrew}>
      <ToggleText>{label}</ToggleText>
      <Toggle checked={input.value} onChange={input.onChange} />
    </ToggleContainer>
  );
};

const StudentUpdate = ({ isPending, history, location, user, view }) => {
  const [menuItems, setMenuItems] = useState([
    dictionary.editStudent.basic,
    dictionary.editStudent.ranks,
    dictionary.editStudent.textBooks,
    dictionary.editStudent.others,
  ]);
  const [currentOpenedView, setCurrentOpenedView] = useState(
    user.shortStudentTicket
      ? dictionary.editStudent.ticket
      : dictionary.editStudent.basic
  );

  useEffect(() => {
    if (user.studentTicket) {
      setMenuItems([...menuItems, dictionary.editStudent.ticket]);
    } else if (user.shortStudentTicket) {
      setMenuItems([dictionary.editStudent.ticket]);
    }

    if (location.state.view) {
      setCurrentOpenedView(location.state.view);
    }
  }, []);

  return (
    <Container>
      <div style={{ fontSize: "1vmax" }}>
        {location.state.studentData.firstName}&nbsp;
        {location.state.studentData.lastName}&nbsp;סניף:&nbsp;
        {location.state.studentData.branchName}
      </div>
      <NavAndBackContainer>
        <div />
        <Navbar
          menuOptions={menuItems}
          currentView={currentOpenedView}
          setCurrentOpenedView={setCurrentOpenedView}
        />
        <i
          className="fa fa-arrow-right"
          aria-hidden="true"
          style={{ cursor: "pointer" }}
          onClick={() => history.goBack()}
        />
      </NavAndBackContainer>
      {currentOpenedView === dictionary.editStudent.basic && (
        <GeneralData
          renderSelect={DefaultSelect}
          renderInput={InputWrappedWithField}
          renderDatePickerField={DefaultDatePicker}
          renderTextArea={NotesInput}
          studentData={location.state.studentData}
        />
      )}
      {currentOpenedView === dictionary.editStudent.others && (
        <AdditionalData
          renderSelect={DefaultSelect}
          renderInput={InputWrappedWithField}
          renderDatePickerField={DefaultDatePicker}
          studentData={location.state.studentData}
          renderToggle={renderToggle}
        />
      )}
      {currentOpenedView === dictionary.editStudent.ranks && (
        <Ranks studentId={location.state.studentData.studentId} />
      )}
      {currentOpenedView === dictionary.editStudent.textBooks && (
        <TextBooks
          studentId={location.state.studentData.studentId}
          studentData={location.state.studentData}
        />
      )}
      {currentOpenedView === dictionary.editStudent.ticket && (
        <StudentTicket
          studentId={location.state.studentData.studentId}
          studentname={`${location.state.studentData.firstName} ${location.state.studentData.lastName}`}
          phone={location.state.studentData.phone}
          glasses={location.state.studentData.glasses}
          isChecked={location.state.studentData.isChecked}
          id={location.state.studentData.id}
        />
      )}
      <StyledLoader active={isPending} spinner text="Creating Student..." />
    </Container>
  );
};

StudentUpdate.defaultProps = {
  location: { state: { studentData: { birthday: new Date() } } },
};

function mapStateToProps(state) {
  return { user: state.session.user };
}

export default withRouter(connect(mapStateToProps, {})(StudentUpdate));
