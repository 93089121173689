import styled from "styled-components";
import LoadingOverlay from "react-loading-overlay";
import i18n from "../../../../lib/i18n/i18n";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .error {
    border: 1px solid red !important;
  }

  .react-datepicker-wrapper:after {
    font-family: "FontAwesome";
    position: relative;
    left: ${isHebrew ? "-90%" : "-5%"};
    content: "\f133";
  }

  .react-datepicker-wrapper {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    width: 100%;
    height: 6vh;
    margin-top: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__input-container input {
    width: 100%;
    ${isHebrew ? "padding-right" : "padding-left"}: 1vw;
    direction: ${isHebrew ? "rtl" : "ltr"};

    &::placeholder {
      color: #c2c2c2;
    }
  }

  .react-toggle-track {
    background-color: #f5f5f5 !important;
    border: 1px solid #e5e5e5;
    padding: 0.2vh;
  }

  .react-toggle--checked .react-toggle-thumb {
    background-color: #edca92;
  }

  .react-toggle-thumb {
    background-color: #e5e3e0;
    border: none;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 85%;
  align-items: center;
`;

export const customStyles = {
  container: (provided, state) => ({
    ...provided,
    marginTop: "1vh",
    width: "30%",
    marginLeft: "2vw",
  }),
  control: (provided, state) => ({
    ...provided,
    height: "6vh",
    border: "1px solid #e5e5e5",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#c2c2c2",
    [isHebrew ? "paddingRight" : "paddingLeft"]: "0.5vw",
  }),
};

export const StyledLoader = styled(LoadingOverlay)`
  ._loading_overlay_overlay {
    position: fixed !important;
  }
`;

export const Icon = styled.i`
  cursor: pointer;
  font-size: 2vh;
  margin-right: 1vw;
  color: #cb9d3c;
`;

export const TextBookRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 35%;
  align-items: center;
  justify-content: space-between;
  margin-top: 1vh;
`;
