import React from "react";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { updateSettings } from "../../../actions/settingsActions";
import CalendarEventColor from "./CalendarEventColor";
import {
  Container,
  Body,
  Button,
  ButtonContainer,
} from "./calendarColors.styles";

const CalendarColors = ({
  handleSubmit,
  updateSettings,
  lessonColor,
  fontLessonColor,
  doneLessonColor,
  fontDoneLessonColor,
  paidLessonColor,
  fontPaidLessonColor,
  canceledLessonColor,
  fontCanceledLessonColor,
  canceledAndPaidLessonColor,
  fontCanceledAndPaidLessonColor,
  testColor,
  fontTestColor,
  doneTestColor,
  fontDoneTestColor,
  paidTestColor,
  fontPaidTestColor,
  canceledTestColor,
  fontCanceledTestColor,
  canceledAndPaidTestColor,
  fontCanceledAndPaidTestColor,
  internalTestColor,
  fontInternalTestColor,
  doneInternalTestColor,
  fontDoneInternalTestColor,
  paidInternalTestColor,
  fontPaidInternalTestColor,
  canceledInternalTestColor,
  fontCanceledInternalTestColor,
  canceledAndPaidInternalTestColor,
  fontCanceledAndPaidInternalTestColor,
  meetingColor,
  fontMeetingColor,
  doneMeetingColor,
  fontDoneMeetingColor,
  canceledMeetingColor,
  fontCanceledMeetingColor,
  history,
  chosenStudentColor,
  fontChosenStudentColor,
}) => {
  const { t, i18n } = useTranslation("common");
  const abubu = {
    lesson: [
      {
        text: t("settings.lessonColor"),
        backgroundFieldName: "lessonColor",
        background: lessonColor,
        fontFieldName: "fontLessonColor",
        font: fontLessonColor,
        icon: "icons8-question-mark",
      },
      {
        text: t("settings.doneLessonColor"),
        backgroundFieldName: "doneLessonColor",
        background: doneLessonColor,
        fontFieldName: "fontDoneLessonColor",
        font: fontDoneLessonColor,
        icon: "icons8-checkmark",
      },
      {
        text: t("settings.paidLessonColor"),
        backgroundFieldName: "paidLessonColor",
        background: paidLessonColor,
        fontFieldName: "fontPaidLessonColor",
        font: fontPaidLessonColor,
        icon: "icons8-shekel",
      },
      {
        text: t("settings.canceledLessonColor"),
        backgroundFieldName: "canceledLessonColor",
        background: canceledLessonColor,
        fontFieldName: "fontCanceledLessonColor",
        font: fontCanceledLessonColor,
        icon: "icons8-cancel",
      },
      {
        text: t("settings.canceledAndPaidLessonColor"),
        backgroundFieldName: "canceledAndPaidLessonColor",
        background: canceledAndPaidLessonColor,
        fontFieldName: "fontCanceledAndPaidLessonColor",
        font: fontCanceledAndPaidLessonColor,
        icon: "icons8-cancel-with-usd",
      },
    ],
    test: [
      {
        text: t("settings.lessonColor"),
        backgroundFieldName: "testColor",
        background: testColor,
        fontFieldName: "fontTestColor",
        font: fontTestColor,
        icon: "icons8-question-mark",
      },
      {
        text: t("settings.doneLessonColor"),
        backgroundFieldName: "doneTestColor",
        background: doneTestColor,
        fontFieldName: "fontDoneTestColor",
        font: fontDoneTestColor,
        icon: "icons8-checkmark",
      },
      {
        text: t("settings.paidLessonColor"),
        backgroundFieldName: "paidTestColor",
        background: paidTestColor,
        fontFieldName: "fontPaidTestColor",
        font: fontPaidTestColor,
        icon: "icons8-shekel",
      },
      {
        text: t("settings.canceledLessonColor"),
        backgroundFieldName: "canceledTestColor",
        background: canceledTestColor,
        fontFieldName: "fontCanceledTestColor",
        font: fontCanceledTestColor,
        icon: "icons8-cancel",
      },
      {
        text: t("settings.canceledAndPaidLessonColor"),
        backgroundFieldName: "canceledAndPaidTestColor",
        background: canceledAndPaidTestColor,
        fontFieldName: "fontCanceledAndPaidTestColor",
        font: fontCanceledAndPaidTestColor,
        icon: "icons8-cancel-with-usd",
      },
    ],
    internalTest: [
      {
        text: t("settings.lessonColor"),
        backgroundFieldName: "internalTestColor",
        background: internalTestColor,
        fontFieldName: "fontInternalTestColor",
        font: fontInternalTestColor,
        icon: "icons8-question-mark",
      },
      {
        text: t("settings.doneLessonColor"),
        backgroundFieldName: "doneInternalTestColor",
        background: doneInternalTestColor,
        fontFieldName: "fontDoneInternalTestColor",
        font: fontDoneInternalTestColor,
        icon: "icons8-checkmark",
      },
      {
        text: t("settings.paidLessonColor"),
        backgroundFieldName: "paidInternalTestColor",
        background: paidInternalTestColor,
        fontFieldName: "fontPaidInternalTestColor",
        font: fontPaidInternalTestColor,
        icon: "icons8-shekel",
      },
      {
        text: t("settings.canceledLessonColor"),
        backgroundFieldName: "canceledInternalTestColor",
        background: canceledInternalTestColor,
        fontFieldName: "fontCanceledInternalTestColor",
        font: fontCanceledInternalTestColor,
        icon: "icons8-cancel",
      },
      {
        text: t("settings.canceledAndPaidLessonColor"),
        backgroundFieldName: "canceledAndPaidInternalTestColor",
        background: canceledAndPaidInternalTestColor,
        fontFieldName: "fontCanceledAndPaidInternalTestColor",
        font: fontCanceledAndPaidInternalTestColor,
        icon: "icons8-cancel-with-usd",
      },
    ],
    meeting: [
      {
        text: t("settings.lessonColor"),
        backgroundFieldName: "meetingColor",
        background: meetingColor,
        fontFieldName: "fontMeetingColor",
        font: fontMeetingColor,
        icon: "icons8-question-mark",
      },
      {
        text: t("settings.doneLessonColor"),
        backgroundFieldName: "doneMeetingColor",
        background: doneMeetingColor,
        fontFieldName: "fontDoneMeetingColor",
        font: fontDoneMeetingColor,
        icon: "icons8-checkmark",
      },

      {
        text: t("settings.canceledLessonColor"),
        backgroundFieldName: "canceledMeetingColor",
        background: canceledMeetingColor,
        fontFieldName: "fontCanceledMeetingColor",
        font: fontCanceledMeetingColor,
        icon: "icons8-cancel",
      },
    ],
    student: [
      {
        text: "תלמיד",
        backgroundFieldName: "chosenStudentColor",
        background: chosenStudentColor,
        fontFieldName: "fontChosenStudentColor",
        font: fontChosenStudentColor,
        icon: "icons8-question-mark",
      },
    ],
  };

  const updateData = (formValues) => {
    updateSettings(formValues);
    history.push("/calendar");
  };

  return (
    <Container onSubmit={handleSubmit(updateData)}>
      <Body>
        <CalendarEventColor header="שיעור" elements={abubu.lesson} />
        <CalendarEventColor header="טסט" elements={abubu.test} />
        <CalendarEventColor header="מבחן פנימי" elements={abubu.internalTest} />
        <CalendarEventColor header="פגישה" elements={abubu.meeting} />
        <CalendarEventColor header="תלמיד" elements={abubu.student} />
        <Button type="submit">{t("buttons.save")}</Button>
      </Body>
    </Container>
  );
};

const selector = formValueSelector("calendarColors"); // <-- same as form name

function mapStateToProps(state, ownProps) {
  return {
    lessonColor: selector(state, "lessonColor"),
    fontLessonColor: selector(state, "fontLessonColor"),
    doneLessonColor: selector(state, "doneLessonColor"),
    fontDoneLessonColor: selector(state, "fontDoneLessonColor"),
    paidLessonColor: selector(state, "paidLessonColor"),
    fontPaidLessonColor: selector(state, "fontPaidLessonColor"),
    canceledLessonColor: selector(state, "canceledLessonColor"),
    fontCanceledLessonColor: selector(state, "fontCanceledLessonColor"),
    canceledAndPaidLessonColor: selector(state, "canceledAndPaidLessonColor"),
    fontCanceledAndPaidLessonColor: selector(
      state,
      "fontCanceledAndPaidLessonColor"
    ),
    testColor: selector(state, "testColor"),
    fontTestColor: selector(state, "fontTestColor"),
    doneTestColor: selector(state, "doneTestColor"),
    fontDoneTestColor: selector(state, "fontDoneTestColor"),
    paidTestColor: selector(state, "paidTestColor"),
    fontPaidTestColor: selector(state, "fontPaidTestColor"),
    canceledTestColor: selector(state, "canceledTestColor"),
    fontCanceledTestColor: selector(state, "fontCanceledTestColor"),
    canceledAndPaidTestColor: selector(state, "canceledAndPaidTestColor"),
    fontCanceledAndPaidTestColor: selector(
      state,
      "fontCanceledAndPaidTestColor"
    ),
    internalTestColor: selector(state, "internalTestColor"),
    fontInternalTestColor: selector(state, "fontInternalTestColor"),
    doneInternalTestColor: selector(state, "doneInternalTestColor"),
    fontDoneInternalTestColor: selector(state, "fontDoneInternalTestColor"),
    paidInternalTestColor: selector(state, "paidInternalTestColor"),
    fontPaidInternalTestColor: selector(state, "fontPaidInternalTestColor"),
    canceledInternalTestColor: selector(state, "canceledInternalTestColor"),
    fontCanceledInternalTestColor: selector(
      state,
      "fontCanceledInternalTestColor"
    ),
    canceledAndPaidInternalTestColor: selector(
      state,
      "canceledAndPaidInternalTestColor"
    ),
    fontCanceledAndPaidInternalTestColor: selector(
      state,
      "fontCanceledAndPaidInternalTestColor"
    ),
    meetingColor: selector(state, "meetingColor"),
    fontMeetingColor: selector(state, "fontMeetingColor"),
    doneMeetingColor: selector(state, "doneMeetingColor"),
    fontDoneMeetingColor: selector(state, "fontDoneMeetingColor"),
    canceledMeetingColor: selector(state, "canceledMeetingColor"),
    fontCanceledMeetingColor: selector(state, "fontCanceledMeetingColor"),
    chosenStudentColor: selector(state, "chosenStudentColor"),
    fontChosenStudentColor: selector(state, "fontChosenStudentColor"),
    initialValues: {
      lessonColor: state.session.user.lessonColor,
      fontLessonColor: state.session.user.fontLessonColor,
      doneLessonColor: state.session.user.doneLessonColor,
      fontDoneLessonColor: state.session.user.fontDoneLessonColor,
      paidLessonColor: state.session.user.paidLessonColor,
      fontPaidLessonColor: state.session.user.fontPaidLessonColor,
      canceledLessonColor: state.session.user.canceledLessonColor,
      fontCanceledLessonColor: state.session.user.fontCanceledLessonColor,
      canceledAndPaidLessonColor: state.session.user.canceledAndPaidLessonColor,
      fontCanceledAndPaidLessonColor:
        state.session.user.fontCanceledAndPaidLessonColor,
      testColor: state.session.user.testColor,
      fontTestColor: state.session.user.fontTestColor,
      doneTestColor: state.session.user.doneTestColor,
      fontDoneTestColor: state.session.user.fontDoneTestColor,
      paidTestColor: state.session.user.paidTestColor,
      fontPaidTestColor: state.session.user.fontPaidTestColor,
      canceledTestColor: state.session.user.canceledTestColor,
      fontCanceledTestColor: state.session.user.fontCanceledTestColor,
      canceledAndPaidTestColor: state.session.user.canceledAndPaidTestColor,
      fontCanceledAndPaidTestColor:
        state.session.user.fontCanceledAndPaidTestColor,
      internalTestColor: state.session.user.internalTestColor,
      fontInternalTestColor: state.session.user.fontInternalTestColor,
      doneInternalTestColor: state.session.user.doneInternalTestColor,
      fontDoneInternalTestColor: state.session.user.fontDoneInternalTestColor,
      paidInternalTestColor: state.session.user.paidInternalTestColor,
      fontPaidInternalTestColor: state.session.user.fontPaidInternalTestColor,
      chosenStudentColor: state.session.user.chosenStudentColor,
      fontChosenStudentColor: state.session.user.fontChosenStudentColor,
      fontCanceledInternalTestColor:
        state.session.user.fontCanceledInternalTestColor,
      canceledAndPaidInternalTestColor:
        state.session.user.canceledAndPaidInternalTestColor,
      fontCanceledAndPaidInternalTestColor:
        state.session.user.fontCanceledAndPaidInternalTestColor,
      meetingColor: state.session.user.meetingColor,
      fontMeetingColor: state.session.user.fontMeetingColor,
      doneMeetingColor: state.session.user.doneMeetingColor,
      fontDoneMeetingColor: state.session.user.fontDoneMeetingColor,
      canceledMeetingColor: state.session.user.canceledMeetingColor,
      fontCanceledMeetingColor: state.session.user.fontCanceledMeetingColor,
      id: state.session.user.settingsId,
    },
  };
}

export default withRouter(
  connect(mapStateToProps, { updateSettings })(
    reduxForm({
      form: "calendarColors",
    })(CalendarColors)
  )
);
