import React, { useState } from "react";
import {
  Container,
  Icon,
  TitleContainer,
  TextAfterIcon,
  IconsAndTextContainer,
} from "./eventView.styles";
import _ from "lodash";
import { connect } from "react-redux";
import EditEvent from "../EditEvent/EditEvent";
import ReactTooltip from "react-tooltip";
import { isMobile } from "react-device-detect";
import useTeacheres from "../../useComponents/useTeachers";
import { getUsers } from "../../../actions/userActions";
import moment from "moment";
import { getStudentIncomes } from "../../../actions/incomesAction";
import { getStudentDiscountsAndObligations } from "../../../actions/discountsAndObligationsActions";
import { getAgreementDetails } from "../../../actions/agreementsActions";
import {
  getStudentEvents,
  getStudentEventCount,
} from "../../../actions/schedulerActions";
import {
  getStudentTextBooks,
  fetchTextBook,
} from "../../../actions/textBooksActions";

const eventStatusIcons = {
  0: "icons8-question-mark",
  1: "icons8-checkmark",
  2: "icons8-shekel",
  3: "icons8-cancel",
  4: "icons8-cancel-with-usd",
};

const drivingPermitsOptions = [
  { value: 1, label: "הכשרה" },
  { value: 2, label: "רענון נהיגה" },
  { value: 3, label: "מטרונית" },
  { value: 4, label: "מפרקית" },
  { value: 5, label: "אוטובוס חשמלי" },
  { value: 6, label: "אוטובוס" },
  { value: 7, label: "אוטובוס בינערוני" },
  { value: 8, label: "מיניבוס" },
  { value: 9, label: "הסבה לקו" },
];

const EventAgendaView = (event) => {
  const [balance, setBalance] = useState(0);
  const [displayBalance, setDisplayBalance] = useState(false);
  const [isEditLessonModalOpen, setIsEditLessonModalOpen] = useState(false);
  const [studentEventCount, setStudentEventCount] = useState(0);
  const [isShowCount, setIsShowCount] = useState(false);
  const teachersOptions = useTeacheres(
    event.isUsersLoaded,
    event.getUsers,
    event.users
  );

  const getEventTypeIcon = (eventType) => {
    switch (eventType) {
      case 1:
        return <Icon className="icons8-steering-wheel" />;
      case 2:
        return <Icon className="icons8-graduation-cap" />;
      case 3:
        return <Icon className="icons8-road" />;
      case 4:
        return <Icon className="icons8-tie" />;
      default:
        return "";
    }
  };

  const getStudentEventCount = async () => {
    if (!isShowCount) {
      setStudentEventCount(
        await event.getStudentEventCount(
          event.event.eventType,
          event.event.studentId,
          event.event.textBookId,
          event.event.start
        )
      );
      setIsShowCount(true);
    } else {
      setIsShowCount(false);
    }
  };

  const findActiveAgreement = (agreements, startDateFieldName, start) => {
    const agreementsArray = agreements.sort(
      (a, b) =>
        new Date(b[startDateFieldName]) - new Date(a[startDateFieldName])
    );
    let index = 0;
    let tata = true;
    for (index; index < agreementsArray.length; index++) {
      const element = agreementsArray[index];
      if (!moment(element[startDateFieldName]).isSameOrAfter(start)) {
        tata = false;
        break;
      }
    }
    if (tata) {
      index = index - 1;
    }
    return agreementsArray[index];
  };

  const getTextBookAgreementOpeningBalance = async (agreements) => {
    let openingBalance = 0;
    for (let index = 0; index < agreements.length; index++) {
      const element = agreements[index];
      if (moment(new Date()).isAfter(element.startDate)) {
        const agreementsDetails = await event.getAgreementDetails(
          element.agreementId
        );
        const activeAgreementAmount = findActiveAgreement(
          agreementsDetails["amountAgreementDetails"],
          "from",
          new Date(element.startDate)
        );
        openingBalance += activeAgreementAmount?.price || 0;
      }
    }
    return openingBalance;
  };

  const getNameStyle = (studentData) => {
    let styles = {};
    if (studentData.internalTestStatus) {
      styles.borderTop = "1px solid black";
    }
    if (
      studentData.conversion ||
      studentData.refresh ||
      studentData.renewal ||
      studentData.control
    ) {
      styles.borderLeft = "1px solid black";
      styles.borderRight = "1px solid black";
    }
    if (
      studentData.fundingBody !== 999 &&
      studentData.fundingBody !== null &&
      typeof studentData.fundingBody !== "undefined"
    ) {
      styles.borderBottom = "1px solid black";
    }

    return styles;
  };

  const setStudentBalance = async () => {
    if (displayBalance) {
      setDisplayBalance(false);
    } else {
      // const userEvents = await event.getStudentEvents(event.event.studentId);
      // const fetchedStundetTextBooks = await event.getStudentTextBooks(
      //   event.event.studentId
      // );
      // const textBooksData = await event.fetchTextBook(
      //   fetchedStundetTextBooks[0].textBookId
      // );

      // const agreementsDetails = await event.getAgreementDetails(
      //   textBooksData.agreements[0].agreementId
      // );

      // let studentBalance = 0;
      // studentBalance -= agreementsDetails.price;

      // userEvents.map((x, i) => {
      //   const today = new Date();
      //   if (moment(x.start).isSameOrBefore(today)) {
      //     studentBalance -= x.price;
      //   }
      //   setBalance(studentBalance);
      // });
      const fetchedStundetTextBooks = await event.getStudentTextBooks(
        event.event.studentId
      );
      const id = fetchedStundetTextBooks[0].textBookId;
      const [userEvents, textBooksData, incomes, discountsAndObligations] =
        await Promise.all([
          event.getStudentEvents(event.event.studentId),
          event.fetchTextBook(id),
          event.getStudentIncomes(event.event.studentId),
          event.getStudentDiscountsAndObligations(event.event.studentId),
        ]);

      let studentBalance = parseFloat(textBooksData.openingBalance) || 0;
      let studentBalanceWithFutureBalance = 0;
      studentBalance -= await getTextBookAgreementOpeningBalance(
        textBooksData.agreements
      );

      let eventsFiltered = userEvents.filter((x) => x.textBookId === id);
      const eventsThatNotHaveTextBook = userEvents.filter(
        (x) => x.textBookId === null
      );

      let studentData = [
        ...eventsFiltered,
        ...eventsThatNotHaveTextBook,
        ...incomes.filter(
          (y) =>
            y.textbookId === "[object Object]" ||
            y.textbookId === id.toString() ||
            fetchedStundetTextBooks.findIndex(
              (x) => x.textbookId?.toString() === y.textbookId
            ) === -1 ||
            y.textbookId === null
        ),
        ...discountsAndObligations,
      ];

      _.sortBy(studentData, [
        function (o) {
          return o.start ? new Date(o.start) : new Date(o.date);
        },
      ]).map((x, i) => {
        if (x?.isStudentInvoice) {
          if (x.documentType === 0 || x.documentType === 1) {
            studentBalance += x.amount;
          } else if (x.documentType === 3 || x.documentType === 4) {
            studentBalance -= x.amount;
          }
        } else if (x.hasOwnProperty("isDiscount")) {
          if (x.isDiscount) {
            studentBalance += x.amount;
          } else {
            studentBalance -= x.amount;
          }
        } else {
          try {
            const today = new Date();
            if (x.eventStatus !== 3) {
              if (
                moment(x.start).isSameOrBefore(today) &&
                x.eventStatus !== 0
              ) {
                studentBalance -= x.price;
              } else {
                studentBalanceWithFutureBalance -= x.price;
              }
            }
          } catch (err) {}
        }
      });

      setBalance((studentBalance + studentBalanceWithFutureBalance).toFixed(2));
      setDisplayBalance(true);
    }
  };

  return !isMobile ? (
    <Container
      dir="auto"
      chosenStudentColor={event.chosenStudentColor}
      fontChosenStudentColor={event.fontChosenStudentColor}
      isStudentChosen={
        typeof event.chosenStudentId !== "undefined" &&
        event.chosenStudentId === event.event.studentId
      }
    >
      <ReactTooltip
        place="top"
        type="light"
        effect="float"
        html={true}
        id={event.event.id.toString()}
      />
      {isEditLessonModalOpen && (
        <EditEvent
          isNewAgreementModalOpen={isEditLessonModalOpen}
          setNewAgreementModalIsOpen={setIsEditLessonModalOpen}
          step={event.eventTime}
          selectedEvent={{
            ...event.event,
            name: event.event.name
              ? event.event.name
              : `${event.event.studentId} - ${event.event.firstName} ${event.event.lastName}`,
          }}
        />
      )}
      <TitleContainer dir="auto" className="eventView" isDay={event.isMargin}>
        {event.showEventStatus ? (
          <IconsAndTextContainer
            onClick={() => event.openEventStatusChangeModal(event.event)}
            style={{ margin: 0 }}
          >
            <Icon
              className={eventStatusIcons[event.event.eventStatus]}
              aria-hidden="true"
            />
          </IconsAndTextContainer>
        ) : null}
        {event.showTime ? (
          <IconsAndTextContainer onClick={() => setIsEditLessonModalOpen(true)}>
            <Icon className="icons8-clock" />
            <TextAfterIcon>
              {Math.round((event.event.end - event.event.start) / 1000 / 60)}
            </TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {event.showEventType ? (
          <IconsAndTextContainer onClick={() => getStudentEventCount()}>
            {getEventTypeIcon(event.event.eventType)}
            <TextAfterIcon>
              {isShowCount ? studentEventCount : event.event.title}
            </TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {event.showVehicle && typeof event.event.description === "string" ? (
          <IconsAndTextContainer>
            <Icon
              className="icons8-car"
              data-tip={`<span dir="auto">${event.event.description}</span>`}
              data-for={event.event.id.toString()}
            />
            <TextAfterIcon>{event.event.description}</TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {/* <IconsAndTextContainer>
          <Icon className="fa fa-book" aria-hidden="true" />
          <TextAfterIcon>כרטסת</TextAfterIcon>
        </IconsAndTextContainer> */}
        {event.showPickUpLocation &&
        typeof event.event.pickupLocationName === "string" &&
        event.event.pickupLocationName !== "ללא" ? (
          <IconsAndTextContainer
            onClick={() => event.openPickupModal(event.event.id)}
          >
            <Icon
              className="icons8-location"
              data-tip={`<span dir="auto">${event.event.pickupLocationName}</span>`}
              data-for={event.event.id.toString()}
            />
            <TextAfterIcon>{event.event.pickupLocationName}</TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {event.showStudent && typeof event.event.firstName === "string" ? (
          <IconsAndTextContainer
            onClick={() => event.openStudentMenuModal(event.event)}
          >
            <Icon
              className="icons8-user-group-man-man"
              data-tip={`<span dir="auto">${event.event.firstName} ${
                event.event.lastName
              }</span><br/><span dir="auto">${
                event.event.address ? event.event.address : ""
              }, ${
                event.event.city ? event.event.city : ""
              }</span><br/><span dir="auto">${event.event.phone}</span>`}
              data-for={event.event.id.toString()}
            />
            <TextAfterIcon
              isDay={event.isDay}
              style={{
                ...getNameStyle(event.event),
              }}
            >
              {event.event.firstName}&nbsp;
              {typeof event.event.nickname === "string" &&
                `(${event.event.nickname}) `}
              {event.event.lastName}
            </TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {event.showDrivingPermit &&
        typeof event.event.drivingPermitId === "string" &&
        event.event.drivingPermitId !== "6" ? (
          <IconsAndTextContainer>
            <Icon className="fa fa-id-card" />
            <TextAfterIcon>
              {
                drivingPermitsOptions.find(
                  (x) => x.value.toString() === event.event.drivingPermitId
                )?.label
              }
            </TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {event.showBalance ? (
          <IconsAndTextContainer onClick={() => setStudentBalance()}>
            <Icon className="icons8-scales" />
            {displayBalance && <TextAfterIcon>{balance}</TextAfterIcon>}
          </IconsAndTextContainer>
        ) : null}
        {event.showNotes && typeof event.event.notes === "string" ? (
          <IconsAndTextContainer
            data-tip={event.event.notes}
            onClick={() => event.openNotesChangeModal(event.event)}
            data-for={event.event.id.toString()}
          >
            <Icon className="icons8-comments" />
            {<TextAfterIcon>{event.event.notes}</TextAfterIcon>}
          </IconsAndTextContainer>
        ) : null}
        {event.showBranch && event.event.eventType !== 4 ? (
          <IconsAndTextContainer>
            <Icon className="fa fa-building" />
            <TextAfterIcon>{event.event.branchName}</TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {event.showTeacher &&
        teachersOptions.length !== 0 &&
        event.event.teacherId !== null ? (
          <IconsAndTextContainer>
            <Icon className="icons8-classroom" />
            <TextAfterIcon>
              {
                teachersOptions.find(
                  (x) => x.value.toString() === event.event.teacherId.toString()
                )?.label
              }
            </TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {event.showHealthDeclaration ? (
          <IconsAndTextContainer
            onClick={() => event.openSignatureModal(event.event)}
          >
            <Icon className="fa fa-ambulance" />
          </IconsAndTextContainer>
        ) : null}
      </TitleContainer>
    </Container>
  ) : (
    <Container
      dir="auto"
      chosenStudentColor={event.event.chosenStudentColor}
      fontChosenStudentColor={event.event.fontChosenStudentColor}
      isStudentChosen={
        typeof event.chosenStudentId !== "undefined" &&
        event.chosenStudentId === event.event.studentId
      }
    >
      {isEditLessonModalOpen && (
        <EditEvent
          isNewAgreementModalOpen={isEditLessonModalOpen}
          setNewAgreementModalIsOpen={setIsEditLessonModalOpen}
          step={event.eventTime}
          selectedEvent={{
            ...event.event,
            name: event.event.name
              ? event.event.name
              : `${event.event.studentId} - ${event.event.firstName} ${event.event.lastName}`,
          }}
        />
      )}
      <TitleContainer dir="auto" className="eventView" isDay={event.isMargin}>
        {event.showNotes ? (
          <IconsAndTextContainer
            data-tip={event.event.notes}
            onClick={() => event.openEventStatusChangeModal(event.event)}
            style={{ margin: 0 }}
            data-for={event.event.id.toString()}
          >
            <Icon
              className={eventStatusIcons[event.event.eventStatus]}
              aria-hidden="true"
            />
          </IconsAndTextContainer>
        ) : null}
        {event.showTime ? (
          <IconsAndTextContainer onClick={() => setIsEditLessonModalOpen(true)}>
            <Icon className="icons8-clock" />
            <TextAfterIcon>
              {Math.round((event.event.end - event.event.start) / 1000 / 60)}
            </TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {event.showEventType ? (
          <IconsAndTextContainer onClick={() => getStudentEventCount()}>
            {getEventTypeIcon(event.event.eventType)}
            <TextAfterIcon>
              {isShowCount ? studentEventCount : event.event.title}
            </TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {event.showBalance ? (
          <IconsAndTextContainer onClick={() => setStudentBalance()}>
            <Icon className="icons8-scales" />
            {displayBalance && <TextAfterIcon>{balance}</TextAfterIcon>}
          </IconsAndTextContainer>
        ) : null}
        {event.showNotes && typeof event.event.notes === "string" ? (
          <IconsAndTextContainer
            data-tip={event.event.notes}
            onClick={() => event.openNotesChangeModal(event.event)}
            data-for={event.event.id.toString()}
          >
            <Icon className="icons8-comments" />
            <TextAfterIcon />
          </IconsAndTextContainer>
        ) : null}
      </TitleContainer>
      <div>
        {event.showStudent && typeof event.event.firstName === "string" ? (
          <IconsAndTextContainer
            onClick={() => event.openStudentMenuModal(event.event)}
          >
            <Icon
              className="icons8-user-group-man-man"
              data-tip={`<span dir="auto">${event.event.firstName} ${event.event.lastName}</span><br/><span dir="auto">${event.event.address}, ${event.event.city}</span><br/><span dir="auto">${event.event.phone}</span>`}
              data-for={event.event.id.toString()}
            />
            <TextAfterIcon
              isDay={event.isDay}
              style={{
                ...getNameStyle(event.event),
              }}
            >
              {event.event.firstName}&nbsp;
              {typeof event.event.nickname === "string" &&
                `(${event.event.nickname}) `}
              {event.event.lastName}
            </TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {event.showVehicle && typeof event.event.description === "string" ? (
          <IconsAndTextContainer>
            <Icon
              className="icons8-car"
              data-tip={`<span dir="auto">${event.event.description}</span>`}
              data-for={event.event.id.toString()}
            />
            <TextAfterIcon>{event.event.description}</TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {typeof event.event.pickupLocationName === "string" &&
        event.event.pickupLocationName !== "ללא" ? (
          <IconsAndTextContainer
            onClick={() => event.openPickupModal(event.event.id)}
          >
            <Icon
              className="icons8-location"
              data-tip={`<span dir="auto">${event.event.pickupLocationName}</span>`}
              data-for={event.event.id.toString()}
            />
            <TextAfterIcon>{event.event.pickupLocationName}</TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {event.showBranch && event.event.eventType !== 4 ? (
          <IconsAndTextContainer>
            <Icon className="fa fa-building" />
            <TextAfterIcon>{event.event.branchName}</TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {event.showTeacher &&
        teachersOptions.length !== 0 &&
        event.event.teacherId !== null ? (
          <IconsAndTextContainer>
            <Icon className="icons8-classroom" />
            <TextAfterIcon>
              {
                teachersOptions.find(
                  (x) => x.value.toString() === event.event.teacherId.toString()
                )?.label
              }
            </TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {event.showHealthDeclaration ? (
          <IconsAndTextContainer
            onClick={() => event.openSignatureModal(event.event)}
          >
            <Icon className="fa fa-ambulance" />
          </IconsAndTextContainer>
        ) : null}
      </div>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    showEventType: state.session.user.showEventType,
    eventTime: state.session.user.eventTime,
    showEventStatus: state.session.user.showEventStatus,
    showStudent: state.session.user.showStudent,
    showVehicle: state.session.user.showVehicle,
    showHealthDeclaration: state.session.user.showHealthDeclaration,
    showTime: state.session.user.showTime,
    showBalance: state.session.user.showBalance,
    showBranch: state.session.user.showBranch,
    showNotes: state.session.user.showNotes,
    showTeacher: state.session.user.showTeacher,
    showPickUpLocation: state.session.user.showPickUpLocation,
    isUsersLoaded: state.UsersReducer.isLoaded,
    users: state.UsersReducer.users,
    chosenStudentColor: state.session.user.chosenStudentColor,
    fontChosenStudentColor: state.session.user.fontChosenStudentColor,
  };
}

export default connect(mapStateToProps, {
  getUsers,
  getAgreementDetails,
  getStudentEvents,
  getStudentEventCount,
  getStudentTextBooks,
  fetchTextBook,
  getStudentIncomes,
  getStudentDiscountsAndObligations,
})(EventAgendaView);
