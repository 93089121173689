import schedulerApi from "../apis/scheduler";
import holidaysApi from "../apis/holidaysApi";
import {
  NEW_EVENT_FAILED,
  NEW_EVENT_SUCCESS,
  NEW_EVENT_STARTED,
  EVENTS_FETCH_FAILED,
  EVENTS_FETCH_SUCCESS,
  EVENTS_FETCH_STARTED,
  EDIT_EVENT_SUCCESS,
  HIDE_EVENT,
  EVENTS_FETCH_HOLIDAYS_SUCCESS,
} from "../constants/actionTypes";
import { toast } from "react-toastify";

export const createEvent =
  (event, currVehicle) => async (dispatch, getState) => {
    try {
      dispatch({
        type: NEW_EVENT_STARTED,
      });
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const { branchId, id, schoolId } = getState().session.user;
      const response = await schedulerApi.post(
        "/createEvent",
        {
          event: {
            ...event,
            branchId,
            pickupLocationId: event.pickupLocationId.value,
            eventType: event.eventType.value,
            studentId: event.studentId ? event.studentId.studentId : null,
            userId: id,
            schoolId,
          },
        },
        {
          headers,
        }
      );
      dispatch({
        type: NEW_EVENT_SUCCESS,
        payload: {
          ...event,
          name: event.studentId ? event.studentId.label : null,
          studentId: event.studentId ? event.studentId.studentId : null,
          branchId,
          id: response.data.eventId,
          title: event.eventType.label,
          firstName: event.studentId ? event.studentId.firstName : null,
          lastName: event.studentId ? event.studentId.lastName : null,
          internalTestStatus: event.internalTestStatus,
          address: event.studentId ? event.studentId.address : null,
          city: event.studentId ? event.studentId.city : null,
          phone: event.studentId ? event.studentId.phone : null,
          updateUsername: event.updateUsername ? event.updateUsername : null,
          updated: new Date(),
          eventType: event.eventType.value,
          description: currVehicle ? currVehicle.description : null,
          testStatus: 0,
          pickupLocationId: event.pickupLocationId.value,
          pickupLocationName: event.pickupLocationId.label,
          drivingPermitId: event.drivingPermitId
            ? event.drivingPermitId.toString()
            : null,
          textBookId: event.textBookId ? event.textBookId.toString() : null,
          teacherId: event.teacherId ? event.teacherId : null,
          vehicleId: event.vehicleId ? event.vehicleId : null,
          eventStatus: 0,
        },
      });
    } catch (error) {
      dispatch({
        type: EVENTS_FETCH_FAILED,
      });
      toast.error("כישלון ביצירת אירוע");
    }
  };

export const loadHolidays =
  (event, currVehicle) => async (dispatch, getState) => {
    try {
      const response = await holidaysApi.get();

      dispatch({
        type: EVENTS_FETCH_HOLIDAYS_SUCCESS,
        payload: response.data.items.map((x) => ({
          title: x.hebrew,
          start: new Date(x.date),
          end: new Date(x.date),
          allDay: true,
        })),
      });
    } catch (error) {
      dispatch({
        type: EVENTS_FETCH_FAILED,
      });
      toast.error("כישלון ביצירת אירוע");
    }
  };

export const updateEvent = (event) => async (dispatch, getState) => {
  try {
    dispatch({
      type: NEW_EVENT_STARTED,
    });
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { branchId, id, schoolId } = getState().session.user;
    const response = await schedulerApi.patch(
      "/updateEvent",
      {
        event: {
          ...event,
          branchId,
          eventType: event.eventType.value,
          userId: id,
          schoolId,
          pickupLocationId: event.pickupLocationId.value,
          studentId: event.studentId ? event.studentId.studentId : null,
        },
      },
      {
        headers,
      }
    );

    dispatch({
      type: EDIT_EVENT_SUCCESS,
      payload: {
        ...event,
        title: event.eventType.label,
        eventType: event.eventType.value,
        textBookId: event.textBookId ? event.textBookId.toString() : null,
        drivingPermitId: event.drivingPermitId
          ? event.drivingPermitId.toString()
          : null,
        firstName: event.studentId ? event.studentId.firstName : null,
        studentId: event.studentId ? event.studentId.studentId : null,
        lastName: event.studentId ? event.studentId.lastName : null,
        pickupLocationId: event.pickupLocationId.value,
        pickupLocationName: event.pickupLocationId.label,
      },
    });
  } catch (error) {
    dispatch({
      type: EVENTS_FETCH_FAILED,
    });
    toast.error("כישלון בעדכון אירוע");
  }
};

export const updatePickupLocation =
  (eventId, pickupLocationId, pickupLocationName) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: NEW_EVENT_STARTED,
      });
      const token = window.localStorage.getItem("token");

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const { branchId, id, schoolId } = getState().session.user;

      const response = await schedulerApi.patch(
        "/updatePickupLocation",
        {
          eventId,
          pickupLocationId,
        },
        {
          headers,
        }
      );
      dispatch({
        type: EDIT_EVENT_SUCCESS,
        payload: {
          id: eventId,
          pickupLocationId,
          pickupLocationName,
        },
      });
    } catch (error) {
      dispatch({
        type: EVENTS_FETCH_FAILED,
      });
      toast.error("כישלון בעדכון אירוע");
    }
  };

export const updateEventStatus =
  (eventId, eventStatus, cancelReason, eventType, testStatus = 0, type = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: NEW_EVENT_STARTED,
      });
      const token = window.localStorage.getItem("token");

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const { branchId, id, schoolId } = getState().session.user;

      const response = await schedulerApi.patch(
        "/updateEventStatus",
        {
          eventId,
          eventStatus,
          cancelReason,
          eventType,
          testStatus,
          type,
        },
        {
          headers,
        }
      );

      if (eventStatus === 3 || eventStatus === 4) {
        dispatch({
          type: HIDE_EVENT,
          payload: {
            id: eventId,
            eventStatus,
          },
        });
      } else if (type !== "") {
        dispatch({
          type: EDIT_EVENT_SUCCESS,
          payload: {
            id: eventId,
            eventStatus,
            cancelReason,
            [type]: testStatus,
          },
        });
      } else {
        dispatch({
          type: EDIT_EVENT_SUCCESS,
          payload: {
            id: eventId,
            eventStatus,
            cancelReason,
            testStatus,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: EVENTS_FETCH_FAILED,
      });
      toast.error("כישלון בעדכון אירוע");
    }
  };

export const getEvents = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EVENTS_FETCH_STARTED,
    });
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { showCanceled, showCanceledAndCharged, schoolId } =
      getState().session.user;

    const response = await schedulerApi.get(
      `/getEventsForUser/${id}/${showCanceled}/${showCanceledAndCharged}/${schoolId}`,
      {
        headers,
      }
    );

    dispatch({
      type: EVENTS_FETCH_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: NEW_EVENT_FAILED,
    });
    //toast.error("כישלון בשליפת אירועים");
  }
};

export const getFundingBodyStudentEvents =
  (students) => async (dispatch, getState) => {
    try {
      const token = window.localStorage.getItem("token");

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const { schoolId } = getState().session.user;
      const response = await schedulerApi.get(
        `/getFundingBodyStudentEvents/${schoolId}/${students}`,
        {
          headers,
        }
      );

      return response.data;
    } catch (error) {
      //toast.error("כישלון בשליפת אירועים");
    }
  };

export const getAllSchoolEvents = (from, to) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EVENTS_FETCH_STARTED,
    });

    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const {
      schoolId,
      showCanceled,
      showCanceledAndCharged,
      branchId,
      userType,
    } = getState().session.user;
    const response =
      from && to
        ? await schedulerApi.get(
            `/getAllSchoolEvents/${schoolId}/${showCanceled}/${showCanceledAndCharged}/${branchId}/${userType}/${from}/${to}`,
            {
              headers,
            }
          )
        : await schedulerApi.get(
            `/getAllSchoolEvents/${schoolId}/${showCanceled}/${showCanceledAndCharged}/${branchId}/${userType}`,
            {
              headers,
            }
          );
    dispatch({
      type: EVENTS_FETCH_SUCCESS,
      payload: response.data,
    });

    return response.data;
  } catch (error) {
    dispatch({
      type: NEW_EVENT_FAILED,
    });
    //toast.error("כישלון בשליפת אירועים");
  }
};

export const getAllSchoolCanceledEvents =
  (from, to) => async (dispatch, getState) => {
    try {
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const { schoolId } = getState().session.user;
      const response = await schedulerApi.get(
        `/getAllSchoolCanceledEvents/${schoolId}/${from}/${to}`,
        {
          headers,
        }
      );

      return response.data;
    } catch (error) {
      //toast.error("כישלון בשליפת אירועים");
    }
  };

export const getLastStudentsEvent =
  (studentIds) => async (dispatch, getState) => {
    try {
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const { schoolId } = getState().session.user;
      const response = await schedulerApi.post(
        "/getLastStudentsEvent",
        {
          schoolId,
          studentIds,
        },
        {
          headers,
        }
      );
      return response.data;
    } catch (error) {
      //toast.error("כישלון בשליפת אירועים");
    }
  };

export const getAllSchoolTestsEvents =
  (from, to) => async (dispatch, getState) => {
    try {
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const { schoolId } = getState().session.user;
      const response = await schedulerApi.get(
        `/getAllSchoolTestsEvents/${schoolId}/${from}/${to}`,
        {
          headers,
        }
      );

      return response.data;
    } catch (error) {
      //toast.error("כישלון בשליפת אירועים");
    }
  };

export const getAllTeacherEvents =
  (teacherId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EVENTS_FETCH_STARTED,
      });

      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const { showCanceled, showCanceledAndCharged } = getState().session.user;

      const response = await schedulerApi.get(
        `/getEventsForTeacher/${teacherId}/${showCanceled}/${showCanceledAndCharged}`,
        {
          headers,
        }
      );

      return response.data;
    } catch (error) {
      dispatch({
        type: NEW_EVENT_FAILED,
      });
      //toast.error("כישלון בשליפת אירועים");
    }
  };

export const getAllTeacherEventsWithCanceled =
  (teacherId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EVENTS_FETCH_STARTED,
      });

      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await schedulerApi.get(
        `/getEventsForTeacher/${teacherId}/${1}/${1}`,
        {
          headers,
        }
      );

      return response.data;
    } catch (error) {
      dispatch({
        type: NEW_EVENT_FAILED,
      });
      //toast.error("כישלון בשליפת אירועים");
    }
  };

export const getEventsForTypeBetween =
  (type, start, end, typeId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EVENTS_FETCH_STARTED,
      });
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const { schoolId } = getState().session.user;

      const response = await schedulerApi.get(
        `/countEventForTypeBetween/${schoolId}/${type}/${typeId}/${start}/${end}`,
        {
          headers,
        }
      );

      return response.data;
    } catch (error) {
      dispatch({
        type: NEW_EVENT_FAILED,
      });
      //toast.error("כישלון בשליפת אירועים");
    }
  };

export const getStudentEventCount =
  (eventType, studentId, textbookId, startDate) =>
  async (dispatch, getState) => {
    try {
      const token = window.localStorage.getItem("token");
      const { branchId, id, schoolId } = getState().session.user;
      if (typeof branchId !== "undefined") {
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await schedulerApi.get(
          `getStudentEventCount/${studentId}/${textbookId}/${eventType}/${startDate}/${schoolId}/${branchId}`,
          {
            headers,
          }
        );

        return response.data;
      } else {
        return 0;
      }
    } catch (error) {
      toast.error("כישלון בבדיקת אירועים עבור תלמיד");
    }
  };

export const getStudentTextbookAllEvents =
  (studentId, textbookId) => async (dispatch, getState) => {
    try {
      const token = window.localStorage.getItem("token");
      const { branchId, id, schoolId } = getState().session.user;
      if (typeof branchId !== "undefined") {
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await schedulerApi.get(
          `countStudentTextBookAllEvents/${studentId}/${textbookId}/${schoolId}/${branchId}`,
          {
            headers,
          }
        );

        return response.data;
      } else {
        return 0;
      }
    } catch (error) {
      toast.error("כישלון בבדיקת אירועים עבור תלמיד");
    }
  };

export const getStudentEvents = (studentId) => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");
    const { branchId, id, schoolId } = getState().session.user;
    if (typeof branchId !== "undefined") {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await schedulerApi.get(
        `getStudentEvents/${studentId}/${schoolId}/${branchId}`,
        {
          headers,
        }
      );

      return response.data;
    } else {
      return 0;
    }
  } catch (error) {
    toast.error("כישלון בבדיקת אירועים עבור תלמיד");
  }
};

export const getStudentEventsByTextBook =
  (studentId, textBookId) => async (dispatch, getState) => {
    try {
      const token = window.localStorage.getItem("token");
      const { branchId, id, schoolId } = getState().session.user;
      if (typeof branchId !== "undefined") {
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await schedulerApi.get(
          `getStudentEventsByTextBook/${studentId}/${schoolId}/${branchId}/${textBookId}`,
          {
            headers,
          }
        );

        return response.data;
      } else {
        return 0;
      }
    } catch (error) {
      toast.error("כישלון בבדיקת אירועים עבור תלמיד");
    }
  };

export const updateEventNote =
  (eventId, notes) => async (dispatch, getState) => {
    try {
      dispatch({
        type: NEW_EVENT_STARTED,
      });
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await schedulerApi.patch(
        "/updateEventNote",
        {
          eventId,
          notes,
        },
        {
          headers,
        }
      );

      dispatch({
        type: EDIT_EVENT_SUCCESS,
        payload: {
          id: eventId,
          notes,
        },
      });
    } catch (error) {
      dispatch({
        type: EVENTS_FETCH_FAILED,
      });
      toast.error("כישלון בעדכון אירוע");
    }
  };

export const updateStudentFundingBodyStatus =
  (eventId, newStatus) => async (dispatch, getState) => {
    try {
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await schedulerApi.patch(
        "/updateStudentFundingBodyStatus",
        {
          eventId,
          newStatus,
        },
        {
          headers,
        }
      );
    } catch (error) {
      toast.error("כישלון בעדכון אירוע");
    }
  };

export const updateTeacher =
  (eventId, teacherId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: NEW_EVENT_STARTED,
      });
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await schedulerApi.patch(
        "/updateTeacher",
        {
          eventId,
          teacherId,
        },
        {
          headers,
        }
      );

      dispatch({
        type: EDIT_EVENT_SUCCESS,
        payload: {
          id: eventId,
          teacherId,
        },
      });
    } catch (error) {
      dispatch({
        type: EVENTS_FETCH_FAILED,
      });
      toast.error("כישלון בעדכון אירוע");
    }
  };

export const updateVehicle =
  (eventId, vehicleId, description) => async (dispatch, getState) => {
    try {
      dispatch({
        type: NEW_EVENT_STARTED,
      });
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await schedulerApi.patch(
        "/updateVehicle",
        {
          eventId,
          vehicleId,
        },
        {
          headers,
        }
      );

      dispatch({
        type: EDIT_EVENT_SUCCESS,
        payload: {
          id: eventId,
          vehicleId: vehicleId.toString(),
          description,
        },
      });
    } catch (error) {
      dispatch({
        type: EVENTS_FETCH_FAILED,
      });
      toast.error("כישלון בעדכון אירוע");
    }
  };

export const updateEventPrice =
  (eventId, newPrice) => async (dispatch, getState) => {
    try {
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await schedulerApi.patch(
        "/updateEventPrice",
        {
          eventId,
          newPrice,
        },
        {
          headers,
        }
      );
    } catch (error) {
      dispatch({
        type: EVENTS_FETCH_FAILED,
      });
      toast.error("כישלון בעדכון אירוע");
    }
  };
