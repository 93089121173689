import React from "react";
import { connect } from "react-redux";
import i18n from "../../../../lib/i18n/i18n";
import { Field, reduxForm } from "redux-form";
import SivanModal from "../../../Modal/Modal";
import { Body, Section, Row } from "./newProfessionalTeacher.styles";
import { create } from "../../../../actions/professionalTeachersActions";
import CreateButton from "../../../UiComponents/Buttons/Default";
import InputWrappedWithField from "../../../UiComponents/Inputs/DefaultWrappedWithField";

const NewProfessionalTeacher = ({
  isModalOpen,
  setIsModalOpen,
  handleSubmit,
  create,
}) => {
  const fundingBodyCreateAction = async (formValues) => {
    await create(formValues);
    setIsModalOpen(false);
  };

  return (
    <SivanModal
      titleText="צור גוף מימון"
      modalIsOpen={isModalOpen}
      closeModal={() => setIsModalOpen(false)}
    >
      <Body onSubmit={handleSubmit(fundingBodyCreateAction)}>
        <Section basicData noBorder>
          <Row>
            <Field
              name="firstName"
              component={InputWrappedWithField}
              placeholder="שם פרטי"
              type="text"
            />
            <Field
              name="lastName"
              component={InputWrappedWithField}
              placeholder="שם משפחה"
              type="text"
            />
            <Field
              name="professionalTeacherNumber"
              component={InputWrappedWithField}
              placeholder="מספר מנהל מקצועי"
              type="number"
            />
            <Field
              name="phoneNumber"
              component={InputWrappedWithField}
              placeholder="טלפון"
              type="text"
            />
            <Field
              name="amount"
              component={InputWrappedWithField}
              placeholder="סכום"
              type="number"
            />
          </Row>
        </Section>
        <CreateButton text="צור" type="submit" />
      </Body>
    </SivanModal>
  );
};

export default connect(null, { create })(
  reduxForm({
    form: "NewProfessionalTeacher",
  })(NewProfessionalTeacher)
);
