import {
  CLIENTS_FETCH_FAILED,
  CLIENTS_FETCH_SUCCESS,
  CLIENTS_FETCH_STARTED,
  CLIENT_CREATE_SUCCESS,
  CLIENT_DELETE_SUCCESS,
  CLIENT_UPDATE_SUCCESS,
} from "../constants/actionTypes";

const INITIAL_STATE = { isPending: false, clients: [], isLoaded: false };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CLIENT_CREATE_SUCCESS:
      return {
        ...state,
        clients: [...state.clients, action.payload],
      };
    case CLIENT_DELETE_SUCCESS:
      return {
        ...state,
        clients: state.clients.filter(
          (client) => client.id !== action.payload.id
        ),
      };
    case CLIENT_UPDATE_SUCCESS:
      const clients = state.clients.map((client) =>
        client.id === action.payload.id
          ? { ...client, ...action.payload }
          : client
      );
      return { ...state, clients };
    case CLIENTS_FETCH_SUCCESS:
      return { ...state, clients: action.payload, isLoaded: true };
    case CLIENTS_FETCH_FAILED:
      return { ...state, isPending: false };
    case CLIENTS_FETCH_STARTED:
      return { ...state, isPending: true };
    default:
      return state;
  }
}
