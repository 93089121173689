import React, { useState, useEffect } from "react";
import i18n from "../../../lib/i18n/i18n";
import { connect } from "react-redux";
import { Container, Title } from "./clients.styles";
import NewClient from "./NewClient/NewClient";
import ClientData from "./ClientData/ClientData";
import { getSchoolClients } from "../../../actions/clientsActions";
import Table from "../../SivanPlusTable/SivanPlusTable";
import CreateButton from "../../UiComponents/Buttons/Default";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const vehicleColumns = [
  {
    Header: dictionary.table.name,
    accessor: "name",
  },
  {
    Header: dictionary.table.phone,
    accessor: "phoneNumber",
  },
  {
    Header: dictionary.table.active,
    accessor: "active",
    Cell: ({ row: { original } }) => {
      return original.isActive === 1
        ? dictionary.users.active
        : dictionary.users.inactive;
    },
  },
];

const FundingBodies = ({ clients, isLoaded, getSchoolClients }) => {
  const [isClientModalOpen, setClientModalOpen] = useState(false);
  const [isClientDetailsModalOpen, setClientDetailsModalOpen] = useState(false);
  const [clientData, setClientData] = useState(false);

  const onRowClick = async (rowObject) => {
    setClientDetailsModalOpen(true);
    setClientData(rowObject.original);
  };

  useEffect(() => {
    async function fetchData() {
      if (!isLoaded) {
        await getSchoolClients();
      }
    }
    fetchData();
  }, []);

  return (
    <Container>
      <NewClient
        isModalOpen={isClientModalOpen}
        setIsModalOpen={setClientModalOpen}
      />
      <ClientData
        isModalOpen={isClientDetailsModalOpen}
        closeModal={() => setClientDetailsModalOpen(false)}
        clientData={clientData}
      />
      <Title>
        לקוחות
        <CreateButton onClick={() => setClientModalOpen(true)} text="צור חדש" />
      </Title>
      <Table
        data={clients}
        componentColumns={vehicleColumns}
        onRowClick={onRowClick}
      />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    clients: state.ClientsReducer.clients,
    isLoaded: state.ClientsReducer.isLoaded,
    createVehicle: state.session.user.createVehicle,
    editVehicle: state.session.user.editVehicle,
  };
}

export default connect(mapStateToProps, {
  getSchoolClients,
})(FundingBodies);
