import moment from "moment";
import {
  eventTypeToTextbookOpeningBalanceString,
  agreementsDetailTypes,
} from "../constants/textbook";

export const studentBalance = async (
  incomes,
  discountsAndObligations,
  fetchTextBook,
  getTextBookAgreementOpeningBalance,
  studentEvents,
  textBooksData
) => {
  let discounts = 0;
  let obligations = 0;
  let paid = 0;
  for (let index = 0; index < discountsAndObligations.length; index++) {
    const element = discountsAndObligations[index];
    if (element.isDiscount) {
      discounts += element.amount;
    } else {
      obligations -= element.amount;
    }
  }

  for (let index = 0; index < incomes.length; index++) {
    const element = incomes[index];
    if (element.documentType === 0 || element.documentType === 1) {
      paid += element.amount;
    } else if (element.documentType === 3 || element.documentType === 4) {
      paid -= element.amount;
    }
  }

  let balance = paid + discounts + obligations;

  for (let index = 0; index < textBooksData.length; index++) {
    const element = textBooksData[index];
    const textBookDetails = await fetchTextBook(element.textBookId);
    const agreementAmount = await getTextBookAgreementOpeningBalance(
      textBookDetails.agreements
    );
    balance += (parseFloat(element.openingBalance) || 0) - agreementAmount;
  }

  for (let index = 0; index < studentEvents.length; index++) {
    const event = studentEvents[index];
    if (event.eventType !== 4) {
      if (
        event.eventType === 1 &&
        event.eventStatus !== 3 &&
        event.eventStatus !== 4
      ) {
        balance -= event.price;
      } else if (
        event.eventType === 2 &&
        event.eventStatus !== 3 &&
        event.eventStatus !== 4
      ) {
        balance -= event.price;
      } else if (
        event.eventType === 3 &&
        event.eventStatus !== 3 &&
        event.eventStatus !== 4
      ) {
        balance -= event.price;
      } else if (event.eventType === 1 && event.eventStatus === 4) {
        balance -= event.price;
      }
    }
  }
  return balance;
};

export const eventPriceCalculator = (
  event,
  studentEventsFiltered,
  agreementsDetails,
  studentTextBookData
) => {
  const filterd = studentEventsFiltered.filter(
    (x) => x.eventType === event.eventType && x.id !== event.id
  );

  const eventTypeAgreementDetails =
    agreementsDetails[agreementsDetailTypes[event.eventType]];

  let numberOfEvents = calculateNumberOfEventsOfType(
    event,
    filterd,
    eventTypeAgreementDetails
  );

  const activeAgreementEventType = findActiveAgreement(
    eventTypeAgreementDetails,
    "from",
    new Date(event.start)
  );

  const studentEventOpeningBalance = getEventOfTypeOpeningBalance(
    studentTextBookData,
    event.eventType
  );

  const duration = eventDurationInMinutes(event.start, event.end);
  const eventDuration = Math.round(
    duration / activeAgreementEventType.duration
  );

  return calculateEventPrice(
    eventDuration,
    activeAgreementEventType,
    studentEventOpeningBalance,
    numberOfEvents,
    event
  );
};

export const findActiveAgreement = (agreements, startDateFieldName, start) => {
  const agreementsArray = agreements.sort(
    (a, b) => new Date(b[startDateFieldName]) - new Date(a[startDateFieldName])
  );
  let index = 0;
  let sameOrAfter = true;
  for (index; index < agreementsArray.length; index++) {
    const element = agreementsArray[index];
    if (!moment(element[startDateFieldName]).isSameOrAfter(start)) {
      sameOrAfter = false;
      break;
    }
  }
  if (sameOrAfter) {
    index = index - 1;
  }
  return agreementsArray[index];
};

const calculateEventPrice = (
  eventDuration,
  activeAgreementEventType,
  studentEventOpeningBalance,
  numberOfEvents,
  event
) => {
  let eventPrice = 0;
  for (let index = 1; index <= eventDuration; index++) {
    if (
      (parseInt(activeAgreementEventType.amount) || 0) -
        Math.ceil(numberOfEvents) -
        index -
        studentEventOpeningBalance >=
      0
    ) {
      eventPrice += activeAgreementEventType.price * 1;
    } else {
      eventPrice += activeAgreementEventType.priceAfter * 1;
    }
  }
  return eventPrice;
};

const getEventOfTypeOpeningBalance = (studentTextBookData, eventType) => {
  if (eventType === 1) {
    return (
      studentTextBookData[eventTypeToTextbookOpeningBalanceString[eventType]] /
      40
    );
  } else {
    return studentTextBookData[
      eventTypeToTextbookOpeningBalanceString[eventType]
    ];
  }
};

const calculateNumberOfEventsOfType = (
  event,
  studentEventsOfType,
  eventTypeAgreementDetails
) => {
  let numberOfEvents = 0;

  for (let index = 0; index < studentEventsOfType.length; index++) {
    const currEvent = studentEventsOfType[index];

    if (moment(event.start).isAfter(currEvent.start)) {
      const eventDuration = eventDurationInMinutes(
        currEvent.end,
        currEvent.start
      );
      numberOfEvents += eventDuration / eventTypeAgreementDetails[0].duration;
    } else if (moment(currEvent.start).isSame(event.start)) {
      numberOfEvents += 1;
    }
  }

  return numberOfEvents;
};

export const eventDurationInMinutes = (eventStartTime, eventEndTime) =>
  Math.abs(
    new Date(eventEndTime).getTime() - new Date(eventStartTime).getTime()
  ) / 60000;
