import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Navbar from "../../../Navbar/Navbar";
import DatePicker from "react-datepicker";
import i18n from "../../../../lib/i18n/i18n";
import { NavAndBackContainer, StyledLoader } from "./incomeData.styles";
import IncomeDetails from "./IncomeDetails/IncomeDetails";
// import StudentTicket from "./StudentTicket/StudentTicket";
import SivanModal from "../../../Modal/Modal";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const FundingBodiesData = ({
  isPending,
  user,
  isModalOpen,
  closeModal,
  providerData,
}) => {
  const [menuItems, setMenuItems] = useState([dictionary.editStudent.basic]);
  const [currentOpenedView, setCurrentOpenedView] = useState(
    dictionary.editStudent.basic
  );

  useEffect(() => {
    user.studentTicket && setMenuItems([...menuItems]);
  }, []);

  return (
    <SivanModal
      titleText={providerData.name}
      modalIsOpen={isModalOpen}
      closeModal={closeModal}
    >
      {currentOpenedView === dictionary.editStudent.basic && (
        <IncomeDetails providerData={providerData} closeModal={closeModal} />
      )}
      {/* {currentOpenedView === dictionary.editStudent.ticket && (
        <StudentTicket studentId={location.state.studentData.studentId} />
      )} */}
      <StyledLoader active={isPending} spinner text="Creating Student..." />
    </SivanModal>
  );
};

FundingBodiesData.defaultProps = {
  location: { state: { studentData: { birthday: new Date() } } },
};

function mapStateToProps(state) {
  return { user: state.session.user };
}

export default connect(mapStateToProps)(FundingBodiesData);
