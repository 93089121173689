import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import SivanModal from "../../Modal/Modal";
import { Body, Button } from "./updatePickupLocation.styles";
import { getPickupLocations } from "../../../actions/pickupLocationsActions";
import { updatePickupLocation } from "../../../actions/schedulerActions";

const UpdatePickupsLocation = ({
  isNewAgreementModalOpen,
  setNewAgreementModalIsOpen,
  pickupLocations,
  getPickupLocations,
  selectedEvent,
  updatePickupLocation,
}) => {
  const { t, i18n } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(true);
  // const [pickupLocations, setPickupLocations] = useState([]);

  useEffect(() => {
    (async () => {
      await getPickupLocations();
      setIsLoading(false);
    })();
  }, []);

  const makeAction = async (pickupLocationId, pickupLocationName) => {
    await updatePickupLocation(
      selectedEvent.eventId,
      pickupLocationId,
      pickupLocationName
    );
    setNewAgreementModalIsOpen(false);
  };
  return (
    <SivanModal
      titleText="עדכון מקום איסוף"
      modalIsOpen={isNewAgreementModalOpen}
      closeModal={() => setNewAgreementModalIsOpen(false)}
      isLoading={isLoading}
    >
      <Body>
        {pickupLocations.map((x) => (
          <Button key={x.id} onClick={() => makeAction(x.id, x.name)}>
            {x.name}
          </Button>
        ))}
      </Body>
    </SivanModal>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    pickupLocations: state.PickupLocationsReducer.locations,
    isPickupLocationsLoaded: state.PickupLocationsReducer.isLoaded,
  };
}

export default connect(mapStateToProps, {
  getPickupLocations,
  updatePickupLocation,
})(UpdatePickupsLocation);
