import teachersApi from "../apis/teachers";
import {
  SET_TEACHER_VACATION_START,
  SET_TEACHER_VACATION_SUCCESS,
  SET_TEACHER_VACATION_FAILED,
} from "../constants/actionTypes";
import { toastr } from "react-redux-toastr";
import { toast } from "react-toastify";

export const updateTeacherVacations = (vacations, teacherId) => async (
  dispatch
) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await teachersApi.patch(
      `/updateTeacherVacations`,
      { teacherId, vacations },
      {
        headers,
      }
    );
    return true;
  } catch (error) {
    toast.error("כשלון בעדכון חופשות למורה");
    return false;
  }
};

export const getAllTeachers = () => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await teachersApi.get(`/getAllTeachers/1`, {
      headers,
    });

    return response.data;
  } catch (error) {
    toastr.error("please try again");
    return false;
  }
};

export const getTeacherVacations = (teacherId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await teachersApi.get(
      `/getTeacherVacations/${teacherId}`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return false;
  }
};

export const getTeacheresVehicles = (teacherId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await teachersApi.get(
      `/getTeacheresVehicles/${teacherId}`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return false;
  }
};

export const updateTeacherVehicles = (vehicels, teacherId) => async (
  dispatch
) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await teachersApi.patch(
      `/updateTeacherVehicle`,
      { teacherId, vehicels },
      {
        headers,
      }
    );
    return true;
  } catch (error) {
    toast.error("כשלון בעדכון רכבים למורה");
    return false;
  }
};
