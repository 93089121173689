import {
  INCOMES_FETCH_FAILED,
  INCOMES_FETCH_SUCCESS,
  INCOMES_FETCH_STARTED,
  INCOME_CREATE_SUCCESS,
  INCOME_DELETE_SUCCESS,
  INCOME_UPDATE_SUCCESS,
  INCOMES_FUNDING_BODIES_FETCH_SUCCESS,
} from "../constants/actionTypes";

const INITIAL_STATE = {
  isPending: false,
  incomes: [],
  incomesFromStudents: [],
  incomesFromFundingBodies: [],
  isLoaded: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case INCOME_CREATE_SUCCESS:
      return {
        ...state,
        incomes: [...state.incomes, action.payload],
      };
    case INCOME_DELETE_SUCCESS:
      return {
        ...state,
        incomes: state.incomes.filter(
          (income) => income.id !== action.payload.id
        ),
      };
    case INCOMES_FUNDING_BODIES_FETCH_SUCCESS:
      return {
        ...state,
        incomesFromFundingBodies: action.payload,
      };
    case INCOME_UPDATE_SUCCESS:
      const incomes = state.incomes.map((income) =>
        income.id === action.payload.id
          ? { ...income, ...action.payload }
          : income
      );
      return { ...state, incomes };
    case INCOMES_FETCH_SUCCESS:
      return { ...state, incomes: action.payload, isLoaded: true };
    case INCOMES_FETCH_FAILED:
      return { ...state, isPending: false };
    case INCOMES_FETCH_STARTED:
      return { ...state, isPending: true };
    default:
      return state;
  }
}
