import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import { useTranslation } from "react-i18next";
import validate from "./validate";
import {
  Body,
  PermanentDetailsContainer,
  AdditionalDetailsContainer,
  ColumnTitle,
  Button,
  Fields,
  ButtonContainer,
} from "./createStudent.styles";
import {
  updateStudent,
  disableStudent,
  deleteStudent,
  reactivateStudent,
} from "../../../actions/studentesActions";
import { getStudentEvents } from "../../../actions/schedulerActions";
import { getBranches } from "../../../actions/branchActions";

const genderOptions = [
  { value: "male", label: "זכר" },
  { value: "female", label: "נקבה" },
];

const CreateStudent = ({
  handleSubmit,
  renderSelect,
  renderInput,
  renderDatePickerField,
  renderTextArea,
  updateStudent,
  disableStudent,
  studentData,
  history,
  getStudentEvents,
  deleteStudent,
  getBranches,
  change,
  reactivateStudent,
}) => {
  const [isDeleteAllowed, setIsDeleteAllowed] = useState(false);
  const { t, i18n } = useTranslation("common");
  const [branchOptions, setBranchOptions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const optionsArray = [];
      const allBranches = await getBranches();
      allBranches.map(({ id, name }) =>
        optionsArray.push({ value: id, label: name })
      );
      const branchIndex = optionsArray.findIndex(
        (x) => x.value === studentData.branchId
      );
      setBranchOptions(optionsArray);
      if (branchIndex !== -1) change("branchId", optionsArray[branchIndex]);
    }
    fetchData();
  }, []);

  const updateStudentAndSetLoading = async (formValues) => {
    if (
      await updateStudent({
        lastName: formValues.lastName,
        firstName: formValues.firstName,
        studentId: formValues.studentId,
        birthday: formValues.birthday,
        address: formValues.address,
        city: formValues.city,
        phone: formValues.phone,
        homePhone: formValues.homePhone,
        workPhone: formValues.workPhone,
        email: formValues.email,
        notes: formValues.notes,
        business: formValues.business,
        nickname: formValues.nickname,
        gender: formValues.gender.value,
        branchId: formValues.branchId.value,
        id: formValues.id,
      })
    )
      history.push("/studentSearch");
  };

  const deleteChosenStudent = async () => {
    if (await deleteStudent(studentData.id)) history.push("/studentSearch");
  };

  useEffect(() => {
    async function fetchData() {
      const count = await getStudentEvents(studentData.studentId);
      if (count.length === 0) {
        setIsDeleteAllowed(true);
      }
    }
    fetchData();
  }, []);

  return (
    <Body onSubmit={handleSubmit(updateStudentAndSetLoading)}>
      <Fields isRtl={i18n.language === "he"}>
        <PermanentDetailsContainer>
          <ColumnTitle dir="auto">
            {t("newStudent.permanentDetails")}
          </ColumnTitle>
          <Field
            name="lastName"
            component={renderInput}
            placeholder={t("newStudent.lastName")}
            disabled={!isDeleteAllowed}
          />
          <Field
            name="firstName"
            component={renderInput}
            placeholder={t("newStudent.firstName")}
            disabled={!isDeleteAllowed}
          />
          <Field
            name="studentId"
            component={renderInput}
            placeholder={t("newStudent.id")}
            type="number"
            disabled={!isDeleteAllowed}
          />
          <Field
            name="birthday"
            component={renderDatePickerField}
            placeholder={t("newStudent.birthday")}
          />
          <Field
            name="branchId"
            component={renderSelect}
            placeholder={t("newStudent.branch")}
            options={branchOptions}
          />
        </PermanentDetailsContainer>
        <AdditionalDetailsContainer>
          <ColumnTitle dir="auto">
            {t("newStudent.additionalDetails")}
          </ColumnTitle>
          <Field
            name="gender"
            component={renderSelect}
            placeholder={t("newStudent.gender")}
            options={genderOptions}
          />
          <Field
            name="nickname"
            component={renderInput}
            placeholder={t("newStudent.nickname")}
          />
          <Field
            name="address"
            component={renderInput}
            placeholder={t("newStudent.address")}
          />
          <Field
            name="city"
            component={renderInput}
            placeholder={t("newStudent.city")}
          />
          <Field
            name="postalCode"
            component={renderInput}
            placeholder={t("newStudent.postalCode")}
          />
          <Field
            name="phone"
            component={renderInput}
            placeholder={t("newStudent.phone")}
          />
        </AdditionalDetailsContainer>
        <AdditionalDetailsContainer>
          <ColumnTitle />
          <Field
            name="homePhone"
            component={renderInput}
            placeholder={t("newStudent.homePhone")}
          />
          <Field
            name="workPhone"
            component={renderInput}
            placeholder={t("newStudent.workPhone")}
          />
          <Field
            name="email"
            component={renderInput}
            placeholder={t("newStudent.email")}
          />
          <Field
            name="business"
            component={renderInput}
            placeholder={t("newStudent.business")}
          />
          <Field
            name="notes"
            component={renderTextArea}
            placeholder={t("newStudent.notes")}
          />
        </AdditionalDetailsContainer>
      </Fields>
      <ButtonContainer>
        <Button type="submit">{t("buttons.save")}</Button>
        <Button
          onClick={() =>
            studentData.active === 2
              ? reactivateStudent(studentData.id)
              : disableStudent(studentData.id)
          }
          type="button"
        >
          {studentData.active === 2
            ? t("buttons.active")
            : t("buttons.inactive")}
        </Button>
        {isDeleteAllowed && (
          <Button onClick={deleteChosenStudent} type="button">
            {t("buttons.delete")}
          </Button>
        )}
      </ButtonContainer>
    </Body>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    initialValues: {
      ...ownProps.studentData,
      birthday: new Date(ownProps.studentData.birthday),
      gender:
        typeof ownProps.studentData.gender === "object"
          ? ownProps.studentData.gender
          : genderOptions.find(
              ({ value }) => value === ownProps.studentData.gender
            ),
    },
  };
}

export default withRouter(
  connect(mapStateToProps, {
    updateStudent,
    disableStudent,
    getStudentEvents,
    deleteStudent,
    getBranches,
    change,
    reactivateStudent,
  })(
    reduxForm({
      form: "updateStudentBasicData", // a unique identifier for this form
      destroyOnUnmount: true, // <------ preserve form data
      forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
      validate,
      enableReinitialize: true,
    })(CreateStudent)
  )
);
