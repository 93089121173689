import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Table = styled.table`
  direction: rtl;
  border: 1px solid black;
  font-weight: bold;
  font-size: 1vmax;
  width: 100%;

  td {
    border: 1px solid black;
    padding: 6px;
    text-align: center;
  }

  th {
    border: 1px solid black;
    padding: 6px;
    text-align: center;
  }
`;
