import styled from "styled-components";
import i18n from "../../../../../lib/i18n/i18n";
import { isMobile } from "react-device-detect";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const Body = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${isMobile ? "80vw" : "70vw"};

  .react-toggle-track {
    background-color: #f5f5f5 !important;
    border: 1px solid #e5e5e5;
    padding: 0.2vh;
  }

  .react-toggle--checked .react-toggle-thumb {
    background-color: #edca92;
  }

  .react-toggle-thumb {
    background-color: #e5e3e0;
    border: none;
  }

  .react-autosuggest__container {
    position: relative;
    border-radius: 5px;
    width: 100%;
    height: 6vh;
    margin-top: 1vh;
    border: 1px solid #e5e5e5;
  }

  .react-autosuggest__input {
    width: 100%;
    height: 100%;
    direction: rtl;
    padding: 10px 20px;
    // font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-radius: 4px;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    right: 0;
    top: 51px;
    border: 1px solid #aaa;
    background-color: #fff;
    // font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    width: 100%;
    direction: rtl;
    height: 30vh;
    overflow: auto;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }

  .error {
    border: 1px solid red !important;
    border-radius: 4px;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: ${isHebrew ? "row-reverse" : "row"};
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 30%;

  .react-datepicker-wrapper:after {
    font-family: "FontAwesome";
    position: relative;
    left: ${isHebrew ? "-90%" : "-5%"};
    content: "\f133";
  }

  .react-datepicker-wrapper {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    margin-top: 1vh;
    width: 100%;
    height: 6vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__input-container input {
    width: 100%;
    ${isHebrew ? "padding-right" : "padding-left"}: 1vw;
    direction: ${isHebrew ? "rtl" : "ltr"};

    &::placeholder {
      color: #c2c2c2;
    }
  }
`;
