import branchesApi from "../apis/branches";
import {
  VEHICLE_FETCH_FAILED,
  VEHICLE_FETCH_STARTED,
  VEHICLE_FETCH_SUCCESS,
} from "../constants/actionTypes";
import { toast } from "react-toastify";

export const getBranches = () => async (dispatch) => {
  try {
    // dispatch({
    //   type: VEHICLE_FETCH_STARTED,
    // });
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await branchesApi.get("/allBranches", {
      headers,
    });

    // dispatch({
    //   type: VEHICLE_FETCH_SUCCESS,
    //   payload: response.data,
    // });

    return response.data;
  } catch (error) {
    // dispatch({
    //   type: VEHICLE_FETCH_FAILED,
    // });
  }
};
