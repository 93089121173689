import fundingBodiesApi from "../apis/fundingBodies";
import {
  FUNDING_BODIES_FETCH_FAILED,
  FUNDING_BODIES_FETCH_SUCCESS,
  FUNDING_BODIES_FETCH_STARTED,
  FUNDING_BODY_CREATE_SUCCESS,
  FUNDING_BODY_DELETE_SUCCESS,
  FUNDING_BODY_UPDATE_SUCCESS,
} from "../constants/actionTypes";
import { toast } from "react-toastify";

export const create = (fundingBody) => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const { branchId, schoolId } = getState().session.user;

    const response = await fundingBodiesApi.post(
      "/create",
      {
        fundingBody: { ...fundingBody, schoolId },
      },
      {
        headers,
      }
    );
    dispatch({
      type: FUNDING_BODY_CREATE_SUCCESS,
      payload: { ...fundingBody, id: response.data.id },
    });
  } catch (error) {
    toast.error("כישלון ביצירת גוף מימון");
  }
};

export const getSchoolFundingBodies = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: FUNDING_BODIES_FETCH_STARTED,
    });
    const token = window.localStorage.getItem("token");
    const { branchId, schoolId } = getState().session.user;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await fundingBodiesApi.get(`/getAll/${schoolId}`, {
      headers,
    });

    dispatch({
      type: FUNDING_BODIES_FETCH_SUCCESS,
      payload: response.data,
    });

    return response.data;
  } catch (error) {
    dispatch({
      type: FUNDING_BODIES_FETCH_FAILED,
    });
  }
};

export const getPaymentRequests = (clientId) => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");
    const { branchId, schoolId } = getState().session.user;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await fundingBodiesApi.get(
      `/getPaymentRequest/${schoolId}/${clientId}`,
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {}
};

export const getPaymentRequestById = (id) => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");
    const { branchId, schoolId } = getState().session.user;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await fundingBodiesApi.get(
      `/getPaymentRequestById/${id}/${schoolId}`,
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {}
};

export const update = (fundingBody) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await fundingBodiesApi.patch(
      "/update",
      {
        fundingBody,
      },
      {
        headers,
      }
    );

    dispatch({
      type: FUNDING_BODY_UPDATE_SUCCESS,
      payload: { ...fundingBody },
    });
  } catch (error) {
    toast.error("כישלון בעדכון גוף מימון");
  }
};
export const updatePaymentRequestStatus =
  (id, newStatus) => async (dispatch) => {
    try {
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await fundingBodiesApi.patch(
        "/updatePaymentRequestStatus",
        {
          id,
          newStatus,
        },
        {
          headers,
        }
      );
    } catch (error) {
      toast.error("כישלון בעדכון גוף דרישת תשלום");
    }
  };
export const sendPaymentRequest =
  (clientData, userData, invoiceData) => async (dispatch) => {
    try {
      const token = window.localStorage.getItem("token");

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await fundingBodiesApi.post(
        "/sendPaymentRequest",
        {
          clientData,
          userData,
          invoiceData,
        },
        {
          headers,
        }
      );
      return response.data.id;
    } catch (error) {
      toast.error("כישלון בעדכון גוף מימון");
    }
  };

export const deleteItem = (fundingBodyId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await fundingBodiesApi.delete(
      "/delete",
      {
        data: { fundingBodyId },
      },
      {
        headers,
      }
    );

    dispatch({
      type: FUNDING_BODY_DELETE_SUCCESS,
      payload: { id: fundingBodyId },
    });
  } catch (error) {
    toast.error("כישלון במחיקת גוף מימון");
  }
};
