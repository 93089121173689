import styled from "styled-components";

export const Container = styled.div`
  .react-datepicker-wrapper {
    height: 3vh;
  }
  height: 74vh;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FieldText = styled.span``;

export const Field = styled.div``;

export const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided, state) => ({
    ...provided,
    height: "3vh",
    border: "1px solid #e5e5e5",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#c2c2c2",
  }),
};
