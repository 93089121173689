import React, { PureComponent } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { connect } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import ErrorModal from "../Modal/ErrorModal";
import NewLesson from "./NewLesson/NewLesson";
import NewCharge from "./NewCharge/NewCharge";
import UpdatePickupLocation from "./UpdatePickupLocation/UpdatePickupLocation";
import NoteChange from "./NoteChange/NoteChange";
import EventStatusChange from "./EventStatusChange/EventStatusChange";
import TeacherChange from "./TeacherChange/TeacherChange";
import VehicleChange from "./VehicleChange/VehicleChange";
import Day from "react-big-calendar/lib/Day";
import StudentMenu from "./StudentMenu/StudentMenu";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Container,
  UserOptionsContainer,
  customStyles,
} from "./calendar.styles";
import {
  getEvents,
  getStudentEventCount,
  getAllSchoolEvents,
  loadHolidays,
  createEvent,
} from "../../actions/schedulerActions";
import { isMobileOnly, isTablet, isMobile } from "react-device-detect";
import { getStudents } from "../../actions/studentesActions";
import EventDayView from "./CalendarViews/EventDayView";
import EventBikeDayView from "./CalendarViews/EventBikeDayView";
import EventAgendaView from "./CalendarViews/EventAgendaView";
import EventMonthView from "./CalendarViews/EventMonthView";
import EventWorkWeekView from "./CalendarViews/EventWorkWeekView";
import EventWeekView from "./CalendarViews/EventWeekView";
import StudentBasicInfo from "./StudentBasicInfo/StudentBasicInfo";
import PrintCalendar from "./PrintCalendar/PrintCalendar";
import StudentSignature from "./StudentSignature/StudentSignature";
import { getUsers } from "../../actions/userActions";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/he";
import Select from "react-select";
import Button from "../UiComponents/Buttons/Default";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Toggle from "react-toggle";
import Input from "../UiComponents/Inputs/Default";
import { getVehicles } from "../../actions/vehicleActions";

const localizer = momentLocalizer(moment);
const messages = {
  allDay: "כל היום",
  previous: "הקודם",
  next: "הבא",
  today: "היום",
  month: "חודש",
  week: "שבוע",
  work_week: "שבוע עבודה",
  day: "יום",
  agenda: "אגנדה",
  date: "תאריך",
  time: "שעה",
  event: "אירוע",
  myweek: "יומי אופנועים",
};

class Calendara extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isNewLessonModalOpen: false,
      isStudentMenuModalOpen: false,
      isErrorModalOpen: false,
      errorModalText: "",
      isEditLessonModalOpen: false,
      isNewChargeModalOpen: false,
      isPickupModalOpen: false,
      isNotesModalOpen: false,
      teacherOptions: [],
      selectedTeacher: { value: -999, label: "הכל" },
      selectedVehicle: null,
      isEventStatusChangeModalOpen: false,
      isChangeTeacherModalOpen: false,
      isStudentSignatureModalOpen: false,
      isChangeVehicleModalOpen: false,
      blockText: "",
      isBlockActive: false,
      isPrintOpen: false,
      selectedEvent: null,
      defaultView: props.user.defaultView,
      currentDate: new Date(),
      students: [],
      vehicleOptions: [],
      chosenStudent: null,
      studentOptions: [],
      isStudentInfoShowOpen: !isTablet,
      views: {
        day: true,
        month: true,
        week: EventWorkWeekView,
        agenda: true,
      },
    };
  }

  componentWillMount = () => {
    if (this.props.user.showDailyBikes) {
      this.setState({ views: { ...this.state.views, myweek: Day } });
    }
  };

  componentDidMount = async () => {
    if (this.props.isLoggedIn) {
      if (!this.props.isHolidaysLoaded) {
        await this.props.loadHolidays();
      }
      if (!this.props.isUsersLoaded) {
        await this.props.getUsers();
      }
      if (!this.props.isVehiclesLoaded) {
        await this.props.getVehicles();
      }
      const vehicleOptions = this.props.vehicles
        .filter((x) => x.active === 1)
        .map((x) => ({ value: x.id, label: x.description }));
      const teacherOptions = [
        { value: -999, label: "הכל" },
        ...this.props.users
          .filter(
            (x) => (x.userType === 1 || x.userType === 4) && x.isActive === 1
          )
          .map((x) => ({
            value: x.id,
            label: `${x.firstName} ${x.lastName}`,
          })),
      ];
      this.setState({
        teacherOptions,
        vehicleOptions,
      });
      if (this.props.user.userType !== 555) {
        const calendarUser = window.localStorage.getItem("calendarUser");
        if (calendarUser && JSON.parse(calendarUser) !== null) {
          const parsedCalendarUser = JSON.parse(calendarUser);
          const index = this.props.users.findIndex(
            (x) => x.id === parsedCalendarUser[0].value
          );
          if (index === -1) {
            await this.handleTeacherChange([
              {
                value: this.props.session.user.id,
                label: `${this.props.session.user.firstName} ${this.props.session.user.lastName}`,
              },
            ]);
          } else {
            const localStorageDate = window.localStorage.getItem("date");
            if (localStorageDate) {
              this.setState({ currentDate: new Date(localStorageDate) });
            }
            await this.handleTeacherChange(parsedCalendarUser);
          }
        } else {
          await this.handleTeacherChange([
            {
              value: this.props.session.user.id,
              label: `${this.props.session.user.firstName} ${this.props.session.user.lastName}`,
            },
          ]);
        }
      }
      if (!this.props.isStudentsLoaded) {
        await this.props.getStudents();
        this.setState({
          studentOptions: this.props.students.map((x) => ({
            value: x.studentId,
            label: `${x.studentId} - ${x.firstName} ${x.lastName}`,
          })),
        });
      } else {
        console.log(this.props.students);
      }
    }
  };

  closeModal = (type) => this.setState({ [type]: false });
  openModal = (type, selectedEvent = null) =>
    this.setState({ [type]: true, selectedEvent });

  closeEditLessonModalOpen = () =>
    this.setState({ isEditLessonModalOpen: false });

  setEventColors = (eventType, eventStatus) => {
    const {
      fontLessonColor,
      lessonColor,
      doneLessonColor,
      fontDoneLessonColor,
      paidLessonColor,
      fontPaidLessonColor,
      canceledLessonColor,
      fontCanceledLessonColor,
      canceledAndPaidLessonColor,
      fontCanceledAndPaidLessonColor,
      testColor,
      fontTestColor,
      doneTestColor,
      fontDoneTestColor,
      paidTestColor,
      fontPaidTestColor,
      canceledTestColor,
      fontCanceledTestColor,
      canceledAndPaidTestColor,
      fontCanceledAndPaidTestColor,
      internalTestColor,
      fontInternalTestColor,
      doneInternalTestColor,
      fontDoneInternalTestColor,
      paidInternalTestColor,
      fontPaidInternalTestColor,
      canceledInternalTestColor,
      fontCanceledInternalTestColor,
      canceledAndPaidInternalTestColor,
      fontCanceledAndPaidInternalTestColor,
      meetingColor,
      fontMeetingColor,
      doneMeetingColor,
      fontDoneMeetingColor,
      canceledMeetingColor,
      fontCanceledMeetingColor,
    } = this.props.user;
    let color = "";
    let backgroundColor = "";
    if (eventType === 1) {
      const params = this.eventColors(
        lessonColor,
        fontLessonColor,
        doneLessonColor,
        fontDoneLessonColor,
        paidLessonColor,
        fontPaidLessonColor,
        canceledLessonColor,
        fontCanceledLessonColor,
        canceledAndPaidLessonColor,
        fontCanceledAndPaidLessonColor,
        eventStatus
      );

      backgroundColor = params.backgroundColor;
      color = params.color;
    } else if (eventType === 2) {
      const params = this.eventColors(
        testColor,
        fontTestColor,
        doneTestColor,
        fontDoneTestColor,
        paidTestColor,
        fontPaidTestColor,
        canceledTestColor,
        fontCanceledTestColor,
        canceledAndPaidTestColor,
        fontCanceledAndPaidTestColor,
        eventStatus
      );

      backgroundColor = params.backgroundColor;
      color = params.color;
    } else if (eventType === 3) {
      const params = this.eventColors(
        internalTestColor,
        fontInternalTestColor,
        doneInternalTestColor,
        fontDoneInternalTestColor,
        paidInternalTestColor,
        fontPaidInternalTestColor,
        canceledInternalTestColor,
        fontCanceledInternalTestColor,
        canceledAndPaidInternalTestColor,
        fontCanceledAndPaidInternalTestColor,
        eventStatus
      );

      backgroundColor = params.backgroundColor;
      color = params.color;
    } else if (eventType === 4) {
      const params = this.eventColors(
        meetingColor,
        fontMeetingColor,
        doneMeetingColor,
        fontDoneMeetingColor,
        doneMeetingColor,
        fontDoneMeetingColor,
        canceledMeetingColor,
        fontCanceledMeetingColor,
        canceledMeetingColor,
        fontCanceledMeetingColor,
        eventStatus
      );
      backgroundColor = params.backgroundColor;
      color = params.color;
    }

    return { backgroundColor, color };
  };

  eventColors = (
    orderBackground,
    orderFontColor,
    doneBackground,
    doneFontColor,
    paidBackground,
    paidFontColor,
    canceledBackground,
    canceledFontColor,
    canceledAndPaidBackground,
    canceledAndPaidPaidFontColor,
    eventStatus
  ) => {
    if (eventStatus === 0) {
      return { backgroundColor: orderBackground, color: orderFontColor };
    } else if (eventStatus === 1) {
      return { backgroundColor: doneBackground, color: doneFontColor };
    } else if (eventStatus === 2) {
      return { backgroundColor: paidBackground, color: paidFontColor };
    } else if (eventStatus === 3) {
      return { backgroundColor: canceledBackground, color: canceledFontColor };
    } else if (eventStatus === 4) {
      return {
        backgroundColor: canceledAndPaidBackground,
        color: canceledAndPaidPaidFontColor,
      };
    }
  };
  eventStyleGetter = (event, start, end, isSelected) => {
    const b = this.setEventColors(event.eventType, event.eventStatus);
    var style = {
      borderRadius: "0px",
      border: "0px",
      display: "block",
      borderLeft: "3px solid",
      borderTop: "1px solid",
      borderRight: "1px solid",
      ...b,
    };
    return {
      style: style,
    };
  };

  handleTeacherChange = async (selectedTeacher) => {
    this.setState({ selectedTeacher });
    if (selectedTeacher) {
      window.localStorage.setItem(
        "calendarUser",
        JSON.stringify(selectedTeacher)
      );

      if (
        !selectedTeacher ||
        selectedTeacher.findIndex((x) => x.value === -999) !== -1
      ) {
        await this.props.getAllSchoolEvents();
      } else {
        await this.props.getEvents(selectedTeacher.map((x) => x.value));
      }
    }
  };

  handleVehicleChange = async (selectedVehicle) => {
    this.setState({ selectedVehicle });
  };

  createBlockEvent = async (slot) => {
    const { blockText, selectedTeacher, teacherOptions } = this.state;
    const isAllIndex = selectedTeacher.findIndex((x) => x.value === -999);
    let teacherList = selectedTeacher;
    if (isAllIndex !== -1) {
      teacherList = teacherOptions;
    }
    const eventsToCreate = [];
    for (let index = 0; index < teacherList.length; index++) {
      const element = teacherList[index];
      eventsToCreate.push(
        this.props.createEvent({
          notes: blockText,
          eventType: { value: 4, label: "פגישה" },
          updateUsername: `${this.props.user.firstName} ${this.props.user.lastName}`,
          teacherId: element.value,
          pickupLocationId: { value: 999, label: "ללא" },
          start: slot.start,
          end: slot.end,
        })
      );
    }

    await Promise.all(eventsToCreate);
  };
  render() {
    const {
      isNewLessonModalOpen,
      selectedEvent,
      isPickupModalOpen,
      defaultView,
      isEventStatusChangeModalOpen,
      currentDate,
      isErrorModalOpen,
      errorModalText,
      teacherOptions,
      selectedTeacher,
      isNotesModalOpen,
      isStudentMenuModalOpen,
      isNewChargeModalOpen,
      studentOptions,
      chosenStudent,
      isChangeTeacherModalOpen,
      isChangeVehicleModalOpen,
      isStudentInfoShowOpen,
      isPrintOpen,
      views,
      selectedVehicle,
      isStudentSignatureModalOpen,
      vehicleOptions,
    } = this.state;
    const { events, holidays, user } = this.props;
    const filteredEvents =
      selectedVehicle === null || selectedVehicle.length === 0
        ? [...events, ...holidays]
        : [
            ...events.filter(
              (x) =>
                selectedVehicle
                  .map((x) => x.value.toString())
                  .indexOf(x.vehicleId) !== -1
            ),
            ...holidays,
          ];
    return (
      <Container>
        <UserOptionsContainer>
          {user.blockEvents ? (
            <div
              style={{
                width: !isMobile && "9vw",
                display: "flex",
                alignItems: "center",
                marginRight: "0.5vw",
                flexDirection: isMobile ? "row" : "column",
              }}
            >
              <label htmlFor="cheese-status" style={{ margin: 0 }}>
                חסימות
              </label>
              <Toggle
                id="cheese-status"
                defaultChecked={false}
                onChange={() =>
                  this.setState({ isBlockActive: !this.state.isBlockActive })
                }
              />
              <Input
                value={this.state.blockText}
                onChange={(e) => this.setState({ blockText: e.target.value })}
                placeholder="סיבת חסימה"
              />
            </div>
          ) : null}
          <div>
            {user.createStudentIncome ? (
              <Button
                text="מסמך כספי"
                onClick={() => this.setState({ isNewChargeModalOpen: true })}
                marginRight="1"
              />
            ) : null}
            <Button
              text="יצירת אירוע"
              onClick={() =>
                this.openModal("isNewLessonModalOpen", {
                  start: new Date(),
                  slots: [{}, {}],
                })
              }
              marginRight="1"
            />
            <Button
              text="הדפסת יומן"
              onClick={() =>
                this.openModal("isPrintOpen", {
                  events,
                })
              }
              marginRight="1"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "row" : "column",
              width: isMobile ? "100%" : "30%",
            }}
          >
            {this.props.user.userType === 1 ? null : (
              <Select
                options={teacherOptions}
                isMulti
                value={selectedTeacher}
                placeholder="מורה"
                styles={customStyles}
                isRtl={true}
                onChange={this.handleTeacherChange}
              />
            )}
            {this.props.user.userType === 1 ? null : (
              <Select
                options={vehicleOptions}
                isMulti
                value={selectedVehicle}
                placeholder="רכב"
                styles={customStyles}
                isRtl={true}
                onChange={this.handleVehicleChange}
              />
            )}
          </div>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={currentDate}
            onChange={(date) => this.setState({ currentDate: date })}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "0.5vw",
              flexDirection: isMobile ? "row" : "column",
            }}
          >
            {!isMobileOnly && isTablet && this.props.students.length > 0 && (
              <label htmlFor="cheese-status" style={{ margin: 0 }}>
                הצג פרטי תלמיד
              </label>
            )}
            {!isMobileOnly && isTablet && this.props.students.length > 0 && (
              <Toggle
                id="cheese-status"
                defaultChecked={this.state.isStudentInfoShowOpen}
                onChange={() =>
                  this.setState({
                    isStudentInfoShowOpen: !this.state.isStudentInfoShowOpen,
                  })
                }
              />
            )}
            {!isMobileOnly && this.props.students.length > 0 && (
              <Autocomplete
                id="combo-box-demo"
                options={this.props.students
                  .filter((x) => x.active === 1)
                  .map((x) => ({
                    value: x.studentId,
                    label: `${x.studentId} - ${x.firstName} ${x.lastName}`,
                  }))}
                getOptionLabel={(option) => option.label}
                value={chosenStudent}
                onChange={(event, value) =>
                  this.setState({ chosenStudent: value })
                }
                style={{ width: !isMobile ? "12vw" : !isMobileOnly && "40vw" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    label="בחר תלמיד"
                    variant="outlined"
                  />
                )}
              />
            )}
          </div>
        </UserOptionsContainer>
        <Calendar
          popup
          views={views}
          messages={messages}
          localizer={localizer}
          workWeekStart={0}
          culture="he"
          showAllEvents={true}
          components={{
            myweek: {
              event: (props) =>
                !props.event.allDay ? (
                  <EventBikeDayView
                    event={props.event}
                    isMargin={true}
                    chosenStudentId={chosenStudent?.value}
                    step={user.calendarSpacingInMinutes}
                    openPickupModal={(eventId) =>
                      this.openModal("isPickupModalOpen", { eventId })
                    }
                    openEventStatusChangeModal={(event) =>
                      this.openModal("isEventStatusChangeModalOpen", event)
                    }
                    openStudentMenuModal={(event) =>
                      this.openModal("isStudentMenuModalOpen", event)
                    }
                    openNotesChangeModal={(eventId) =>
                      this.openModal("isNotesModalOpen", { eventId })
                    }
                    getStudentEventCount={this.props.getStudentEventCount}
                    onStudentClick={(value) =>
                      this.setState({ chosenStudent: value })
                    }
                  />
                ) : (
                  <div style={{ textAlign: "center" }}>{props.event.title}</div>
                ),
            },
            agenda: {
              event: (props) =>
                !props.event.allDay ? (
                  <EventAgendaView
                    event={props.event}
                    isMargin={true}
                    chosenStudentId={chosenStudent?.value}
                    step={user.calendarSpacingInMinutes}
                    openPickupModal={(eventId) =>
                      this.openModal("isPickupModalOpen", { eventId })
                    }
                    openSignatureModal={(eventId) =>
                      this.openModal("isStudentSignatureModalOpen", { eventId })
                    }
                    openEventStatusChangeModal={(event) =>
                      this.openModal("isEventStatusChangeModalOpen", event)
                    }
                    openStudentMenuModal={(event) =>
                      this.openModal("isStudentMenuModalOpen", event)
                    }
                    openNotesChangeModal={(eventId) =>
                      this.openModal("isNotesModalOpen", { eventId })
                    }
                    getStudentEventCount={this.props.getStudentEventCount}
                  />
                ) : (
                  <div style={{ textAlign: "center" }}>{props.event.title}</div>
                ),
            },
            day: {
              event: (props) =>
                !props.event.allDay ? (
                  <EventDayView
                    event={props.event}
                    chosenStudentId={chosenStudent?.value}
                    step={user.calendarSpacingInMinutes}
                    openPickupModal={(eventId) =>
                      this.openModal("isPickupModalOpen", { eventId })
                    }
                    openEventStatusChangeModal={(event) =>
                      this.openModal("isEventStatusChangeModalOpen", event)
                    }
                    openTeacherChange={(event) =>
                      this.openModal("isChangeTeacherModalOpen", event)
                    }
                    openVehicleChange={(event) =>
                      this.openModal("isChangeVehicleModalOpen", event)
                    }
                    openNotesChangeModal={(eventId) =>
                      this.openModal("isNotesModalOpen", { eventId })
                    }
                    openStudentMenuModal={(event) =>
                      this.openModal("isStudentMenuModalOpen", event)
                    }
                    getStudentEventCount={this.props.getStudentEventCount}
                    onStudentClick={(value) =>
                      this.setState({ chosenStudent: value })
                    }
                  />
                ) : (
                  <div style={{ textAlign: "center" }}>{props.event.title}</div>
                ),
            },
            month: {
              event: (props) =>
                !props.event.allDay ? (
                  <EventMonthView
                    event={props.event}
                    chosenStudentId={chosenStudent?.value}
                    step={user.calendarSpacingInMinutes}
                  />
                ) : (
                  <div style={{ textAlign: "center" }}>{props.event.title}</div>
                ),
            },
            week: {
              event: (props) =>
                !props.event.allDay ? (
                  <EventWeekView
                    chosenStudentId={chosenStudent?.value}
                    isBlockActive={this.state.isBlockActive}
                    event={props.event}
                    step={user.calendarSpacingInMinutes}
                    openNotesChangeModal={(eventId) =>
                      this.openModal("isNotesModalOpen", { eventId })
                    }
                    openEventStatusChangeModal={(event) =>
                      this.openModal("isEventStatusChangeModalOpen", event)
                    }
                    onStudentClick={(value) =>
                      this.setState({ chosenStudent: value })
                    }
                  />
                ) : (
                  <div style={{ textAlign: "center" }}>{props.event.title}</div>
                ),
            },
          }}
          events={filteredEvents}
          step={user.calendarSpacingInMinutes}
          timeslots={1}
          selectable
          startAccessor="start"
          endAccessor="end"
          rtl={true}
          slotEventOverlap={false}
          onNavigate={(date) => {
            this.setState({ currentDate: date });
            window.localStorage.setItem("date", date);
          }}
          view={defaultView}
          onView={(e) => this.setState({ defaultView: e })}
          date={currentDate}
          length={0.5}
          min={
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate(),
              new Date(user.calendarStartTime).getHours()
            )
          }
          max={
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate(),
              new Date(user.calendarEndTime).getHours()
            )
          }
          eventPropGetter={this.eventStyleGetter}
          style={{ height: !isMobile && "82.9vh" }}
          onSelectSlot={(e) =>
            typeof e.resourceId !== "undefined" &&
            (this.state.isBlockActive
              ? this.createBlockEvent(e)
              : this.openModal("isNewLessonModalOpen", e))
          }
        />
        {isNewLessonModalOpen && (
          <NewLesson
            isNewAgreementModalOpen={isNewLessonModalOpen}
            setNewAgreementModalIsOpen={() =>
              this.closeModal("isNewLessonModalOpen")
            }
            selectedEvent={selectedEvent}
            step={user.eventTime}
            studentFromCalendar={chosenStudent?.value}
          />
        )}
        {isNotesModalOpen && (
          <NoteChange
            isNewAgreementModalOpen={isNotesModalOpen}
            setNewAgreementModalIsOpen={() =>
              this.closeModal("isNotesModalOpen")
            }
            selectedEvent={selectedEvent}
          />
        )}
        {isErrorModalOpen && (
          <ErrorModal
            modalIsOpen={isErrorModalOpen}
            closeModal={() => this.closeModal("isErrorModalOpen")}
            text={errorModalText}
          />
        )}
        {isPickupModalOpen && (
          <UpdatePickupLocation
            isNewAgreementModalOpen={isPickupModalOpen}
            setNewAgreementModalIsOpen={() =>
              this.closeModal("isPickupModalOpen")
            }
            selectedEvent={selectedEvent}
            step={user.calendarSpacingInMinutes}
          />
        )}
        {isStudentMenuModalOpen && (
          <StudentMenu
            isNewAgreementModalOpen={isStudentMenuModalOpen}
            setNewAgreementModalIsOpen={() =>
              this.closeModal("isStudentMenuModalOpen")
            }
            selectedEvent={selectedEvent}
          />
        )}
        {isStudentSignatureModalOpen && (
          <StudentSignature
            isNewAgreementModalOpen={isStudentSignatureModalOpen}
            setNewAgreementModalIsOpen={() =>
              this.closeModal("isStudentSignatureModalOpen")
            }
            selectedEvent={selectedEvent}
          />
        )}
        {isEventStatusChangeModalOpen && (
          <EventStatusChange
            isNewAgreementModalOpen={isEventStatusChangeModalOpen}
            setNewAgreementModalIsOpen={() =>
              this.closeModal("isEventStatusChangeModalOpen")
            }
            selectedEvent={selectedEvent}
            step={user.calendarSpacingInMinutes}
          />
        )}
        {isChangeTeacherModalOpen && (
          <TeacherChange
            isNewAgreementModalOpen={isChangeTeacherModalOpen}
            setNewAgreementModalIsOpen={() =>
              this.closeModal("isChangeTeacherModalOpen")
            }
            selectedEvent={selectedEvent}
          />
        )}
        {isChangeVehicleModalOpen && (
          <VehicleChange
            isNewAgreementModalOpen={isChangeVehicleModalOpen}
            setNewAgreementModalIsOpen={() =>
              this.closeModal("isChangeVehicleModalOpen")
            }
            selectedEvent={selectedEvent}
          />
        )}
        {isNewChargeModalOpen && (
          <NewCharge
            isNewAgreementModalOpen={isNewChargeModalOpen}
            setNewAgreementModalIsOpen={() =>
              this.closeModal("isNewChargeModalOpen")
            }
          />
        )}
        {isPrintOpen && (
          <PrintCalendar
            isNewAgreementModalOpen={isPrintOpen}
            setNewAgreementModalIsOpen={() => this.closeModal("isPrintOpen")}
            events={events}
            teachers={selectedTeacher}
            currentDate={currentDate}
          />
        )}
        {chosenStudent !== null && isStudentInfoShowOpen && (
          <StudentBasicInfo
            studentId={chosenStudent.value}
            close={() => this.closeModal("isStudentInfoShowOpen")}
            handleTeacherChange={this.handleTeacherChange}
            dateChange={(date) => this.setState({ currentDate: date })}
            openStudentMenuModal={(event) =>
              this.openModal("isStudentMenuModalOpen", event)
            }
          />
        )}
      </Container>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    vehicles: state.VehiclesReducer.vehicles,
    isLoggedIn: state.session.isLoggedIn,
    userId: state.session.user.id,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    showDailyBikes: state.session.user.showDailyBikes,
    events: state.CalendarReducer.events,
    holidays: state.CalendarReducer.holidays,
    isHolidaysLoaded: state.CalendarReducer.isHolidaysLoaded,
    isUsersLoaded: state.UsersReducer.isLoaded,
    users: state.UsersReducer.users,
    isStudentsLoaded: state.StudentsReducer.isLoaded,
    user: state.session.user,
    students: state.StudentsReducer.students,
  };
}

export default connect(mapStateToProps, {
  getUsers,
  getEvents,
  getStudentEventCount,
  getAllSchoolEvents,
  loadHolidays,
  getStudents,
  createEvent,
  getVehicles,
})(Calendara);
