import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  FieldText,
  Field,
  Table,
} from "./activityReport.styles";
import { connect } from "react-redux";
import { getAll } from "../../../actions/drivingPermits";
import { getAllSchoolTestsEvents } from "../../../actions/schedulerActions";
import i18n from "../../../lib/i18n/i18n";
import DatePicker from "react-datepicker";
import moment from "moment";
import _ from "lodash";

const ActivityReport = ({
  getAllSchoolTestsEvents,
  drivingPermits,
  getAll,
}) => {
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [endDate, setEndDate] = useState(new Date());
  const [permitsTableData, setPermitsTableData] = useState({});
  const [tableData, setTableData] = useState({
    internal: { passed: 0, failed: 0, unknown: 0 },
    test: { passed: 0, failed: 0, unknown: 0 },
  });
  useEffect(() => {
    (async () => {
      if (drivingPermits.length === 0) {
        await getAll();
      }
    })();
  }, [startDate, endDate]);

  useEffect(() => {
    (async () => {
      if (drivingPermits.length > 0) {
        const testEvents = await getAllSchoolTestsEvents(startDate, endDate);
        buildDataArray(testEvents);
      }
    })();
  }, [startDate, endDate, drivingPermits]);

  const buildDataArray = (testEvents) => {
    let arrayData = [];
    console.log(drivingPermits);
    const b = _.groupBy(testEvents, "studentId");
    const permits = _.groupBy(drivingPermits, "id");
    const permitsIds = Object.keys(permits);
    let elem = [];
    for (let index = 0; index < permitsIds.length; index++) {
      const element = permitsIds[index];
      for (let index = 0; index < testEvents.length; index++) {
        const testEvent = testEvents[index];
        if (
          testEvent.drivingPermitId !== null &&
          testEvent.drivingPermitId.toString() === element
        ) {
          if (typeof elem[element] === "undefined") {
            elem[element] = 0;
          } else {
            elem[element] += 1;
          }
        }
      }
    }
    setPermitsTableData(elem);
    const passed = testEvents.filter((x) => x.eventStatus === 1);
    const failed = testEvents.filter((x) => x.eventStatus === 2);
    const unkown = testEvents.filter((x) => x.eventStatus === 0);
    const passedInternal = passed.filter((x) => x.eventType === 3).length;
    const failedInternal = failed.filter((x) => x.eventType === 3).length;
    const unkownInternal = unkown.filter((x) => x.eventType === 3).length;
    const passedTest = passed.filter((x) => x.eventType === 2).length;
    const failedTest = failed.filter((x) => x.eventType === 2).length;
    const unkownTest = unkown.filter((x) => x.eventType === 2).length;
    setTableData({
      internal: {
        passed: passedInternal,
        failed: failedInternal,
        unknown: unkownInternal,
      },
      test: { passed: passedTest, failed: failedTest, unknown: unkownTest },
    });
  };
  return (
    <Container>
      <Row>
        <>
          <Field>
            <FieldText>תאריך סוף הדוח</FieldText>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              locale={i18n.language}
              startDate={startDate}
              dateFormat="dd/MM/yyyy"
              endDate={endDate}
              minDate={startDate}
            />
          </Field>
          <Field>
            <FieldText>תאריך התחלת הדוח</FieldText>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              dateFormat="dd/MM/yyyy"
              locale={i18n.language}
              endDate={endDate}
            />
          </Field>
        </>
      </Row>
      <Table>
        <thead>
          <tr>
            <th> </th>
            {["סהכ", "עברו", "נכשלו", "לא ידוע"].map((x, i) => {
              return <th>{x}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>מבחן מעשי</td>
            <td>
              {tableData.internal.unknown +
                tableData.internal.passed +
                tableData.internal.failed}
            </td>
            <td>{tableData.internal.passed}</td>
            <td>{tableData.internal.failed}</td>
            <td>{tableData.internal.unknown}</td>
          </tr>
          <tr>
            <td>מבחן פנימי</td>
            <td>
              {tableData.test.unknown +
                tableData.test.passed +
                tableData.test.failed}
            </td>
            <td>{tableData.test.passed}</td>
            <td>{tableData.test.failed}</td>
            <td>{tableData.test.unknown}</td>
          </tr>
        </tbody>
      </Table>
      <br />
      <Table>
        <thead>
          <tr>
            <th> </th>
            <th>תלמידים </th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(permitsTableData).map((x) => {
            return (
              <tr>
                <td>
                  {drivingPermits.find((y) => y.id.toString() === x)?.name}
                </td>
                <td>{permitsTableData[x]}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isLoggedIn: state.session.isLoggedIn,
    userId: state.session.user.id,
    drivingPermits: state.DrivingPermitsReducer.drivingPermits,
    user: state.session.user,
  };
}

export default connect(mapStateToProps, {
  getAll,
  getAllSchoolTestsEvents,
})(ActivityReport);
