import React from "react";
import { Field, reduxForm } from "redux-form";
import i18n from "../../../../lib/i18n/i18n";
import Toggle from "react-toggle";
import {
  ToggleContainer,
  ToggleText,
  AdditionalDetailsContainer,
  Fields,
} from "../permissions.styles";
import InputWrappedWithField from "../../../UiComponents/Inputs/DefaultWrappedWithField";

const renderToggle = ({ input, label }) => {
  const isHebrew = i18n.language === "he";
  return (
    <ToggleContainer isRtl={isHebrew}>
      <ToggleText>{label}</ToggleText>
      <Toggle checked={input.value} onChange={input.onChange} />
    </ToggleContainer>
  );
};

const CalendarPermissions = () => {
  return (
    <Fields>
      <AdditionalDetailsContainer>
        <Field
          label="מעקף חסימת אובליגו"
          component={renderToggle}
          name="allowBypassObligo"
        />
        <Field label="חסימות" component={renderToggle} name="blockEvents" />
        <Field
          label="ביטול מ.פנימי"
          component={renderToggle}
          name="cancelInternalTest"
        />
        <Field label="ביטול טסט" component={renderToggle} name="cancelTest" />
        <Field
          label="ביטול שיעור"
          component={renderToggle}
          name="cancelLesson"
        />
        <Field
          label="ביטול פגישה"
          component={renderToggle}
          name="cancelMeetings"
        />
        <Field
          label="קביעת טסט ללא בדיקת כרטסת"
          component={renderToggle}
          name="isAllowedTestWithoutCardCheck"
        />
        <Field
          label="קביעת מ.פ ללא בדיקת כרטסת"
          component={renderToggle}
          name="isAllowedInternalTestWithoutCardCheck"
        />
        <Field
          label="קביעת טסט"
          component={renderToggle}
          name="isAllowedSettingTest"
        />
        <Field
          label="קביעת מ.פ"
          component={renderToggle}
          name="isAllowedSettingInternalTest"
        />
        <Field
          label="קביעת שיעור"
          component={renderToggle}
          name="isAllowedSettingLesson"
        />
        <Field
          label="קביעת פגישה"
          component={renderToggle}
          name="isAllowedSettingMeetings"
        />
        <Field
          label="פצל שיעורים"
          component={renderToggle}
          name="isSplitLessons"
        />
        <Field
          placeholder="שיעור - ביצוע אוטומטי"
          component={InputWrappedWithField}
          name="autoCompleteLesson"
        />
        <Field
          placeholder="טסט פנימי - ביצוע אוטומטי"
          component={InputWrappedWithField}
          name="autoCompleteInternalTest"
        />
        <Field
          placeholder="טסט - ביצוע אוטומטי"
          component={InputWrappedWithField}
          name="autoCompleteTest"
        />
        <Field
          placeholder="פגישה - ביצוע אוטומטי"
          component={InputWrappedWithField}
          name="autoCompleteAppointment"
        />
      </AdditionalDetailsContainer>
    </Fields>
  );
};

export default reduxForm({
  form: "permissions", // a unique identifier for this form
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  enableReinitialize: true, // this is needed!!
})(CalendarPermissions);
