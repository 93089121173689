import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import i18n from "../../../../../../lib/i18n/i18n";
import { useTranslation } from "react-i18next";
import {
  Body,
  AdditionalDetailsContainer,
  Button,
  Fields,
  ButtonContainer,
} from "./dates.styles";
import { updateTextBookDates } from "../../../../../../actions/textBooksActions";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const TextBookGeneral = ({
  handleSubmit,
  renderDatePickerField,
  updateTextBookDates,
  setIsLoading,
  closeModal,
}) => {
  const { t, i18n } = useTranslation("common");
  const userCreateAction = async (formValues) => {
    setIsLoading(true);
    await updateTextBookDates(formValues);
    setIsLoading(false);
    closeModal();
  };
  return (
    <Body onSubmit={handleSubmit(userCreateAction)}>
      <Fields>
        <AdditionalDetailsContainer>
          <Field
            name="documentCheck"
            component={renderDatePickerField}
            placeholder="הזנה בברוש"
          />
          <Field
            name="formEntry"
            component={renderDatePickerField}
            placeholder="הזנת מסמכים"
          />
        </AdditionalDetailsContainer>
        <AdditionalDetailsContainer>
          <Field
            name="eyeExamination"
            component={renderDatePickerField}
            placeholder="בדיקת עיניים"
          />
          <Field
            name="theory"
            component={renderDatePickerField}
            placeholder="תאוריה"
          />
          <Field
            name="medicalExamination"
            component={renderDatePickerField}
            placeholder="בדיקה רפואית"
          />
        </AdditionalDetailsContainer>
      </Fields>
      <ButtonContainer>
        <Button type="submit">{t("buttons.save")}</Button>
      </ButtonContainer>
    </Body>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    initialValues: {
      ...ownProps.textBookData,
      eyeExamination: ownProps.textBookData.eyeExamination
        ? ownProps.textBookData.eyeExamination
        : null,
      documentCheck: ownProps.textBookData.documentCheck
        ? ownProps.textBookData.documentCheck
        : null,
      formEntry: ownProps.textBookData.formEntry
        ? ownProps.textBookData.formEntry
        : null,
      theory: ownProps.textBookData.theory
        ? ownProps.textBookData.theory
        : null,
      medicalExamination: ownProps.textBookData.medicalExamination
        ? ownProps.textBookData.medicalExamination
        : null,
    },
  };
}

export default connect(mapStateToProps, { updateTextBookDates })(
  reduxForm({
    form: "textBookDates", // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize: true,
  })(TextBookGeneral)
);
