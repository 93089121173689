import vehiclesApi from "../apis/vehicles";
import {
  VEHICLE_FETCH_FAILED,
  VEHICLE_FETCH_STARTED,
  VEHICLE_FETCH_SUCCESS,
  VEHICLE_CREATE_SUCCESS,
  VEHICLE_UPDATE_SUCCESS,
  VEHICLE_DELETE_SUCCESS,
} from "../constants/actionTypes";
import { toast } from "react-toastify";

//TODO: added teacherId and schoolId
export const createVehicle = (vehicle, reset) => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const { branchId, schoolId } = getState().session.user;

    const response = await vehiclesApi.post(
      "/create",
      {
        vehicle: { ...vehicle, branchId, schoolId },
      },
      {
        headers,
      }
    );
    dispatch({
      type: VEHICLE_CREATE_SUCCESS,
      payload: { ...vehicle, id: response.data.id },
    });
    reset();
  } catch (error) {
    toast.error("כישלון ביצירת רכב");
  }
};

export const getVehicles = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: VEHICLE_FETCH_STARTED,
    });
    const token = window.localStorage.getItem("token");
    const { branchId, schoolId } = getState().session.user;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await vehiclesApi.get(
      `/allVehicles/${branchId}/${schoolId}`,
      {
        headers,
      }
    );

    dispatch({
      type: VEHICLE_FETCH_SUCCESS,
      payload: response.data,
    });

    return response.data;
  } catch (error) {
    dispatch({
      type: VEHICLE_FETCH_FAILED,
    });
  }
};

export const updateVehicle = (vehicle, vehicleId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await vehiclesApi.patch(
      "/updateOne",
      {
        vehicle,
        vehicleId,
      },
      {
        headers,
      }
    );

    dispatch({
      type: VEHICLE_UPDATE_SUCCESS,
      payload: { ...vehicle, licenseType: vehicle.licenseType.value },
    });
  } catch (error) {
    toast.error("כישלון בעדכון רכב");
  }
};

export const getVehicleScheduler = (vehicleId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await vehiclesApi.get(`/vehicleScheduler/${vehicleId}`, {
      headers,
    });

    return response.data[0];
    // dispatch({
    //   type: VEHICLE_FETCH_SUCCESS,
    //   payload: response.data,
    // });
  } catch (error) {
    toast.error("כישלון בעדכון רכב");
  }
};

export const getVehicle = (vehicleId) => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { branchId, schoolId } = getState().session.user;

    const response = await vehiclesApi.get(`/fetchVehicle/${vehicleId}`, {
      headers,
    });
    return response.data;
  } catch (error) {}
};

export const setVehicleDisabled = (vehicleId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await vehiclesApi.patch(
      "/disable",
      {
        vehicleId,
      },
      {
        headers,
      }
    );
    dispatch({
      type: VEHICLE_UPDATE_SUCCESS,
      payload: { id: vehicleId, active: 2 },
    });
  } catch (error) {
    toast.error("כישלון בביטול רכב");
  }
};

export const deleteVehicle = (vehicleId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await vehiclesApi.delete(
      "/deleteVehicle",
      {
        data: { vehicleId },
      },
      {
        headers,
      }
    );

    dispatch({
      type: VEHICLE_DELETE_SUCCESS,
      payload: { id: vehicleId },
    });
  } catch (error) {
    toast.error("כישלון במחיקת הרכב");
  }
};
