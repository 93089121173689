import React, { useState, useEffect } from "react";
import { Container, Title, CreateButton, TitleText } from "./agreements.styles";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import i18n from "../../../lib/i18n/i18n";
import NewAgreement from "./NewAgreement/NewAgreement";
import Table from "../../SivanPlusTable/SivanPlusTable";
import {
  getAgreements,
  getAgreementDetails,
} from "../../../actions/agreementsActions";
import FadeLoader from "react-spinners/FadeLoader";
import EditAgreement from "./EditAgreement/EditAgreement";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const agreementsColumns = [
  {
    Header: dictionary.table.description,
    accessor: "description",
  },
  {
    Header: dictionary.table.price,
    accessor: "price",
  },
  {
    Header: dictionary.table.active,
    accessor: "status",
    Cell: ({ row: { original } }) => {
      return original.active === 1
        ? dictionary.users.active
        : dictionary.users.inactive;
    },
  },
];

const Agreement = ({
  getAgreements,
  getAgreementDetails,
  agreements,
  isLoaded,
  createagreement,
  updateagreement,
}) => {
  const [isNewAgreementModalOpen, setNewAgreementModalIsOpen] = useState(false);
  const [isEditAgreementModalOpen, setEditAgreementModalIsOpen] = useState(
    false
  );
  const { t, i18n } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);
  const [agreementData, setAgreementData] = useState({});

  useEffect(() => {
    async function fetchData() {
      if (!isLoaded) {
        setIsLoading(true);
        await getAgreements();
      }
      setIsLoading(false);
    }
    fetchData();
  }, []);

  const onRowClick = async (rowObject) => {
    if (updateagreement) {
      const agreementDetails = await getAgreementDetails(rowObject.original.id);
      setAgreementData({ ...rowObject.original, ...agreementDetails });
      setEditAgreementModalIsOpen(true);
    }
  };

  return (
    <Container>
      {isLoading && (
        <div
          style={{
            height: "78%",
            width: "74%",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FadeLoader size={100} color={"#123abc"} loading={true} />
        </div>
      )}
      {setNewAgreementModalIsOpen && (
        <NewAgreement
          isNewAgreementModalOpen={isNewAgreementModalOpen}
          setNewAgreementModalIsOpen={setNewAgreementModalIsOpen}
        />
      )}
      {isEditAgreementModalOpen && (
        <EditAgreement
          isEditAgreementModalOpen={isEditAgreementModalOpen}
          setEditAgreementModalIsOpen={setEditAgreementModalIsOpen}
          agreementData={agreementData}
        />
      )}
      <Title>
        <TitleText>{t("agreements.title")}</TitleText>
        {createagreement ? (
          <CreateButton onClick={() => setNewAgreementModalIsOpen(true)}>
            {t("agreements.createNew")}
          </CreateButton>
        ) : null}
      </Title>
      <Table
        data={agreements}
        componentColumns={agreementsColumns}
        onRowClick={onRowClick}
      />
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    agreements: state.AgreementsReducer.agreements,
    isLoaded: state.AgreementsReducer.isLoaded,
    createagreement: state.session.user.createagreement,
    updateagreement: state.session.user.updateagreement,
  };
}

export default connect(mapStateToProps, { getAgreements, getAgreementDetails })(
  Agreement
);
