import React, { PureComponent } from "react";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import SivanPlusLogo from "./sivanLogo.png";
import { useTranslation } from "react-i18next";
import {
  Container,
  Logo,
  LogoContainer,
  LoggedUserContainer,
  LoggedUserName,
} from "./header.styles";

const Header = ({ user }) => {
  const { t, i18n } = useTranslation("common");
  return (
    <Container isRtl={i18n.language === "he"}>
      <LogoContainer>
        <Logo src={SivanPlusLogo} alt="Logo" />
      </LogoContainer>
      <LoggedUserContainer isRtl={i18n.language === "he"}>
        <LoggedUserName></LoggedUserName>
        <LoggedUserName dir="auto"> בית ספר: {user.schoolName}</LoggedUserName>
        <LoggedUserName>
          {user.firstName} {user.lastName}
        </LoggedUserName>
      </LoggedUserContainer>
      <LogoContainer>
        <i
          class="fa fa-shopping-cart"
          style={{
            fontSize: "2vh",
            cursor: "pointer",
            top: 0,
            left: "17vw",
            position: isMobile && "absolute",
          }}
          onClick={() => window.open("https://sivan-school.com/board")}
        />
      </LogoContainer>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.session.user,
  };
}

export default connect(mapStateToProps)(Header);
