import styled from "styled-components";
import i18n from "../../../lib/i18n/i18n";
import { isMobile } from "react-device-detect";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const Body = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  .sigCanvas {
    border: 1px solid black;
  }
`;

export const Button = styled.div`
  width: ${isMobile ? "100%" : "14vw"};
  text-align: center;
  height: 3vh;
  background-image: linear-gradient(to bottom, #ebd794, #d2ab51);
  font-size: 15pt;
  cursor: pointer;
  margin-top: 0.5vh;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-image: linear-gradient(to bottom, #ebd794, #b5913e);
  }
`;

export const customStyles = {
  container: (provided, state) => ({
    ...provided,
    marginTop: "1vh",
    width: "100%",
  }),
  control: (provided, state) => ({
    ...provided,
    height: "6vh",
    border: "1px solid #e5e5e5",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#c2c2c2",
    [isHebrew ? "paddingRight" : "paddingLeft"]: "0.5vw",
  }),
};
