import React, { useState, useEffect } from "react";
import i18n from "../../../lib/i18n/i18n";
import { connect } from "react-redux";
import { Container, Title } from "./incomesFromStudents.styles";
import IncomeData from "./IncomeData/IncomeData";
import { getAll } from "../../../actions/incomesAction";
import Table from "../../SivanPlusTable/SivanPlusTable";
import CreateButton from "../../UiComponents/Buttons/Default";
import NewCharge from "../../Calendar/NewCharge/NewCharge";
import CreditCardBill from "./CreditCardBill/CreditCardBill";
import { DateColumnFilter } from "../../SivanPlusTable/filters";
import moment from "moment";
import useTeacheres from "../../useComponents/useTeachers";
import { getUsers } from "../../../actions/userActions";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;
const documentTypes = [
  { value: 0, label: "חשבונית/קבלה" },
  { value: 1, label: "קבלה" },
  { value: 2, label: "חשבונית" },
  { value: 3, label: "חשבונית/קבלה זיכוי" },
  { value: 4, label: "קבלה זיכוי" },
  { value: 5, label: "חשבונית זיכוי" },
];

const paymentMethods = [
  { value: 0, label: "מזומן" },
  { value: 1, label: "שיק" },
  { value: 3, label: "העברה" },
  { value: 4, label: "אשראי" },
  { value: 5, label: "אפליקציה" },
];
const Incomes = ({
  incomes,
  isLoaded,
  getAll,
  user,
  users,
  isUsersLoaded,
  getUsers,
}) => {
  const [isProviderModalOpen, setProviderModalOpen] = useState(false);
  const [providerData, setProviderData] = useState(false);
  const [isShowBillingCard, setIsShowBillingCard] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [isProviderDetailsModalOpen, setProviderDetailsModalOpen] =
    useState(false);
  const teachersOptions = useTeacheres(isUsersLoaded, getUsers, users);

  useEffect(() => {
    async function fetchData() {
      await getAll(1);
    }
    fetchData();
  }, []);

  const vehicleColumns = [
    {
      Header: dictionary.table.date,
      accessor: "date",
      Cell: ({ row: { original } }) => {
        const date = moment(original.date).format("DD/MM/YYYY");

        return original.documentType === 3 ||
          original.documentType === 4 ||
          original.documentType === 5 < 0 ? (
          <span style={{ color: "red", fontWeight: "bold" }}>{date}</span>
        ) : (
          date
        );
      },
      Filter: DateColumnFilter,
      filter: (array, s, date) => {
        return array.filter((x) => moment(x.original.date).isSame(date, "day"));
      },
    },
    {
      Header: "מספר מסמך כספי",
      accessor: "documentNumber",
      Cell: ({ row: { original } }) => {
        return original.documentType === 3 ||
          original.documentType === 4 ||
          original.documentType === 5 < 0 ? (
          <span style={{ color: "red", fontWeight: "bold" }}>
            {original.documentNumber}
          </span>
        ) : (
          original.documentNumber
        );
      },
    },
    {
      Header: "מורה",
      accessor: "teacherId",
      Cell: ({ row: { original } }) => {
        let teacherName = "";
        if (teachersOptions.length !== 0)
          teacherName = teachersOptions.find(
            (x) => x.value.toString() === original.teacherId
          )?.label;

        return original.documentType === 3 ||
          original.documentType === 4 ||
          original.documentType === 5 < 0 ? (
          <span style={{ color: "red", fontWeight: "bold" }}>
            {teacherName}
          </span>
        ) : (
          teacherName
        );
      },
    },
    {
      Header: dictionary.table.studentId,
      accessor: "clientId",
      Cell: ({ row: { original } }) => {
        return original.documentType === 3 ||
          original.documentType === 4 ||
          original.documentType === 5 < 0 ? (
          <span style={{ color: "red", fontWeight: "bold" }}>
            {original.clientId}
          </span>
        ) : (
          original.clientId
        );
      },
    },
    {
      Header: dictionary.table.vehicleNumber,
      accessor: "vehicleNumber",
      Cell: ({ row: { original } }) => {
        const vehicle =
          original.vehicleId === 999 ? "כל הרכבים" : original.vehicleNumber;

        return original.documentType === 3 ||
          original.documentType === 4 ||
          original.documentType === 5 < 0 ? (
          <span style={{ color: "red", fontWeight: "bold" }}>{vehicle}</span>
        ) : (
          vehicle
        );
      },
    },
    {
      Header: dictionary.table.amount,
      accessor: "amount",
      Cell: ({ row: { original } }) => {
        return original.documentType === 3 ||
          original.documentType === 4 ||
          original.documentType === 5 < 0 ? (
          <span style={{ color: "red", fontWeight: "bold" }}>
            {original.amount}
          </span>
        ) : (
          original.amount
        );
      },
    },
    {
      Header: dictionary.table.documentType,
      accessor: "documentType",
      Cell: ({ row: { original } }) => {
        const document = documentTypes.find(
          (x) => x.value === original.documentType
        )?.label;

        return original.documentType === 3 ||
          original.documentType === 4 ||
          original.documentType === 5 < 0 ? (
          <span style={{ color: "red", fontWeight: "bold" }}>{document}</span>
        ) : (
          document
        );
      },
    },
    {
      Header: "צורת תשלום",
      accessor: "paymentMethod",
      Cell: ({ row: { original } }) => {
        const method = paymentMethods.find(
          (x) => x.value === original.paymentMethod
        )?.label;

        return original.documentType === 3 ||
          original.documentType === 4 ||
          original.documentType === 5 < 0 ? (
          <span style={{ color: "red", fontWeight: "bold" }}>{method}</span>
        ) : (
          method
        );
      },
    },
    {
      Header: "נוצר ע״י",
      accessor: "createUsername",
    },
  ];

  const onRowClick = async (rowObject) => {
    setProviderDetailsModalOpen(true);
    setProviderData(rowObject.original);
  };
  const handlePrint = useReactToPrint({
    content: () => document.getElementById("table-to-xls"),
  });
  return (
    <Container>
      {isProviderModalOpen && (
        <NewCharge
          isNewAgreementModalOpen={isProviderModalOpen}
          setNewAgreementModalIsOpen={() => setProviderModalOpen(false)}
        />
      )}
      <CreditCardBill
        isNewAgreementModalOpen={isShowBillingCard}
        setNewAgreementModalIsOpen={() => setIsShowBillingCard(false)}
      />
      <IncomeData
        isModalOpen={isProviderDetailsModalOpen}
        closeModal={() => setProviderDetailsModalOpen(false)}
        providerData={providerData}
      />
      <Title>
        הכנסות מתלמידים
        {user.createStudentIncome ? (
          <CreateButton
            onClick={() => setProviderModalOpen(true)}
            text="צור חדש"
          />
        ) : null}
        {/* <CreateButton
          onClick={() => setIsShowBillingCard(true)}
          text="חייב אשראי"
        /> */}
      </Title>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="table-to-xls"
        filename="הכנסות מתלמידים"
        sheet="הכנסות"
        buttonText="יצא אקסל"
      />
      <div style={{ cursor: "pointer" }} onClick={handlePrint}>
        <span>הדפס &nbsp;</span>
        <i className="fa fa-print" aria-hidden="true" />
      </div>
      <br />
      סהכ:
      {filteredData
        .reduce((a, { original }) => a + original.amount || 0, 0)
        .toFixed(2)}
      ש״ח
      <Table
        data={incomes}
        componentColumns={vehicleColumns}
        onRowClick={onRowClick}
        setFilteredData={setFilteredData}
      />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    incomes: state.IncomesReducer.incomes,
    isLoaded: state.IncomesReducer.isLoaded,
    createVehicle: state.session.user.createVehicle,
    user: state.session.user,
    editVehicle: state.session.user.editVehicle,
    isUsersLoaded: state.UsersReducer.isLoaded,
    users: state.UsersReducer.users,
  };
}

export default connect(mapStateToProps, {
  getUsers,
  getAll,
})(Incomes);
