import expenseTypesApi from "../apis/expenseTypes";
import {
  EXPENSE_TYPES_FETCH_FAILED,
  EXPENSE_TYPES_FETCH_SUCCESS,
  EXPENSE_TYPES_FETCH_STARTED,
  EXPENSE_TYPE_CREATE_SUCCESS,
  EXPENSE_TYPE_DELETE_SUCCESS,
  EXPENSE_TYPE_UPDATE_SUCCESS,
} from "../constants/actionTypes";
import { toast } from "react-toastify";

export const create = (expenseType) => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const { branchId, schoolId } = getState().session.user;

    const response = await expenseTypesApi.post(
      "/create",
      {
        expenseType: { ...expenseType, schoolId },
      },
      {
        headers,
      }
    );
    dispatch({
      type: EXPENSE_TYPE_CREATE_SUCCESS,
      payload: { ...expenseType, id: response.data.id, isActive: 1 },
    });
  } catch (error) {
    toast.error("כישלון ביצירת סוג הוצאה");
  }
};

export const getSchoolExpenseTypes = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: EXPENSE_TYPES_FETCH_STARTED,
    });
    const token = window.localStorage.getItem("token");
    const { branchId, schoolId } = getState().session.user;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await expenseTypesApi.get(`/getAll/${schoolId}`, {
      headers,
    });
    dispatch({
      type: EXPENSE_TYPES_FETCH_SUCCESS,
      payload: response.data,
    });

    return response.data;
  } catch (error) {
    dispatch({
      type: EXPENSE_TYPES_FETCH_FAILED,
    });
  }
};

export const update = (expenseType) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await expenseTypesApi.patch(
      "/update",
      {
        expenseType,
      },
      {
        headers,
      }
    );

    dispatch({
      type: EXPENSE_TYPE_UPDATE_SUCCESS,
      payload: { ...expenseType },
    });
  } catch (error) {
    toast.error("כישלון בעדכון סוג הוצאה");
  }
};

export const deleteItem = (expenseTypeId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await expenseTypesApi.delete(
      "/delete",
      {
        data: { expenseTypeId },
      },
      {
        headers,
      }
    );

    dispatch({
      type: EXPENSE_TYPE_DELETE_SUCCESS,
      payload: { id: expenseTypeId },
    });
  } catch (error) {
    toast.error("כישלון במחיקת סוג הוצאה");
  }
};
