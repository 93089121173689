import {
  USER_CREATE_START,
  USER_SIGN_IN_START,
  USER_SIGN_IN_SUCCESS,
  USER_SIGN_IN_FAILED,
  USER_LOGOUT_FAILED,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_STARTED,
} from "../constants/actionTypes";

const INITIAL_STATE = {
  isPending: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case USER_SIGN_IN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isPending: false,
      };
    case USER_CREATE_START:
    case USER_SIGN_IN_START:
    case USER_LOGOUT_STARTED:
      return {
        ...state,
        isPending: true,
      };
    case USER_LOGOUT_SUCCESS:
      return INITIAL_STATE;
    case USER_SIGN_IN_FAILED:
    case USER_LOGOUT_FAILED:
      return {
        ...state,
        isPending: false,
      };
    default:
      return state;
  }
}
