import styled from "styled-components";

export const Body = styled.div`
  width: 100%;

  .react-toggle-track {
    background-color: #f5f5f5 !important;
    border: 1px solid #e5e5e5;
    padding: 0.2vh;
  }

  .react-toggle-thumb {
    background-color: #edca92;
    border: none;
  }

  .react-datepicker-wrapper:after {
    font-family: "FontAwesome";
    position: relative;
    left: -5px;
    content: "\f133";
  }

  .react-datepicker-wrapper {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    width: 45%;
    height: 6vh;
    margin-top: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__input-container {
    text-align: center;
  }

  .react-datepicker__input-container input {
    text-align: center;
  }

  .react-datepicker__input-container input {
    width: 100%;

    &::placeholder {
      text-align: left;
      padding-left: 1vw;
      color: #c2c2c2;
    }
  }

  .react-toggle-track {
    background-color: #f5f5f5 !important;
    border: 1px solid #e5e5e5;
    padding: 0.2vh;
  }

  .react-toggle--checked .react-toggle-thumb {
    background-color: #edca92;
  }

  .react-toggle-thumb {
    background-color: #e5e3e0;
    border: none;
  }
`;

export const Input = styled.input`
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  height: 6vh;
  margin-top: 1vh;
  width: 45%;

  &::placeholder {
    padding-left: 1vw;
    color: #656566;
  }
`;

export const AddMoreText = styled.span`
  color: #d2ab51;
  cursor: pointer;
  padding-left: 1vh;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1vh;
  justify-content: space-between;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
`;

export const CreateButton = styled.button`
  border-radius: 5px;
  color: white;
  height: 3vw;
  width: 8vw;
  background-image: linear-gradient(to bottom, #ebd794, #d2ab51);
  font-size: 15pt;
`;

export const AddMoreButton = styled.span`
  color: #d2ab51;
`;

export const AdvancesToIrsForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
