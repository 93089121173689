import { useEffect, useState } from "react";
import { optionsArrrayWithOneParam } from "../../utils/buildOptionsArray";

const UsePickupLocations = (isLoaded, pickupLocations, getPickupLocations) => {
  const [pickupLocationsArray, setPickupLocationsArray] = useState([]);

  useEffect(() => {
    (async () => {
      if (isLoaded) {
        setPickupLocationsArray(
          optionsArrrayWithOneParam(
            [{ id: 999, name: "ללא" }, ...pickupLocations],
            "id",
            "name"
          )
        );
      } else {
        await getPickupLocations();
      }
    })();
  }, [isLoaded]);
  return pickupLocationsArray;
};

export default UsePickupLocations;
