import styled from "styled-components";

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .MuiCheckbox-colorPrimary.Mui-checked {
    color: #d2ab51;
  }
`;

export const Title = styled.span`
  font-size: 22pt;
  font-weight: bold;
  color: #404041;
  display: block;
  height: 7vh;
`;

export const Body = styled.div`
  width: 100%;
  margin-top: 3vh;
  background-color: white;
  border: 1px solid #ffefca;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 3vh;
`;

export const PermanentDetailsContainer = styled.div`
  padding-left: 2vh;
  flex: 1;
`;
export const AdditionalDetailsContainer = styled.div`
  padding-left: 2vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Button = styled.button`
  border-radius: 5px;
  height: 6vh;
  background: ${(props) =>
    props.reset ? "linear-gradient(to bottom, #ebd794, #d2ab51)" : "white"};
  border: ${(props) => (props.reset ? "" : "1px solid #e1c89d")};
  color: ${(props) => (props.reset ? "white" : "#e1c89d")};
  font-size: 15pt;
  margin-top: 5vh;
  font-weight: bolder;
  width: ${(props) => (props.fit ? "fit-content" : "45%")};

  &:hover {
    background: ${(props) =>
      !props.reset ? "linear-gradient(to bottom, #ebd794, #d2ab51)" : "white"};
    border: ${(props) => (!props.reset ? "" : "1px solid #e1c89d")};
    color: ${(props) => (!props.reset ? "white" : "#e1c89d")};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 25vw;
  justify-content: space-between;
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const ToggleContainer = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  width: 100%;
  height: 6vh;
  margin-top: 1vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1vw;
  padding-right: 1vw;
`;

export const ToggleText = styled.span`
  font-size: 1.55vh;
`;
