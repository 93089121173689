import { useEffect, useState } from "react";
import { optionsArrrayWithTwoParams } from "../../utils/buildOptionsArray";

const useLicenseTypes = (isLoaded, getLicenseTypes, licenseTypes) => {
  const [licenseTypesOptionsArray, setLicenseTypesOptionsArray] = useState([]);
  useEffect(() => {
    if (licenseTypes.length === 0) getLicenseTypes();
    else {
      setLicenseTypesOptionsArray(
        optionsArrrayWithTwoParams(licenseTypes, "id", "name", "type")
      );
    }
  }, [isLoaded]);
  return licenseTypesOptionsArray;
};

export default useLicenseTypes;
