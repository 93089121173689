import React, { useState } from "react";
import { connect } from "react-redux";
import i18n from "../../../../../lib/i18n/i18n";
import { Field, reduxForm } from "redux-form";
import Toggle from "react-toggle";
import {
  Body,
  Section,
  Row,
  ToggleContainer,
  ToggleText,
} from "./testersDetails.styles";
import { update, deleteItem } from "../../../../../actions/testersActions";
import CreateButton from "../../../../UiComponents/Buttons/Default";
import SivanQuestionModal from "../../../../Modal/QuestionModal";
import InputWrappedWithField from "../../../../UiComponents/Inputs/DefaultWrappedWithField";

const renderToggle = ({
  input,
  label,
  meta: { touched, error },
  type = "text",
}) => {
  const isHebrew = i18n.language === "he";
  const dir = isHebrew ? "rtl" : "ltr";
  return (
    <ToggleContainer isRtl={isHebrew}>
      <ToggleText>{label}</ToggleText>
      <Toggle checked={input.value} onChange={input.onChange} />
    </ToggleContainer>
  );
};

const FundingBodiesDetails = ({
  handleSubmit,
  update,
  providerData,
  deleteItem,
  closeModal,
}) => {
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);

  const fundingBodyCreateAction = async (formValues) => {
    await update(formValues);
    closeModal();
  };

  const fundingBodyDeleteAction = async () => {
    setIsQuestionModalOpen(false);
    await deleteItem(providerData.id);
    closeModal();
  };

  return (
    <Body onSubmit={handleSubmit(fundingBodyCreateAction)}>
      <SivanQuestionModal
        titleText="מחיקה"
        bodySecondRow="גוף המימון ימחק לצמיתות"
        modalIsOpen={isQuestionModalOpen}
        closeModal={() => setIsQuestionModalOpen(false)}
        deleteItem={fundingBodyDeleteAction}
        isDelete
      />
      <Section basicData noBorder>
        <Row>
          <Field
            name="firstName"
            component={InputWrappedWithField}
            placeholder="שם פרטי"
            type="text"
          />
          <Field
            name="lastName"
            component={InputWrappedWithField}
            placeholder="שם משפחה"
            type="text"
          />
          <Field name="isActive" component={renderToggle} label="פעיל" />
        </Row>
      </Section>
      <CreateButton
        text="מחק"
        type="button"
        isDelete
        marginRight={2}
        onClick={() => setIsQuestionModalOpen(true)}
      />
      <CreateButton text="עדכן" type="submit" />
    </Body>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    initialValues: {
      ...ownProps.providerData,
    },
  };
}

export default connect(mapStateToProps, { update, deleteItem })(
  reduxForm({
    form: "UpdateFundingBodies",
  })(FundingBodiesDetails)
);
