import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { AssignmentContainer, IconContainer } from "../../assignment.styles";
import moment from "moment";

const statuses = { 1: "check", 2: "question", 3: "times" };

const Assignment = ({
  assignment,
  assignmentId,
  changeAssigmentStatus,
  changeAssigmentNote,
  changeAssigmentDate,
  status,
  note,
  date,
}) => {
  const [assignmentStatus, setAssignmentStatus] = useState(status);
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState(note);

  const changeStatus = () => {
    if (assignmentStatus === 3) {
      setAssignmentStatus(1);
      changeAssigmentStatus(assignmentId, 1);
      // changeAssigmentDate(assignmentId, new Date());
    } else {
      setAssignmentStatus(assignmentStatus + 1);
      changeAssigmentStatus(assignmentId, assignmentStatus + 1);
      // changeAssigmentDate(assignmentId, new Date());
    }
  };
  return (
    <AssignmentContainer dir="auto" assignmentStatus={assignmentStatus}>
      <div style={{ fontSize: "1vmax", width: "100%", textAlign: "right" }}>
        <div>
          <i
            className={`fa fa-${statuses[assignmentStatus]}-circle-o`}
            aria-hidden="true"
            onClick={changeStatus}
            style={{ cursor: "pointer" }}
          />
          {assignment}
        </div>
        {(showInput || inputValue) && (
          <div>
            הערה:
            <input
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              type="text"
              style={{
                border: "1px solid lightgray",
                borderRadius: "3px",
                width: "90%",
              }}
              onBlur={(e) => changeAssigmentNote(assignmentId, e)}
            />
          </div>
        )}
      </div>
      <i
        className="fa fa-pencil-square-o"
        style={{ cursor: "pointer" }}
        onClick={() => setShowInput(true)}
      />
    </AssignmentContainer>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isAssignmentsLoaded: state.LearningAssignmentsReducer.isLoaded,
    learningAssignments: state.LearningAssignmentsReducer.learningAssignments,
  };
}

export default connect(mapStateToProps)(Assignment);
