import professionalTeachersApi from "../apis/professionalTeachers";
import {
  PROFESSIONAL_TEACHERS_FETCH_FAILED,
  PROFESSIONAL_TEACHERS_FETCH_SUCCESS,
  PROFESSIONAL_TEACHERS_FETCH_STARTED,
  PROFESSIONAL_TEACHER_CREATE_SUCCESS,
  PROFESSIONAL_TEACHER_DELETE_SUCCESS,
  PROFESSIONAL_TEACHER_UPDATE_SUCCESS,
} from "../constants/actionTypes";
import { toast } from "react-toastify";

export const create = (professionalTeacher) => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const { branchId, schoolId } = getState().session.user;

    const response = await professionalTeachersApi.post(
      "/create",
      {
        professionalTeacher: { ...professionalTeacher, schoolId },
      },
      {
        headers,
      }
    );
    dispatch({
      type: PROFESSIONAL_TEACHER_CREATE_SUCCESS,
      payload: { ...professionalTeacher, id: response.data.id, isActive: 1 },
    });
  } catch (error) {
    toast.error("כישלון ביצירת מורה מקצועי");
  }
};

export const getSchoolProfessionalTeachers = () => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: PROFESSIONAL_TEACHERS_FETCH_STARTED,
    });
    const token = window.localStorage.getItem("token");
    const { branchId, schoolId } = getState().session.user;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await professionalTeachersApi.get(`/getAll/${schoolId}`, {
      headers,
    });

    dispatch({
      type: PROFESSIONAL_TEACHERS_FETCH_SUCCESS,
      payload: response.data,
    });

    return response.data;
  } catch (error) {
    dispatch({
      type: PROFESSIONAL_TEACHERS_FETCH_FAILED,
    });
  }
};

export const update = (professionalTeacher) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await professionalTeachersApi.patch(
      "/update",
      {
        professionalTeacher,
      },
      {
        headers,
      }
    );

    dispatch({
      type: PROFESSIONAL_TEACHER_UPDATE_SUCCESS,
      payload: { ...professionalTeacher },
    });
  } catch (error) {
    toast.error("כישלון בעדכון מורה מקצועי");
  }
};

export const deleteItem = (professionalTeacherId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await professionalTeachersApi.delete(
      "/delete",
      {
        data: { professionalTeacherId },
      },
      {
        headers,
      }
    );

    dispatch({
      type: PROFESSIONAL_TEACHER_DELETE_SUCCESS,
      payload: { id: professionalTeacherId },
    });
  } catch (error) {
    toast.error("כישלון במחיקת מורה מקצועי");
  }
};
