import styled from "styled-components";
import i18n from "../../lib/i18n/i18n";
import { isMobile, isMobileOnly } from "react-device-detect";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const Container = styled.div`
  .rbc-header,
  .rbc-month-view,
  .rbc-month-row,
  .rbc-day-bg,
  .rbc-timeslot-group,
  .rbc-time-content,
  .rbc-events-container,
  .rbc-time-view,
  .rbc-time-header-content {
    border-color: #ffefca;
  }
  // .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  .rbc-agenda-table > tbody > tr {
    display: table-row !important;
  }

  .rbc-row-content {
    z-index: 0;
  }

  .rbc-time-slot {
    min-height: 15vh;
  }

  .rbc-agenda-event-cell,
  .rbc-agenda-time-cell {
    display: table-cell !important;
  }

  .rbc-event-label {
    display: none;
  }

  .rbc-timeslot-group:nth-child(2n + 1) {
    background-color: #fbf9f9;
  }

  .rbc-toolbar-label {
    ${isMobileOnly && "display: none;"}
  }

  .react-datepicker-wrapper {
    width: ${isMobile ? "40vw" : "10%"};
    height: ${isMobile ? "4vh" : "2.5vh"};
    margin-top: 0;
  }

  .react-datepicker-popper {
    z-index: 343443;
  }

  .rbc-agenda-content {
    max-height: 72vh;
  }

  .rbc-day-slot .rbc-events-container {
    ${isMobileOnly && "margin:0;"}
  }

  .rbc-event {
    ${isMobileOnly && "padding: 1px 2px;"}
  }
`;

export const UserOptionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${isMobile ? "column" : "row"};
  align-items: center;
  justify-content: center;
`;

export const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided, state) => ({
    ...provided,
    height: "3vh",
    border: "1px solid #e5e5e5",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#c2c2c2",
    [isHebrew ? "paddingRight" : "paddingLeft"]: "0.5vw",
  }),
};
