import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container } from "./FundingBodyStudents.styles";
import Table from "../../../../SivanPlusTable/SivanPlusTable";
import { getFundingBodyStudents } from "../../../../../actions/textBooksActions";

const studentColumns = [
  {
    Header: "שם",
    accessor: "firstName",
  },
  {
    Header: "שם משפחה",
    accessor: "lastName",
  },
  {
    Header: "תז",
    accessor: "studentId",
  },
  {
    Header: "רישיון",
    accessor: "licenseType",
  },
  {
    Header: "מספר רכב",
    accessor: "vehicleNumber",
  },
];

const FundingBodyStudents = ({ getFundingBodyStudents, fundingBodyData }) => {
  const [students, setStudents] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const data = await getFundingBodyStudents(fundingBodyData.id);
      console.log(data);
      setStudents(data);
    }
    fetchData();
  }, []);

  return (
    <Container>
      <Table
        data={students}
        componentColumns={studentColumns}
        onRowClick={() => {}}
      />
    </Container>
  );
};

export default withRouter(
  connect(null, {
    getFundingBodyStudents,
  })(FundingBodyStudents)
);
