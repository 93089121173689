import styled from "styled-components";
import LoadingOverlay from "react-loading-overlay";
import i18n from "../../../../lib/i18n/i18n";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "30%",
  }),
  control: (provided, state) => ({
    ...provided,
    height: "6vh",
    border: "1px solid #e5e5e5",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#c2c2c2",
    [isHebrew ? "paddingRight" : "paddingLeft"]: "0.5vw",
  }),
};

export const StyledLoader = styled(LoadingOverlay)`
  ._loading_overlay_overlay {
    position: fixed !important;
  }
`;

export const Title = styled.span`
  font-size: 1.9vh;
  font-weight: bold;
  margin-top: 1vh;
`;

export const Icon = styled.i`
  cursor: pointer;
  margin-right: 1vw;
  color: #cb9d3c;
  font-size: ${(props) => (props.small ? "3vh" : "5vh")};
`;

export const IconContainer = styled.div``;

export const RankText = styled.span``;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 1vh;
`;

export const RankRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  align-items: center;
  justify-content: space-between;
  margin-top: 1vh;
`;
