import React from "react";
import { Field, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import i18n from "../../../lib/i18n/i18n";
import { useTranslation } from "react-i18next";
import {
  Body,
  AdditionalDetailsContainer,
  Button,
  Fields,
  ButtonContainer,
} from "./createStudent.styles";
import { updateStudent } from "../../../actions/studentesActions";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;
const smsOptions = [
  { value: "sms", label: dictionary.newStudent.sms },
  { value: "whatsapp", label: dictionary.newStudent.whatsapp },
];

const smsLanguages = [
  { value: "hebrew", label: dictionary.newStudent.hebrew },
  { value: "arabic", label: dictionary.newStudent.arabic },
  { value: "russian", label: dictionary.newStudent.russian },
];

const AdditionalDetails = ({
  handleSubmit,
  pristine,
  submitting,
  renderSelect,
  renderInput,
  renderDatePickerField,
  renderToggle,
  studentData,
  updateStudent,
  history,
}) => {
  const { t, i18n } = useTranslation("common");
  const updateStudentAndSetLoading = async (formValues) => {
    if (
      await updateStudent({
        ...formValues,
        smsType:
          typeof formValues.smsType === "object"
            ? formValues.smsType.value
            : null,
        smsLanguage:
          typeof formValues.smsLanguage === "object"
            ? formValues.smsLanguage.value
            : null,
      })
    )
      history.push("/studentSearch");
  };
  return (
    <Body onSubmit={handleSubmit(updateStudentAndSetLoading)}>
      <Fields>
        <AdditionalDetailsContainer>
          <Field
            label={t("newStudent.registrationFees")}
            component={renderToggle}
            name="registrationFees"
          />
          <Field
            label={t("newStudent.glasses")}
            component={renderToggle}
            name="glasses"
          />
          <Field
            label={t("newStudent.smsOnEventCreation")}
            component={renderToggle}
            name="smsOnEventCreation"
          />
          <Field
            name="smsLanguage"
            component={renderSelect}
            placeholder={t("newStudent.smsLanguage")}
            options={smsLanguages}
          />
        </AdditionalDetailsContainer>
        <AdditionalDetailsContainer>
          <Field
            name="smsType"
            component={renderSelect}
            placeholder={t("newStudent.smsType")}
            options={smsOptions}
          />
          <Field
            name="certificateOfGoodConduct"
            component={renderDatePickerField}
            placeholder={t("newStudent.certificateOfGoodConduct")}
          />
          <Field
            name="medicalInstitute"
            component={renderDatePickerField}
            placeholder={t("newStudent.medicalInstitute")}
          />
        </AdditionalDetailsContainer>
      </Fields>
      <ButtonContainer>
        <Button type="submit">{t("buttons.save")}</Button>
      </ButtonContainer>
    </Body>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    initialValues: {
      id: ownProps.studentData.id,
      registrationFees: ownProps.studentData.registrationFees,
      glasses: ownProps.studentData.glasses,
      smsOnEventCreation: ownProps.studentData.smsOnEventCreation,
      medicalInstitute: ownProps.studentData.medicalInstitute
        ? new Date(ownProps.studentData.medicalInstitute)
        : null,
      certificateOfGoodConduct: ownProps.studentData.certificateOfGoodConduct
        ? new Date(ownProps.studentData.certificateOfGoodConduct)
        : null,
      smsType:
        typeof ownProps.studentData.smsType === "object"
          ? ownProps.studentData.smsType
          : smsOptions.find(
              ({ value }) => value === ownProps.studentData.smsType
            ),
      smsLanguage:
        typeof ownProps.studentData.smsLanguage === "object"
          ? ownProps.studentData.smsLanguage
          : smsLanguages.find(
              ({ value }) => value === ownProps.studentData.smsLanguage
            ),
    },
  };
}
export default withRouter(
  connect(mapStateToProps, { updateStudent })(
    reduxForm({
      form: "updateStudentAdditionalData", // a unique identifier for this form
      destroyOnUnmount: false, // <------ preserve form data
      forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
      enableReinitialize: true,
    })(AdditionalDetails)
  )
);
