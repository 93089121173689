import validator from "validator";

export default (values) => {
  const errors = {};
  if (!values.vehicleNumber) {
    errors.vehicleNumber = "Required";
  }

  if (!values.description) {
    errors.description = "Required";
  }

  if (!values.licenseType) {
    errors.licenseType = "Required";
  }

  if (!values.internalDuration && values.internalDuration !== 0) {
    errors.internalDuration = "Required";
  }

  return errors;
};
