import styled from "styled-components";
import { isMobile, isMobileOnly } from "react-device-detect";

export const Container = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Body = styled.div`
  width: 100%;
  margin-top: 3vh;
  background-color: white;
  border: 1px solid #ffefca;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  padding: 3vh;
  flex-direction: column;
  align-items: center;
`;

export const Button = styled.button`
  border-radius: 5px;
  height: 6vh;
  background: ${(props) =>
    props.reset ? "linear-gradient(to bottom, #ebd794, #d2ab51)" : "white"};
  border: ${(props) => (props.reset ? "" : "1px solid #e1c89d")};
  color: ${(props) => (props.reset ? "white" : "#e1c89d")};
  font-size: 15pt;
  margin-top: 5vh;
  font-weight: bolder;
  width: 45%;

  &:hover {
    background: ${(props) =>
      !props.reset ? "linear-gradient(to bottom, #ebd794, #d2ab51)" : "white"};
    border: ${(props) => (!props.reset ? "" : "1px solid #e1c89d")};
    color: ${(props) => (!props.reset ? "white" : "#e1c89d")};
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  border: solid cadetblue;
  border-radius: 40px;
  padding: 15px 10px 10px;
  width: 95%;
  direction: rtl;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 25vw;
  justify-content: space-between;
`;

export const SectionContainer = styled.div`
  border: 1px solid #ffefca;
  border-radius: 15px;
  padding: 15px 10px 10px;
  width: 95%;
  direction: rtl;
  margin-top: 1vh;
  display: flex;
  flex-direction: column;
  flex: 0 18%;
  justify-content: space-between;
`;

export const SectionContainerHeader = styled.div`
  background-color: #ffffff;
  padding: 0 10px;
  margin: 0 20px;
  color: ${(props) => props.fontColor};
  background: ${(props) => props.background};
  border-radius: 10px;
  padding: 2px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Icon = styled.span`
  font-size: ${isMobileOnly ? "7vw" : "3vh"};
  margin-right: ${isMobileOnly && "0.3vw"};
`;
