import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { slide as BurgerMenu } from "react-burger-menu";
import ArrowIcon from "./arrow.svg";
import { withRouter } from "react-router-dom";
import ArrowDownIcon from "./arrowDown.svg";
import CalendarYellow from "./menuIcons/Calendar/calendar.svg";
import CalendarBlack from "./menuIcons/Calendar/calendar.svg";
import Financial from "./menuIcons/Financial/financial.svg";
import FinancialBlack from "./menuIcons/Financial/financial.svg";
import StudentsSelectedIcon from "./menuIcons/Students/student.svg";
import StudentsIcon from "./menuIcons/Students/student.svg";
import GeneralIcon from "./menuIcons/General/general.svg";
import { logOut } from "../../../../../actions/session";
import SettingsIcon from "./menuIcons/Settings/settings.svg";
import {
  CategoryItems,
  MenuContainer,
  Category,
  Item,
  Icon,
  CategoryText,
  CategoryTitle,
  Container,
} from "./menu.styles";

const Menu = ({ history, user, logOut }) => {
  const [currentCategory, setCurrentCategory] = useState("students");
  const [isFold, setIsFold] = useState(false);
  const [currentSelectedSubCategory, setCurrentSelectedSubCategory] =
    useState("newStudent");
  const { t, i18n } = useTranslation("common");
  const categories = {
    [t("menuCategories.calendar")]: {
      icon: CalendarBlack,
      selectedIcon: CalendarYellow,
      subCategories: [],
      action: () => history.push("calendar"),
      url: "calendar",
    },
    [t("menuCategories.students")]: {
      icon: StudentsIcon,
      selectedIcon: StudentsSelectedIcon,
      subCategories: [
        {
          text: t("menuSubCategories.students.studentSearch"),
          icon: SettingsIcon,
          action: () => history.push("studentSearch"),
          url: "studentSearch",
        },
        {
          text: t("menuSubCategories.students.agreements"),
          icon: SettingsIcon,
          action: () => history.push("agreements"),
          url: "agreements",
        },
        {
          text: t("menuSubCategories.students.generalMessages"),
          icon: SettingsIcon,
          action: () => history.push("generalMessages"),
          url: "generalMessages",
        },
        {
          text: t("menuSubCategories.students.pickupLocations"),
          icon: SettingsIcon,
          action: () => history.push("pickupLocations"),
          url: "pickupLocations",
        },
      ],
    },
    [t("menuCategories.financial")]: {
      icon: FinancialBlack,
      selectedIcon: Financial,
      subCategories: [
        {
          text: t("menuSubCategories.financial.incomes"),
          icon: SettingsIcon,
          action: () => history.push("incomes"),
          url: "incomes",
        },
        {
          text: t("menuSubCategories.financial.incomesFromStudents"),
          icon: SettingsIcon,
          action: () => history.push("incomesFromStudents"),
          url: "incomesFromStudents",
        },
        {
          text: "הכנסות מגופי מימון",
          icon: SettingsIcon,
          action: () => history.push("incomesFromFundingBodies"),
          url: "incomesFromFundingBodies",
        },
        {
          text: t("menuSubCategories.financial.expenses"),
          icon: SettingsIcon,
          action: () => history.push("expenses"),
          url: "expenses",
        },
        // {
        //   text: t("menuSubCategories.financial.sendInvoiceToTheCPA"),
        //   icon: SettingsIcon,
        //   url: "",
        // },
        // {
        //   text: t("menuSubCategories.financial.Reports"),
        //   icon: SettingsIcon,
        //   url: "",
        // },
        {
          text: t("menuSubCategories.financial.clients"),
          icon: SettingsIcon,
          action: () => history.push("clients"),
          url: "clients",
        },
        {
          text: t("menuSubCategories.financial.providers"),
          icon: SettingsIcon,
          action: () => history.push("providers"),
          url: "providers",
        },
        {
          text: t("menuSubCategories.financial.expenseType"),
          icon: SettingsIcon,
          action: () => history.push("expensesTypes"),
          url: "expensesTypes",
        },
        {
          text: t("menuSubCategories.financial.fundingBody"),
          icon: SettingsIcon,
          action: () => history.push("fundingBodies"),
          url: "fundingBodies",
        },
        // {
        //   text: t("menuSubCategories.financial.invoiceFundingBody"),
        //   icon: SettingsIcon,
        //   url: "",
        // },
        // {
        //   text: t("menuSubCategories.financial.receiptFundingBody"),
        //   icon: SettingsIcon,
        //   url: "",
        // },
      ],
    },
    [t("menuCategories.general")]: {
      icon: GeneralIcon,
      selectedIcon: StudentsSelectedIcon,
      subCategories: [
        {
          text: t("menuSubCategories.general.users"),
          icon: SettingsIcon,
          action: () => history.push("users"),
          url: "users",
        },
        {
          text: t("menuSubCategories.general.drivingPermits"),
          icon: SettingsIcon,
          action: () => history.push("drivingPermits"),
          url: "drivingPermits",
        },
        {
          text: t("menuSubCategories.general.professionalTeacherTeachers"),
          icon: SettingsIcon,
          action: () => history.push("professionalTeachers"),
          url: "professionalTeachers",
        },
        {
          text: "בוחנים",
          icon: SettingsIcon,
          action: () => history.push("testers"),
          url: "testers",
        },
        {
          text: t("menuSubCategories.general.vehicles"),
          icon: SettingsIcon,
          action: () => history.push("vehicles"),
          url: "vehicles",
        },
        // {
        //   text: t("menuSubCategories.general.odometer"),
        //   icon: SettingsIcon,
        //   url: "",
        // },
        // {
        //   text: t("menuSubCategories.general.licenseRanks"),
        //   icon: SettingsIcon,
        //   url: "",
        // },
        {
          text: t("menuSubCategories.general.permissions"),
          icon: SettingsIcon,
          action: () => history.push("permissions"),
          url: "permissions",
        },
        {
          text: t("menuSubCategories.general.creatUser"),
          icon: SettingsIcon,
          action: () => history.push("createUser"),
          url: "createUser",
        },
        {
          text: "ספידומטר",
          icon: SettingsIcon,
          action: () => history.push("odoMeter"),
          url: "odoMeter",
        },
        {
          text: t("menuSubCategories.general.trafficLaw"),
          action: () => history.push("trafficLaws"),
          icon: SettingsIcon,
          url: "calendar",
        },
        {
          text: "סמלים",
          icon: SettingsIcon,
          action: () => history.push("symbols"),
          url: "symbols",
        },
      ],
    },
    [t("menuCategories.reports")]: {
      icon: GeneralIcon,
      selectedIcon: StudentsSelectedIcon,
      subCategories: [
        {
          text: t("menuSubCategories.reports.weekly"),
          icon: SettingsIcon,
          action: () => history.push("teacherWeekly"),
          url: "teacherWeekly",
        },
        {
          text: "דוח שנתי למורה",
          icon: SettingsIcon,
          action: () => history.push("teacherYearlyReport"),
          url: "teacherYearlyReport",
        },
        {
          text: "דוח הכנסות",
          icon: SettingsIcon,
          action: () => history.push("cpaReport"),
          url: "cpaReport",
        },
        {
          text: "פעילות חודשית",
          icon: SettingsIcon,
          action: () => history.push("monthlyActivity"),
          url: "monthlyActivity",
        },
        {
          text: "דוח פעילות שנתית",
          icon: SettingsIcon,
          action: () => history.push("yearlyActivity"),
          url: "yearlyActivity",
        },
        {
          text: "דוח ביטולים",
          icon: SettingsIcon,
          action: () => history.push("canceledReport"),
          url: "canceledReport",
        },
        {
          text: "דוח פעילות תקופתית",
          icon: SettingsIcon,
          action: () => history.push("activityReport"),
          url: "activityReport",
        },
        {
          text: "דוח חייבים",
          icon: SettingsIcon,
          action: () => history.push("studentBalances"),
          url: "studentBalances",
        },
        {
          text: "דוח מבחנים פנימיים",
          icon: SettingsIcon,
          action: () => history.push("internalTestsReport"),
          url: "internalTestsReport",
        },
        {
          text: "דוח מבחנים מעשיים",
          icon: SettingsIcon,
          action: () => history.push("testsReport"),
          url: "testsReport",
        },
        {
          text: "דוח תלמידים",
          icon: SettingsIcon,
          action: () => history.push("studentsReport"),
          url: "studentsReport",
        },
        {
          text: "דוח ספידומטר",
          icon: SettingsIcon,
          action: () => history.push("odoMeterReport"),
          url: "odoMeterReport",
        },
        {
          text: "תלמידים ללא אירוע",
          icon: SettingsIcon,
          action: () => history.push("inactiveStudents"),
          url: "inactiveStudents",
        },
        {
          text: "תלמידים חדשים ללא אירוע",
          icon: SettingsIcon,
          action: () => history.push("inactiveNewStudents"),
          url: "inactiveNewStudents",
        },
        {
          text: "היתר נהיגה שנתי",
          icon: SettingsIcon,
          action: () => history.push("yearlyDrivingPermitsActivity"),
          url: "yearlyDrivingPermitsActivity",
        },
      ],
    },
    תרשימים: {
      icon: GeneralIcon,
      selectedIcon: StudentsSelectedIcon,
      subCategories: [
        {
          text: "תלמידים חדשים לפי חודשים",
          icon: SettingsIcon,
          action: () => history.push("newStudentsByMonth"),
          url: "newStudentsByMonth",
        },
        {
          text: "תלמידים חדשים מול עברו טסט",
          icon: SettingsIcon,
          action: () => history.push("newStudentsVSPassedTests"),
          url: "newStudentsVSPassedTests",
        },
        {
          text: "תלמידים חדשים מול חשבוניות",
          icon: SettingsIcon,
          action: () => history.push("newStudentsVSInvoices"),
          url: "newStudentsVSInvoices",
        },
        {
          text: "טסטים לפי חודש",
          icon: SettingsIcon,
          action: () => history.push("testByMonth"),
          url: "testByMonth",
        },
        {
          text: "התפלגות חודשית לטסטים",
          icon: SettingsIcon,
          action: () => history.push("testTypesByMonth"),
          url: "testTypesByMonth",
        },
        {
          text: "כמות שיעורים לפי חודש",
          icon: SettingsIcon,
          action: () => history.push("lessonsByMonth"),
          url: "lessonsByMonth",
        },
        {
          text: "הכנסות לפי חודשים",
          icon: SettingsIcon,
          action: () => history.push("incomesByMonth"),
          url: "incomesByMonth",
        },
        {
          text: "סטטיסטיקות טסטים",
          icon: SettingsIcon,
          action: () => history.push("testStatistics"),
          url: "testStatistics",
        },
        {
          text: "תלמידים חדשים לפי ימים",
          icon: SettingsIcon,
          action: () => history.push("newStudentsByDay"),
          url: "newStudentsByDay",
        },
        {
          text: "סוג מסמך לפי חודש",
          icon: SettingsIcon,
          action: () => history.push("invoicesTypesByMonth"),
          url: "invoicesTypesByMonth",
        },
      ],
    },
    [t("menuCategories.settings")]: {
      icon: GeneralIcon,
      selectedIcon: StudentsSelectedIcon,
      subCategories: [],
      action: () => history.push("settings"),
      url: "settings",
    },
    ["התנתק"]: {
      icon: SettingsIcon,
      selectedIcon: StudentsSelectedIcon,
      subCategories: [],
      action: () => {
        history.push("/");
        logOut();
      },
      url: "calendar",
    },
  };

  const changeCategory = (categoryName) => {
    setCurrentCategory(categoryName);
  };

  const changeSubCategory = (subCategory) => {
    setCurrentSelectedSubCategory(subCategory);
  };
  const renderMenu = (currentCategory, currentSelectedSubCategory) => {
    const menuItemsArray = [];
    const isRtl = i18n.language === "he";
    for (let key in categories) {
      if (categories.hasOwnProperty(key)) {
        menuItemsArray.push(
          <Category key={key}>
            <CategoryTitle
              isSelected={currentCategory === key}
              onClick={() =>
                categories[key].subCategories.length === 0
                  ? categories[key].action()
                  : changeCategory(key)
              }
              isRtl={isRtl}
            >
              <CategoryText isRtl={isRtl}>
                <Icon
                  src={
                    currentCategory === key
                      ? categories[key].selectedIcon
                      : categories[key].icon
                  }
                  isRtl={isRtl}
                  alt={key}
                />
                {key}
              </CategoryText>
              {categories[key].subCategories.length !== 0 && (
                <Icon
                  src={currentCategory === key ? ArrowDownIcon : ArrowIcon}
                  style={{ transform: `${isRtl ? "rotate(180deg)" : ""}` }}
                  alt="arrow"
                />
              )}
            </CategoryTitle>
            {currentCategory === key && (
              <CategoryItems>
                {categories[key].subCategories.map((subCategory) => (
                  <Item
                    isSelected={currentSelectedSubCategory === subCategory.text}
                    changeSubCategory={() =>
                      changeSubCategory(subCategory.text)
                    }
                    onClick={subCategory.action}
                    isRtl={isRtl}
                    display={user[subCategory.url]}
                    key={subCategory.text}
                  >
                    <Icon
                      src={subCategory.icon}
                      alt={subCategory.text}
                      isRtl={isRtl}
                    />
                    <span style={{ textAlign: "right" }}>
                      {subCategory.text}
                    </span>
                  </Item>
                ))}
              </CategoryItems>
            )}
          </Category>
        );
      }
    }

    return menuItemsArray;
  };

  return !isMobile ? (
    <MenuContainer isFold={isFold}>
      <i
        className={`fa fa-arrow-${isFold ? "left" : "right"}`}
        style={{ cursor: "pointer" }}
        onClick={() => setIsFold(!isFold)}
      ></i>
      {!isFold && renderMenu(currentCategory, currentSelectedSubCategory)}
    </MenuContainer>
  ) : (
    <Container>
      <BurgerMenu>
        {renderMenu(currentCategory, currentSelectedSubCategory)}
      </BurgerMenu>
    </Container>
  );
};

function mapStateToProps(state) {
  return { user: state.session.user };
}

export default withRouter(connect(mapStateToProps, { logOut })(Menu));
