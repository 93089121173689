import {
  DRIVING_PERMITS_FETCH_FAILED,
  DRIVING_PERMITS_FETCH_SUCCESS,
  DRIVING_PERMITS_FETCH_STARTED,
  DRIVING_PERMIT_CREATE_SUCCESS,
  DRIVING_PERMIT_DELETE_SUCCESS,
} from "../constants/actionTypes";

const INITIAL_STATE = { isPending: false, drivingPermits: [], isLoaded: false };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case DRIVING_PERMIT_CREATE_SUCCESS:
      return {
        ...state,
        drivingPermits: [...state.drivingPermits, action.payload],
      };
    case DRIVING_PERMIT_DELETE_SUCCESS:
      return {
        ...state,
        drivingPermits: state.drivingPermits.filter(
          (drivingPermit) => drivingPermit.id !== action.payload.id
        ),
      };
    // case drivingPermit_UPDATE_SUCCESS:
    //   const drivingPermits = state.drivingPermits.map((drivingPermit) =>
    //     drivingPermit.id === action.payload.id
    //       ? { ...drivingPermit, ...action.payload }
    //       : drivingPermit
    //   );
    //   return { ...state, drivingPermits };
    case DRIVING_PERMITS_FETCH_SUCCESS:
      return { ...state, drivingPermits: action.payload, isLoaded: true };
    case DRIVING_PERMITS_FETCH_FAILED:
      return { ...state, isPending: false };
    case DRIVING_PERMITS_FETCH_STARTED:
      return { ...state, isPending: true };
    default:
      return state;
  }
}
