import React, { useRef, useState, useEffect } from "react";
import {
  Container,
  Row,
  FieldText,
  Field,
  Table,
  Tr,
} from "./studentReport.styles";
import { connect } from "react-redux";
import UseLoadStudents from "../../useComponents/UseLoadStudents";
import { getStudents } from "../../../actions/studentesActions";
import { getLicenseTypes } from "../../../actions/licenseTypes";
import { useReactToPrint } from "react-to-print";
import _ from "lodash";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useLicenseTypes from "../../useComponents/useLicenseTypes";

const ActivityReport = ({
  drivingPermits,
  isLoading,
  getStudents,
  students,
  getLicenseTypes,
  licenseTypes,
  isLoaded,
}) => {
  const componentRef = useRef();
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [selectedLicense, setSelectedLicense] = useState(null);
  const [endDate, setEndDate] = useState(new Date());
  const [licenseFilteredOptions, setLicenseFilteredOptions] = useState([]);
  const [sortType, setSortType] = useState("firstName");
  UseLoadStudents(isLoading, getStudents);
  const licenseTypesOptions = useLicenseTypes(
    isLoaded,
    getLicenseTypes,
    licenseTypes
  );
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (licenseTypesOptions.length > 0 && students.length > 0) {
      var studentsLicenses = students
        .filter((x) => x.licenseId !== null)
        .reduce(
          (acc, x) =>
            acc.concat(acc.find((y) => y === x.licenseId) ? [] : [x.licenseId]),
          []
        );
      let newLicenseArray = [];
      for (let index = 0; index < licenseTypesOptions.length; index++) {
        const element = licenseTypesOptions[index];
        if (studentsLicenses.includes(element.value)) {
          newLicenseArray.push(element);
        }
      }
      setLicenseFilteredOptions(newLicenseArray);
    }
  }, [licenseTypesOptions]);

  const returnSortedArray = () => {
    const sortedStudents = students
      .filter((x) => x.active === 1)
      .sort(function (a, b) {
        if (a[sortType].trim() < b[sortType].trim()) {
          return -1;
        }
        if (a[sortType].trim() > b[sortType].trim()) {
          return 1;
        }
        return 0;
      });
    if (selectedLicense) {
      return sortedStudents.filter(
        (x) => x.licenseId === selectedLicense.value && x.licenseId !== null
      );
    } else {
      return sortedStudents;
    }
  };

  return (
    <Container>
      <Row>
        <div style={{ cursor: "pointer" }} onClick={handlePrint}>
          <FieldText>הדפס &nbsp;</FieldText>
          <i className="fa fa-print" aria-hidden="true" />
        </div>
        <Autocomplete
          id="combo-box-demo"
          options={licenseFilteredOptions}
          getOptionLabel={(option) => option.label}
          value={selectedLicense}
          onChange={(event, value) => setSelectedLicense(value)}
          style={{ width: "20%" }}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              label="בחר רישיון"
              variant="outlined"
            />
          )}
        />
      </Row>
      <Table ref={componentRef}>
        <thead>
          <tr>
            <th
              onClick={() => setSortType("firstName")}
              style={{ cursor: "pointer" }}
            >
              שם
            </th>
            <th
              onClick={() => setSortType("lastName")}
              style={{ cursor: "pointer" }}
            >
              שם משפחה
            </th>
            <th>טלפון</th>
            <th>ת.ז.</th>
            <th>רישיון</th>
          </tr>
        </thead>
        <tbody>
          {returnSortedArray().map((x) => (
            <Tr key={x.studentId}>
              <td>{x.firstName}</td>
              <td>{x.lastName}</td>
              <td>{x.phone}</td>
              <td>{x.studentId}</td>
              <td>{x.licenseType}</td>
            </Tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isLoggedIn: state.session.isLoggedIn,
    userId: state.session.user.id,
    drivingPermits: state.DrivingPermitsReducer.drivingPermits,
    user: state.session.user,
    students: state.StudentsReducer.students,
    isLoading: state.StudentsReducer.isLoaded,
    licenseTypes: state.LicenseTypesReducer.licenseTypes,
    isLoaded: state.LicenseTypesReducer.isLoaded,
  };
}

export default connect(mapStateToProps, {
  getStudents,
  getLicenseTypes,
})(ActivityReport);
