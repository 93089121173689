import React, { useEffect, useState, useRef } from "react";
import { Container, Table } from "./teacherYearly.styles";
import { connect } from "react-redux";
import { getAllTeacherEventsWithCanceled } from "../../../actions/schedulerActions";
import useTeacheres from "../../useComponents/useTeachers";
import { getUsers } from "../../../actions/userActions";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getLicenseTypes } from "../../../actions/licenseTypes";
import { getVehicles } from "../../../actions/vehicleActions";
import _ from "lodash";

const TeacherWeekly = ({
  getAllTeacherEventsWithCanceled,
  isUsersLoaded,
  getUsers,
  users,
  getVehicles,
  getLicenseTypes,
  eventTime,
}) => {
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [teacherData, setTeacherData] = useState([]);
  const [teacherTotals, setTeacherTotals] = useState([]);
  const [monthsArray, setMonthsArray] = useState([]);
  const teachersOptions = useTeacheres(isUsersLoaded, getUsers, users);
  useEffect(() => {
    (async () => {
      if (selectedTeacher) {
        const teacherEvents = await getAllTeacherEventsWithCanceled(
          selectedTeacher.value
        );
        await buildTable(teacherEvents);
      }
    })();
  }, [selectedTeacher]);

  const buildTable = async (events) => {
    const schoolVehicles = await getVehicles();
    const licenseTypes = await getLicenseTypes();
    let tableData = [];
    let totals = [];
    let monthsInReport = [];
    _.sortBy(events, [
      function (o) {
        return o.start ? new Date(o.start) : new Date(o.date);
      },
    ])
      .filter((x) => x.eventStatus !== 3)
      .map((x) => {
        const month = new Date(x.start).getMonth() + 1;
        const year = new Date(x.start).getFullYear();
        if (year === 2022) {
          if (x.eventType === 4 && typeof x.vehicleId !== "string") {
          } else {
            const vehicleLicenseType = schoolVehicles.find(
              (y) => y.id.toString() === x.vehicleId.toString()
            )?.licenseType;
            const license = licenseTypes.find(
              (x) => x.id === parseInt(vehicleLicenseType)
            );
            const numberOfEvents = Math.abs(
              calculteNumberOfEvents(
                x.start,
                x.end,
                x.eventType === 1 ? eventTime : 40
              )
            );
            let eventTotal = "order";
            let eventTypeStatus = "";

            if (x.eventStatus === 1 || x.eventStatus === 2) {
              eventTotal = "done";
              eventTypeStatus = "doneLesson";
              if (x.eventType === 2) {
                eventTypeStatus = "doneTest";
              } else if (x.eventType === 3) {
                eventTypeStatus = "doneInternal";
              } else if (x.eventType === 4) {
                eventTypeStatus = "doneMeeting";
              }
            } else if (x.eventStatus === 4) {
              eventTotal = "canceled";
              eventTypeStatus = "canceledLesson";
              if (x.eventType === 2) {
                eventTypeStatus = "canceledTest";
              } else if (x.eventType === 3) {
                eventTypeStatus = "canceledInternal";
              } else if (x.eventType === 4) {
                eventTypeStatus = "canceledMeeting";
              }
            } else {
              eventTypeStatus = "orderLesson";
              if (x.eventType === 2) {
                eventTypeStatus = "orderTest";
              } else if (x.eventType === 3) {
                eventTypeStatus = "orderInternal";
              } else if (x.eventType === 4) {
                eventTypeStatus = "orderMeeting";
              }
            }

            if (typeof tableData[license?.name] === "undefined") {
              tableData[license?.name] = [];
            }
            if (typeof tableData[license?.name][month] === "undefined") {
              tableData[license?.name][month] = {};
            }
            typeof tableData[license?.name][month][eventTotal] === "undefined"
              ? (tableData[license?.name][month][eventTotal] = numberOfEvents)
              : (tableData[license?.name][month][eventTotal] += numberOfEvents);
            typeof tableData[license?.name][month][eventTypeStatus] ===
            "undefined"
              ? (tableData[license?.name][month][eventTypeStatus] =
                  numberOfEvents)
              : (tableData[license?.name][month][eventTypeStatus] +=
                  numberOfEvents);

            if (typeof totals[month] === "undefined") {
              totals[month] = {
                order: 0,
                done: 0,
                canceled: 0,
                doneLesson: 0,
                doneTest: 0,
                doneInternal: 0,
                doneMeeting: 0,
                canceledLesson: 0,
                canceledTest: 0,
                canceledInternal: 0,
                canceledMeeting: 0,
                orderLesson: 0,
                orderTest: 0,
                orderInternal: 0,
                orderMeeting: 0,
              };
            }

            if (!monthsInReport.includes(month)) {
              monthsInReport = [...monthsInReport, month];
            }
            totals[month][eventTotal] += numberOfEvents;
            totals[month][eventTypeStatus] += numberOfEvents;
          }
        }
      });
    for (const licenseKey in tableData) {
      for (let index = 0; index < monthsInReport.length; index++) {
        if (Object.hasOwnProperty.call(tableData, licenseKey)) {
          const currMonth = monthsInReport[index];
          const monthTotals = tableData[licenseKey];
          if (typeof monthTotals[currMonth] === "undefined") {
            monthTotals[currMonth] = {
              order: "",
              orderLesson: "",
              orderTest: "",
              orderInternal: "",
              orderMeeting: "",
            };
          }
        }
      }
    }
    setMonthsArray(monthsInReport);
    setTeacherData(tableData);
    setTeacherTotals(totals);
  };

  function calculteNumberOfEvents(start, end, duration) {
    return Math.ceil((new Date(end) - new Date(start)) / (1000 * 60) / 40);
  }

  return (
    <Container>
      <Autocomplete
        id="combo-box-demo"
        options={teachersOptions}
        getOptionLabel={(option) => option.label}
        value={selectedTeacher}
        onChange={(event, value) => setSelectedTeacher(value)}
        style={{ width: "20%" }}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="dense"
            label="בחר מורה"
            variant="outlined"
          />
        )}
      />
      <Table>
        <thead>
          <tr>
            <th> </th>
            <th> </th>
            {monthsArray.map((x, i) => {
              return <th>{x}</th>;
            })}
          </tr>
        </thead>
        {/* <tbody> */}

        {/*       Object.keys(teacherData[x]).map((y) =>
                Object.keys(teacherData[x][y]).map((l, i) => (
                  <tr>
                    <td>{i === 0 ? x : null}</td>
                    <td>{l}</td>
                    {teacherData[x].map((z) => (
                      <td>{z[l]}</td>
                    ))}
                  </tr>
                ))
              ) */}
        {Object.keys(teacherData).map((x, i) => (
          <tbody>
            <tr>
              <td>{x}</td>
              <td style={{ textDecoration: "underline" }}>הזמנה - סה״כ</td>
              {teacherData[x].map((y) => {
                return (
                  <td style={{ textDecoration: "underline" }}>{y["order"]}</td>
                );
              })}
            </tr>
            <tr>
              <td></td>
              <td>שיעורים</td>
              {teacherData[x].map((y) => {
                return <td>{y["orderLesson"]}</td>;
              })}
            </tr>
            <tr>
              <td></td>
              <td>טסטים</td>
              {teacherData[x].map((y) => {
                return <td>{y["orderTest"]}</td>;
              })}
            </tr>
            <tr>
              <td></td>
              <td>מ.פ</td>
              {teacherData[x].map((y) => {
                return <td>{y["orderInternal"]}</td>;
              })}
            </tr>
            <tr>
              <td></td>
              <td>פגישות</td>
              {teacherData[x].map((y) => {
                return <td>{y["orderMeeting"]}</td>;
              })}
            </tr>
            <tr>
              <td></td>
              <td style={{ textDecoration: "underline" }}>בוצע - סה״כ</td>
              {teacherData[x].map((y) => {
                return (
                  <td style={{ textDecoration: "underline" }}>{y["done"]}</td>
                );
              })}
            </tr>
            <tr>
              <td></td>
              <td>שיעורים</td>
              {teacherData[x].map((y) => {
                return <td>{y["doneLesson"]}</td>;
              })}
            </tr>
            <tr>
              <td></td>
              <td>טסטים</td>
              {teacherData[x].map((y) => {
                return <td>{y["doneTest"]}</td>;
              })}
            </tr>
            <tr>
              <td></td>
              <td>מ.פ</td>
              {teacherData[x].map((y) => {
                return <td>{y["doneInternal"]}</td>;
              })}
            </tr>
            <tr>
              <td></td>
              <td>פגישות</td>
              {teacherData[x].map((y) => {
                return <td>{y["doneMeeting"]}</td>;
              })}
            </tr>
            <tr>
              <td></td>
              <td style={{ textDecoration: "underline" }}>בוטל וחויב - סה״כ</td>
              {teacherData[x].map((y) => {
                return (
                  <td style={{ textDecoration: "underline" }}>
                    {y["canceled"]}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td></td>
              <td>שיעורים</td>
              {teacherData[x].map((y) => {
                return <td>{y["canceledLesson"]}</td>;
              })}
            </tr>
            <tr>
              <td></td>
              <td>טסטים</td>
              {teacherData[x].map((y) => {
                return <td>{y["canceledTest"]}</td>;
              })}
            </tr>
            <tr>
              <td></td>
              <td>מ.פ</td>
              {teacherData[x].map((y) => {
                return <td>{y["canceledInternal"]}</td>;
              })}
            </tr>
            <tr>
              <td></td>
              <td>פגישות</td>
              {teacherData[x].map((y) => {
                return <td>{y["canceledMeeting"]}</td>;
              })}
            </tr>
          </tbody>
        ))}
        {/* </tbody> */}
        <tfoot>
          <tr>
            <td>סה״כ למורה</td>
            <td style={{ textDecoration: "underline" }}>בוצע - סה״כ</td>
            {Object.keys(teacherTotals).map((x, i) => {
              return (
                <td style={{ textDecoration: "underline" }}>
                  {teacherTotals[x]["done"]}
                </td>
              );
            })}
          </tr>
          <tr>
            <td></td>
            <td>שיעורים</td>
            {Object.keys(teacherTotals).map((x, i) => {
              return <td>{teacherTotals[x]["doneLesson"]}</td>;
            })}
          </tr>
          <tr>
            <td></td>
            <td>טסטים</td>
            {Object.keys(teacherTotals).map((x, i) => {
              return <td>{teacherTotals[x]["doneTest"]}</td>;
            })}
          </tr>
          <tr>
            <td></td>
            <td>מ.פ</td>
            {Object.keys(teacherTotals).map((x, i) => {
              return <td>{teacherTotals[x]["doneInternal"]}</td>;
            })}
          </tr>
          <tr>
            <td></td>
            <td>פגישות</td>
            {Object.keys(teacherTotals).map((x, i) => {
              return <td>{teacherTotals[x]["doneMeeting"]}</td>;
            })}
          </tr>
          <tr>
            <td></td>
            <td style={{ textDecoration: "underline" }}>בוטל וחויב - סה״כ</td>
            {Object.keys(teacherTotals).map((x, i) => {
              return (
                <td style={{ textDecoration: "underline" }}>
                  {teacherTotals[x]["canceled"]}
                </td>
              );
            })}
          </tr>
          <tr>
            <td></td>
            <td>שיעורים</td>
            {Object.keys(teacherTotals).map((x, i) => {
              return <td>{teacherTotals[x]["canceledLesson"]}</td>;
            })}
          </tr>
          <tr>
            <td></td>
            <td>טסטים</td>
            {Object.keys(teacherTotals).map((x, i) => {
              return <td>{teacherTotals[x]["canceledTest"]}</td>;
            })}
          </tr>
          <tr>
            <td></td>
            <td>מ.פ</td>
            {Object.keys(teacherTotals).map((x, i) => {
              return <td>{teacherTotals[x]["canceledInternal"]}</td>;
            })}
          </tr>
          <tr>
            <td></td>
            <td>פגישות</td>
            {Object.keys(teacherTotals).map((x, i) => {
              return <td>{teacherTotals[x]["canceledMeeting"]}</td>;
            })}
          </tr>
          <tr>
            <td></td>
            <td style={{ textDecoration: "underline" }}>הזמנה - סה״כ</td>
            {Object.keys(teacherTotals).map((x, i) => {
              return (
                <td style={{ textDecoration: "underline" }}>
                  {teacherTotals[x]["order"]}
                </td>
              );
            })}
          </tr>
          <tr>
            <td></td>
            <td>שיעורים</td>
            {Object.keys(teacherTotals).map((x, i) => {
              return <td>{teacherTotals[x]["orderLesson"]}</td>;
            })}
          </tr>
          <tr>
            <td></td>
            <td>טסטים</td>
            {Object.keys(teacherTotals).map((x, i) => {
              return <td>{teacherTotals[x]["orderTest"]}</td>;
            })}
          </tr>
          <tr>
            <td></td>
            <td>מ.פ</td>
            {Object.keys(teacherTotals).map((x, i) => {
              return <td>{teacherTotals[x]["orderInternal"]}</td>;
            })}
          </tr>
          <tr>
            <td></td>
            <td>מפגשים</td>
            {Object.keys(teacherTotals).map((x, i) => {
              return <td>{teacherTotals[x]["orderMeeting"]}</td>;
            })}
          </tr>
        </tfoot>
      </Table>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isLoggedIn: state.session.isLoggedIn,
    userId: state.session.user.id,
    user: state.session.user,
    events: state.CalendarReducer.events,
    isUsersLoaded: state.UsersReducer.isLoaded,
    users: state.UsersReducer.users,
    eventTime: state.session.user.eventTime,
    teacherWeeklyOnlyHimself: state.session.user.teacherWeeklyOnlyHimself,
  };
}

export default connect(mapStateToProps, {
  getAllTeacherEventsWithCanceled,
  getUsers,
  getVehicles,
  getLicenseTypes,
})(TeacherWeekly);
