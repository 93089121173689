import React from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  multilineColor: {
    color: "red",
  },
  multilineColorGreen: {
    color: "green",
  },
});

const DefaultInput = ({
  input,
  placeholder,
  autoFocus,
  meta: { touched, error },
  disabled,
  type,
  fullWidth = true,
  classes,
  isNegativeNumberPossible = false,
}) => {
  return (
    <TextField
      id="outlined-basic"
      margin="dense"
      autoFocus={autoFocus}
      label={placeholder}
      fullWidth={fullWidth}
      disabled={disabled}
      {...input}
      variant="outlined"
      name="password"
      autoComplete="off"
      type={type}
      error={touched && error}
      InputProps={
        isNegativeNumberPossible &&
        (type === "number" && parseInt(input.value) < 0
          ? {
              className: classes.multilineColor,
            }
          : { className: classes.multilineColorGreen })
      }
    />
  );
};

export default withStyles(styles)(DefaultInput);
