import styled from "styled-components";
import { isTablet, isMobileOnly, isMobile } from "react-device-detect";

export const Icon = styled.img`
  ${(props) => (props.isRtl ? "padding-left: 1vw;" : "")};
`;

export const Container = styled.div`
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: ${isTablet ? "1.5vw" : "5vw"};
    height: ${isTablet ? "1.5vw" : "5vw"};
    left: 10px;
    top: 1vh;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  .bm-menu-wrap {
    position: fixed;
    width: fit-content !important;
    // height: fit-content !important;
    max-height: 56vh;
  }

  /* General sidebar styles */
  .bm-menu {
    background: white;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
`;

export const Category = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const CategoryText = styled.span`
  font-size: 2.5vh;
  display: flex;
  flex-direction: ${(props) => (props.isRtl ? "row-reverse" : "row")};
`;

export const Item = styled.div`
  padding-top: 1.5vh;
  display: flex;
  flex-direction: ${(props) => (props.isRtl ? "row-reverse" : "row")};
  color: ${(props) => (props.isSelected ? "#cb9d3c;" : "black")};
  display: ${(props) => (props.display ? "" : "none")};

  &:hover {
    font-weight: bold;
  }
`;

export const MenuContainer = styled.div`
  height: 90vh;
  padding-top: 0.5vh;
  border-right: 1px solid #ffefca;

  width: ${(props) => (props.isFold ? "1vw" : "13vw")};
`;

export const CategoryItems = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 2vh;
`;

export const CategoryTitle = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isRtl ? "row-reverse" : "row")};
  justify-content: space-between;
  height: 8vh;
  align-items: center;
  border-left: ${(props) =>
    props.isSelected ? "5px solid #d4ae5e;" : "5px solid white;"};
  background: ${(props) =>
    props.isSelected ? "linear-gradient(to right, #ffefca -43vw, white);" : ""};
  color: ${(props) => (props.isSelected ? "#cb9d3c;" : "black")};

  &:hover {
    background-color: #fdfaf4;
  }
`;
