import React from "react";
import { Field, reduxForm } from "redux-form";
import i18n from "../../../../lib/i18n/i18n";
import Toggle from "react-toggle";
import {
  ToggleContainer,
  ToggleText,
  AdditionalDetailsContainer,
  Fields,
} from "../permissions.styles";

const renderToggle = ({ input, label }) => {
  const isHebrew = i18n.language === "he";
  return (
    <ToggleContainer isRtl={isHebrew}>
      <ToggleText>{label}</ToggleText>
      <Toggle checked={input.value} onChange={input.onChange} />
    </ToggleContainer>
  );
};

const StudentsPermissions = () => {
  return (
    <Fields>
      <AdditionalDetailsContainer>
        <Field
          label="תלמידים חדשים לפי חודשים"
          component={renderToggle}
          name="newStudentsByMonth"
        />
        <Field
          label="תלמידים חדשים מול עברו טסט"
          component={renderToggle}
          name="newStudentsVSPassedTests"
        />
        <Field
          label="טסטים לפי חודש"
          component={renderToggle}
          name="testByMonth"
        />
        <Field
          label="התפלגות חודשית לטסטים"
          component={renderToggle}
          name="testTypesByMonth"
        />
        <Field
          label="כמות שיעורים לפי חודש"
          component={renderToggle}
          name="lessonsByMonth"
        />
        <Field
          label="הכנסות לפי חודשים"
          component={renderToggle}
          name="incomesByMonth"
        />
        <Field
          label="סטטיסטיקות טסטים"
          component={renderToggle}
          name="testStatistics"
        />
        <Field
          label="סוג מסמך לפני חודש"
          component={renderToggle}
          name="invoicesTypesByMonth"
        />
        <Field
          label="תלמידים חדשים לפי יום"
          component={renderToggle}
          name="newStudentsByDay"
        />
      </AdditionalDetailsContainer>
    </Fields>
  );
};

export default reduxForm({
  form: "permissions", // a unique identifier for this form
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  enableReinitialize: true, // this is needed!!
})(StudentsPermissions);
