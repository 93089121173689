import React, { useState } from "react";
import SivanModal from "../../../Modal/Modal";
import DatePicker from "react-datepicker";
import {
  CreateButton,
  Body,
  Section,
  Input,
  AdvancesToIrsForm,
  AddMoreText,
} from "./advancesToIrsModal.styles";

const NewAgreement = ({ isModalOpen, closeModal }) => {
  const [startDate, setStartDate] = useState(null);
  return (
    <SivanModal
      titleText="Advances To The IRS"
      isModalOpen={isModalOpen}
      closeModal={closeModal}
    >
      <AdvancesToIrsForm>
        <Body>
          <Section>
            <Input placeholder="Advance Payment Percentage" />
            <span>Until</span>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
              placeholderText="Certificate Of Good Conduct"
              className="date-input"
            />
          </Section>
          <AddMoreText>+ Add More</AddMoreText>
        </Body>
        <CreateButton>Save Changes</CreateButton>
      </AdvancesToIrsForm>
    </SivanModal>
  );
};

export default NewAgreement;
