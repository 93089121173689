import React, { useEffect } from "react";
import { Table, Row, Col, Button, Input, CustomInput } from "reactstrap";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import { Container } from "./sivanPlusTable.styles";
import { Filter, DefaultColumnFilter } from "./filters";
import { useTranslation } from "react-i18next";
import { matchSorter } from "match-sorter";
import "bootstrap/dist/css/bootstrap.css";
import FadeLoader from "react-spinners/FadeLoader";

const SivanPlusTable = ({
  data,
  componentColumns,
  onRowClick,
  isLoading,
  height,
  setFilteredData = () => {},
}) => {
  const { t, i18n } = useTranslation("common");

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
    }),
    []
  );

  const rowInfo = (rowObject, cell) => {
    if (onRowClick) onRowClick({ ...cell, ...rowObject });
  };

  const columns = React.useMemo(() => componentColumns, [componentColumns]);

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    pageCount,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: 0, pageSize: 100 },
      filterTypes,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (rows.length > 0) setFilteredData(rows);
  }, [rows.length]);

  return (
    <Container height={height}>
      {isLoading && (
        <div
          style={{
            height: "73%",
            width: "82%",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 5454,
          }}
        >
          <FadeLoader size={150} color={"#123abc"} loading={true} />
        </div>
      )}
      <Table
        bordered
        hover
        {...getTableProps()}
        id="table-to-xls"
        dir={i18n.language === "en" ? "ltr" : "rtl"}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  style={{ textAlign: "center" }}
                >
                  <div>
                    <span {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </div>
                  <Filter column={column} />
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      style={{
                        textAlign: "center",
                      }}
                      {...cell.getCellProps({
                        onClick: () => rowInfo(row, cell),
                      })}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Row style={{ maxWidth: 1000, margin: "0 auto", textAlign: "center" }}>
        <Col md={3}>
          <Button
            color="primary"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </Button>
          <Button
            color="primary"
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            {"<"}
          </Button>
        </Col>
        <Col md={2} style={{ marginTop: 7 }}>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </Col>
        <Col md={2}>
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>
        <Col md={2}>
          <CustomInput
            type="select"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[50, 100, 150, 200, 250].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </CustomInput>
        </Col>
        <Col md={3}>
          <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
            {">"}
          </Button>
          <Button
            color="primary"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SivanPlusTable;
