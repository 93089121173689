import {
  LEARNING_ASSIGNMENTS_FETCH_SUCCESS,
  LEARNING_ASSIGNMENTS_FETCH_FAILED,
  LEARNING_ASSIGNMENTS_FETCH_STARTED,
} from "../constants/actionTypes";

const INITIAL_STATE = {
  isPending: false,
  learningAssignments: [{ categoryName: "", chapter: 1 }],
  isLoaded: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LEARNING_ASSIGNMENTS_FETCH_SUCCESS:
      return { ...state, learningAssignments: action.payload, isLoaded: true };
    case LEARNING_ASSIGNMENTS_FETCH_FAILED:
      return { ...state, isPending: false };
    case LEARNING_ASSIGNMENTS_FETCH_STARTED:
      return { ...state, isPending: true };
    default:
      return state;
  }
}
