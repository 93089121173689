import React, { useEffect, useState } from "react";
import i18n from "../../../lib/i18n/i18n";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchTextBook,
  getStudentTextBooks,
} from "../../../actions/textBooksActions";
import { Container, Row, Table } from "./studentBasicInfo.styles";
import { getStudent } from "../../../actions/studentesActions";
import { getLicenseTypes } from "../../../actions/licenseTypes";
import { getVehicles } from "../../../actions/vehicleActions";
import useVehicles from "../../useComponents/useVehicles";
import {
  getAgreements,
  getAgreementDetails,
} from "../../../actions/agreementsActions";
import useAgreements from "../../useComponents/useAgreements";
import Assignment from "../../Students/StudentUpdate/TextBooks/EditTextBook/Assignment/Assignment";
import EditTextBook from "../../Students/StudentUpdate/TextBooks/EditTextBook/EditTextBook";
import { getStudentDiscountsAndObligations } from "../../../actions/discountsAndObligationsActions";
import { getBranches } from "../../../actions/branchActions";
import { getUsers } from "../../../actions/userActions";
import {
  // getStudentEventsByTextBook,
  getStudentEvents,
  // updateEventPrice,
} from "../../../actions/schedulerActions";
import { getStudentIncomes } from "../../../actions/incomesAction";
import EditAgreement from "../../Students/Agreements/EditAgreement/EditAgreement";

import moment from "moment";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const StudentBasicInfo = ({
  studentId,
  getStudent,
  getBranches,
  getStudentTextBooks,
  fetchTextBook,
  getUsers,
  isUsersLoaded,
  users,
  getVehicles,
  vehicles,
  isVehiclesLoaded,
  isLicenseTypesLoaded,
  licenseTypes,
  getLicenseTypes,
  getStudentEvents,
  getStudentIncomes,
  getStudentDiscountsAndObligations,
  getAgreementDetails,
  close,
  handleTeacherChange,
  dateChange,
  openStudentMenuModal,
  history,
  user,
}) => {
  const [studentBranch, setStudentBranch] = useState("");
  const [studentData, setStudentData] = useState("");
  const [studentName, setStudentName] = useState("");
  const [studentAddress, setStudentAddress] = useState("");
  const [studentPhone, setStudentPhone] = useState("");
  const [studentTeacher, setStudentTeacher] = useState("");
  const [studentVehicle, setStudentVehicle] = useState("");
  const [studentLicense, setStudentLicense] = useState("");
  const [studentEyeCheck, setStudentEyeCheck] = useState(null);
  const [studentBrosh, setStudentBrosh] = useState(null);
  const [studentObligations, setStudentObligations] = useState(0);
  const [studentDiscounts, setStudentDiscounts] = useState(0);
  const [studentBalance, setStudentBalance] = useState(0);
  const [studentPaid, setStudentPaid] = useState(0);
  const [studentFutureBalance, setStudentFutureBalance] = useState(0);
  const [studentLessonsDone, setStudentLessonsDone] = useState(0);
  const [studentFutureLessons, setStudentFutureLessons] = useState(0);
  const [studentInternalTests, setStudentInternalTests] = useState(0);
  const [studentFutureInternalTests, setStudentFutureInternalTests] =
    useState(0);
  const [studentTests, setStudentTests] = useState(0);
  const [studentFutureTests, setStudentFutureTests] = useState(0);
  const [studentCanceledLessons, setStudentCanceledLessons] = useState(0);
  const [agreement, setAgreement] = useState("");
  const [
    studentCanceledAndChargedLessons,
    setStudentCanceledAndChargedLessons,
  ] = useState(0);
  const [studentFirstFutureLessonDate, setStudentFirstFutureLessonDate] =
    useState(new Date());
  const [studentInternalTestDate, setStudentInternalTestDate] = useState(
    new Date()
  );
  const [studentTestDate, setStudentTestDate] = useState(new Date());
  const [textbookId, setTextbookId] = useState(0);
  const [isEditAgreementModalOpen, setEditAgreementModalIsOpen] =
    useState(false);
  const [agreementData, setAgreementData] = useState({});

  useEffect(() => {
    (async () => {
      if (!isUsersLoaded) {
        await getUsers();
      }
      document.addEventListener("mousedown", handleClickOutside);
      const allBranches = await getBranches();

      const studentData = await getStudent(studentId);
      setStudentBranch(
        allBranches.find((x) => x.id === studentData[0].branchId)?.name
      );
      setStudentData(studentData[0]);
      setStudentName(`${studentData[0].firstName} ${studentData[0].lastName}`);
      setStudentAddress(`${studentData[0].address} ${studentData[0].city}`);
      setStudentPhone(studentData[0].phone);
    })();
  }, [studentId]);

  const handleClickOutside = (event) => {
    if (event.target.className === "fa fa-arrow-right") {
      close();
    }
  };

  useEffect(() => {
    (async () => {
      if (!isVehiclesLoaded) {
        await getVehicles();
      }
      if (!isLicenseTypesLoaded) {
        await getLicenseTypes();
      }
    })();
  }, []);

  useEffect(() => {
    if (isVehiclesLoaded && isLicenseTypesLoaded)
      (async () => {
        const fetchedStundetTextBooks = await getStudentTextBooks(studentId);
        if (fetchedStundetTextBooks.length > 1) {
        } else {
          const teacher = users.find(
            (y) =>
              y.id.toString() ===
              fetchedStundetTextBooks[0].teacherId.toString()
          );
          setStudentTeacher({
            value: fetchedStundetTextBooks[0].teacherId,
            label: `${teacher.firstName} ${teacher.lastName}`,
          });
          const vehicle = vehicles.find(
            (y) =>
              y.id.toString() ===
              fetchedStundetTextBooks[0].vehicleId.toString()
          );
          setStudentVehicle({ value: vehicle.id, label: vehicle.description });

          const licenseType = licenseTypes.find(
            (y) => y.id.toString() === vehicle.licenseType.toString()
          );
          setStudentLicense(licenseType.name);
          setStudentEyeCheck(fetchedStundetTextBooks[0].eyeExamination);
          setStudentBrosh(fetchedStundetTextBooks[0].documentCheck);
          await calculateStudentBalance(fetchedStundetTextBooks);
        }
      })();
  }, [isVehiclesLoaded, isLicenseTypesLoaded, studentId]);

  const calculateStudentBalance = async (studentTextBooks) => {
    const incomes = await getStudentIncomes(studentId);
    const discountsAndObligations = await getStudentDiscountsAndObligations(
      studentId
    );

    let discounts = 0;
    let obligations = 0;
    let paid = 0;
    for (let index = 0; index < discountsAndObligations.length; index++) {
      const element = discountsAndObligations[index];
      if (element.isDiscount) {
        discounts += element.amount;
      } else {
        obligations -= element.amount;
      }
    }

    for (let index = 0; index < incomes.length; index++) {
      const element = incomes[index];
      if (element.documentType === 0 || element.documentType === 1) {
        paid += element.amount;
      } else if (element.documentType === 3 || element.documentType === 4) {
        paid -= element.amount;
      }
    }

    setStudentObligations(obligations);
    setStudentDiscounts(discounts);
    setStudentPaid(paid);
    await studentCalendarEventsData(
      paid,
      studentTextBooks,
      discounts,
      obligations
    );
  };

  const findActiveAgreement = (agreements, startDateFieldName, start) => {
    const agreementsArray = agreements.sort(
      (a, b) =>
        new Date(b[startDateFieldName]) - new Date(a[startDateFieldName])
    );
    let index = 0;
    let tata = true;
    for (index; index < agreementsArray.length; index++) {
      const element = agreementsArray[index];
      if (!moment(element[startDateFieldName]).isSameOrAfter(start)) {
        tata = false;
        break;
      }
    }
    if (tata) {
      index = index - 1;
    }
    return agreementsArray[index];
  };

  const getTextBookAgreementOpeningBalance = async (agreements) => {
    let openingBalance = 0;
    for (let index = 0; index < agreements.length; index++) {
      const element = agreements[index];
      const startDate = element.startDate ? element.startDate : new Date(2001);

      if (moment(new Date()).isAfter(startDate)) {
        const agreementsDetails = await getAgreementDetails(
          element.agreementId
        );

        const activeAgreementAmount = findActiveAgreement(
          agreementsDetails["amountAgreementDetails"],
          "from",
          new Date(element.startDate)
        );
        openingBalance += activeAgreementAmount?.price || 0;
      }
    }
    return openingBalance;
  };

  const studentCalendarEventsData = async (
    paid,
    studentTextBooks,
    discounts,
    obligations
  ) => {
    const events = await getStudentEvents(studentId);
    let lessons = 0;
    let futureLessons = 0;
    let internalTests = 0;
    let futureInternalTests = 0;
    let tests = 0;
    let futureTests = 0;
    let canceledLessons = 0;
    let chargedAndCanceledLessons = 0;
    let balance = paid + discounts + obligations;

    setAgreement(studentTextBooks[studentTextBooks.length - 1].description);
    for (let index = 0; index < studentTextBooks.length; index++) {
      const element = studentTextBooks[index];
      const textBookDetails = await fetchTextBook(element.textBookId);
      setTextbookId(element.textBookId);
      const agreementsDetails = await getAgreementDetails(
        textBookDetails.agreements[0].agreementId
      );
      balance += parseFloat(textBookDetails.openingBalance) || 0;

      balance -= await getTextBookAgreementOpeningBalance(
        textBookDetails.agreements
      );
      lessons += element.remaininglessonsInMinutes / 40;
      internalTests += element.remainingInternalTests;
      tests += element.remainingTests;

      if (studentTextBooks.length - 1 === index) {
        setAgreementData({ ...agreementsDetails });
      }
    }

    let futureBalance = 0;
    let alreadySetFutureLesson = false;

    const today = new Date();
    for (let index = 0; index < events.length; index++) {
      const event = events[index];
      const numberOfEventsToAdd = calculteNumberOfEvents(
        event.start,
        event.end
      );
      if (event.eventType !== 4) {
        if (
          event.eventType === 1 &&
          event.eventStatus !== 3 &&
          event.eventStatus !== 4
        ) {
          if (moment(event.start).isSameOrBefore(today)) {
            lessons += numberOfEventsToAdd;
            balance -= event.price;
          } else {
            futureLessons += numberOfEventsToAdd;
            futureBalance -= event.price;
            if (!alreadySetFutureLesson) {
              setStudentFirstFutureLessonDate(new Date(event.start));
              alreadySetFutureLesson = true;
            }
          }
        } else if (
          event.eventType === 2 &&
          event.eventStatus !== 3 &&
          event.eventStatus !== 4
        ) {
          if (moment(event.start).isSameOrBefore(today)) {
            tests += numberOfEventsToAdd;
            balance -= event.price;
          } else {
            futureTests += numberOfEventsToAdd;
            futureBalance -= event.price;
            setStudentTestDate(new Date(event.start));
          }
        } else if (
          event.eventType === 3 &&
          event.eventStatus !== 3 &&
          event.eventStatus !== 4
        ) {
          if (moment(event.start).isSameOrBefore(today)) {
            internalTests += numberOfEventsToAdd;
            balance -= event.price;
          } else {
            futureInternalTests += numberOfEventsToAdd;
            setStudentInternalTestDate(new Date(event.start));
            futureBalance -= event.price;
          }
        } else if (event.eventType === 1 && event.eventStatus === 3) {
          canceledLessons += numberOfEventsToAdd;
        } else if (event.eventType === 1 && event.eventStatus === 4) {
          chargedAndCanceledLessons += numberOfEventsToAdd;
          if (moment(event.start).isSameOrBefore(today)) {
            balance -= event.price;
          } else {
            futureBalance -= event.price;
          }
        }
      }
    }
    futureBalance += balance;
    setStudentBalance(balance);
    setStudentFutureBalance(futureBalance);
    setStudentLessonsDone(lessons);
    setStudentFutureLessons(futureLessons);
    setStudentInternalTests(internalTests);
    setStudentFutureInternalTests(futureInternalTests);
    setStudentFutureTests(futureTests);
    setStudentTests(tests);
    setStudentCanceledLessons(canceledLessons);
    setStudentCanceledAndChargedLessons(chargedAndCanceledLessons);
  };

  function calculteNumberOfEvents(start, end) {
    return Math.ceil(
      (new Date(end) - new Date(start)) /
        (1000 * 60) /
        user.calendarSpacingInMinutes
    );
  }

  const onClickAgreement = () => {
    setEditAgreementModalIsOpen(true);
  };

  return (
    <Container>
      <Table>
        <tr
          onClick={() =>
            history.push(`/updateStudent`, {
              studentData,
              view: dictionary.editStudent.ticket,
            })
          }
          style={{ color: "#0064ff", cursor: "pointer" }}
        >
          <Row>שם תלמיד:</Row>
          <Row>{studentName}</Row>
        </tr>
        <tr>
          <Row>טלפון:</Row>
          <Row>{studentPhone}</Row>
        </tr>
        <tr>
          <Row>תז:</Row>
          <Row>{studentId}</Row>
        </tr>
        <tr
          onClick={() => handleTeacherChange([studentTeacher])}
          style={{ color: "#0064ff", cursor: "pointer" }}
        >
          <Row>מורה:</Row>
          <Row>{studentTeacher.label}</Row>
        </tr>
        <tr>
          <Row>סניף:</Row>
          <Row>{studentBranch}</Row>
        </tr>
        <tr>
          <Row>סוג רישיון:</Row>
          <Row>{studentLicense}</Row>
        </tr>
        <tr>
          <Row>רכב:</Row>
          <Row>{studentVehicle.label}</Row>
        </tr>
        {studentAddress !== "null null" && (
          <tr>
            <Row>כתובת:</Row>
            <Row>{studentAddress}</Row>
          </tr>
        )}
      </Table>
      <Table>
        <tr>
          <Row>ש.שבוצעו:</Row>
          <Row>{studentLessonsDone}</Row>
        </tr>
        <tr>
          <Row>מ.פ שבוצעו:</Row>
          <Row>{studentInternalTests}</Row>
        </tr>
        <tr>
          <Row>טסטים שבוצעו:</Row>
          <Row>{studentTests}</Row>
        </tr>
        <tr
          onClick={() => {
            dateChange(studentFirstFutureLessonDate);
            handleTeacherChange([studentTeacher]);
          }}
          style={{ color: "#0064ff", cursor: "pointer" }}
        >
          <Row>ש.בהזמנה:</Row>
          <Row>{studentFutureLessons}</Row>
        </tr>
        <tr
          onClick={() => {
            dateChange(studentInternalTestDate);
            handleTeacherChange([studentTeacher]);
          }}
          style={{ color: "#0064ff", cursor: "pointer" }}
        >
          <Row>מ.פ בהזמנה:</Row>
          <Row>{studentFutureInternalTests}</Row>
        </tr>
        <tr
          onClick={() => {
            dateChange(studentTestDate);
            handleTeacherChange([studentTeacher]);
          }}
          style={{ color: "#0064ff", cursor: "pointer" }}
        >
          <Row>טסטים בהזמנה:</Row>
          <Row>{studentFutureTests}</Row>
        </tr>
      </Table>
      <Table>
        <tr>
          <Row>יתרה:</Row>
          <Row style={{ color: studentBalance < 0 ? "red" : "green" }}>
            {studentBalance}
          </Row>
        </tr>
        <tr>
          <Row>יתרה + הזמנות:</Row>
          <Row style={{ color: studentFutureBalance < 0 ? "red" : "green" }}>
            {studentFutureBalance}
          </Row>
        </tr>
        <tr>
          <Row>הנחות:</Row>
          <Row>{studentDiscounts}</Row>
        </tr>
        <tr>
          <Row>חיובים:</Row>
          <Row>{studentObligations}</Row>
        </tr>
        <tr>
          <Row>ש.שבוטלו:</Row>
          <Row>{studentCanceledLessons}</Row>
        </tr>
        <tr>
          <Row>ש.שבוטל וחויב:</Row>
          <Row>{studentCanceledAndChargedLessons}</Row>
        </tr>
        <tr>
          <Row>סהכ ששולם:</Row>
          <Row style={{ color: "green" }}>{studentPaid}</Row>
        </tr>
      </Table>
      <Table>
        <tr
          onClick={() =>
            openStudentMenuModal({
              eventType: 1,
              studentId: studentId,
              textBookId: textbookId,
              vehicleId: studentVehicle.value,
              firstName: studentName,
            })
          }
          style={{ color: "#0064ff", cursor: "pointer" }}
        >
          <Row> בדיקת עיניים:</Row>
          <Row>
            {studentEyeCheck !== null &&
              moment(studentEyeCheck).format("DD/MM/YYYY")}
          </Row>
        </tr>
        <tr
          onClick={() =>
            openStudentMenuModal({
              eventType: 1,
              studentId: studentId,
              textBookId: textbookId,
              vehicleId: studentVehicle.value,
              firstName: studentName,
            })
          }
          style={{ color: "#0064ff", cursor: "pointer" }}
        >
          <Row>תאריך ברוש:</Row>
          <Row>
            {studentBrosh !== null && moment(studentBrosh).format("DD/MM/YYYY")}
          </Row>
        </tr>
        <tr
          onClick={onClickAgreement}
          style={{ color: "#0064ff", cursor: "pointer" }}
        >
          <Row>הסכם:</Row>
          <Row>{agreement}</Row>
        </tr>
      </Table>
      {isEditAgreementModalOpen && (
        <EditAgreement
          isEditAgreementModalOpen={isEditAgreementModalOpen}
          setEditAgreementModalIsOpen={setEditAgreementModalIsOpen}
          agreementData={agreementData}
        />
      )}
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    user: state.session.user,
    vehicles: state.VehiclesReducer.vehicles,
    licenseTypes: state.LicenseTypesReducer.licenseTypes,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    isLicenseTypesLoaded: state.LicenseTypesReducer.isLoaded,
    isUsersLoaded: state.UsersReducer.isLoaded,
    users: state.UsersReducer.users,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getStudent,
    getVehicles,
    fetchTextBook,
    getAgreements,
    getLicenseTypes,
    getBranches,
    getStudentTextBooks,
    getUsers,
    getStudentEvents,
    getStudentIncomes,
    getStudentDiscountsAndObligations,
    getAgreementDetails,
  })(StudentBasicInfo)
);
