import React from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import i18n from "../../../lib/i18n/i18n";
import {
  Container,
  Row,
  Column,
  Title,
  ColumnsContainer,
  AdditionalDetailsContainer,
  Input,
} from "./expired.styles";

const renderInput = ({
  input,
  placeholder,
  meta: { touched, error },
  type = "text",
}) => {
  const isHebrew = i18n.language === "he";
  const dir = isHebrew ? "rtl" : "ltr";
  return (
    <Input
      isRtl={isHebrew}
      dir={dir}
      type={type}
      placeholder={placeholder}
      {...input}
      isError={touched && error}
      autocomplete="off"
    />
  );
};

const Expired = () => {
  return (
    <Container>
      <Row>
        <Title>Validity</Title>
        <AdditionalDetailsContainer>
          <Field name="address" component={renderInput} placeholder="Theory" />
          <Field name="address" component={renderInput} placeholder="d.m.v" />
          <Field
            name="address"
            component={renderInput}
            placeholder="A Medical Examination"
          />
          <Field
            name="address"
            component={renderInput}
            placeholder="Eye Examination"
          />
          <Field
            name="address"
            component={renderInput}
            placeholder="Certificate Of Good Conduct"
          />
          <Field
            name="address"
            component={renderInput}
            placeholder="Medical Institute"
          />
          <Field
            name="address"
            component={renderInput}
            placeholder="A Theoretical Course"
          />
        </AdditionalDetailsContainer>
      </Row>
      <Row>
        <Title>Warning Times</Title>
        <AdditionalDetailsContainer>
          <Field name="address" component={renderInput} placeholder="Theory" />
          <Field name="address" component={renderInput} placeholder="d.m.v" />
          <Field
            name="address"
            component={renderInput}
            placeholder="A Medical Examination"
          />
          <Field
            name="address"
            component={renderInput}
            placeholder="Eye Examination"
          />
          <Field
            name="address"
            component={renderInput}
            placeholder="Certificate Of Good Conduct"
          />
          <Field
            name="address"
            component={renderInput}
            placeholder="Medical Institute"
          />
          <Field
            name="address"
            component={renderInput}
            placeholder="A Theoretical Course"
          />
        </AdditionalDetailsContainer>
      </Row>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isPending: state.StudentsReducer.isPending,
    initialValues: {
      registrationFees: false,
      smsOnEventCreation: false,
      glasses: false,
      registrationFees: false,
    },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "settingsGeneral",
  })(Expired)
);
