import LanguageDetector from "i18next-browser-languagedetector";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import common_he from "./locales/he/translation.json";
import common_en from "./locales/en/translation.json";

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "he",
    react: {
      useSuspense: false,
    },
    fallbackLng: "en",
    resources: {
      en: {
        common: common_en,
      },
      he: {
        common: common_he,
      },
      interpolation: {
        escapeValue: false,
      },
    },
  });

export default i18next;
