import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import SivanModal from "../../../Modal/Modal";
import SivanQuestionModal from "../../../Modal/QuestionModal";
import { Field, reduxForm, change } from "redux-form";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import i18n from "../../../../lib/i18n/i18n";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { connect } from "react-redux";
import validate from "./validate";
import "react-toggle/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import {
  Body,
  AdditionalDetailsContainer,
  Button,
  Fields,
  ButtonContainer,
  CheckBoxContainer,
  RowFieldsContainer,
  DayContainer,
} from "./editVehicle.styles";
import {
  updateVehicle,
  setVehicleDisabled,
  deleteVehicle,
} from "../../../../actions/vehicleActions";
import { getLicenseTypes } from "../../../../actions/licenseTypes";
import InputWrappedWithField from "../../../UiComponents/Inputs/DefaultWrappedWithField";
import DefaultSelect from "../../../UiComponents/Select/Default";

const currLanguage = i18n.language;
const licenseTypeOptions = [{ value: 1, label: "B" }];
const deleteText = "שים לב שהרכב ימחק מהמערכת ואין דרך לשחזר אותו";
const disableText = "שים לב שהרכב יבוטל ולא ניתן יהיה לעשות עליו פעולות";
const deleteTitle = "מחיקת רכב";
const disableTitle = "הפיכת הרכב ללא פעיל";

const renderDatePickerField = ({ input, placeholder, meta: { error } }) => {
  const isHebrew = i18n.language === "he";
  const dir = isHebrew ? "rtl" : "ltr";
  return (
    <div>
      <span style={{ display: "block" }} dir="auto">
        {placeholder}
      </span>
      <DatePicker
        selected={input.value}
        dir={dir}
        onChange={input.onChange}
        placeholderText={placeholder}
        dateFormat="dd/MM/yyyy"
        className={`${error && "error"} date-input`}
        locale={i18n.language}
      />
    </div>
  );
};

const renderCheckBox = ({ input, label }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          checked={true}
          checked={input.value}
          onChange={input.onChange}
        />
      }
      label={label}
      labelPlacement="end"
    />
  );
};

Modal.setAppElement("#root");

const EditVehicle = ({
  isEditVehicleModalOpen,
  setEditVehicleModalIsOpen,
  vehicleData,
  handleSubmit,
  isPending,
  updateVehicle,
  branchOptions,
  setVehicleDisabled,
  deleteVehicle,
  getLicenseTypes,
  licenseTypes,
  change,
}) => {
  const { t, i18n } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);
  const [licenseTypesOptions, setLicenseTypesOptions] = useState([]);
  const [isDelete, setIsDelete] = useState(true);
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      if (licenseTypes.length === 0) {
        await getLicenseTypes();
      } else {
        let optionsArray = [];
        licenseTypes.map(({ id, name }) =>
          optionsArray.push({ value: id, label: name })
        );
        optionsArray.find((x) => x.value === vehicleData.licenseType);
        setLicenseTypesOptions(optionsArray);
        change(
          "licenseType",
          optionsArray.find((x) => x.value === vehicleData.licenseType)
        );
      }
      setIsLoading(false);
    }
    fetchData();
  }, [licenseTypes]);

  const onSubmitEditEvent = async (formValues) => {
    setIsLoading(true);
    const { sunday, monday, tuesday, wednesday, thursday, friday, saturday } =
      formValues;
    const days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    const day = days[new Date().getDay()];
    await updateVehicle(
      {
        ...formValues,
        sunday: sunday.value,
        branchName: formValues[day].label,
        monday: monday.value,
        tuesday: tuesday.value,
        wednesday: wednesday.value,
        thursday: thursday.value,
        friday: friday.value,
        saturday: saturday.value,
      },
      vehicleData.id
    );
    setIsLoading(false);
    setEditVehicleModalIsOpen(false);
  };

  const setActiveOrInactive = async () => {
    setIsLoading(true);
    vehicleData.active === 1
      ? await setVehicleDisabled(vehicleData.id)
      : await onSubmitEditEvent({
          ...vehicleData,
          active: 1,
          licenseType: { value: 1 },
        });
    setIsLoading(false);
    setEditVehicleModalIsOpen(false);
  };

  const deleteVehicleAction = async () => {
    await deleteVehicle(vehicleData.id);
    setEditVehicleModalIsOpen(false);
  };

  const openQuestionModal = (isModalForDelete) => {
    setIsDelete(isModalForDelete);
    setIsQuestionModalOpen(true);
  };

  return (
    <SivanModal
      titleText={t("editVehicle.title")}
      modalIsOpen={isEditVehicleModalOpen}
      closeModal={() => setEditVehicleModalIsOpen(false)}
      isLoading={isLoading}
    >
      <Body onSubmit={handleSubmit(onSubmitEditEvent)}>
        <SivanQuestionModal
          titleText={isDelete ? deleteTitle : disableTitle}
          bodySecondRow={isDelete ? deleteText : disableText}
          modalIsOpen={isQuestionModalOpen}
          closeModal={() => setIsQuestionModalOpen(false)}
          deleteItem={deleteVehicleAction}
          disableItem={setActiveOrInactive}
          isDelete={isDelete}
        />
        <Fields>
          <RowFieldsContainer>
            <AdditionalDetailsContainer>
              <Field
                name="vehicleNumber"
                component={InputWrappedWithField}
                placeholder={t("createVehicle.vehicleNumber")}
                type="number"
              />
              <Field
                name="comprehensiveInsurance"
                component={renderDatePickerField}
                placeholder={t("createVehicle.comprehensiveDate")}
              />
              <Field
                name="insurance"
                component={renderDatePickerField}
                placeholder={t("createVehicle.insuranceUntil")}
              />
              <Field
                name="carLicense"
                component={renderDatePickerField}
                placeholder={t("createVehicle.carLicenseUntil")}
              />
            </AdditionalDetailsContainer>
            <AdditionalDetailsContainer>
              <Field
                name="alertBeforeExpiration"
                component={InputWrappedWithField}
                placeholder={t("createVehicle.alertBeforeExpiration")}
                type="number"
              />
              <Field
                name="licenseType"
                component={DefaultSelect}
                placeholder={t("createVehicle.teachingLicenseType")}
                options={licenseTypesOptions}
              />
              <Field
                name="description"
                component={InputWrappedWithField}
                placeholder={t("createVehicle.description")}
              />
              <Field
                name="internalDuration"
                component={InputWrappedWithField}
                placeholder={t("createVehicle.internalDuration")}
                type="number"
              />
              <CheckBoxContainer>
                <Field
                  name="reportOz"
                  component={renderCheckBox}
                  label={t("createVehicle.reportOz")}
                />
              </CheckBoxContainer>
            </AdditionalDetailsContainer>
          </RowFieldsContainer>
          <RowFieldsContainer>
            <DayContainer>
              <label>א</label>
              <Field
                name="sunday"
                component={DefaultSelect}
                placeholder={t("createVehicle.branch")}
                options={branchOptions}
                branch
              />
            </DayContainer>
            <DayContainer>
              <label>ב</label>
              <Field
                name="monday"
                component={DefaultSelect}
                placeholder={t("createVehicle.branch")}
                options={branchOptions}
                branch
              />
            </DayContainer>
            <DayContainer>
              <label>ג</label>
              <Field
                name="tuesday"
                component={DefaultSelect}
                placeholder={t("createVehicle.branch")}
                options={branchOptions}
                branch
              />
            </DayContainer>
            <DayContainer>
              <label>ד</label>
              <Field
                name="wednesday"
                component={DefaultSelect}
                placeholder={t("createVehicle.branch")}
                options={branchOptions}
                branch
              />
            </DayContainer>
            <DayContainer>
              <label>ה</label>
              <Field
                name="thursday"
                component={DefaultSelect}
                placeholder={t("createVehicle.branch")}
                options={branchOptions}
                branch
              />
            </DayContainer>
            <DayContainer>
              <label>ו</label>
              <Field
                name="friday"
                component={DefaultSelect}
                placeholder={t("createVehicle.branch")}
                options={branchOptions}
                branch
              />
            </DayContainer>
            <DayContainer>
              <label>ש</label>
              <Field
                name="saturday"
                component={DefaultSelect}
                placeholder={t("createVehicle.branch")}
                options={branchOptions}
                branch
              />
            </DayContainer>
          </RowFieldsContainer>
        </Fields>
        <ButtonContainer>
          <Button type="submit">{t("buttons.save")}</Button>
          <Button
            type="button"
            reset
            onClick={() => {
              vehicleData.active === 1
                ? openQuestionModal(false)
                : setActiveOrInactive();
            }}
          >
            {vehicleData.active === 1
              ? t("buttons.inactive")
              : t("buttons.active")}
          </Button>
          <Button type="button" onClick={() => openQuestionModal(true)}>
            {t("buttons.delete")}
          </Button>
        </ButtonContainer>
      </Body>
    </SivanModal>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isPending: state.StudentsReducer.isPending,
    branchOptions: ownProps.vehicleData.branchOptions,
    licenseTypes: state.LicenseTypesReducer.licenseTypes,
    initialValues: {
      ...ownProps.vehicleData,
      comprehensiveInsurance: ownProps.vehicleData.comprehensiveInsurance
        ? new Date(ownProps.vehicleData.comprehensiveInsurance)
        : null,
      insurance: ownProps.vehicleData.insurance
        ? new Date(ownProps.vehicleData.comprehensiveInsurance)
        : null,
      carLicense: ownProps.vehicleData.carLicense
        ? new Date(ownProps.vehicleData.carLicense)
        : null,
      licenseType:
        state.LicenseTypesReducer.licenseTypes.length > 0
          ? {
              value: ownProps.vehicleData.licenseType,
              label: state.LicenseTypesReducer.licenseTypes.find(
                (x) => x.id.toString() === ownProps.vehicleData.licenseType
              ).name,
            }
          : null,
    },
  };
}

export default connect(mapStateToProps, {
  updateVehicle,
  setVehicleDisabled,
  deleteVehicle,
  getLicenseTypes,
  change,
})(
  reduxForm({
    form: "editVehicle",
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize: true, // this is needed!!
    validate,
  })(EditVehicle)
);
