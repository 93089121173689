import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, FieldText, Field } from "./InactiveStudents.styles";
import Table from "../../SivanPlusTable/SivanPlusTable";
import { connect } from "react-redux";
import { allActiveStudentsTextbooks } from "../../../actions/studentesActions";
import {
  getAllSchoolEvents,
  getLastStudentsEvent,
} from "../../../actions/schedulerActions";
import i18n from "../../../lib/i18n/i18n";
import DatePicker from "react-datepicker";
import _ from "lodash";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";
const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;
let columns = [
  {
    Header: "שם",
    accessor: "firstName",
  },
  {
    Header: "שם משפחה",
    accessor: "lastName",
  },
  {
    Header: "טלפון",
    accessor: "phone",
  },
  {
    Header: "תז",
    accessor: "studentId",
  },
  {
    Header: "נוצר על ידי",
    accessor: "createdBy",
  },
  {
    Header: "רישיון",
    accessor: "licenseType",
  },
  {
    Header: "מורה",
    accessor: "teacherName",
  },
  {
    Header: "אירוע אחרון",
    accessor: "start",
    Cell: ({ row: { original } }) => {
      const date = original.start
        ? moment(original.start).format("DD/MM/YYYY")
        : "";
      return date;
    },
  },
];

const InactiveStudents = ({
  getAllSchoolEvents,
  allActiveStudentsTextbooks,
  getLastStudentsEvent,
  history,
}) => {
  const [endDate, setEndDate] = useState(new Date());
  const [tableData, setTableData] = useState([]);
  const [studentsTextBooks, setStudentsTextBooks] = useState([]);

  useEffect(() => {
    (async () => {
      const students = await allActiveStudentsTextbooks();
      setStudentsTextBooks(students.filter((x) => x.active === 1));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (studentsTextBooks.length > 0) {
        const testEvents = await getAllSchoolEvents(
          new Date(new Date(endDate).setDate(new Date(endDate).getDate() - 7)),
          endDate
        );

        const buba = _.uniqBy(testEvents, "textBookId");
        const data = [];
        for (let index = 0; index < studentsTextBooks.length; index++) {
          const element = studentsTextBooks[index];
          if (!buba.some((elem) => elem.textBookId === element.textbookId)) {
            data.push(element);
          }
        }
        const textBookIds = data
          .filter((x) => x.textbookId !== null)
          .map((x) => x.textbookId.toString());
        const studentLastEventsByTextBookId = await getLastStudentsEvent(
          textBookIds
        );

        setTableData(
          data.map((t1) => ({
            ...t1,
            start:
              studentLastEventsByTextBookId.find(
                (t2) => t2.textBookId === t1.textbookId
              )?.start || null,
            testStatus:
              studentLastEventsByTextBookId.find(
                (t2) => t2.textBookId === t1.textbookId
              )?.testStatus || null,
          }))
        );
      }
    })();
  }, [endDate, studentsTextBooks]);

  const handlePrint = useReactToPrint({
    content: () => document.getElementById("table-to-xls"),
  });

  const onRowClick = async (rowObject) => {
    if (
      rowObject.column.id === "firstName" ||
      rowObject.column.id === "lastName"
    ) {
      history.push(`/updateStudent`, {
        studentData: rowObject.original,
        view: dictionary.editStudent.ticket,
      });
    }
  };

  return (
    <Container>
      <Row>
        <Field>
          <FieldText>שבוע אחורה מ</FieldText>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            locale={i18n.language}
            dateFormat="dd/MM/yyyy"
          />
        </Field>
      </Row>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="table-to-xls"
        filename={`דוח תלמידים לא פעילים ${moment(endDate).format(
          "DD/MM/YYYY"
        )}`}
        sheet="דוח תלמידים לא פעילים"
        buttonText="יצא אקסל"
      />
      <div style={{ cursor: "pointer" }} onClick={handlePrint}>
        <FieldText>הדפס &nbsp;</FieldText>
        <i className="fa fa-print" aria-hidden="true" />
      </div>
      <Table
        data={tableData.filter(
          (x) =>
            x.testStatus !== 1 &&
            moment(new Date(x.start)).isBefore(new Date(), "days")
        )}
        onRowClick={onRowClick}
        componentColumns={columns}
      />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isLoggedIn: state.session.isLoggedIn,
    userId: state.session.user.id,
    user: state.session.user,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getAllSchoolEvents,
    allActiveStudentsTextbooks,
    getLastStudentsEvent,
  })(InactiveStudents)
);
