import { useEffect, useState } from "react";
import { optionsArrrayWithOneParam } from "../../utils/buildOptionsArray";

const useFundingBodies = (isLoaded, getSchoolFundingBodies, fundingBodies) => {
  const [fundingBodiesOptions, setFundingBodiesOptions] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        if (!isLoaded) await getSchoolFundingBodies();
        else {
          setFundingBodiesOptions(
            optionsArrrayWithOneParam(
              [{ id: 999, name: "ללא" }, ...fundingBodies],
              "id",
              "name"
            )
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, [isLoaded]);
  return fundingBodiesOptions;
};

export default useFundingBodies;
