export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_CREATE_START = "USER_CREATE_START";
export const USER_SIGN_IN_START = "USER_SIGN_IN_START";
export const USER_SIGN_IN_SUCCESS = "USER_SIGN_IN_SUCCESS";
export const USER_SIGN_IN_FAILED = "USER_SIGN_IN_FAILED";
export const USER_LOGOUT_FAILED = "USER_LOGOUT_FAILED";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_STARTED = "USER_LOGOUT_STARTED";

export const CLEAR_SESSION = "CLEAR_SESSION";
export const SET_SESSION = "SET_SESSION";
export const UPDATE_SESSION = "UPDATE_SESSION";

// Students
export const STUDENT_CREATE_SUCCESS = "STUDENT_CREATE_SUCCESS";
export const STUDENT_CREATE_FAILED = "STUDENT_CREATE_FAILED";
export const STUDENT_CREATE_STARTED = "STUDENT_CREATE_STARTED";
export const STUDENT_FETCH_SUCCESS = "STUDENT_FETCH_SUCCESS";
export const STUDENT_UPDATE_SUCCESS = "STUDENT_UPDATE_SUCCESS";
export const STUDENT_DELETE_SUCCESS = "STUDENT_DELETE_SUCCESS";

// Vehicles
export const VEHICLE_DELETE_SUCCESS = "VEHICLE_DELETE_SUCCESS";
export const VEHICLE_UPDATE_SUCCESS = "VEHICLE_UPDATE_SUCCESS";
export const VEHICLE_CREATE_SUCCESS = "VEHICLE_CREATE_SUCCESS";
export const VEHICLE_CREATE_FAILED = "VEHICLE_CREATE_FAILED";
export const VEHICLE_CREATE_START = "VEHICLE_CREATE_START";
export const VEHICLE_FETCH_SUCCESS = "VEHICLE_FETCH_SUCCESS";
export const VEHICLE_FETCH_STARTED = "VEHICLE_FETCH_STARTED";
export const VEHICLE_FETCH_FAILED = "VEHICLE_FETCH_FAILED";

// License Types
export const LICENSE_TYPES_FETCH_SUCCESS = "LICENSE_TYPES_FETCH_SUCCESS";
export const LICENSE_TYPES_FETCH_STARTED = "LICENSE_TYPES_FETCH_STARTED";
export const LICENSE_TYPES_FETCH_FAILED = "LICENSE_TYPES_FETCH_FAILED";

// Users Types
export const USER_FETCH_FAILED = "USER_FETCH_FAILED";
export const USER_FETCH_SUCCESS = "USER_FETCH_SUCCESS";
export const USER_FETCH_STARTED = "USER_FETCH_STARTED";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";

// Agreements Types
export const AGREEMENTS_FETCH_FAILED = "AGREEMENTS_FETCH_FAILED";
export const AGREEMENTS_FETCH_SUCCESS = "AGREEMENTS_FETCH_SUCCESS";
export const AGREEMENTS_FETCH_STARTED = "AGREEMENTS_FETCH_STARTED";
export const AGREEMENT_CREATE_SUCCESS = "AGREEMENT_CREATE_SUCCESS";
export const AGREEMENT_UPDATE_SUCCESS = "AGREEMENT_UPDATE_SUCCESS";
export const AGREEMENT_DELETE_SUCCESS = "AGREEMENT_DELETE_SUCCESS";

// Learning Assignments Types
export const LEARNING_ASSIGNMENTS_FETCH_SUCCESS =
  "LEARNING_ASSIGNMENTS_FETCH_SUCCESS";
export const LEARNING_ASSIGNMENTS_FETCH_FAILED =
  "LEARNING_ASSIGNMENTS_FETCH_FAILED";
export const LEARNING_ASSIGNMENTS_FETCH_STARTED =
  "LEARNING_ASSIGNMENTS_FETCH_STARTED";

// Pickup Locations Types
export const PICKUP_LOCATIONS_FETCH_FAILED = "PICKUP_LOCATIONS_FETCH_FAILED";
export const PICKUP_LOCATIONS_FETCH_SUCCESS = "PICKUP_LOCATIONS_FETCH_SUCCESS";
export const PICKUP_LOCATIONS_FETCH_STARTED = "PICKUP_LOCATIONS_FETCH_STARTED";
export const PICKUP_LOCATION_CREATE_SUCCESS = "PICKUP_LOCATION_CREATE_SUCCESS";
export const PICKUP_LOCATION_DELETE_SUCCESS = "PICKUP_LOCATION_DELETE_SUCCESS";

// Calendar Types
export const EVENTS_FETCH_FAILED = "EVENTS_FETCH_FAILED";
export const EVENTS_FETCH_SUCCESS = "EVENTS_FETCH_SUCCESS";
export const EVENTS_FETCH_HOLIDAYS_SUCCESS = "EVENTS_FETCH_HOLIDAYS_SUCCESS";
export const EVENTS_FETCH_STARTED = "EVENTS_FETCH_STARTED";

export const NEW_EVENT_FAILED = "NEW_EVENT_FAILED";
export const NEW_EVENT_SUCCESS = "NEW_EVENT_SUCCESS";
export const NEW_EVENT_STARTED = "NEW_EVENT_STARTED";

export const EDIT_EVENT_SUCCESS = "EDIT_EVENT_SUCCESS";
export const EDIT_EVENT_FIELD_SUCCESS = "EDIT_EVENT_FIELD_SUCCESS";

export const HIDE_EVENT = "HIDE_EVENT";

// Driving Permits Types
export const DRIVING_PERMITS_FETCH_FAILED = "DRIVING_PERMITS_FETCH_FAILED";
export const DRIVING_PERMITS_FETCH_SUCCESS = "DRIVING_PERMITS_FETCH_SUCCESS";
export const DRIVING_PERMITS_FETCH_STARTED = "DRIVING_PERMITS_FETCH_STARTED";
export const DRIVING_PERMIT_CREATE_SUCCESS = "DRIVING_PERMIT_CREATE_SUCCESS";
export const DRIVING_PERMIT_DELETE_SUCCESS = "DRIVING_PERMIT_DELETE_SUCCESS";

// Funding Body Types
export const FUNDING_BODIES_FETCH_FAILED = "FUNDING_BODIES_FETCH_FAILED";
export const FUNDING_BODIES_FETCH_SUCCESS = "FUNDING_BODIES_FETCH_SUCCESS";
export const FUNDING_BODIES_FETCH_STARTED = "FUNDING_BODIES_FETCH_STARTED";
export const FUNDING_BODY_CREATE_SUCCESS = "FUNDING_BODY_CREATE_SUCCESS";
export const FUNDING_BODY_DELETE_SUCCESS = "FUNDING_BODY_DELETE_SUCCESS";
export const FUNDING_BODY_UPDATE_SUCCESS = "FUNDING_BODY_UPDATE_SUCCESS";

// Clients Types
export const CLIENTS_FETCH_FAILED = "CLIENTS_FETCH_FAILED";
export const CLIENTS_FETCH_SUCCESS = "CLIENTS_FETCH_SUCCESS";
export const CLIENTS_FETCH_STARTED = "CLIENTS_FETCH_STARTED";
export const CLIENT_CREATE_SUCCESS = "CLIENT_CREATE_SUCCESS";
export const CLIENT_DELETE_SUCCESS = "CLIENT_DELETE_SUCCESS";
export const CLIENT_UPDATE_SUCCESS = "CLIENT_UPDATE_SUCCESS";

// Providers Types
export const PROVIDERS_FETCH_FAILED = "PROVIDERS_FETCH_FAILED";
export const PROVIDERS_FETCH_SUCCESS = "PROVIDERS_FETCH_SUCCESS";
export const PROVIDERS_FETCH_STARTED = "PROVIDERS_FETCH_STARTED";
export const PROVIDER_CREATE_SUCCESS = "PROVIDER_CREATE_SUCCESS";
export const PROVIDER_DELETE_SUCCESS = "PROVIDER_DELETE_SUCCESS";
export const PROVIDER_UPDATE_SUCCESS = "PROVIDER_UPDATE_SUCCESS";

// Expense Types Types
export const EXPENSE_TYPES_FETCH_FAILED = "EXPENSE_TYPES_FETCH_FAILED";
export const EXPENSE_TYPES_FETCH_SUCCESS = "EXPENSE_TYPES_FETCH_SUCCESS";
export const EXPENSE_TYPES_FETCH_STARTED = "EXPENSE_TYPES_FETCH_STARTED";
export const EXPENSE_TYPE_CREATE_SUCCESS = "EXPENSE_TYPE_CREATE_SUCCESS";
export const EXPENSE_TYPE_DELETE_SUCCESS = "EXPENSE_TYPE_DELETE_SUCCESS";
export const EXPENSE_TYPE_UPDATE_SUCCESS = "EXPENSE_TYPE_UPDATE_SUCCESS";

// professional Teachers Types
export const PROFESSIONAL_TEACHERS_FETCH_FAILED =
  "PROFESSIONAL_TEACHERS_FETCH_FAILED";
export const PROFESSIONAL_TEACHERS_FETCH_SUCCESS =
  "PROFESSIONAL_TEACHERS_FETCH_SUCCESS";
export const PROFESSIONAL_TEACHERS_FETCH_STARTED =
  "PROFESSIONAL_TEACHERS_FETCH_STARTED";
export const PROFESSIONAL_TEACHER_CREATE_SUCCESS =
  "PROFESSIONAL_TEACHER_CREATE_SUCCESS";
export const PROFESSIONAL_TEACHER_DELETE_SUCCESS =
  "PROFESSIONAL_TEACHER_DELETE_SUCCESS";
export const PROFESSIONAL_TEACHER_UPDATE_SUCCESS =
  "PROFESSIONAL_TEACHER_UPDATE_SUCCESS";

// Testers Types
export const TESTERS_FETCH_FAILED = "TESTERS_FETCH_FAILED";
export const TESTERS_FETCH_SUCCESS = "TESTERS_FETCH_SUCCESS";
export const TESTERS_FETCH_STARTED = "TESTERS_FETCH_STARTED";
export const TESTER_CREATE_SUCCESS = "TESTER_CREATE_SUCCESS";
export const TESTER_DELETE_SUCCESS = "TESTER_DELETE_SUCCESS";
export const TESTER_UPDATE_SUCCESS = "TESTER_UPDATE_SUCCESS";

// Expenses Types
export const EXPENSES_FETCH_FAILED = "EXPENSES_FETCH_FAILED";
export const EXPENSES_FETCH_SUCCESS = "EXPENSES_FETCH_SUCCESS";
export const EXPENSES_FETCH_STARTED = "EXPENSES_FETCH_STARTED";
export const EXPENSE_CREATE_SUCCESS = "EXPENSE_CREATE_SUCCESS";
export const EXPENSE_DELETE_SUCCESS = "EXPENSE_DELETE_SUCCESS";
export const EXPENSE_UPDATE_SUCCESS = "EXPENSE_UPDATE_SUCCESS";

// Incomes Types
export const INCOMES_FETCH_FAILED = "INCOMES_FETCH_FAILED";
export const INCOMES_FETCH_SUCCESS = "INCOMES_FETCH_SUCCESS";
export const INCOMES_FETCH_STARTED = "INCOMES_FETCH_STARTED";
export const INCOME_CREATE_SUCCESS = "INCOME_CREATE_SUCCESS";
export const INCOME_DELETE_SUCCESS = "INCOME_DELETE_SUCCESS";
export const INCOME_UPDATE_SUCCESS = "INCOME_UPDATE_SUCCESS";
export const INCOMES_FUNDING_BODIES_FETCH_SUCCESS =
  "INCOMES_FUNDING_BODIES_FETCH_SUCCESS";
