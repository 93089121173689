import styled from "styled-components";
import i18n from "../../../../../lib/i18n/i18n";
import { isMobile } from "react-device-detect";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const Body = styled.form`
  width: 100%;
  text-align: center;

  .react-datepicker-wrapper:after {
    font-family: "FontAwesome";
    position: relative;
    content: "\f133";
  }

  .react-datepicker-wrapper {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    margin-top: 1vh;
    width: 100%;
    height: 6vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__input-container input {
    width: 100%;
    ${isHebrew ? "padding-right" : "padding-left"}: 1vw;
    direction: ${isHebrew ? "rtl" : "ltr"};

    &::placeholder {
      color: #c2c2c2;
    }
  }
`;

export const InvoiceCompanyNameHeader = styled.span`
  font-size: 3vh;
`;

export const InvoiceType = styled.span`
  font-size: 2vh;
`;

export const InvoiceStatus = styled.span``;

export const Line = styled.hr`
  border-top: 2px solid dimgrey;
  width: 100%;
`;

export const InvoiceBody = styled.div``;
export const InvoiceHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: bold;
  direction: rtl;
`;

export const InvoiceContainer = styled.div`
  font-family: arial;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Table = styled.table`
  width: 100%;
  margin-top: 0.5vh;
  direction: rtl;
`;

export const TableHeader = styled.th`
  text-align: center;
  ${(props) => props.alignRight && "text-align:right"};
`;

export const TableCell = styled.td`
  border: 3px solid white;
  background: ${(props) => (props.specialBackground ? "whitesmoke" : "")};
  text-align: ${(props) => (props.alignLeft ? "left" : "center")};
  ${(props) => props.alignRight && "text-align:right"};
  font-weight: ${(props) => (props.bold ? "bold" : "")};
`;

export const TableRow = styled.tr`
  height: 3vh;
`;

export const PrintFooter = styled.span`
  text-align: center;
  display: block;
  font-size: 18px;
  font-weight: bold;
  bottom: 0;
  width: 99%;
`;

export const ClientData = styled.div`
  direction: rtl;
  display: flex;
  flex-direction: column;
`;

export const ClientDataText = styled.span`
  text-align: right;
  font-size: 1.5vh;
  margin-top: 0.5vh;
  font-weight: bold;
`;

export const NotesContainer = styled.div``;

export const NotesText = styled.span``;
