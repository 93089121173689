import styled from "styled-components";
import i18n from "../../../lib/i18n/i18n";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const Container = styled.div`
  padding: 1rem;
`;

export const Title = styled.div`
  display: block;
  height: 9vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${isHebrew ? "row-reverse" : "row"};
`;

export const TitleText = styled.span`
  font-size: 2.5vh;
  font-weight: bold;
  color: #404041;
`;

export const CreateButton = styled.button`
  border-radius: 5px;
  color: white;
  height: 3vw;
  width: 8vw;
  background-image: linear-gradient(to bottom, #ebd794, #d2ab51);
  font-size: 15pt;
`;
