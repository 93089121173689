import React from "react";
import { Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import Amplify from "aws-amplify";
import awsconfig from "../../aws-exports";
import { ErrorBoundary } from "react-error-boundary";
// import Callback from "../Callback/Callback";
import Routes from "./routes";
import GlobalStyles from "./styles/global";
import "font-awesome/css/font-awesome.min.css";
import { ToastContainer, toast } from "react-toastify";
import { registerLocale } from "react-datepicker";
import "react-toggle/style.css";
import "react-toastify/dist/ReactToastify.css";
import he from "date-fns/locale/he"; // the locale you want
registerLocale("he", he); // register it with the name you want

Amplify.configure(awsconfig);

const history = createBrowserHistory();
function ErrorFallback({ error, componentStack, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <pre>{componentStack}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}
function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Router history={history}>
        {/* <Route exact path="/callback" component={Callback} /> */}
        <Routes />
        <GlobalStyles />
        <ToastContainer position={toast.POSITION.TOP_CENTER} />
      </Router>
    </ErrorBoundary>
  );
}

export default App;
