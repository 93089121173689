import styled from "styled-components";
import i18n from "../../../lib/i18n/i18n";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const Container = styled.div`
  direction: rtl;

  .error {
    border: 1px solid red !important;
    border-radius: 4px;
  }
`;

export const Title = styled.span`
  font-size: 22pt;
  font-weight: bold;
  color: #404041;
  display: block;
  height: 7vh;
`;

export const Body = styled.form`
  height: 75vh;
  width: 100%;
  background-color: white;
  border: 1px solid #ffefca;
  border-radius: 5px;
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  padding: 3vh;
  align-items: center;
`;

export const SectionTitle = styled.span`
  font-size: 18pt;
  color: #404041;
  height: 7vh;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

export const CreateButton = styled.button`
  border-radius: 5px;
  color: white;
  height: 3vw;
  width: 8vw;
  background-image: linear-gradient(to bottom, #ebd794, #d2ab51);
  font-size: 15pt;
  margin-top: 3vh;
`;
