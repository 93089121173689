import expensesApi from "../apis/expenses";
import {
  EXPENSES_FETCH_FAILED,
  EXPENSES_FETCH_SUCCESS,
  EXPENSES_FETCH_STARTED,
  EXPENSE_CREATE_SUCCESS,
  EXPENSE_DELETE_SUCCESS,
  EXPENSE_UPDATE_SUCCESS,
} from "../constants/actionTypes";
import { toast } from "react-toastify";

export const create = (expense) => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const { branchId, schoolId } = getState().session.user;

    const response = await expensesApi.post(
      "/create",
      {
        expense: { ...expense, schoolId },
      },
      {
        headers,
      }
    );
    dispatch({
      type: EXPENSE_CREATE_SUCCESS,
      payload: { ...expense, id: response.data.id, isActive: 1 },
    });
  } catch (error) {
    toast.error("כישלון ביצירת הוצאה");
  }
};

export const getSchoolExpenses = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: EXPENSES_FETCH_STARTED,
    });
    const token = window.localStorage.getItem("token");
    const { branchId, schoolId } = getState().session.user;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await expensesApi.get(`/getAll/${schoolId}`, {
      headers,
    });

    dispatch({
      type: EXPENSES_FETCH_SUCCESS,
      payload: response.data,
    });

    return response.data;
  } catch (error) {
    dispatch({
      type: EXPENSES_FETCH_FAILED,
    });
  }
};

export const update = (expense) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await expensesApi.patch(
      "/update",
      {
        expense,
      },
      {
        headers,
      }
    );

    dispatch({
      type: EXPENSE_UPDATE_SUCCESS,
      payload: { ...expense },
    });
  } catch (error) {
    toast.error("כישלון בעדכון הוצאה");
  }
};

export const deleteItem = (expenseId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await expensesApi.delete(
      "/delete",
      {
        data: { expenseId },
      },
      {
        headers,
      }
    );

    dispatch({
      type: EXPENSE_DELETE_SUCCESS,
      payload: { id: expenseId },
    });
  } catch (error) {
    toast.error("כישלון במחיקת הוצאה");
  }
};
