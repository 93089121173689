import { useEffect, useState } from "react";
import { optionsArrrayWithTwoParams } from "../../utils/buildOptionsArray";

const useTeachers = (isUsersLoaded, getUsers, users) => {
  const [teacherssOptions, setTeacherssOptions] = useState([]);
  useEffect(() => {
    async function fetchData() {
      if (!isUsersLoaded) await getUsers();
      else {
        setTeacherssOptions(
          optionsArrrayWithTwoParams(users, "userId", "firstName", "lastName")
        );
      }
    }
    fetchData();
  }, [isUsersLoaded]);
  return teacherssOptions;
};

export default useTeachers;
