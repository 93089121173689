import React, { useState, useEffect } from "react";
import ErrorModal from "../../../../Modal/ErrorModal";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, change } from "redux-form";
import validate from "./validate";
import { useTranslation } from "react-i18next";
import SivanModal from "../../../../Modal/NewModal";
import { Body, Section, Row } from "./updateCharge.styles";
import { getVehicles, getVehicle } from "../../../../../actions/vehicleActions";
import useVehicles from "../../../../useComponents/useVehicles";
import UseLoadStudents from "../../../../useComponents/UseLoadStudents";
import {
  getStudents,
  getStudent,
} from "../../../../../actions/studentesActions";
import useTeacheres from "../../../../useComponents/useTeachers";
import { getUsers } from "../../../../../actions/userActions";
import {
  getStudentTextBooks,
  fetchTextBook,
} from "../../../../../actions/textBooksActions";
import { update } from "../../../../../actions/discountsAndObligationsActions";
import {
  create,
  getDocumentNumber,
  updateOne,
} from "../../../../../actions/incomesAction";
import { create as createObligoOrDiscount } from "../../../../../actions/discountsAndObligationsActions";
import CreateButton from "../../../../UiComponents/Buttons/Default";
import { getAll } from "../../../../../actions/drivingPermits";
import DefaultSelect from "../../../../UiComponents/Select/Default";

const NewEvent = ({
  isNewAgreementModalOpen,
  setNewAgreementModalIsOpen,
  handleSubmit,
  submitting,
  isStudentsLoaded,
  getStudents,
  getStudentTextBooks,
  change,
  studentId,
  isUsersLoaded,
  isVehiclesLoaded,
  users,
  vehicles,
  getVehicles,
  getUsers,
  textbookId,
  updateOne,
  update,
  income,
}) => {
  const { t, i18n } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);
  const [allStudentTextBookData, setAllStudentTextBookData] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalText, setErrorModalText] = useState("");
  const [providerData, setProviderData] = useState(false);
  const [textbookOptions, setTextbookOptions] = useState(false);
  const [isProviderDetailsModalOpen, setProviderDetailsModalOpen] = useState(
    false
  );
  const teachersOptions = useTeacheres(isUsersLoaded, getUsers, users);
  const vehicleOptions = useVehicles(isVehiclesLoaded, getVehicles, vehicles);

  UseLoadStudents(isStudentsLoaded, getStudents);

  useEffect(() => {
    if (!isProviderDetailsModalOpen && typeof studentId !== "undefined") {
      setNewAgreementModalIsOpen(false);
    }
  }, [isProviderDetailsModalOpen]);

  useEffect(() => {
    if (typeof studentId !== "undefined")
      (async () => {
        const fetchedStundetTextBooks = await getStudentTextBooks(studentId);
        if (fetchedStundetTextBooks.length === 0) {
          setIsErrorModalOpen(true);
          setErrorModalText("חסר לתלמיד תיק לימוד");
        } else {
          setAllStudentTextBookData(fetchedStundetTextBooks);
          const options = fetchedStundetTextBooks.map((x) => ({
            value: x.textBookId,
            label: x.description,
          }));
          setTextbookOptions(options);
          if (options.length === 1) change("textbookId", options[0]);
        }
      })();
  }, [studentId]);

  useEffect(() => {
    const textbook = allStudentTextBookData.find(
      (x) => x.textBookId === textbookId.value
    );
    const teacher = teachersOptions.find(
      (x) => x.value.toString() === textbook.teacherId.toString()
    );
    const vehicle = vehicleOptions.find(
      (x) => x.value.toString() === textbook.vehicleId.toString()
    );
    change("teacherId", teacher);
    change("vehicleId", vehicle);
  }, [textbookId]);

  const fundingBodyCreateAction = async (formValues) => {
    if (income.isDiscount) {
      await update({
        id: formValues.id,
        vehicleId: formValues.vehicleId.value.toString(),
        textbookId: formValues.textbookId.value.toString(),
        teacherId: formValues.teacherId.value.toString(),
      });
    } else {
      await updateOne(
        formValues.id,
        formValues.vehicleId.value,
        formValues.textbookId.value,
        formValues.teacherId.value
      );
    }

    setNewAgreementModalIsOpen(false);
  };

  return (
    <SivanModal
      titleText="עדכון מסמך כספי לתלמיד"
      modalIsOpen={isNewAgreementModalOpen}
      closeModal={() => setNewAgreementModalIsOpen(false)}
      isLoading={isLoading}
    >
      {isErrorModalOpen && (
        <ErrorModal
          modalIsOpen={isErrorModalOpen}
          closeModal={() => setIsErrorModalOpen(false)}
          text={errorModalText}
        />
      )}
      <Body onSubmit={handleSubmit(fundingBodyCreateAction)}>
        <div style={{ width: "100%", maxHeight: "80vh" }}>
          <Section basicData noBorder>
            {/* <Row>
              <Field
                name="documentType"
                component={DefaultSelect}
                placeholder="סוג"
                options={documentTypes}
              />
              <Field
                name="clientId"
                component={DefaultSelect}
                options={suggestions}
                placeholder="בחר תלמיד"
              />
              <Field
                name="date"
                component={DefaultDateTimePicker}
                placeholder="תאריך ושעה"
              />
              {documentType?.value !== 6 && documentType?.value !== 7 && (
                <Field
                  name="vat"
                  component={DefaultWrappedWithField}
                  placeholder="מע״מ"
                  disabled
                />
              )}
              {documentType?.value !== 6 && documentType?.value !== 7 && (
                <Field
                  name="documentNumber"
                  component={DefaultWrappedWithField}
                  placeholder="מספר מסמך"
                  type="number"
                  disabled
                />
              )}
            </Row> */}
            <Row>
              <Field
                name="textbookId"
                component={DefaultSelect}
                placeholder="תיק לימוד"
                options={textbookOptions}
              />
              <Field
                name="teacherId"
                component={DefaultSelect}
                placeholder="מורה"
                options={teachersOptions}
              />
              <Field
                name="vehicleId"
                component={DefaultSelect}
                placeholder="רכב"
                options={vehicleOptions}
              />
              {/* {documentType?.value !== 6 && documentType?.value !== 7 && (
                <Field
                  name="description"
                  component={DefaultWrappedWithField}
                  placeholder="עבור"
                />
              )}
              <Field name="notes" component={NotesInput} placeholder="הערות" /> */}
            </Row>
          </Section>
        </div>
        <CreateButton
          type="submit"
          disabled={submitting}
          text="עדכון"
          marginTop={2}
        />
      </Body>
    </SivanModal>
  );
};
const selector = formValueSelector("newCharge"); // <-- same as form name

function mapStateToProps(state, ownProps) {
  const { id, clientId } = ownProps.income;
  return {
    isAllowedInvoice: state.session.user.isAllowedInvoice,
    isAllowedReceipt: state.session.user.isAllowedReceipt,
    isAllowedInvoiceReceipt: state.session.user.isAllowedInvoiceReceipt,
    isAllowedCreditReceipt: state.session.user.isAllowedCreditReceipt,
    isAllowedCreditInvoiceReceipt:
      state.session.user.isAllowedCreditInvoiceReceipt,
    isAllowedCreditInvoice: state.session.user.isAllowedCreditInvoice,
    isAllowedDiscount: state.session.user.isAllowedDiscount,
    isAllowedBilling: state.session.user.isAllowedBilling,
    vehicles: state.VehiclesReducer.vehicles,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    isUsersLoaded: state.UsersReducer.isLoaded,
    user: state.session.user,
    users: state.UsersReducer.users,
    documentType: selector(state, "documentType"),
    paymentMethod: selector(state, "paymentMethod"),
    textbookId: selector(state, "textbookId"),
    studentId: selector(state, "clientId"),
    students: state.StudentsReducer.students,
    isStudentsLoaded: state.StudentsReducer.isLoaded,
    initialValues: {
      id,
      clientId,
    },
  };
}

export default connect(mapStateToProps, {
  getVehicles,
  getStudents,
  getUsers,
  getStudentTextBooks,
  change,
  fetchTextBook,
  getVehicle,
  getStudent,
  getAll,
  create,
  getDocumentNumber,
  createObligoOrDiscount,
  updateOne,
  update,
})(
  reduxForm({
    form: "newCharge",
    destroyOnUnmount: true, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
  })(NewEvent)
);
