import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import SivanModal from "../../../Modal/Modal";
import { Body, Section, Row } from "./newProvider.styles";
import { create } from "../../../../actions/providersActions";
import CreateButton from "../../../UiComponents/Buttons/Default";
import InputWrappedWithField from "../../../UiComponents/Inputs/DefaultWrappedWithField";
import NotesInput from "../../../UiComponents/TextArea/Default";

const NewFundingBodies = ({
  isModalOpen,
  setIsModalOpen,
  handleSubmit,
  create,
}) => {
  const fundingBodyCreateAction = async (formValues) => {
    await create(formValues);
    setIsModalOpen(false);
  };

  return (
    <SivanModal
      titleText="צור ספק"
      modalIsOpen={isModalOpen}
      closeModal={() => setIsModalOpen(false)}
    >
      <Body onSubmit={handleSubmit(fundingBodyCreateAction)}>
        <Section basicData noBorder>
          <Row>
            <Field
              name="name"
              component={InputWrappedWithField}
              placeholder="שם"
              type="text"
            />
            <Field
              name="providerId"
              component={InputWrappedWithField}
              placeholder="ת.ז/ע.מ/ח.פ."
              type="number"
            />
            <Field
              name="address"
              component={InputWrappedWithField}
              placeholder="כתובת"
              type="text"
            />
            <Field
              name="city"
              component={InputWrappedWithField}
              placeholder="עיר"
              type="text"
            />
            <Field
              name="postalCode"
              component={InputWrappedWithField}
              placeholder="מיקוד"
              type="number"
            />
          </Row>
          <Row>
            <Field
              name="phoneNumber"
              component={InputWrappedWithField}
              placeholder="טלפון"
              type="text"
            />
            <Field
              name="email"
              component={InputWrappedWithField}
              placeholder="מייל"
              type="email"
            />
            <Field
              name="contactName"
              component={InputWrappedWithField}
              placeholder="איש קשר"
              type="text"
            />
            <Field name="notes" component={NotesInput} placeholder="הערות" />
            <Field
              name="openingBalance"
              component={InputWrappedWithField}
              placeholder="יתרת פתיחה"
              type="number"
            />
          </Row>
        </Section>
        <CreateButton text="צור" type="submit" />
      </Body>
    </SivanModal>
  );
};

export default connect(null, { create })(
  reduxForm({
    form: "NewFundingBodies",
  })(NewFundingBodies)
);
