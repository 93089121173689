import React from "react";
import Modal from "react-modal";
import { Title, Circle, TitleText, CloseIcon } from "./modal.styles";
import FadeLoader from "react-spinners/FadeLoader";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "15vw",
    minHeight: "10vh",
    paddingTop: "0",
    border: "1px solid red",
    textAlign: "center",
  },
  overlay: { zIndex: 1080 },
};

Modal.setAppElement("#root");

const ErrorModal = ({ modalIsOpen, closeModal, text }) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      style={customStyles}
      contentLabel="Example Modal"
      onRequestClose={closeModal}
    >
      <Title>
        <TitleText>שגיאה</TitleText>
        <Circle onClick={closeModal}>
          <CloseIcon className="fa fa-close" />
        </Circle>
      </Title>
      {text}
    </Modal>
  );
};

export default ErrorModal;
