import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fffdf9;
  height: 100vh;
  align-items: center;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 3vw;
  width: 25vw;
  height: fit-content;
`;

export const BackgroundImage = styled.img`
  height: 100vh;
`;

export const Logo = styled.img`
  height: 15vh;
`;

export const Input = styled.input`
  border: 1px solid black;
  border-radius: 5px;
  width: 100%;
  height: 5vh;
  margin-top: 1vh;

  &::placeholder {
    padding-left: 1vw;
    color: black;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 49%
    );
  }
`;

export const LoginButton = styled.button`
  border-radius: 5px;
  color: white;
  height: 6vh;
  width: 100%;
  background-image: linear-gradient(to bottom, #ebd794, #d2ab51);
  font-size: 15pt;
  margin-top: 5vh;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
`;

export const SubHeader = styled.span`
  font-size: 17pt;
  color: #cdccc9;
  text-align: center;
  margin-top: 5vh;
`;

export const ForgotPassword = styled.span`
  font-size: 17pt;
  margin-top: 10vh;
  cursor: pointer;
`;

export const JoinUsText = styled.span`
  margin-top: 20vh;
  font-size: 17pt;
  color: #aeadab;
`;

export const ColoredText = styled.span`
  color: #cc9f3e;
  font-size: 18pt;
  cursor: pointer;
`;
