export default (values) => {
  const errors = {};
  if (!values.amount || parseInt(values.amount) <= 0) {
    errors.amount = "Required";
  }
  if (!values.clientId) {
    errors.clientId = "Required";
  }
  if (!values.teacherId) {
    errors.teacherId = "Required";
  }
  if (!values.vehicleId) {
    errors.vehicleId = "Required";
  }

  if (!values.textbookId) {
    errors.textbookId = "Required";
  }

  if (!values.date) {
    errors.date = "Required";
  }

  if (
    (values.documentType?.value === 6 || values.documentType?.value === 7) &&
    !values.notes
  ) {
    errors.notes = "Required";
  }
  return errors;
};
