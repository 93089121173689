import React, { useState, useEffect } from "react";
import Select from "react-select";
import i18n from "../../../../lib/i18n/i18n";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import "react-toggle/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import {
  Container,
  customStyles,
  StyledLoader,
  Icon,
  Row,
  Title,
  RankRow,
  RankText,
  IconContainer,
} from "./ranks.styles";
import { getLicenseTypes } from "../../../../actions/licenseTypes";
import {
  addLicenseType,
  getStudentLicenseType,
  deleteLicenseType,
} from "../../../../actions/studentesActions";
import useLicenseTypes from "../../../useComponents/useLicenseTypes";
import * as _ from "underscore";

const Ranks = ({
  getLicenseTypes,
  isPending,
  licenseTypes,
  addLicenseType,
  studentId,
  getStudentLicenseType,
  deleteLicenseType,
  isLoaded,
}) => {
  const { t, i18n } = useTranslation("common");
  const [studentLicenseTypesOptions, setStudentLicenseTypesOptions] = useState(
    licenseTypes
  );
  const [selectedOption, setSelectedOption] = useState();
  const [ranks, setRanks] = useState([]);
  const licenseTypesOptions = useLicenseTypes(
    isLoaded,
    getLicenseTypes,
    licenseTypes
  );

  useEffect(() => {
    (async () => {
      if (licenseTypes.length !== 0 && licenseTypesOptions.length !== 0) {
        const userLicenseRanks = await getStudentLicenseType(studentId);
        setStudentLicenseTypesOptions(licenseTypesOptions);
        setSelectedOption(licenseTypesOptions[0]);
        setRanks(
          userLicenseRanks.map((x) => {
            const licenseType = licenseTypesOptions.find(
              (l) => l.value === x.licenseRankId
            );
            return { label: licenseType.label, value: licenseType.value };
          })
        );
      }
    })();
  }, [isLoaded, licenseTypesOptions]);

  useEffect(() => {
    if (ranks.length === 0) return;
    filterLicenseTypesOptions();
  }, [ranks]);

  const handleChange = (selectedOption) => setSelectedOption(selectedOption);

  const filterLicenseTypesOptions = () => {
    const newRanksOptions = _.reject(licenseTypesOptions, function (item) {
      return _.find(ranks, function (o) {
        return o.value === item.value;
      });
    });
    setStudentLicenseTypesOptions(newRanksOptions);
    setSelectedOption(newRanksOptions[0]);
  };

  const addRank = async () => {
    if (await addLicenseType(studentId, selectedOption.value))
      setRanks([
        ...ranks,
        { value: selectedOption.value, label: selectedOption.label },
      ]);
  };

  const deleteRank = async (license) => {
    if (await deleteLicenseType(studentId, license.value))
      setRanks(ranks.filter((x) => x.label !== license.label));
  };

  const isHebrew = i18n.language === "he";

  return (
    <Container>
      <Row>
        <Icon className="fa fa-plus-square" onClick={addRank} />
        <Select
          isRtl={isHebrew}
          options={studentLicenseTypesOptions}
          value={selectedOption}
          placeholder="רישיון"
          styles={customStyles}
          onChange={handleChange}
        />
      </Row>
      <Title>דרגות רישיון נוכחיות</Title>
      {ranks.map((x) => (
        <RankRow key={x.value}>
          <RankText>{x.label}</RankText>
          <IconContainer>
            <Icon className="fa fa-info-circle" small />
            <Icon
              className="fa fa-trash-o"
              small
              onClick={() => deleteRank(x)}
            />
          </IconContainer>
        </RankRow>
      ))}
      <StyledLoader active={isPending} spinner text="Creating Student..." />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isPending: state.LicenseTypesReducer.isPending,
    licenseTypes: state.LicenseTypesReducer.licenseTypes,
    isLoaded: state.LicenseTypesReducer.isLoaded,
  };
}

export default connect(mapStateToProps, {
  getLicenseTypes,
  addLicenseType,
  getStudentLicenseType,
  deleteLicenseType,
})(Ranks);
