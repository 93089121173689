import React, { useState, useEffect } from "react";
import i18n from "../../../lib/i18n/i18n";
import { isMobile } from "react-device-detect";
import { reduxForm, Field, formValueSelector } from "redux-form";
import validate from "./validate";
import { connect } from "react-redux";
import { Container, FormContainer } from "./odoMeter.styles";
import useVehicles from "../../useComponents/useVehicles";
import Table from "../../SivanPlusTable/SivanPlusTable";
import { getVehicles } from "../../../actions/vehicleActions";
import InputWrappedWithField from "../../UiComponents/Inputs/DefaultWrappedWithField";
import DefaultSelect from "../../UiComponents/Select/Default";
import CreateButton from "../../UiComponents/Buttons/Default";
import DefaultDatePicker from "../../UiComponents/DatePicker/Default";
import { create, getAllVehicleReports } from "../../../actions/odometerActions";
import { DateColumnFilter } from "../../SivanPlusTable/filters";
import moment from "moment";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const odometerColumns = [
  {
    Header: "תאריך",
    accessor: "date",
    Cell: ({ row: { original } }) => moment(original.date).format("DD/MM/YYYY"),
    Filter: DateColumnFilter,
    filter: (array, s, date) => {
      return array.filter((x) => moment(x.original.date).isSame(date, "day"));
    },
  },
  {
    Header: "קילומטר",
    accessor: "km",
  },
  {
    Header: "הערה",
    accessor: "notes",
  },
];

const OdoMeter = ({
  vehicles,
  isVehiclesLoaded,
  getVehicles,
  handleSubmit,
  submitting,
  create,
  getAllVehicleReports,
  vehicleId,
}) => {
  const [vehicleReports, setVehicleReports] = useState([]);
  const [latestData, setLatestDate] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const vehicleOptions = useVehicles(isVehiclesLoaded, getVehicles, vehicles);

  useEffect(() => {
    async function fetchData() {
      if (typeof vehicleId !== "undefined") {
        const reports = await getAllVehicleReports(vehicleId.value);
        if (reports.length > 0) {
          setVehicleReports(reports);
          const last = reports.reduce((a, b) => {
            return new Date(a.date) > new Date(b.date) ? a : b;
          });
          setLatestDate(last);
          setIsDataLoaded(true);
        } else {
          setVehicleReports([]);
        }
      }
    }
    fetchData();
  }, [vehicleId]);

  const saveOrUpdateReport = async (formValues) => {
    await create(formValues);
    const index = vehicleReports.findIndex((x) =>
      moment(x.date).isSame(formValues.date, "day")
    );
    if (index === -1) {
      setVehicleReports([...vehicleReports, formValues]);
    } else {
      let copy = [...vehicleReports];
      copy[index] = formValues;
      setVehicleReports(copy);
    }
  };
  return (
    <Container>
      <FormContainer onSubmit={handleSubmit(saveOrUpdateReport)}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "50vw",
          }}
        >
          <Field
            name="date"
            component={DefaultDatePicker}
            placeholder="תאריך"
          />
          <Field
            name="vehicleId"
            component={DefaultSelect}
            placeholder="רכב"
            options={vehicleOptions}
          />
        </div>
        {isDataLoaded && (
          <div>
            דיווח אחרון {latestData.km} ק״מ בתאריך&nbsp;
            {moment(latestData.date).format("DD/MM/YYYY")}
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            width: !isMobile && "20vw",
          }}
        >
          <Field
            name="notes"
            component={InputWrappedWithField}
            placeholder="הערה"
            fullWidth={false}
          />
          <Field
            name="km"
            component={InputWrappedWithField}
            placeholder="קילומטר"
            type="number"
            fullWidth={false}
          />
        </div>
        <CreateButton
          type="submit"
          disabled={submitting}
          text="שמירה"
          marginTop={2}
        />
        <br />
      </FormContainer>
      <Table data={vehicleReports} componentColumns={odometerColumns} />
    </Container>
  );
};
const selector = formValueSelector("odoMeter"); // <-- same as form name

function mapStateToProps(state, ownProps) {
  return {
    vehicles: state.VehiclesReducer.vehicles,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    vehicleId: selector(state, "vehicleId"),
    initialValues: {
      date: new Date(),
    },
  };
}

export default connect(mapStateToProps, {
  getVehicles,
  create,
  getAllVehicleReports,
})(
  reduxForm({
    form: "odoMeter",
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
  })(OdoMeter)
);
