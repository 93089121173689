import styled from "styled-components";
import i18n from "../../../../../../lib/i18n/i18n";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .sigCanvas {
    border: 1px solid black;
    margin-top: 1vh;
  }
`;

export const ChapterContainer = styled.div``;

export const ChapterTitle = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

export const ChapterAssignments = styled.div`
  border: 0.5px solid #dadada;
  border-raduis: 3px;
`;
export const IconContainer = styled.div``;
export const AssignmentContainer = styled.div`
  padding: 1vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => (props.assignmentStatus === 1 ? "#00800094" : "")};
  background: ${(props) => (props.assignmentStatus === 2 ? "#ffa5006b" : "")};
`;

export const Button = styled.button`
  border-radius: 5px;
  height: 6vh;
  background: ${(props) =>
    props.reset ? "linear-gradient(to bottom, #ebd794, #d2ab51)" : "white"};
  border: ${(props) => (props.reset ? "" : "1px solid #e1c89d")};
  color: ${(props) => (props.reset ? "white" : "#e1c89d")};
  font-size: 15pt;
  margin-top: 5vh;
  font-weight: bolder;
  width: 45%;

  &:hover {
    background: ${(props) =>
      !props.reset ? "linear-gradient(to bottom, #ebd794, #d2ab51)" : "white"};
    border: ${(props) => (!props.reset ? "" : "1px solid #e1c89d")};
    color: ${(props) => (!props.reset ? "white" : "#e1c89d")};
  }
`;
